import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FaPause } from 'react-icons/lib/fa';
import CampaignEditModal from '../campaign/campaignEditModal';
import IconModalLauncher from './iconModalLauncher';

class PauseIconBtnModal extends Component {
	static propTypes = {
		campaignId: PropTypes.string,
		name: PropTypes.string,
		handler: PropTypes.func
	}

	constructor(props) {
		super(props);
	}

	render() {
		const { campaignId, name, handler } = this.props;

		return (
			<CampaignEditModal
					actionBtn={<button className="btn btn-danger" onClick={handler}>Pause</button>}
					action={handler}
					launchBtn={<IconModalLauncher><FaPause /></IconModalLauncher>}
					campaignId={campaignId}
					title="Pause Campaign"
					message={`Are you sure you want to pause ${name}? This Campaign will be redirected to the default link.`} />
		);
	}
}

export default PauseIconBtnModal;
