import PropTypes from 'prop-types';
import React, { Component } from 'react';

const style = {
	iconBtn: {
		fontSize: '14px',
		padding: '2px 6px',
		margin: '0px 5px'
	}
};

class IconBtn extends Component {
	static propTypes = {
		handler: PropTypes.func,
		icon: PropTypes.element
	}

	constructor(props) {
		super(props);
	}

	render() {
		const { handler, icon } = this.props;
		return (
			<button onClick={handler} className="btn btn-primary" style={style.iconBtn}>
				{icon}
			</button>
		);
	}
}

export default IconBtn;
