// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchUser, clearCurrentUser } from '../../ducks/user.duck';
import UserEditForm from './userEditForm';
import PageContainer from '../utilities/pageContainer';
import PageHeading from '../utilities/pageHeading';
import Spinner from '../utilities/spinner';

type Props = {
	// Redux Store
	user: User,
	// Wrapped Actions,
	fetchUser: Function,
	clearCurrentUser: Function,
	// React Router
	params: {
		id: number
	}
};

class UserEdit extends Component {
	constructor(props: Props) {
		super(props);
	}

	componentDidMount() {
		const { match } = this.props;
		this.props.fetchUser(match.params.id);
	}

	componentWillUnmount() {
		this.props.clearCurrentUser();
	}

	render() {
		const { user } = this.props;

		return (
			<PageContainer>
				<PageHeading>
					<span>
						Edit User:&nbsp;
						{user &&
							<span>
								{user.FullName}
							</span>
						}
					</span>
				</PageHeading>

				{user && <UserEditForm />}
				{!user && <Spinner />}
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user.currentUser
	};
}

UserEdit = connect(mapStateToProps, { fetchUser, clearCurrentUser })(UserEdit);

export default UserEdit;
