import { combineReducers } from 'redux';
import axios from 'axios';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import codesetReducer from './codeset.duck';
import campaignReducer from './campaign.duck';
import actionReducer from './action.duck';
import targetReducer from './target.duck';
import decoderTypesReducer from './decoderType.duck.js';
import touchcodeReducer from './touchcode.duck';
import customerReducer from './customer.duck';
import userReducer from './user.duck';
import userRoleReducer from './userRole.duck';
import settingsReducer from './settings.duck';
import analyticsReducer from './analytics.duck';
import testReducer from './test.duck';
import authReducer, { UNAUTH_USER } from './auth.duck';
import { LS } from '../util/constants';
import { CLEAR_USER_ROLES_FORM } from './userRole.duck';

axios.defaults.headers.common.Authorization = localStorage.getItem(LS.TOKEN);

export const BASE_URL = '/api/v1';

export function createActionName(duckName, actionName) {
	return `touchcode/${duckName}/${actionName}`;
}

const appReducer = combineReducers({
	codeset: codesetReducer,
	campaign: campaignReducer,
	action: actionReducer,
	target: targetReducer,
	decoderType: decoderTypesReducer,
	touchcode: touchcodeReducer,
	customer: customerReducer,
	user: userReducer,
	userRole: userRoleReducer,
	settings: settingsReducer,
	auth: authReducer,
	toastr: toastrReducer,
	test: testReducer,
	analytics: analyticsReducer,
	form: formReducer.plugin({
		userEdit: (state, action) => {
			switch (action.type) {
				case CLEAR_USER_ROLES_FORM:
					if (state.values && state.values.userRole) {
						state.values.userRole = undefined;
					}
					return state;
				default:
					return state;
			}
		}
	})
});

const rootReducer = (state, action) => {
	if (action.type === UNAUTH_USER) {
		return appReducer(undefined, action);
	}

	return appReducer(state, action);
};

export default rootReducer;
