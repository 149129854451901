import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import CommonStyle from '../../styles/commonStyles';

class AuthExpired extends Component {
	constructor(props) {
		super(props);

		this.login = this.login.bind(this);
	}

	login() {
		this.props.auth.login();
	}

	render() {
		return (
			<div className="splashContainer">
				<Row>
					<Col xs={12} style={CommonStyle.textCenter}>
						<h3>Your session has expired</h3>
						<a onClick={this.login.bind(this)} className="btn btn-primary">
							Back to Sign In
			    			</a>
					</Col>
				</Row>
			</div>
		);
	}
}

export default AuthExpired;
