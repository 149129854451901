import { createActionName, BASE_URL } from './index';
import axios from 'axios';
import history from '../util/history';
import { routes } from '../util/constants';

// Actions
const DUCK_NAME = 'settings';
const FETCH_SETTINGS = createActionName(DUCK_NAME, 'FETCH_SETTINGS');

// Reducer
export default function (state = {}, action = {}) {
	switch (action.type) {
		case FETCH_SETTINGS: {
			return {
				...state,
				buildNumber: action.BuildNumber,
				releaseNumber: action.ReleaseNumber,
				resourceGroup: action.ResourceGroup,
				status: action.status
			};
		}
		default:
			return state;
	}
}

// Action Creators
export function fetchSettings() {
	return dispatch => {
		axios.get(`${BASE_URL}/settings`)
			.then(res => {
				const { BuildNumber, ReleaseNumber, ResourceGroup } = res.data;
				dispatch({
					type: FETCH_SETTINGS,
					status: 'success',
					BuildNumber,
					ReleaseNumber,
					ResourceGroup
				});
			})
			.catch(err => {
				dispatch({
					type: FETCH_SETTINGS,
					status: 'error'
				});
			});
	};
}
