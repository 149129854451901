import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../util/history';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { FaPencil, FaPlusCircle } from 'react-icons/lib/fa';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import v from 'voca';
import moment from 'moment-timezone';
import { fetchCampaigns, amendCampaign, clearCampaigns } from '../../ducks/campaign.duck';
import { setCustomerId, setCustomerIdEnabled, setRefreshActions } from '../../ducks/auth.duck';
import { routes } from '../../util/constants.js';
import { tableCaretRender } from '../../util/tableUtil';
import { statusOptions } from '../../util/constants';
import CommonStyle from '../../styles/commonStyles.js';
import PageHeading from '../utilities/pageHeading';
import PageContainer from '../utilities/pageContainer';
import IconBtn from '../utilities/iconBtn';
import Spinner from '../utilities/spinner';
import ReactTooltip from 'react-tooltip';
import { toastr } from 'react-redux-toastr';
import { fetchSettings } from '../../ducks/settings.duck.js';
import CondensedCampaignIcons from './condensedCampaignIcons';

const style = {
	active: {
		color: '#33B643'
	},
	paused: {
		color: '#D89224'
	},
	upcoming: {
		color: '#AF84CA'
	},
	archived: {
		color: '#5C3C0A'
	},
	savedDraft: {
		color: '#18289F'
	},
	deleted: {
		color: '#ff0000'
	},
	default: {
		color: 'black'
	},
	bold: {
		fontWeight: 'bold'
	},
	iconBtn: {
		fontSize: '14px',
		padding: '2px 6px',
		margin: '0px 5px'
	},
	hidden: {
		visibility: 'hidden'
	}
};

class CampaignManage extends Component {
	static propTypes = {
		// Redux Store
		campaigns: PropTypes.array,
		// Wrapped Actions
		fetchCampaigns: PropTypes.func,
		amendCampaign: PropTypes.func
	}

	constructor(props) {
		super(props);

		this.iconBtn = this.iconBtn.bind(this);

		this.state = {
			sortName: 'Name',
			sortOrder: 'asc',
			copyMessage: '',
			messageId: '',
		};
	}

	componentDidMount() {
		this.props.fetchSettings();
		this.props.clearCampaigns();
		this.props.setCustomerIdEnabled(true);
		const removeDeleted = true;
		this.props.setRefreshActions([() => fetchCampaigns(true)]);
		this.props.fetchCampaigns(removeDeleted);
	}

	componentWillUnmount() {
		this.props.setCustomerIdEnabled(true);
	}

	fetchCampaignsTrue() {
		return this.props.fetchCampaigns(true);
	}

	handleEditClick(campaignId) {
		history.push(`${routes.campaignEdit}/${campaignId}`);
	}

	iconBtn(icon, handler, key, tooltip) {
		return (
			<span key={key} data-tip data-for={key}>
				<IconBtn
					key={key}
					handler={handler}
					icon={icon}
				/>
				<ReactTooltip id={key} effect="solid" delayShow={500}>
					<div>{tooltip}</div>
				</ReactTooltip>
			</span>
		);
	}

	coloredStatus(cell) {
		let statusStyle = {};
		switch (cell) {
			case 'active':
				statusStyle = style.active;
				break;
			case 'paused':
				statusStyle = style.paused;
				break;
			case 'saved draft':
				statusStyle = style.savedDraft;
				break;
			case 'archived':
				statusStyle = style.archived;
				break;
			case 'upcoming':
				statusStyle = style.upcoming;
				break;
			case 'deleted':
				statusStyle = style.deleted;
				break;
			default:
				statusStyle = style.default;
		}
		return (
			<span style={{ ...statusStyle, ...style.bold }}>
				{
					v.titleCase(cell)
				}
			</span>
		);
	}

	statusColumnFormatter(cell, row) {
		const { Id, Name } = row;
		const value = v.lowerCase(statusOptions[cell.Id]);
		return (
			<div style={CommonStyle.splitSection}>
				<div style={CommonStyle.clipSection}>{this.coloredStatus(value)}</div>
				<div>
					<CondensedCampaignIcons
						Id = {Id}
						name = {Name}
						cell = {cell}
						row = {row}
						resourceGroup = {this.props.resourceGroup}
						permissions = {this.props.permissions}
						amendCampaign = {this.props.amendCampaign}
						reloadCampaigns = {this.fetchCampaignsTrue.bind(this)} />
				</div>
			</div>
		);
	}

	handleCopyIdClick(event) {
		const idInput = document.querySelector(`#ID-${event}`);
		if (idInput) {
			idInput.select();
			document.execCommand('copy');
			toastr.success('Campaign Id Coppied to Clipboard');
		}
	}

	nameColumnFormatter(cell, row, rowIndex, formatExtraData) {
		const { permissions } = formatExtraData;
		const isCampaignViewer = permissions ? permissions.CampaignViews : null;

		return (
			<div style={CommonStyle.splitSection}>
				<div data-tip={`Copy ID:  ${row.Id}`} data-for={row.Id} style={{ ...CommonStyle.splitSection, flexDirection: 'column' }} onClick={this.handleCopyIdClick.bind(this, row.Id)}>
					<div style={CommonStyle.clipSection} >
						{cell}
					</div>
				</div>
				<input id={`ID-${row.Id}`} value={row.Id} readOnly style={{ position: 'absolute', top: '-1000px' }} />
				<ReactTooltip id={row.Id} effect="solid" place="top" delayShow={500} delayHide={500} />
				{
					isCampaignViewer &&
					<div data-tip data-for="editButton">
						{this.iconBtn(<FaPencil />, this.handleEditClick.bind(this, row.Id), '6', 'Edit Campaign')}
					</div>
				}
			</div>
		);
	}

	startDateColumnFormatter(cell, row) {
		return (
			<span>{cell ? moment.tz(cell, row.TimeZone).format('MM-DD-YYYY') : '-'}</span>
		);
	}

	endDateColumnFormatter(cell, row) {
		if (!cell) {
			return <span>-</span>;
		}

		const displayDate = moment.tz(cell, row.TimeZone).format('MM-DD-YYYY');

		if (moment().isAfter(cell, 'day')) {
			return (
				<span>
					<div>{displayDate}</div>
					<div><small style={CommonStyle.textDanger}>Expired</small></div>
				</span>
			);
		}

		return <span>{displayDate}</span>;
	}

	customerColumnFormatter(cell) {
		return (
			<strong>{cell.Name}</strong>
		);
	}

	customerSortFunc(a, b, order) {
		if (order === 'desc') {
			return b.Customer.Name.localeCompare(a.Customer.Name);
		}

		return a.Customer.Name.localeCompare(b.Customer.Name);
	}

	onSortChange(sortName, sortOrder) {
		let newOrder = sortOrder;
		if (sortName !== this.state.sortName) {
			newOrder = 'asc';
		}

		this.setState({
			sortName,
			sortOrder: newOrder
		});
	}

	render() {
		const { campaigns, permissions } = this.props;
		const isCampaignCreator = permissions ? permissions.CampaignCreates : null;
		return (
			<PageContainer>
				<PageHeading>
					Campaigns
				</PageHeading>

				{isCampaignCreator &&
					<Row>
						<Col xs={12} style={CommonStyle.textRight}>
							<Link to={routes.campaignNew} className="btn btn-primary" style={CommonStyle.btn}>
								<FaPlusCircle /> New Campaign
							</Link>
						</Col>
					</Row>
				}

				{campaigns === undefined &&
					<Spinner />
				}

				{campaigns !== undefined && campaigns.length === 0 &&
					<h4 className="text-center">
						{!permissions.AdminView ?
							'You do not currently have any created Campaigns. Please speak with a Touchcode representative to create a new Campaign' :
							'There are currently no Campaigns created for the selected Customer. Please create a Campaign by selecting the New Campaign button'
						}
					</h4>
				}

				{campaigns !== undefined && campaigns.length > 0 &&
					<BootstrapTable
						data={campaigns}
						columns={[{
							dataField: 'Name',
							text: 'Campaign Name',
							formatter: this.nameColumnFormatter.bind(this),
							formatExtraData: { permissions },
							sort: true,
							sortCaret: tableCaretRender,
							filter: textFilter({ delay: 250 }),
							headerStyle: () => {
								const width = '250px';
								return {
									minWidth: width,
									width: width
								};
							}
						}, {
							dataField: 'Id',
							text: 'CampaignId',
							hidden: true,
							sort: true
						}, {
							dataField: 'StartDate',
							text: 'Start Date',
							formatter: this.startDateColumnFormatter,
							sort: true,
							sortCaret: tableCaretRender,
							align: 'center',
							headerStyle: () => {
								const width = '90px';
								return {
									minWidth: width,
									width: width
								};
							}
						}, {
							dataField: 'EndDate',
							text: 'End Date',
							formatter: this.endDateColumnFormatter,
							sort: true,
							sortCaret: tableCaretRender,
							align: 'center',
							headerStyle: () => {
								const width = '90px';
								return {
									minWidth: width,
									width: width
								};
							}
						}, {
							dataField: 'TimeZone',
							text: 'Timezone',
							hidden: true
						}, {
							dataField: 'CampaignState',
							text: 'Status',
							formatter: this.statusColumnFormatter.bind(this),
							filter: selectFilter({
								options: statusOptions,
								defaultValue: 1,
								placeholder: 'All'
							}),
							filterValue: cell => cell.Id,
							headerStyle: () => {
								const width = '250px';
								return {
									minWidth: width,
									width: width
								};
							}
						}]}
						keyField="Id"
						bodyClasses="touchcodeTbody"
						options={{
							sortName: this.state.sortName,
							sortOrder: this.state.sortOrder,
							onSortChange: this.onSortChange.bind(this)
						}}
						filter={filterFactory()}
						striped
						hover
					/>
				}
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	return {
		campaigns: state.campaign.campaigns,
		permissions: state.userRole.permissions,
		resourceGroup: state.settings.resourceGroup
	};
}

CampaignManage = connect(mapStateToProps, {
	setRefreshActions,
	fetchCampaigns,
	amendCampaign,
	setCustomerId,
	setCustomerIdEnabled,
	clearCampaigns,
	fetchSettings
})(CampaignManage);

export default CampaignManage;
