import React from 'react'
import { VictoryLabel, VictoryTooltip } from 'victory'

class VictoryChartLabel extends React.Component {
    render() {
        return (
            <g>
                <VictoryLabel {...this.props} />
                <VictoryTooltip
                    {...this.props}
                    text={`${this.props.text}`}
                    orientation="top"
                    pointerLength={0}
                    cornerRadius={20}
                    width={40}
                    height={40}
                    flyoutStyle={{ fill: "black" }}
                />
            </g>
        );
    }
}
export default VictoryChartLabel