import { Component } from 'react';
import { signOut } from '../../ducks/auth.duck';

class Logout extends Component {
	render() {
		let URL = encodeURI('http://localhost:3000');
		if (localStorage.getItem('returnURL')) {
			URL = encodeURI(localStorage.getItem('returnURL'));
		}

		let domain = encodeURI('touchcode-dev.auth0.com');
		if (localStorage.getItem('auth0domain')) {
			domain = encodeURI(localStorage.getItem('auth0domain'));
		}

		signOut();
		window.location = `https://${domain}/v2/logout?returnTo=${URL}`;

		return null;
	}
}

export default Logout;
