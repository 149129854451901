import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import history from '../../util/history';
import { FaPlusCircle, FaCheck, FaTimesCircle } from 'react-icons/lib/fa';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import {
	Field,
	FormSection,
	reduxForm,
	formValueSelector,
	getFormValues,
	getFormSyncErrors,
	hasSubmitFailed,
	getFormInitialValues,
	isValid,
	touch
} from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import { fetchTargets } from '../../ducks/target.duck';
import { createAction, updateAction, deleteAction } from '../../ducks/action.duck';
import { setCustomerId, setCustomerIdEnabled } from '../../ducks/auth.duck';
import * as validators from '../../util/FormValidators';
import { routes, formNames, RULE_TYPES, triggerTypes } from '../../util/constants.js';
import CommonStyle, { mergeStyles } from '../../styles/commonStyles';
import FieldWrapper from '../utilities/fieldWrapper';
import FormContainer from '../utilities/formContainer';
import ConfirmModal from '../utilities/confirmModal';
import ActionEditFormTime from './actionEditFormTime';
import ActionEditFormLocation from './actionEditFormLocation';
import ActionEditFormDate from './actionEditFormDate';
import SelectInput from '../utilities/selectInput';
import HR from '../utilities/horizontalRule';
import Spinner from '../utilities/spinner';
import moment from 'moment-timezone';
import ReactTooltip from 'react-tooltip';
import CancelModal from '../utilities/cancelModal';

const formName = formNames.ACTION_EDIT;
const style = {
	btnRow: {
		marginTop: '20px'
	},
	deleteModalSubheading: {
		margin: '50px 0px 25px 0px'
	}
};

const dateRangeEnd = (value, allValues) => {
	const endDate = moment(value);
	if (!allValues.rule || !allValues.rule.startDate) {
		return undefined;
	}
	const startDate = moment(allValues.rule.startDate);
	if (endDate.isBefore(startDate, 'day')) {
		return "End Date can't be before Start Date";
	}

	if (moment().isAfter(endDate, 'day')) {
		return 'End date must be at least today';
	}
	return undefined;
};

class ActionEditForm extends Component {
	static propTypes = {
		// From Parent
		isNew: PropTypes.bool,
		// Redux Form
		handleSubmit: PropTypes.func,
		trigger: PropTypes.object,
		// Redux Store
		targets: PropTypes.array,
		action: PropTypes.object,
		// Wrapped actions
		fetchTargets: PropTypes.func,
		createAction: PropTypes.func,
		updateAction: PropTypes.func,
		deleteAction: PropTypes.func,
		// React router
		router: PropTypes.object,
		route: PropTypes.object
	}

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const { permissions, customerId } = this.props;
		const isAdminViewer = permissions.AdminView;
		if (isAdminViewer && customerId == null) {
			toastr.error('No Customer Selected', 'Select a Customer from the dropdown at the top of the page');
			history.push(routes.actionManage);
		}

		this.props.setCustomerIdEnabled(false);
		this.props.fetchTargets();
	}

	componentDidUpdate() {
		const { action, customerId } = this.props;

		if (action) {
			if (String(action.CustomerId) !== String(customerId)) {
				this.props.setCustomerId(action.CustomerId);
			}
		}
	}

	componentWillUnmount() {
		this.props.setCustomerIdEnabled(true);
	}

	handleDeleteClick(event) {
		const { action } = this.props;
		this.props.deleteAction(action, routes.actionManage);
		if (event) {
			event.preventDefault();
		}
	}

	handleCancelClick(event) {
		history.push(routes.actionManage);
		if (event) {
			event.preventDefault();
		}
	}

	handleFormSubmit(form) {
		const { action, isNew, customerId } = this.props;
		const {
			name,
			target,
			trigger,
			rule,
			timezone,
			location
		} = form;

		let Rule = {};
		let RuleType = '';
		let TriggerTypeId = 0;

		switch (trigger.value) {
			case RULE_TYPES.TIME: {
				const { startTime, endTime } = rule;
				RuleType = RULE_TYPES.TIME;
				Rule.startTime = startTime;
				Rule.endTime = endTime;
				TriggerTypeId = triggerTypes.TIME;
				break;
			}
			case RULE_TYPES.DATE: {
				const { startDate, endDate } = rule;
				RuleType = RULE_TYPES.DATE;
				Rule = {
					startDate: moment.tz(startDate, timezone).format(),
					endDate: moment.tz(endDate, timezone).format(),
					timezone: timezone
				};
				TriggerTypeId = triggerTypes.DATE;
				break;
			}
			case RULE_TYPES.LOCATION: {
				RuleType = RULE_TYPES.LOCATION;
				Rule = { SW: location.SW, NE: location.NE };
				TriggerTypeId = triggerTypes.LOCATION;
				break;
			}
			default: {
				throw new Error('Trigger type is not one of the expected enums');
			}
		}

		const newAction = {
			Name: name,
			TargetId: target.value,
			Trigger: trigger.value,
			TriggerTypeId,
			RuleType,
			Rule,
			CustomerId: customerId
		};

		if (isNew) {
			this.props.createAction(newAction, routes.actionManage);
		} else {
			this.props.updateAction(newAction, action.Id, routes.actionManage);
		}
	}

	handleKeyPress(event) {
		if (event.key === 'Enter') {
			event.preventDefault();

			if (this.props.isValid) {
				this.handleFormSubmit(this.props.formValues);
			} else {
				const { dispatch } = this.props;
				dispatch(touch(formName, 'name'));
				dispatch(touch(formName, 'target'));
				dispatch(touch(formName, 'trigger'));
				dispatch(touch(formName, 'rule.startDate'));
				dispatch(touch(formName, 'rule.endDate'));
				dispatch(touch(formName, 'rule.startTime'));
				dispatch(touch(formName, 'rule.endTime'));
				dispatch(touch(formName, 'location'));
			}
		}
	}

	renderTriggerInput() {
		const {
			trigger,
			dispatch,
			formErrors,
			submitFailed,
			formInitialValues,
			formValues
		} = this.props;

		if (!trigger) { return null; }

		switch (trigger.value) {
			case RULE_TYPES.TIME: {
				return <ActionEditFormTime dispatch={dispatch} />;
			}
			case RULE_TYPES.DATE: {
				return (
					<Col md={4}>
						<ActionEditFormDate
							formName={formName}
							dispatch={this.props.dispatch}
							startDateValue={formValues && formValues.startDate}
							endDateValue={formValues && formValues.endDate}
							timezone={formValues && formValues.timezone}
							fieldPrefix="rule."
						/>
					</Col>);
			}
			case RULE_TYPES.LOCATION: {
				return (
					<ActionEditFormLocation
						dispatch={dispatch}
						formErrors={formErrors}
						submitFailed={submitFailed}
						formInitialValues={formInitialValues}
					/>
				);
			}
			default: return null;
		}
	}

	renderDeleteActionMessage() {
		const { action } = this.props;

		return (
			<div>
				<div>
					{'The Action cannot be deleted as it is currently used in an Active Campaign(s).' +
						'The Action can only be deleted if the Campaign(s) have been paused, archived, deleted ' +
						'or if the Campaign reaches its End Date.'}
				</div>
				<h4 style={style.deleteModalSubheading}>Used in the following campaigns</h4>
				<ul style={CommonStyle.listReset}>
					{
						action.ActiveCampaigns.map(c => {
							return (
								<li key={c.Id}>
									<Link to={`${routes.campaignEdit}/${c.Id}`}>
										{c.Name}
									</Link>
								</li>
							);
						})
					}
				</ul>
			</div>
		);
	}

	renderDeleteButton() {
		return (
			<button className="btn btn-danger" style={mergeStyles(CommonStyle.btn, CommonStyle.floatRight)}>
				Delete Action
			</button>
		);
	}

	handleFieldChange() {
		ReactTooltip.hide();
	}

	render() {
		const { handleSubmit, targets, action, trigger, formValues, initialValues } = this.props;

		const bsResponsive = {
			xs: 12,
			sm: 6,
			md: 4,
			lg: 4
		};
		return (
			<FormContainer submitHandler={handleSubmit(this.handleFormSubmit.bind(this))}>
				<div onKeyPress={this.handleKeyPress.bind(this)}>
					<Row>
						<Col {...bsResponsive}>
							<Field className="form-group"
								name="name" type="text" placeholder="Action Name"
								component={FieldWrapper} label="Name"
								validate={[validators.required, validators.charLimit50]} />
						</Col>

						<Col {...bsResponsive} data-tip data-for="targetDropdown">
							{targets ? (
								<Field name="target"
									component={SelectInput}
									label="Target"
									placeholder="Select a Target"
									isClearable={false}
									attachedBtn={<Link to={`${routes.targetManage}/true/true`} className="btn btn-primary btn-sm"><FaPlusCircle /> New Target</Link>}
									validate={[validators.required]}
									options={targets.map(({ Id, Name }) => { return { label: Name, value: Id }; })}
									onChange={this.handleFieldChange.bind(this)}
								/>
							) : (
								<Spinner />
							)}
							<ReactTooltip id="targetDropdown" effect="solid" place="bottom" multiline delayShow={500}>
								The selected Target will be displayed to users when the Action is triggered.
							</ReactTooltip>
						</Col>
					</Row>

					<Row style={CommonStyle.formRow} >
						<Col {...bsResponsive} data-tip data-for="triggerDropdown">
							<Field name="trigger"
								component={SelectInput}
								label="Trigger"
								isClearable={false}
								placeholder="Select a Trigger Type"
								validate={[validators.required]}
								options={[
									{ label: 'Time', value: RULE_TYPES.TIME },
									{ label: 'Date', value: RULE_TYPES.DATE },
									{ label: 'Location', value: RULE_TYPES.LOCATION },
								]}
								onChange={this.handleFieldChange.bind(this)}
							/>
							<ReactTooltip id="triggerDropdown" effect="solid" place="right" multiline delayShow={500}>
								<div>When the trigger is activated by a user, the Action</div>
								<div>will be launched for the associated Touchcode.</div>
							</ReactTooltip>
						</Col>
					</Row>

					<Row>
						{trigger && <HR />}
					</Row>

					<Row>
						<Col xs={12}>
							<FormSection name="rule">
								{this.renderTriggerInput()}
							</FormSection>
						</Col>
					</Row>
				</div>

				<div>
					<Row style={style.btnRow}>
						<Col xs={12}>
							<div style={CommonStyle.floatLeft}>
								{action && action.ActiveCampaigns.length === 0 &&
									<ConfirmModal title="Delete" body="You are about to delete this action. Are you sure?"
										launchBtn={this.renderDeleteButton()} closeBtnText="No"
										confirmBtn={
											<button className="btn btn-danger" onClick={this.handleDeleteClick.bind(this)} >
												Yes
											</button>
										} />
								}

								{action && action.ActiveCampaigns.length > 0 &&
									<ConfirmModal title="Action Locked" body={this.renderDeleteActionMessage()}
										launchBtn={this.renderDeleteButton()} closeBtnText="Okay" />
								}
							</div>
							<div style={CommonStyle.floatRight}>
								<CancelModal
									initialValues={initialValues}
									currentValues={formValues}
									onCancel={this.handleCancelClick.bind(this)}
								/>

								<button type="submit" className="btn btn-primary" style={CommonStyle.btn}>
									{action ? (
										<span><FaCheck /> Save</span>
									) : <span>Create Action</span>
									}
								</button>
							</div>
						</Col>
					</Row>
				</div>
			</FormContainer>
		);
	}
}

const formSelector = formValueSelector(formName);

function mapStateToProps(state) {
	const { currentAction } = state.action;

	let initialValues;

	if (currentAction !== undefined) {
		const { Name, Target, RuleType } = currentAction;
		initialValues = {
			'name': Name,
			'target': { label: Target.Name, value: Target.Id },
			'trigger': { label: _.capitalize(RuleType), value: RuleType }
		};

		let ruleValues = {};

		switch (RuleType) {
			case RULE_TYPES.TIME: {
				const { StartTime, EndTime } = currentAction;
				ruleValues = {
					rule: {
						startTime: StartTime,
						endTime: EndTime,
					}
				};
				break;
			}
			case RULE_TYPES.DATE: {
				const { StartDate, EndDate, TimeZone } = currentAction;
				ruleValues = {
					rule: {
						startDate: StartDate,
						endDate: EndDate,
					}
				};
				initialValues = {
					...initialValues,
					timezone: TimeZone
				};
				break;
			}
			case RULE_TYPES.LOCATION: {
				const { SW, NE } = currentAction;
				ruleValues.location = { SW, NE };
				break;
			}
			default: {
				ruleValues = {
					rule: null
				};
				initialValues.trigger = RULE_TYPES.TIME;
			}
		}

		initialValues = { ...initialValues, ...ruleValues };
	} else {
		initialValues = {
			rule: null,
			timezone: moment.tz.guess(),
		};
	}

	return {
		targets: state.target.targets,
		initialValues: initialValues,
		action: currentAction,
		customerId: state.auth.customerId,
		trigger: formSelector(state, 'trigger'),
		formValues: getFormValues(formName)(state),
		isValid: isValid(formName)(state),
		formErrors: getFormSyncErrors(formName)(state),
		submitFailed: hasSubmitFailed(formName)(state),
		formInitialValues: getFormInitialValues(formName)(state),
		permissions: state.userRole.permissions
	};
}

const validate = values => {
	const errors = {};

	const trigger = values.trigger ? values.trigger.value : null;

	if (trigger === RULE_TYPES.LOCATION) {
		if (values.location == null) {
			errors.location = 'An area must be selected';
		}
	}

	return errors;
};

ActionEditForm = reduxForm({
	validate,
	form: formName
})(ActionEditForm);

ActionEditForm = connect(mapStateToProps, {
	fetchTargets,
	createAction,
	updateAction,
	deleteAction,
	setCustomerId,
	setCustomerIdEnabled
})(ActionEditForm);

export default ActionEditForm;
