// @flow

import React, { Component } from 'react';
import moment from 'moment-timezone';
import { Field, touch, change } from 'redux-form';
import * as validators from '../../util/FormValidators';
import cs from '../../styles/commonStyles';
import DatePickerInputWrapper from './datePickerInputWrapper';
import Select from './SelectInputHeightAware';

type Props = {
	formName: string,
	startDateValue: string,
	endDateValue: string,
	timezone: string,
	noTimezone: boolean,
	dispatch: Function
};

class DateRangePicker extends Component {
	constructor(props: Props) {
		super(props);
		this.state = {
			startDisplayDate: moment({ hour: 0, minute: 0, second: 0 }),
			endDisplayDate: moment({ hour: 0, minute: 0, second: 0 }),
			startDateValue: moment({ hour: 0, minute: 0, second: 0 }),
			endDateValue: moment({ hour: 0, minute: 0, second: 0 }),
			endChanged: false
		};
	}

	handleTimezoneChange(value: ReactSelectOption): void {
		const {
			formName,
			dispatch } = this.props;
		const newTz = value.value;

		dispatch(change(formName, 'timezone', newTz));
	}

	generateTimezoneOptions(): Array<ReactSelectOption> {
		return moment.tz.names().map((tz: string) => {
			return { label: tz, value: tz };
		});
	}

	changeStartDisplayDate(date, saved) {
		if (!this.state.endChanged && this.refs.endDate) {
			this.refs.endDate.getRenderedComponent().updateDisplayDate(date);
			this.setState({ endDisplayDate: date });
		}
		this.setState({ startDisplayDate: date });
		if (saved) {
			this.setState({ startDateValue: date });
			if (!this.state.endChanged && this.refs.endDate) {
				this.setState({ endDateValue: date });
			}
		}
	}

	changeEndDisplayDate(date, saved) {
		this.setState({ endDisplayDate: date });
		if (saved) {
			this.setState({ endChanged: true });
			this.setState({ endDateValue: date });
		}
	}

	onStartClose(date) {
		const { startDateValue } = this.state;
		let dateValue = startDateValue;
		if (date) dateValue = date;
		this.refs.startDate.getRenderedComponent().updateDisplayDate(dateValue);
		this.changeStartDisplayDate(dateValue);
	}

	onStartClear() {
		this.changeStartDisplayDate(moment({ hour: 0, minute: 0, second: 0 }), true);
	}

	onEndClose(date) {
		const { endDateValue } = this.state;
		let dateValue = endDateValue;
		if (date) dateValue = date;
		this.refs.endDate.getRenderedComponent().updateDisplayDate(dateValue);
		this.setState({ endDisplayDate: dateValue });
	}

	onEndClear() {
		const { startDisplayDate, startDateValue } = this.state;
		this.setState({ endChanged: false, endDisplayDate: startDisplayDate, endDateValue: startDateValue });
	}

	render() {
		const { formName, dispatch, timezone, noTimezone, validate, fieldPrefix } = this.props;
		const { startDisplayDate, endDisplayDate } = this.state;

		const timezoneOptions = !noTimezone ? this.generateTimezoneOptions() : [];
		const timezoneValue = !noTimezone && timezone ? timezoneOptions.find(option => option.value === timezone) : null;

		const validation = [];
		validation.push(validators.requiredForLaunch, validators.dateRangeEnd);
		validate && validation.push(validate);
		return (
			<div className="dateRangePicker">
				<div className="inputRow">
					<Field id="startDateInput"
						name="startDate"
						ref="startDate"
						label="Start Date"
						placeholder="Select a Start Date"
						onTouch={() => dispatch(touch(formName, (fieldPrefix ? fieldPrefix : '') + 'startDate'))}
						component={DatePickerInputWrapper}
						forwardRef
						validate={[validators.requiredForLaunch, validators.dateRangeStart]}
						displayDate={startDisplayDate}
						changeDisplayDate={this.changeStartDisplayDate.bind(this)}
						onClose={this.onStartClose.bind(this)}
						onClear={this.onStartClear.bind(this)}
					/>
				</div>
				<div className="inputRow" style={{ margin: '20px 0px' }}>
					<Field id="endDateInput"
						name="endDate"
						ref="endDate"
						label="End Date"
						placeholder="Select an End Date"
						onTouch={() => dispatch(touch(formName, (fieldPrefix ? fieldPrefix : '') + 'endDate'))}
						component={DatePickerInputWrapper}
						endOfDay
						forwardRef
						validate={validation}
						displayDate={endDisplayDate}
						changeDisplayDate={this.changeEndDisplayDate.bind(this)}
						onClose={this.onEndClose.bind(this)}
						onClear={this.onEndClear.bind(this)}
					/>
				</div>
				{!noTimezone &&
					<div className="inputRow">
						<label>Timezone</label>
						<Select
							isClearable={false}
							options={timezoneOptions}
							value={timezoneValue}
							onChange={this.handleTimezoneChange.bind(this)}
						/>
						<div>
							<small style={cs.textWeak}>
								Begins at 12:00 AM and ends at 11:59 PM for the selected Start &amp; End Dates within the selected Time Zone.
							</small>
						</div>
					</div> }
			</div>
		);
	}
}

export default DateRangePicker;
