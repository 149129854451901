// @flow
import { routes } from '../util/constants';
import axios, { AxiosPromise } from 'axios';
import history from '../util/history';
import { toastr } from 'react-redux-toastr';
import { createActionName, BASE_URL } from './index';

type TargetState = {
	targets: ?Array<Target>,
	currentTarget: ?Target
};

const initialState: TargetState = {
	targets: null,
	currentTarget: null
};

// Actions
const DUCK_NAME = 'target';
const FETCH_TARGETS = createActionName(DUCK_NAME, 'FETCH_TARGETS');
const FETCH_TARGET = createActionName(DUCK_NAME, 'FETCH_TARGET');
const CREATE_TARGET = createActionName(DUCK_NAME, 'CREATE_TARGET');
const UPDATE_TARGET = createActionName(DUCK_NAME, 'UPDATE_TARGET');
const DELETE_TARGET = createActionName(DUCK_NAME, 'DELETE_TARGET');
const CLEAR_CURRENT_TARGET = createActionName(DUCK_NAME, 'CLEAR_CURRENT_TARGET');

type TargetAction =
	{ type: FETCH_TARGETS, payload: AxiosPromise }
	| { type: FETCH_TARGET, payload: AxiosPromise }
	| { type: CLEAR_CURRENT_TARGET }
	;

// Reducer
export default function (state: TargetState = initialState, action: TargetAction): TargetState {
	switch (action.type) {
		case FETCH_TARGETS: {
			return { ...state, targets: action.payload.data };
		}
		case FETCH_TARGET: {
			return { ...state, currentTarget: action.payload.data };
		}
		case CREATE_TARGET: {
			return { ...state, targets: null };
		}
		case UPDATE_TARGET: {
			return { ...state, targets: null };
		}
		case DELETE_TARGET: {
			return state;
		}
		case CLEAR_CURRENT_TARGET: {
			return { ...state, currentTarget: null };
		}
		default:
			return state;
	}
}

function notifyError(dispatch, message) {
	toastr.error('Error', message);
}

// Action Creators
export function fetchTargets(): TargetAction {
	return dispatch => {
		axios.get(`${BASE_URL}/target`)
			.then(response => {
				dispatch({
					type: FETCH_TARGETS,
					payload: response
				});
			})
			.catch(err => {
				notifyError(dispatch, err.response.data);
			});
	};
}

export function fetchTargetsByDest(destinationType) {
	return dispatch => {
		axios.get(`${BASE_URL}/target/byType/${destinationType}`)
			.then(response => {
				dispatch({
					type: FETCH_TARGETS,
					payload: response
				});
			})
			.catch(err => {
				notifyError(dispatch, err.response.data);
			});
	};
}

export function fetchTarget(targetId: number): TargetAction {
	return dispatch => {
		axios.get(`${BASE_URL}/target/${targetId}`)
			.then(response => {
				dispatch({
					type: FETCH_TARGET,
					payload: response
				});
			})
			.catch(err => {
				notifyError(dispatch, err.response.data);
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to retreive Target.');
				}
			});
	};
}

export function createTarget(target: Target, goBack: String): Function {
	return dispatch => {
		axios.post(`${BASE_URL}/target`, target)
			.then(response => {
				dispatch({
					type: CREATE_TARGET,
					payload: response
				});
				toastr.success('Success', 'Target created');
				dispatch(fetchTargets());

				if (goBack) history.goBack();
			})
			.catch(err => {
				notifyError(dispatch, err.response.data);
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to create new Target.');
				}
			});
	};
}

export function updateTarget(target: Target, targetId: string, to: String): Function {
	return dispatch => {
		axios.put(`${BASE_URL}/target/${targetId}`, target)
			.then(response => {
				dispatch({
					type: UPDATE_TARGET,
					payload: response
				});
				toastr.success('Success', 'Target updated');
				dispatch(fetchTargets());

				if (to) history.push(to);
			})
			.catch(err => {
				notifyError(dispatch, err.response.data);
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to update Target.');
				}
			});
	};
}

export function deleteTarget(targetId: string): Function {
	return dispatch => {
		axios.delete(`${BASE_URL}/target/${targetId}`)
			.then(response => {
				dispatch({
					type: DELETE_TARGET,
					payload: response
				});
				toastr.success('Success', 'Target deleted');
				dispatch(fetchTargets());
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					notifyError(dispatch, err.response.data);
				}
			});
	};
}

export function clearCurrentTarget(): TargetAction {
	return {
		type: CLEAR_CURRENT_TARGET
	};
}
