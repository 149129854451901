import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Time from '../../util/time';

class TimeInput extends Component {
	static propTypes = {
		minuteStep: PropTypes.number,
		id: PropTypes.string,
		placeholder: PropTypes.string,
		onChange: PropTypes.func,
		touch: PropTypes.func,
		value: PropTypes.string,
		style: PropTypes.object
	}

	constructor(props) {
		super(props);

		const time = new Time();

		this.minuteStep = props.minuteStep || 15;

		this.state = {
			isOpen: false,
			touched: false,
			value: time.toString()
		};

		this.handleDocumentClick = this.handleDocumentClick.bind(this);
	}

	componentDidMount() {
		document.addEventListener('click', this.handleDocumentClick, false);
		document.addEventListener('touchend', this.handleDocumentClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleDocumentClick, false);
		document.removeEventListener('touchend', this.handleDocumentClick, false);
	}

	handleDocumentClick(event) {
		const { isOpen } = this.state;
		if (!ReactDOM.findDOMNode(this).contains(event.target)) {
			if (isOpen) {
				this.handleTouch();
			}
			this.setState({ isOpen: false });
		}
	}

	handleTouch() {
		const { touch } = this.props;
		if (!this.state.touched && touch) {
			touch();
			this.setState({ touched: true });
		}
	}

	handleMouseDown(event) {
		const { isOpen } = this.state;

		if (isOpen) {
			this.handleTouch();
		}

		this.setState({ isOpen: !isOpen });
		event.preventDefault();
	}

	handleFocus(event) {
		this.setState({ isOpen: true });
		event.preventDefault();
	}

	handleBlur(event) {
		event.preventDefault();
		this.handleTouch();
	}

	handleSpinnerFocusChange(event) {
		const temp = event.target.value;
		event.target.value = '';
		event.target.value = temp;
	}

	handleHourChange(direction, event) {
		event.preventDefault();

		const time = new Time(this.getValue());
		if (direction === 0) {
			time.subtractHour(1);
		} else if ( direction === 1) {
			time.addHour(1);
		} else {
			const newValue = this.cleanInput(event.target.value, true);

			if (newValue === '') {
				return;
			}

			try {
				time.hour(parseInt(newValue, 10));
			} catch (e) {
				return;
			}
		}

		this.setValue(time.toString());
	}

	handleMinuteChange(direction, event) {
		event.preventDefault();

		const time = new Time(this.getValue());
		const step = parseInt(time.minute(), 10) % this.minuteStep;
		const subtractStep = step === 0 ? this.minuteStep : step;
		const addStep = step === 0 ? this.minuteStep : this.minuteStep - step;

		if (direction === 0) {
			time.subtractMinute(subtractStep);
		} else if ( direction === 1) {
			time.addMinute(addStep);
		} else {
			const newValue = this.cleanInput(event.target.value, false);
			if (newValue === '') {
				return;
			}

			try {
				time.minute(parseInt(newValue, 10));
			} catch (e) {
				return;
			}
		}

		this.setValue(time.toString());
	}

	handleMeridiemChange(direction, event) {
		event.preventDefault();

		const time = new Time(this.getValue());
		if (direction != null) {
			time.flipMeridiem();
		} else {
			const value = event.target.value.slice(-1);

			if (value === 'a') {
				time.meridiem('am');
			} else if (value === 'p') {
				time.meridiem('pm');
			} else {
				return;
			}
		}

		this.setValue(time.toString());
	}

	cleanInput(value, isHour) {
		if (!value.match(/\d{1,3}/)) {
			return '';
		}

		let newValue;

		const num1 = parseInt(value.substring(0, 2), 10);
		const num2 = parseInt(value.substring(1, 3), 10);

		const max = isHour ? 12 : 60;

		if (!isHour && (num1 === max || num2 === max)) {
			newValue = value.slice(-1);
		} else if (num1 > max || num2 > max) {
			newValue = value.slice(-1);
		} else {
			newValue = value.slice(-2);
		}

		if (isHour) {
			if (parseInt(newValue, 10) === 0) {
				return '';
			}
		}

		return newValue;
	}

	getValue() {
		const { value } = this.state;
		const { onChange } = this.props;
		const passedValue = this.props.value;

		if (onChange != null) {
			return passedValue;
		}

		return value;
	}

	setValue(value) {
		const { onChange } = this.props;

		if (onChange != null) {
			onChange(value);
		}

		this.setState({ value: value });
	}

	Spinner({ value, onChange, onFocus }) {
		return (
			<div className="spinner">
				<div className="spinnerButtonTop" onClick={onChange.bind(null, 1)}><div className="triangleUp" /></div>
				<input autoFocus={onFocus} onFocus={onFocus} value={value} onChange={onChange.bind(null, null)} type="text" />
				<div className="spinnerButtomBottom" onClick={onChange.bind(null, 0)}><div className="triangleDown" /></div>
			</div>
		);
	}

	render() {
		const { isOpen, value } = this.state;
		const { id, placeholder, style } = this.props;
		const { Spinner } = this;

		const time = new Time(value);
		const hour = time.hour();
		const minute = time.minute();
		const meridiem = time.meridiem();

		return (
			<div className="timePicker">
				<input
					id={id}
					css={style}
					placeholder={placeholder}
					type="text"
					value={this.getValue()}
					readOnly
					onMouseDown={this.handleMouseDown.bind(this)}
					onFocus={this.handleFocus.bind(this)}
					onBlur={this.handleBlur.bind(this)}
				/>
				{isOpen &&
				<div className="timePickerDropdown">
					<Spinner value={hour} onChange={this.handleHourChange.bind(this)} onFocus={this.handleSpinnerFocusChange.bind(this)} />
					<Spinner value={minute} onChange={this.handleMinuteChange.bind(this)} />
					<Spinner value={meridiem} onChange={this.handleMeridiemChange.bind(this)} />
				</div>
				}
			</div>
		);
	}
}

export default TimeInput;
