import PropTypes from 'prop-types';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { pingDW } from '../ducks/auth.duck.js';
import { fetchPermissions } from '../ducks/userRole.duck.js';
import { getCampaignsForCustomer } from '../selectors/analytics';
import { connect } from 'react-redux';
import { Segment, Sidebar, SidebarPusher } from 'semantic-ui-react';

import TopNavbar from './utilities/topNavbar';
import SideNavBar from './utilities/sideNavbar';

class Dashboard extends React.Component {
	static propTypes = {
		children: PropTypes.node,
		location: PropTypes.object,
	};

	constructor(props) {
		super(props);
		this.props.fetchPermissions();
		this.state = {
			menuVisible: false,
			selectedCampaignOption: this.props.match.params.campaignId ?? null,
		};
		this.handleCampaignChange = this.handleCampaignChange.bind(this);
		this.handleMenuClick = this.handleMenuClick.bind(this);
		this.handleMenuOffClick = this.handleMenuOffClick.bind(this);
	}

	componentDidUpdate(prevProps) {
		const newCampaignId = this.props.match.params.campaignId ?? null;
		const oldCampaignId = prevProps.match.params.campaignId ?? null;
		if (newCampaignId !== oldCampaignId && newCampaignId !== this.state.selectedCampaignOption) {
			this.setState({ selectedCampaignOption: newCampaignId });
		}
	}

	handleCampaignChange(field) {
		const value = field?.value ?? null;
		this.setState({ selectedCampaignOption: value });
		this.forceUpdate();
	}

	handleMenuClick() {
		this.setState({ menuVisible: !this.state.menuVisible });
	}

	handleMenuOffClick() {
		this.setState({ menuVisible: false });
	}

	render() {
		this.props.pingDW();
		const { location } = this.props;
		const { selectedCampaignOption, menuVisible } = this.state;
		const children = React.Children.map(this.props.children, child =>
			React.cloneElement(child, { selectedCampaign: selectedCampaignOption })
		);

		return (
			<DndProvider backend={HTML5Backend}>
				<div id="dashboard" >
					{/* we need the "transform: none;" style on Sidebar.Pushable to prevent fixed positioned elements from being displayed incorrectly */}
					<Sidebar.Pushable as={Segment} style={{ transform: 'none' }}>
						<SideNavBar visible={menuVisible} handleMenuOffClick={this.handleMenuOffClick} />
						<SidebarPusher>
							<TopNavbar id="topNav" location={location} selectedCampaignOption={selectedCampaignOption} handleMenuClick={this.handleMenuClick} handleCampaignChange={this.handleCampaignChange} />
							<div id="contentWrapperContainer" onClick={this.handleMenuOffClick}>
								<div id="contentWrapper" >
									<div id="content" >
										{children}
									</div>
								</div>
							</div>
						</SidebarPusher>
					</Sidebar.Pushable>
				</div >
			</DndProvider>
		);
	}
}

function mapStateToProps(state) {
	return {
		permissions: state.userRole.permissions,
		campaigns: getCampaignsForCustomer(state)
	};
}

Dashboard = connect(mapStateToProps, { pingDW, fetchPermissions }, null, { forwardRef: true })(Dashboard);

export default Dashboard;
