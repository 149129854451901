import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { colorDanger, colorWarn } from '../../styles/commonStyles';
import { ChromePicker } from 'react-color';

const style = {
	btn: {
		position: 'absolute',
		top: '-12px',
		right: '20px'
	},
	checkbox: {
		cursor: 'default'
	}
};

class FieldWrapper extends Component {
	static propTypes = {
		id: PropTypes.string,
		input: PropTypes.any,
		label: PropTypes.any,
		className: PropTypes.string,
		placeholder: PropTypes.string,
		type: PropTypes.string,
		children: PropTypes.node,
		meta: PropTypes.object,
		attachedBtn: PropTypes.element
	};

	constructor(props) {
		super(props);
		this.state = ({colorPickerValue: props.input?.value || "#ffffff", colorPickerEnabled: false});
	}

	toggleColorPicker() {
		const {colorPickerEnabled} = this.state;
		this.setState({colorPickerEnabled: !colorPickerEnabled});
	}

	setColorPickerValue(color, event, onChange) {
		const {colorPickerValue} = this.state;
		this.setState({colorPickerValue: color.hex});
		this.setState({lastColor: color});
		onChange(color.hex);
	}

	renderInput({ type, input, placeholder, id, children, readOnly }) {
		const popover = {
			position: 'absolute',
			zIndex: '2',
		  }
		  const cover = {
			position: 'fixed',
			top: '0px',
			right: '0px',
			bottom: '0px',
			left: '0px',
		  }
		  const colorRow = {
			display: "flex",
    		flexFlow: "row wrap",
    		marginTop: "10px",
    		gap: "5px"
		  }
		const {colorPickerEnabled, lastColor, colorPickerValue} = this.state;
		const displayedColor = lastColor?.hex || colorPickerValue || "white";
		const colorPreview = {
			width: "25px",
			height: "25px",
			display: "inline-block",
			marginLeft: "10px",
			backgroundColor: displayedColor,
			alignSelf: "center"
		  }
		switch (type) {
			case 'select': {
				return (
					<select id={id} className="form-control" {...input}>
						{children}
					</select>
				);
			}
			case 'date': {
				return <input id={id} className="form-control" {...input} placeholder={placeholder} type={type} max="9999-12-31" />;
			}
			case 'checkbox': {
				return <input id={id} style={style.checkbox} className="form-control" {...input} type={type} onChange={input.onChange}/>
			}
			case 'colorPicker': {
				return (
				<div>
					{ colorPickerEnabled && <div style={ cover } onClick={ this.toggleColorPicker.bind(this) }/>}
					<input readOnly type="hidden" id={id} style={{ textOverflow: 'ellipsis'}} className="form-control" {...input} placeholder={placeholder}/>
					<div style={colorRow}>
						<button type='button' className="btn btn-primary" onClick={this.toggleColorPicker.bind(this)}>Pick Color</button>
						<div style={colorPreview}/>
					</div>

				{ colorPickerEnabled ? <div style={ popover }>
				<ChromePicker color={displayedColor} onChange={((color, event) => {this.setColorPickerValue(color, event, input.onChange)}).bind(this)}/>
			  </div> : null }
			  </div>)
			}
			case "radio": {
				return <input id={id} className="form-check" {...input} placeholder={placeholder} type={type} readOnly={readOnly} />;
			}
			default: {
				return <input style={{ textOverflow: 'ellipsis' }} id={id} className="form-control" {...input} placeholder={placeholder} type={type} readOnly={readOnly} />;
			}
		}
	}

	render() {
		const { label, input, className, placeholder, type, children, id, meta: { touched, error, warning }, attachedBtn, readOnly } = this.props;

		const errorStyle = {
			color: colorDanger
		};

		const warnStyle = {
			color: colorWarn
		};

		return (
			<div className={className}>
				{attachedBtn && <div style={style.btn}>{attachedBtn}</div>}
				<label>{label}</label>
				<div className={`${(touched && error) ? 'has-error' : ''} ${(touched && warning) ? 'has-warning' : ''}`}>
					{this.renderInput({ type, input, placeholder, id, children, readOnly })}
					{touched && ((error && <span style={errorStyle}>{error}</span>)
					|| (warning && <span style={warnStyle}>{warning}</span>))}
				</div>
			</div>
		);
	}
}

export default FieldWrapper;
