import { createActionName, BASE_URL } from './index';
import axios from 'axios';
import history from '../util/history';
import { routes } from '../util/constants';
import { toastr } from 'react-redux-toastr';

// Actions
const DUCK_NAME = 'codeset';
const CLEAR_CODESETS = createActionName(DUCK_NAME, 'CLEAR_CODESETS');
const FETCH_CODESETS = createActionName(DUCK_NAME, 'FETCH_CODESETS');
const FETCH_CODESET = createActionName(DUCK_NAME, 'FETCH_CODESET');
const FETCH_CODESETS_FOR_MANAGE = createActionName(DUCK_NAME, 'FETCH_CODESETS_FOR_MANAGE');
const FETCH_CODESET_LABELS = createActionName(DUCK_NAME, 'FETCH_CODESET_LABELS');
const CLEAR_CODESET_LABELS = createActionName(DUCK_NAME, 'CLEAR_CODESET_LABELS');
const FETCH_CODESET_CODES = createActionName(DUCK_NAME, 'FETCH_CODESET_CODES');
const FETCH_CODESET_CODES_FOR_EXPORT = createActionName(DUCK_NAME, 'FETCH_CODESET_CODES_FOR_EXPORT');
const CLEAR_CURRENT_CODESET = createActionName(DUCK_NAME, 'CLEAR_CURRENT_CODESET');
const UPDATE_CODESET = createActionName(DUCK_NAME, 'UPDATE_CODESET');
const NEW_CODESET = createActionName(DUCK_NAME, 'NEW_CODESET');
const CLEAR_NEW_CODESET_ID = createActionName(DUCK_NAME, 'CLEAR_NEW_CODESET_ID');
const ADD_CODE_TO_CODESET = createActionName(DUCK_NAME, 'ADD_CODE_TO_CODESET');
const ADD_CODES_TO_CODESET = createActionName(DUCK_NAME, 'ADD_CODES_TO_CODESET');
const DELETE_CODES_FROM_CODESET = createActionName(DUCK_NAME, 'DELETE_CODES_FROM_CODESET');
const DELETE_CODE_FROM_CODESET = createActionName(DUCK_NAME, 'DELETE_CODE_FROM_CODESET');
const FETCH_CODES_NOT_IN_CODESET = createActionName(DUCK_NAME, 'FETCH_CODES_NOT_IN_CODESET');
const CLEAR_UPDATE = createActionName(DUCK_NAME, 'CLEAR_UPDATE');
const REDUCE_UPDATE = createActionName(DUCK_NAME, 'REDUCE_UPDATE');
const DELETE_CODESET = createActionName(DUCK_NAME, 'DELETE_CODESET');
const CLEAR_DELETED = createActionName(DUCK_NAME, 'CLEAR_DELETED');

// Reducer
export default function (state = {}, action = {}) {
	switch (action.type) {
		case CLEAR_CODESETS: {
			return { ...state, 'codesets': undefined };
		}
		case FETCH_CODESETS: {
			return { ...state, 'codesets': action.payload.data };
		}
		case FETCH_CODESETS_FOR_MANAGE: {
			return { ...state, 'codesets': action.payload.data };
		}
		case FETCH_CODESET: {
			return { ...state, 'currentCodeset': action.payload.data };
		}
		case FETCH_CODESET_LABELS: {
			return { ...state, 'codesetLabels': action.payload.data };
		}
		case FETCH_CODESET_CODES: {
			return { ...state, 'currentCodesetCodes': action.payload.data };
		}
		case FETCH_CODES_NOT_IN_CODESET: {
			return { ...state, 'codesNotInCodeset': action.payload.data };
		}
		case FETCH_CODESET_CODES_FOR_EXPORT: {
			return { ...state, 'codesForExport': action.payload.data };
		}
		case UPDATE_CODESET: {
			const updateVal = state.update ? state.update + 1 : 1;
			return { ...state, 'update': updateVal };
		}
		case CLEAR_CODESET_LABELS: {
			return { ...state, 'codesetLabels': null };
		}
		case CLEAR_CURRENT_CODESET: {
			return { ...state, 'currentCodeset': null, currentCodesetCodes: null, codesNotInCodeset: null };
		}
		case NEW_CODESET: {
			return { ...state, 'newCodesetId': action.payload.data.Id, 'newCodesetLabel': action.payload.data.Label };
		}
		case CLEAR_NEW_CODESET_ID: {
			return { ...state, 'newCodesetId': undefined, 'newCodesetLabel': undefined };
		}
		case ADD_CODE_TO_CODESET: {
			const updateVal = state.update ? state.update + 1 : 1;
			return { ...state, 'update': updateVal };
		}
		case DELETE_CODE_FROM_CODESET: {
			const updateVal = state.update ? state.update + 1 : 1;
			return { ...state, 'update': updateVal };
		}
		case ADD_CODES_TO_CODESET: {
			const updateVal = state.update ? state.update + 1 : 1;
			return { ...state, 'update': updateVal };
		}
		case DELETE_CODES_FROM_CODESET: {
			const updateVal = state.update ? state.update + 1 : 1;
			return { ...state, 'update': updateVal };
		}
		case DELETE_CODESET: {
			return { ...state, 'deleted': true };
		}
		case CLEAR_DELETED: {
			return { ...state, 'deleted': undefined };
		}
		case REDUCE_UPDATE: {
			const updateVal = state.update ? state.update - action.payload : 0;
			return { ...state, 'update': updateVal };
		}
		case CLEAR_UPDATE: {
			return { ...state, 'update': undefined };
		}
		default:
			return state;
	}
}

// Action Creators
export function fetchCodesets() {
	return dispatch => {
		axios.get(`${BASE_URL}/codeset`)
			.then(response => {
				dispatch({
					type: FETCH_CODESETS,
					payload: response
				});
			})
			.catch(err => {
				// TODO: Implement me
			});
	};
}

export function fetchCodesetsForManage() {
	return dispatch => {
		axios.get(`${BASE_URL}/codeset/ForManage`)
			.then(response => {
				dispatch({
					type: FETCH_CODESETS_FOR_MANAGE,
					payload: response
				});
			})
			.catch(err => {
				// TODO: Implement me
			});
	};
}

export function fetchCodeset(codesetId) {
	return dispatch => {
		axios.get(`${BASE_URL}/codeset/${codesetId}`)
			.then(response => {
				dispatch({
					type: FETCH_CODESET,
					payload: response
				});
			})
			.catch(err => {
				// TODO: Implement me
			});
	};
}

export function fetchCodesetLabels(codesetId, customerId) {
	return dispatch => {
		axios.get(`${BASE_URL}/codeset/${codesetId}/labels/${customerId}`)
			.then(response => {
				dispatch({
					type: FETCH_CODESET_LABELS,
					payload: response
				});
			})
			.catch(err => {
				// TODO: Implement me
			});
	};
}

export function fetchCodesetCodes(codesetId) {
	return dispatch => {
		axios.get(`${BASE_URL}/codeset/${codesetId}/codes/`)
			.then(response => {
				dispatch({
					type: FETCH_CODESET_CODES,
					payload: response
				});
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', `Unable to find codes for codeset ${codesetId}.`);
				}
			});
	};
}

export function fetchCodesNotInCodeset(codesetId) {
	return dispatch => {
		axios.get(`${BASE_URL}/codeset/codesNotIn/${codesetId}`)
			.then(response => {
				dispatch({
					type: FETCH_CODES_NOT_IN_CODESET,
					payload: response
				});
			})
			.catch(err => {
				// TODO: Implement me
			});
	};
}

export function fetchCodesetCodesForExport(codesetId) {
	return dispatch => {
		axios.get(`${BASE_URL}/codeset/${codesetId}/codesForExport/`)
			.then(response => {
				dispatch({
					type: FETCH_CODESET_CODES_FOR_EXPORT,
					payload: response
				});
			})
			.catch(err => {
				// TODO: Implement me
			});
	};
}

export function updateCodeset(codeset, codesetId) {
	if (!codeset.Modified) {
		codeset.Modified = (new Date()).toUTCString();
	}
	return dispatch => {
		axios
			.put(`${BASE_URL}/codeset/${codesetId}`, codeset)
			.then(response => {
				dispatch({
					type: UPDATE_CODESET,
					payload: response
				});
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to update codeset.');
				}
			});
	};
}

export function newCodeset(codeset) {
	return dispatch => {
		axios
			.put(`${BASE_URL}/codeset/new/codeset`, codeset)
			.then(response => {
				dispatch({
					type: NEW_CODESET,
					payload: response
				});
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to create new Codeset.');
				}
			});
	};
}

export function addCodeToCodeset(codeId, codesetId) {
	return dispatch => {
		axios
			.put(`${BASE_URL}/codeset/${codesetId}/add/${codeId}/`)
			.then(response => {
				dispatch({
					type: ADD_CODE_TO_CODESET,
					payload: response
				});
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to add Code to Codeset.');
				}
			});
	};
}

export function deleteCodeFromCodeset(codeId, codesetId) {
	return dispatch => {
		axios
			.put(`${BASE_URL}/codeset/${codesetId}/delete/${codeId}`)
			.then(response => {
				dispatch({
					type: DELETE_CODE_FROM_CODESET,
					payload: response
				});
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to delete code from codeset.');
				}
			});
	};
}

export function addCodesToCodeset(codes, codesetId) {
	return dispatch => {
		axios
			.put(`${BASE_URL}/codeset/${codesetId}/addCodes`, { codes: codes })
			.then(response => {
				dispatch({
					type: ADD_CODES_TO_CODESET,
					payload: response
				});
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to add codes to Codeset.');
				}
			});
	};
}

export function deleteCodesFromCodeset(codes, codesetId) {
	return dispatch => {
		axios
			.put(`${BASE_URL}/codeset/${codesetId}/deleteCodes`, { codes: codes })
			.then(response => {
				dispatch({
					type: DELETE_CODES_FROM_CODESET,
					payload: response
				});
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to delete codes from codeset.');
				}
			});
	};
}

export function clearCurrentCodeset() {
	return {
		type: CLEAR_CURRENT_CODESET
	};
}

export function clearCodesetLabels() {
	return {
		type: CLEAR_CODESET_LABELS
	};
}

export function clearNewCodesetId() {
	return {
		type: CLEAR_NEW_CODESET_ID
	};
}

export function deleteCodeset(id) {
	return dispatch => {
		axios.delete(`${BASE_URL}/codeset/delete/${id}`)
			.then(response => {
				dispatch({
					type: DELETE_CODESET,
					payload: response
				});
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to delete Codeset.');
				}
			});
	};
}

export function clearUpdate() {
	return {
		type: CLEAR_UPDATE
	};
}

export function reduceUpdate(val) {
	return {
		type: REDUCE_UPDATE,
		payload: val
	};
}

export function clearDeleted() {
	return {
		type: CLEAR_DELETED
	};
}

export function clearCodesets() {
	return {
		type: CLEAR_CODESETS
	};
}

