import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FaFolderOpen } from 'react-icons/lib/fa';
import CampaignEditModal from '../campaign/campaignEditModal';
import IconModalLauncher from './iconModalLauncher';

class ArchiveIconBtnModal extends Component {
	static propTypes = {
		campaignId: PropTypes.string,
		name: PropTypes.string,
		handler: PropTypes.func
	}

	constructor(props) {
		super(props);
	}

	render() {
		const { campaignId, name, handler } = this.props;

		return (
			<CampaignEditModal
				actionBtn={<button className="btn btn-danger" onClick={handler}>Archive</button>}
				action={handler}
				launchBtn={<IconModalLauncher><FaFolderOpen /></IconModalLauncher>}
				campaignId={campaignId}
				title="Archive Campaign"
				message={`Are you sure you want to archive ${name}? When archived, user interaction with the Campaign will not display content.`} />
		);
	}
}

export default ArchiveIconBtnModal;
