import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FaRepeat } from 'react-icons/lib/fa';
import CampaignEditModal from '../campaign/campaignEditModal';
import IconModalLauncher from './iconModalLauncher';

class ReactivateIconBtnModal extends Component {
	static propTypes = {
		campaignId: PropTypes.string,
		name: PropTypes.string,
		handler: PropTypes.func
	}

	constructor(props) {
		super(props);
	}

	render() {
		const { campaignId, name, handler } = this.props;

		return (
			<CampaignEditModal
					actionBtn={<button className="btn btn-primary" onClick={handler}>Reactivate</button>}
					action={handler}
					launchBtn={<IconModalLauncher><FaRepeat /></IconModalLauncher>}
					campaignId={campaignId}
					title="Reactivate Campaign"
					message={`Are you sure you want to reactivate ${name}?`} />
		);
	}
}

export default ReactivateIconBtnModal;
