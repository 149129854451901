// @flow

import React, { Component } from 'react';
import CodesetEditForm from './codesetEditForm';
import PageContainer from '../utilities/pageContainer';
import PageHeading from '../utilities/pageHeading';

class CodesetNew extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<PageContainer>
				<PageHeading>
					Create a new Codeset
				</PageHeading>

				<CodesetEditForm isNew />
			</PageContainer>
		);
	}
}

export default CodesetNew;
