import { createActionName, BASE_URL } from './index';
import axios from 'axios';

// Actions
const DUCK_NAME = 'test';
const RUN_TESTS = createActionName(DUCK_NAME, 'RUN_TESTS');
const CLEAR_TESTS = createActionName(DUCK_NAME, 'CLEAR_TESTS');
const VERIFY_FILE_LOCKED = createActionName(DUCK_NAME, 'VERIFY_FILE_LOCKED');
const VERIFY_FILE_RESULTS = createActionName(DUCK_NAME, 'VERIFY_FILE_RESULTS');
// Reducer
export default function(state = {}, action = {}) {
	switch (action.type) {
		case RUN_TESTS: {
			const file = state.file || {};
			file[action.tag] = action.file;
			return {
				...state,
				file,
				fileLocked: true,
			};
		}
		case VERIFY_FILE_RESULTS: {
			const testResults = state.testResults || {};
			testResults[action.tag] = action.testResults;
			return {
				...state,
				testResults,
				fileLocked: false,
			};
		}
		case VERIFY_FILE_LOCKED: {
			return {
				...state,
				fileLocked: true,
			};
		}
		case CLEAR_TESTS: {
			return {
				...state,
				file: undefined,
				testResults: undefined,
				fileLocked: undefined
			};
		}
		default:
			return state;
	}
}

// Action Creators
export function runTests(decodingConfig, codes, codeset, tag) {
	const body = {
		decodingConfig: decodingConfig,
		codes: codes,
		codeset: codeset
	};
	return dispatch => {
		axios
			.put(`${BASE_URL}/test/codes`, body)
			.then(response => {
				dispatch({
					type: RUN_TESTS,
					file: response.data,
					tag: tag
				});
			});
	};
}

export function verifyFile(tag, file) {
	const body = {
		file
	};
	return dispatch => {
		axios
			.put(`${BASE_URL}/test/verify`, body)
			.then(response => {
				if (response.status === 204) {
					dispatch({
						type: VERIFY_FILE_LOCKED,
					});
				} else {
					dispatch({
						type: VERIFY_FILE_RESULTS,
						testResults: response.data,
						tag: tag
					});
				}
			}).catch(() => {
			});
	};
}

export function clearTestResults() {
	return {
		type: CLEAR_TESTS
	};
}
