import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { routes } from '../../util/constants.js';
import { fetchPermissions } from '../../ducks/userRole.duck.js';
import { primaryDarkBlue } from '../../styles/commonStyles';
import { ANALYTICS_VIEWS, CAMPAIGN_VIEWS, ADMIN_VIEW, USER_VIEWS } from '../../util/constants.js';
import { Menu, Sidebar, Icon } from 'semantic-ui-react';
import logo from '../../../img/touchcode-logo.png';

export class SideNavbar extends React.Component {
	// eslint-disable-next-line no-undef
	static propTypes = {
		style: PropTypes.object,
		location: PropTypes.object,
		visible: PropTypes.bool,
	}

	constructor(props) {
		super(props);
		this.props.fetchPermissions();
	}

	render() {
		const { style, permissions, visible, currentUser } = this.props;
		const userFullName = currentUser ? currentUser.FirstName + ' ' + currentUser.LastName : null;
		const links = [{
			text: userFullName,
			to: routes.settings,
			cypressSelector: 'user-settings'
		}, {
			text: 'Overview',
			to: routes.analytics,
			icon: <Icon name="home" size="small" />,
			restriction: ANALYTICS_VIEWS,
			cypressSelector: 'analytics-overview'
		}, {
			text: 'Actions',
			to: routes.actionManage,
			restriction: CAMPAIGN_VIEWS,
			cypressSelector: 'actions'
		}, {
			text: 'Campaigns',
			to: routes.campaignManage,
			restriction: CAMPAIGN_VIEWS,
			cypressSelector: 'campaigns'
		}, {
			text: 'Codesets',
			to: routes.codesetManage,
			restriction: ADMIN_VIEW,
			cypressSelector: 'codesets'
		}, {
			text: 'Customers',
			to: routes.customerManage,
			restriction: ADMIN_VIEW,
			cypressSelector: 'customers'
		}, {
			text: 'Targets',
			to: routes.targetManage,
			restriction: CAMPAIGN_VIEWS,
			cypressSelector: 'targets'
		}, {
			text: 'Touchcodes',
			to: routes.touchcodeManage,
			restriction: ADMIN_VIEW,
			cypressSelector: 'touchcodes'
		}, {
			text: 'Users',
			to: routes.userManage,
			restriction: USER_VIEWS,
			cypressSelector: 'users'
		}, {
			text: 'Sign Out',
			to: routes.logout,
			cypressSelector: 'sign-out'
		}];

		return (
			<Sidebar
				as={Menu}
				animation="push"
				icon="labeled"
				inverted
				vertical
				visible={visible}
				width="wide"
				style={{ backgroundColor: primaryDarkBlue }}
			>
				<div style={style}>
					<div className="title-block">
						<img className="logo" src={logo} /> <h2 > TOUCHCODE </h2>
					</div>
					{
						links.map(link => {
							const { text, to, restriction, icon, cypressSelector } = link;
							if (restriction && permissions && !permissions[restriction]) {
								return null;
							}
							return (
								<Menu.Item key={to}>
									<Link
										className="sideNavLink"
										onClick={this.props.handleMenuOffClick}
										to={to}
										data-cy={cypressSelector}
									>
										{icon ? icon : <Icon />} <span>{text}</span>
									</Link>
								</Menu.Item>
							);
						})
					}
				</div>
			</Sidebar>
		);
	}
}

function mapStateToProps(state) {
	return {
		permissions: state.userRole.permissions,
		currentUser: state.user.me,
	};
}

export default connect(mapStateToProps, { fetchPermissions })(SideNavbar);
