// Polyfills
import 'es6-shim';
// Imports
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxToastr from 'react-redux-toastr';
import promise from 'redux-promise';
import reduxThunk from 'redux-thunk';
import reducers from './ducks/index';
import { AUTH_USER, setGlobalAuthObj } from './ducks/auth.duck';
import { SAVE_WORK_IN_PROGRESS } from './ducks/campaign.duck';
import { LS } from './util/constants';
import './util/storagePrototypeExtensions';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-redux-toastr/src/styles/index.scss';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import './styles/base.scss';
import { makeMainRoutes } from './routes';
import Auth from './components/auth/auth';
import './util/axiosInterceptor.js';
import Spinner from '../src/components/utilities/spinner';

const style = {
	provider: {
		margin: '0',
		padding: '0',
		height: '100%',
		width: '100%',
		display: 'table'
	}
};

const store = createStore(reducers, composeWithDevTools(
	applyMiddleware(promise, reduxThunk)
));

window.store = store;

const token = localStorage.getItem(LS.TOKEN);
const userType = localStorage.getItem(LS.USER_TYPE);
if (token) {
	store.dispatch({
		type: AUTH_USER,
		userType: userType
	});
}

const workInProgress = localStorage.getObject(LS.WIP);
if (workInProgress) {
	for (const campaignId in workInProgress) {
		const campaignWork = workInProgress[campaignId];
		const { formValues, touchcodes } = campaignWork;
		store.dispatch({ type: SAVE_WORK_IN_PROGRESS, campaignId, formValues, touchcodes });
	}

	localStorage.setItem(LS.WIP, null);
}

/**
 * Renders the actual dashboard
 * @param {*} router
 */
const renderReact = router => {
	ReactDOM.render(
		<Provider store={store}>
			<div style={style.provider}>
				{router}
				<ReduxToastr
					preventDuplicates
					timeOut={3000}
					position="top-right"
					transitionIn="fadeIn"
					transitionOut="fadeOut"
				/>
			</div>
		</Provider>
		, document.getElementById('root'));
};

/**
 * Creates a loading spinner while a request is made to auth0 to auth us
 */
const renderLoading = () => {
	ReactDOM.render(
		<div className="loading_spinner">
			<Spinner />
		</div>
		, document.getElementById('root'));
};

/**
 * Creates an Auth.js class and stores it inside of the redux store
 * @param {*} inputData
 */
const createAuthObj = inputData => {
	const data = {
		auth0_domain: inputData.AUTH0_DOMAIN,
		auth0_client_ID: inputData.AUTH0_CLIENT_ID,
		auth0_callback_URL: inputData.AUTH0_CALLBACK_URL,
		auth0_audience: inputData.AUTH0_AUDIENCE
	};

	const auth = new Auth(data);

	window.store.dispatch(setGlobalAuthObj(auth));
	return auth;
};

const render = () => {
	renderLoading();

	const xmlHttp = new XMLHttpRequest();
	xmlHttp.onreadystatechange = () => {
		if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
			const json = JSON.parse(xmlHttp.responseText);
			const authObj = createAuthObj(json);
			const { verifySession } = authObj;

			// don't try to verify the session if we're already on the session callback page
			let sessionPromise = Promise.resolve();
			if (window.location.pathname !== '/callback') {
				sessionPromise = verifySession();
			}

			// Verify the session and then tell react to load
			sessionPromise
				.then(() => {
					// Make the main route after verifying the session
					const router = makeMainRoutes(json, authObj);
					localStorage.setItem('auth0domain', json.AUTH0_DOMAIN);

					if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
						localStorage.setItem('returnURL', 'http://localhost:3000');
					} else {
						localStorage.setItem('returnURL', json.RETURN_URL);
					}
					renderReact(router);
				})
				.catch(error => {
					const router = makeMainRoutes(json, authObj);
					// If its a login required error, we will redirect to the auth page when verifying the session
					if (error && error.error !== 'login_required') {
						renderReact(router);
					}
				});
		}
	};
	xmlHttp.open('GET', '/api/v1/auth0/auth0-data', true);
	xmlHttp.send();
};

render();
