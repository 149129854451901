import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../util/history';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { FaPencil, FaPlusCircle, } from 'react-icons/lib/fa';
import { Row, Col } from 'react-bootstrap';
import v from 'voca';
import CommonStyle from '../../styles/commonStyles.js';
import { fetchActions } from '../../ducks/action.duck';
import { setRefreshActions, setCustomerIdEnabled } from '../../ducks/auth.duck';
import { routes } from '../../util/constants.js';
import { tableCaretRender } from '../../util/tableUtil';
import IconBtn from '../utilities/iconBtn';
import PageHeading from '../utilities/pageHeading';
import PageContainer from '../utilities/pageContainer';
import Spinner from '../utilities/spinner';
import ReactTooltip from 'react-tooltip';

const style = {
	newActionBtnRow: {
		textAlign: 'right',
		margin: '0px 0px 20px 0px'
	}
};

class ActionManage extends Component {
	static propTypes = {
		// Redux Store
		actions: PropTypes.array,
		// Wrapped Actions
		fetchActions: PropTypes.func,
		setRefreshActions: PropTypes.func
	}

	constructor(props) {
		super(props);

		this.state = {
			sortName: 'Name',
			sortOrder: 'asc'
		};
	}

	componentDidMount() {
		this.props.setRefreshActions([fetchActions]);
		this.props.fetchActions();
		this.props.setCustomerIdEnabled(true);
	}

	handleEditClick(actionId) {
		history.push(`${routes.actionEdit}/${actionId}`);
	}

	targetColumnFormatter(cell) {
		return (
			<span>
				{cell.Name}
			</span>
		);
	}

	triggerColumnFormatter(cell) {
		return (
			<span>
				{cell ? v.titleCase(cell) : '-' }
			</span>
		);
	}

	nameColumnFormatter(cell, row) {
		return (
			<div style={CommonStyle.splitSection}>
				<div style={CommonStyle.clipSection}>{cell}</div>
				<div data-tip data-for="actionEdit">
					<IconBtn handler={this.handleEditClick.bind(this, row.Id)} icon={<FaPencil />} />
					<ReactTooltip id="actionEdit" place="left" effect="solid" delayShow={500}>Edit Action</ReactTooltip>
				</div>
			</div>
		);
	}

	onSortChange(sortName, sortOrder) {
		let newOrder = sortOrder;
		if (sortName !== this.state.sortName) {
			newOrder = 'asc';
		}

		this.setState({
			sortName,
			sortOrder: newOrder
		});
	}

	targetSort(a, b, order) {
		if (order === 'desc') {
			return b.Target.Name.localeCompare(a.Target.Name);
		}

		return a.Target.Name.localeCompare(b.Target.Name);
	}

	render() {
		const { actions } = this.props;

		return (
			<PageContainer>
				<PageHeading>Actions</PageHeading>

				<Row style={style.newActionBtnRow}>
					<Col xs={2} xsOffset={10} data-tip data-for="actionNew">
						<Link to={routes.actionNew} className="btn btn-primary">
							<FaPlusCircle /> New Action
						</Link>
						<ReactTooltip id="actionNew" effect="solid" delayShow={500}>
							When a Touchcode is launched by a user, the corresponding Action's Trigger will direct users to the desired Target.
						</ReactTooltip>
					</Col>
				</Row>

				{actions === undefined &&
					<Spinner />
				}

				{actions !== undefined && actions.length === 0 &&
					<Row>
						<Col xs={12}>
							<h4 style={CommonStyle.textCenter}>You haven't created any actions yet.</h4>
						</Col>
					</Row>
				}

				{actions !== undefined && actions.length > 0 &&
					<Row>
						<Col xs={12}>
							<BootstrapTable
								data={actions}
								columns={[{
									dataField: 'Name',
									text: 'Action Name',
									formatter: this.nameColumnFormatter.bind(this),
									sort: true,
									sortCaret: tableCaretRender,
									filter: textFilter({ delay: 250 })
								}, {
									dataField: 'Id',
									text: 'ActionId',
									hidden: true,
									sort: true
								}, {
									dataField: 'Target',
									text: 'Target',
									formatter: this.targetColumnFormatter.bind(this),
									sort: true,
									sortCaret: tableCaretRender,
									sortFunc: this.targetSort.bind(this)
								}, {
									dataField: 'RuleType',
									text: 'Trigger',
									formatter: this.triggerColumnFormatter.bind(this),
									sort: true,
									sortCaret: tableCaretRender
								}]}
								keyField="Id"
								bodyClasses="touchcodeTbody"
								options={{
									sortName: this.state.sortName,
									sortOrder: this.state.sortOrder,
									onSortChange: this.onSortChange.bind(this)
								}}
								filter={filterFactory()}
								striped
								hover
							/>
						</Col>
					</Row>
				}
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	return {
		actions: state.action.actions,
	};
}

ActionManage = connect(mapStateToProps, {
	fetchActions,
	setRefreshActions,
	setCustomerIdEnabled
})(ActionManage);

export default ActionManage;
