import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import Select from 'react-select';

const MAX_MENU_HEIGHT = 200;
const AVG_OPTION_HEIGHT = 36;

class SelectInputHeightAware extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dropUp: false,
		};

		this.determineDropUp = this.determineDropUp.bind(this);
	}

	componentDidMount() {
		this.determineDropUp();
		window.addEventListener('resize', this.determineDropUp);
		window.addEventListener('scroll', this.determineDropUp);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.determineDropUp);
		window.removeEventListener('scroll', this.determineDropUp);
	}

	componentDidUpdate() {
		this.determineDropUp();
	}

	determineDropUp() {
		const options = this.props.options || [];
		const node = findDOMNode(this.selectInst);

		if (!node) {
			return;
		}

		const windowHeight = window.innerHeight;
		const menuHeight = Math.min(MAX_MENU_HEIGHT, (options.length * AVG_OPTION_HEIGHT));
		const instOffsetWithMenu = node.getBoundingClientRect().bottom + menuHeight;
		const dropUp = instOffsetWithMenu >= windowHeight;

		if (dropUp !== this.state.dropUp) {
			this.setState({ dropUp });
		}
	}

	render() {
		const { dropUp } = this.state;
		const { styles = {} } = this.props;
		const { control: controlStyles, menu: menuStyles, ...remainingPropStyles } = styles;

		const customStyles = {
			control: (provided, { isFocused, menuIsOpen }) => {
				if (!menuIsOpen || !dropUp) {
					return {
						...provided,
						...(controlStyles ? controlStyles() : {})
					};
				}
				return {
					...provided,
					...(!(isFocused && !menuIsOpen) ? {} : {
						borderColor: '#66afe9',
						boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6)'
					}),
					borderBottomRightRadius: '4px',
					borderBottomLeftRadius: '4px',
					borderTopLeftRadius: '0px',
					borderTopRightRadius: '0px',
					...(controlStyles ? controlStyles() : {})
				};
			},
			menu: provided => {
				if (!dropUp) {
					return {
						...provided,
						...(menuStyles ? menuStyles() : {})
					};
				}
				return {
					...provided,
					top: 'auto',
					bottom: '100%',
					marginTop: '0px',
					marginBottom: '0px',
					borderBottomRightRadius: '0px',
					borderBottomLeftRadius: '0px',
					borderTopLeftRadius: '4px',
					borderTopRightRadius: '4px',
					boxShadow: '0 0 0 rgba(0, 0, 0, 0.06)',
					zIndex: 2,
					...(menuStyles ? menuStyles() : {})
				};
			},
			...remainingPropStyles
		};

		return (
			<Select
				{...this.props}
				className={this.props.className}
				ref={inst => (this.selectInst = inst)}
				styles={customStyles}
			/>
		);
	}
}

export default SelectInputHeightAware;
