// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Field, reduxForm, isValid, getFormValues, touch } from 'redux-form';
import { FaPencil, FaTimesCircle, FaCheck, FaPlusCircle } from 'react-icons/lib/fa';
import Rodal from 'rodal';
import CommonStyle, { mergeStyles } from '../../styles/commonStyles';
import { formNames, } from '../../util/constants';
import { toastr } from 'react-redux-toastr';
import {
	fetchTargets,
	fetchTarget,
	createTarget,
	updateTarget,
	deleteTarget,
	clearCurrentTarget
} from '../../ducks/target.duck.js';
import { setCustomerId, setCustomerIdEnabled } from '../../ducks/auth.duck';
import * as validators from '../../util/FormValidators';
import FieldWrapper from '../utilities/fieldWrapper';
import IconBtn from '../utilities/iconBtn';
import ConfirmModal from '../utilities/confirmModal';
import Spinner from '../utilities/spinner';
import CancelModal from '../utilities/cancelModal';

import 'rodal/lib/rodal.css';

const formName = formNames.TARGET_EDIT;
const style = {
	modalStyle: {
		width: '60%'
	},
	contentWrapper: {
		padding: '50px'
	},
	heading: {
		marginBottom: '20px'
	},
	btnRow: {
		marginTop: '35px'
	}
};

type Props = {
	// From parents
	isNew: boolean,
	autoOpen: boolean,
	returnLocation: string,
	targetId: string,
	// Redux Form
	handleSubmit: Function,
	reset: Function,
	// Redux Store
	target: Target,
	formValues: Object,
	customerId: number,
	// Wrapped Actions
	dispatch: Function,
	createTarget: Function,
	fetchTargets: Function,
	fetchTarget: Function,
	updateTarget: Function,
	deleteTarget: Function,
	clearCurrentTarget: Function,
	setCustomerId: Function,
	setCustomerIdEnabled: Function
};

export class TargetEditModal extends Component {
	props: Props;

	constructor(props: Props) {
		super(props);

		this.state = {
			visible: false
		};

		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.handleCancelClick = this.handleCancelClick.bind(this);
		this.handleDeleteClick = this.handleDeleteClick.bind(this);
	}

	componentDidMount() {
		if (this.props.autoOpen) {
			this.show();
		}
	}

	componentDidUpdate() {
		const { target, customerId } = this.props;

		if (target) {
			if (String(target.CustomerId) !== String(customerId)) {
				this.props.setCustomerId(target.CustomerId);
			}
		}
	}

	handleFormSubmit(form: Object) {
		const { targetId, isNew, returnLocation, target } = this.props;
		const { name, webDestination, appDestination } = form;
		// const to = returnLocation ? `${routes.campaignEdit}/${returnLocation}` : null;
		const goBack = returnLocation ? true : false;

		const newTarget = {
			Name: name,
			WebDestination: webDestination,
			AppDestination: appDestination
		};

		if (isNew) {
			this.props.createTarget(newTarget, goBack);
		} else {
			newTarget.CustomerId = target.CustomerId;
			this.props.updateTarget(newTarget, targetId, goBack);
		}
		this.props.clearCurrentTarget();
		this.hide();
	}

	handleDeleteClick(event: SyntheticEvent): void {
		this.props.deleteTarget(this.props.targetId);
		this.props.clearCurrentTarget();
		if (event) {
			event.preventDefault();
		}
		this.hide();
	}

	handleCancelClick(event: SyntheticEvent): void {
		if (event) {
			event.preventDefault();
		}
		this.hide();
	}

	handleKeyPress(event: SyntheticEvent) {
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();

			if (this.props.isValid) {
				this.handleFormSubmit(this.props.formValues);
			} else {
				const { dispatch } = this.props;
				dispatch(touch(formName, 'name'));
				dispatch(touch(formName, 'webDestination'));
				dispatch(touch(formName, 'appDestination'));
			}
		}
	}

	show(): void {
		const { permissions, customerId, targetId, isNew } = this.props;
		const isAdminViewer = permissions ? permissions.AdminView : null;
		if (isAdminViewer && customerId == null) {
			toastr.error('No Customer Selected', 'Select a Customer from the dropdown at the top of the page');
		} else {
			if (!isNew) {
				this.props.fetchTarget(targetId);
			}
			this.setState({ visible: true });
		}
	}

	hide(): void {
		this.props.reset();
		this.props.clearCurrentTarget();
		this.props.setCustomerIdEnabled(true);
		this.setState({ visible: false });
	}

	render() {
		const { handleSubmit, target, isNew, formValues, initialValues } = this.props;
		const { visible } = this.state;

		const bsResponsive = {
			xs: 12,
			md: 6
		};
		return (
			<span onKeyPress={this.handleKeyPress}>
				{isNew &&
					<button className="btn btn-primary" onClick={this.show}><FaPlusCircle /> New Target</button>
				}
				{!isNew &&
					<IconBtn icon={<FaPencil />} handler={this.show} />
				}
				<Rodal
					visible={visible}
					height={400}
					showCloseButton={false}
					onClose={this.hide}
					customStyles={style.modalStyle}
				>
					<div style={style.contentWrapper}>
						<h3 style={mergeStyles(style.heading, CommonStyle.textLeft)}>
							{isNew && 'Create Target'}
							{!isNew && 'Edit Target'}
						</h3>

						<form onSubmit={handleSubmit(this.handleFormSubmit)}>
							{((!isNew && target) || isNew) &&
								<div>
									<Row style={CommonStyle.textLeft}>
										<Col style={{ padding: '15px 15px 0px 15px', minHeight: '94px' }} {...bsResponsive}>
											<Field
												name="name"
												label="Name"
												type="text"
												placeholder="Target Name"
												component={FieldWrapper}
												validate={[validators.required, validators.charLimit50]}
											/>
										</Col>
										<Col style={{ padding: '15px 15px 0px 15px', minHeight: '94px' }} {...bsResponsive}>
											<Field
												name="webDestination"
												label="Website Destination"
												type="text"
												placeholder="Website Destination"
												component={FieldWrapper}
												validate={[validators.destinationRequired]}
											/>
										</Col>
										<Col style={{ padding: '15px 15px 0px 15px' }} {...bsResponsive}>
											<Field
												name="appDestination"
												label="App Destination"
												type="text"
												placeholder="App Destination"
												component={FieldWrapper}
												validate={[validators.destinationRequired]}
											/>
										</Col>
									</Row>

									<Row style={style.btnRow}>
										<Col xs={12}>
											<div style={CommonStyle.splitSection}>
												<div style={CommonStyle.clipSection}>
													{!isNew &&
														<ConfirmModal
															title="Delete"
															body="You are about to delete this target. Are you sure?"
															closeBtnText="No"
															launchBtn={
																<button style={CommonStyle.btn} className="btn btn-danger">
																	<FaTimesCircle /> Delete
																</button>
															}
															confirmBtn={
																<button style={CommonStyle.btn} className="btn btn-danger" onClick={this.handleDeleteClick}>
																	Yes
																</button>
															}
															action={this.handleDeleteClick}
														/>
													}
												</div>
												<div>
													<CancelModal
														initialValues={initialValues}
														currentValues={formValues}
														onCancel={this.handleCancelClick}
													/>
													<button style={CommonStyle.btn} type="submit" className="btn btn-primary">
														{target ? (
															<span><FaCheck /> Save</span>
														) : (
															<span>Create Target</span>
														)}
													</button>
												</div>
											</div>
										</Col>
									</Row>
								</div>
							}
							{!isNew && !target &&
								<div style={CommonStyle.textCenter}>
									<Spinner />
								</div>
							}
						</form>
					</div>
				</Rodal>
			</span>
		);
	}
}

function mapStateToProps(state) {
	const { currentTarget } = state.target;

	let initialValues;
	if (currentTarget != null) {
		const { Name, WebDestination, AppDestination } = currentTarget;
		initialValues = {
			'name': Name,
			'webDestination': WebDestination,
			'appDestination': AppDestination
		};
	}

	return {
		target: currentTarget,
		initialValues: initialValues,
		formValues: getFormValues(formName)(state),
		isValid: isValid(formName)(state),
		permissions: state.userRole.permissions,
		customerId: state.auth.customerId
	};
}

TargetEditModal = reduxForm({
	form: formName,
	enableReinitialize: true
})(TargetEditModal);

export default connect(mapStateToProps, {
	fetchTargets,
	fetchTarget,
	createTarget,
	updateTarget,
	deleteTarget,
	clearCurrentTarget,
	setCustomerId,
	setCustomerIdEnabled
})(TargetEditModal);
