import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import history from '../../util/history';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import { Field, reduxForm } from 'redux-form';
import memoize from 'memoize-one';
import SelectInput from '../utilities/selectInput';
import { Row, Col } from 'react-bootstrap';
import { fetchTouchcodesWithExperimental, clearTouchcodes } from '../../ducks/touchcode.duck';
import { setRefreshActions, setCustomerIdEnabled } from '../../ducks/auth.duck';
import { FaPlusCircle } from 'react-icons/lib/fa';
import CommonStyle, { mergeStyles } from '../../styles/commonStyles';
import { routes } from '../../util/constants.js';
import PageHeading from '../utilities/pageHeading';
import PageContainer from '../utilities/pageContainer';
import Spinner from '../utilities/spinner';
import TouchcodeEditModal from './touchcodeEditModal';
import TouchcodeDeleteModal from './TouchcodeDeleteModal';
import ReactTooltip from 'react-tooltip';
import { tableCaretRender } from '../../util/tableUtil';

class TouchcodeManage extends Component {
	static propTypes = {
		// Redux Store
		touchcodes: PropTypes.array,
		// Wrapped Actions
		fetchTouchcodesWithExperimental: PropTypes.func,
		clearTouchodes: PropTypes.func
	}

	constructor(props) {
		super(props);

		this.state = {
			filter: 'all',
			sortName: 'CodeId',
			sortOrder: 'asc'
		};

		this.filterTouchcodes = memoize((touchcodes, filter) => touchcodes.filter(touchcode => (
			(filter === 'all') ||
			(filter === 'experimental' && touchcode.isExperimental) ||
			(filter === 'permanent' && !touchcode.isExperimental)
		)));

		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.codeColumnFormatter = this.codeColumnFormatter.bind(this);
		this.labelColumnFormatter = this.labelColumnFormatter.bind(this);
		this.codeCoordinateColumnFormatter = this.codeCoordinateColumnFormatter.bind(this);
		this.onSortChange = this.onSortChange.bind(this);
	}

	componentDidMount() {
		this.props.setRefreshActions([clearTouchcodes, fetchTouchcodesWithExperimental]);
		if (this.props.customerId !== undefined && this.props.customerId !== null) {
			this.props.fetchTouchcodesWithExperimental();
		} else {
			this.props.clearTouchcodes();
		}
		this.props.setCustomerIdEnabled(true);
	}

	handleEditClick(touchcodeId) {
		history.push(`${routes.touchcodeEdit}/${touchcodeId}`);
	}

	onSortChange(sortName, sortOrder) {
		let newOrder = sortOrder;
		if (sortName !== this.state.sortName) {
			newOrder = 'asc';
		}

		this.setState({
			sortName,
			sortOrder: newOrder
		});
	}

	codeColumnFormatter(cell, row, rowIndex, formatExtraData) {
		const { permissions } = formatExtraData;
		const isAdminViewer = permissions ? permissions.AdminView : null;
		let codeStyle = {};
		if (row.isExperimental) {
			codeStyle = { position: 'relative', right: '40px' };
		}
		return (
			<div style={CommonStyle.splitSection}>
				<div style={CommonStyle.clipSection}>{cell}</div>
				<div style={codeStyle}>
					{row.isExperimental && isAdminViewer &&
						<div data-tip data-for="touchcodeRemove" style={{ float: 'left' }}>
							<TouchcodeDeleteModal touchcodeId={row.CodeId.toString()} />
							<ReactTooltip id="touchcodeRemove" effect="solid" delayShow={500} place="left">
								Delete Touchcode
							</ReactTooltip>
						</div>
					}
					<div data-tip data-for="touchcodeEdit">
						<TouchcodeEditModal touchcodeId={row.CodeId.toString()} />
						<ReactTooltip id="touchcodeEdit" effect="solid" delayShow={500} place="left">
							Edit Touchcode
						</ReactTooltip>
					</div>
				</div>
			</div>
		);
	}

	labelColumnFormatter(cell) {
		return (
			<span>{cell || '-'}</span>
		);
	}

	codeCoordinateColumnFormatter(cell) {
		return (
			<span>{this.formatPattern(cell) || '-'}</span>
		);
	}

	formatPattern(code) {
		const data = code.split('[')[1].split(']')[0].split(/,[ ]?/);
		let display = '';
		for (let i = 0; i < data.length; i += 2) {
			display += '(' + data[i] + ', ' + data[i + 1] + '), ';
		}
		return display.substring(0, display.length - 2);
	}

	handleFilterChange(value) {
		if (value === null || Array.isArray(value)) {
			return;
		}
		this.setState({ filter: value.value });
	}

	render() {
		const { touchcodes, customerId, permissions } = this.props;
		const { filter } = this.state;
		const isAdminViewer = permissions ? permissions.AdminView : null;

		if (isAdminViewer && (customerId === null || customerId === undefined)) {
			return (
				<PageContainer>
					<PageHeading>Touchcodes</PageHeading>
					<Row>
						<Col xs={12}>
							<h4>Select a customer from the dropdown.</h4>
						</Col>
					</Row>
				</PageContainer>
			);
		}

		const hasTouchcodes = touchcodes && touchcodes.length > 0;
		const tableData = hasTouchcodes ? this.filterTouchcodes(touchcodes, filter) : [];

		return (
			<PageContainer>
				<PageHeading>Touchcodes</PageHeading>

				{touchcodes === undefined ? <Spinner /> :
					<div>
						<Row>
							<Col xs={12} style={mergeStyles(CommonStyle.textLeft, { width: '50%', float: 'left', bottom: '15px' })}>
								<Field
									id="filterInput"
									name="filter"
									type="select"
									component={SelectInput}
									defaultValue={{ label: 'All', value: 'all' }}
									isClearable={false}
									onChange={this.handleFilterChange}
									placeholder="All"
									isDisabled={!isAdminViewer}
									options={[
										{ label: 'All', value: 'all' },
										{ label: 'Experimental', value: 'experimental' },
										{ label: 'Permanent', value: 'permanent' }
									]}
								/>
							</Col>
							<Col xs={12} style={mergeStyles(CommonStyle.textRight, { width: '50%', float: 'right' })}>
								{isAdminViewer && <div data-tip data-for="touchcodeAdd">
									<TouchcodeEditModal
										launchButton={
											<div className="btn btn-primary" style={CommonStyle.btn}>
												<FaPlusCircle /> New Code
											</div>
										}
										touchcodeId={0}
										isNew
									/>
								</div>}
							</Col>
						</Row>
						<Row>
							<Col xs={12}>
								{!hasTouchcodes &&
									<h4 style={CommonStyle.textCenter}>You don't have any touchcodes. Speak to an administrator to get touchcodes.</h4>
								}
								{hasTouchcodes &&
									<PaginationProvider
										// set the PaginationProvider's key to the filter to force the table to reset when the filter changes
										key={filter}
										pagination={paginationFactory({
											custom: true,
											totalSize: tableData.length,
											sizePerPageList: [10, 25, 50, 100, 250, 500],
											alwaysShowAllBtns: true
										})}
									>
										{({ paginationProps, paginationTableProps }) => (
											<>
												<BootstrapTable
													data={tableData}
													columns={[{
														dataField: 'CodeId',
														text: 'Touchcode',
														formatter: this.codeColumnFormatter,
														formatExtraData: { permissions },
														sort: true,
														sortCaret: tableCaretRender
													}, {
														dataField: 'Id',
														text: 'Id',
														hidden: true,
														sort: true,
														sortCaret: tableCaretRender
													}, {
														dataField: 'Label',
														text: 'Label',
														formatter: this.labelColumnFormatter,
														sort: true,
														sortCaret: tableCaretRender
													}, ...(isAdminViewer ? [{
														dataField: 'Pattern',
														text: 'Code Coordinates',
														formatter: this.codeCoordinateColumnFormatter
													}] : []), {
														dataField: 'isExperimental',
														hidden: true,
														sort: true,
														sortCaret: tableCaretRender
													}]}
													keyField="Id"
													bodyClasses="touchcodeTbody"
													options={{
														sortName: this.state.sortName,
														sortOrder: this.state.sortOrder,
														onSortChange: this.onSortChange
													}}
													striped
													hover
													{...paginationTableProps}
												/>
												<SizePerPageDropdownStandalone {...paginationProps} variation="dropup" />
												<PaginationListStandalone {...paginationProps} />
											</>
										)}
									</PaginationProvider>
								}
							</Col>
						</Row>
					</div>
				}
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	return {
		touchcodes: state.touchcode.touchcodes,
		permissions: state.userRole.permissions,
		customerId: state.auth.customerId
	};
}

function mapDispatchToProps(dispatch) {
	const actions = bindActionCreators({
		fetchTouchcodesWithExperimental,
		clearTouchcodes,
		setRefreshActions,
		setCustomerIdEnabled
	}, dispatch);
	return { ...actions, dispatch };
}

TouchcodeManage = connect(mapStateToProps, mapDispatchToProps)(TouchcodeManage);

export default reduxForm(
	{ form: 'touchcodeManage' })(TouchcodeManage);
