// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { reduxForm, isValid, getFormValues } from 'redux-form';
import { FaTrash } from 'react-icons/lib/fa';
import Rodal from 'rodal';
import CommonStyle, { mergeStyles } from '../../styles/commonStyles';
import { formNames } from '../../util/constants';
import {
	fetchTouchcode,
	fetchTouchcodesWithExperimental,
	deleteTouchcode,
	newTouchcode,
	clearCurrentTouchcode
} from '../../ducks/touchcode.duck';
import { setCustomerIdEnabled } from '../../ducks/auth.duck';
import IconBtn from '../utilities/iconBtn';

import 'rodal/lib/rodal.css';

const formName = formNames.TOUCHCODE_EDIT;
const style = {
	modalStyle: {
		width: '50%',
		textAlign: 'left'
	},
	contentWrapper: {
		padding: '50px'
	},
	heading: {
		marginBottom: '20px'
	},
	btnRow: {
		marginTop: '35px'
	}
};

type Props = {
	touchcodeId: string,
	formValues: Object,
	dispatch: Function,
	handleSubmit: Function,
	launchButton: PropTypes.element,
	touchcode: Object,
	fetchTouchcodesWithExperimental: Function,
	fetchTouchcode: Function,
	deleteTouchcode: Function,
	clearCurrentTouchcode: Function,
	setCustomerIdEnabled: Function,
	isNew: boolean
};

export class TouchcodeDeleteModal extends Component {
	props: Props;

	constructor(props: Props) {
		super(props);

		this.state = {
			visible: false
		};

		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleCancelClick = this.handleCancelClick.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
	}

	handleFormSubmit() {
		const { touchcodeId } = this.props;

		this.props.deleteTouchcode(touchcodeId);

		this.hide();
	}

	handleCancelClick(event: SyntheticEvent): void {
		this.hide();
		event.preventDefault();
	}

	handleKeyPress(event: SyntheticEvent) {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	}

	show(): void {
		this.props.setCustomerIdEnabled(false);
		this.setState({ visible: true });
	}

	hide(): void {
		this.setState({ visible: false });
		this.props.fetchTouchcodesWithExperimental();
		this.props.setCustomerIdEnabled(true);
	}

	render() {
		const { visible } = this.state;

		return (
			<span onKeyPress={this.handleKeyPress}>
				<IconBtn icon={<FaTrash />} handler={this.show} />
				<Rodal
					visible={visible}
					height={300}
					showCloseButton={false}
					onClose={this.hide}
					customStyles={style.modalStyle}
				>
					<div style={style.contentWrapper}>
						<div style={{ textAlign: 'center' }}>
							<h3 style={mergeStyles(style.heading)}>
								Delete Code?
							</h3>
							<span> Are you sure you want to remove this code? </span>
						</div>
						<form>
							<Row>
								<Col xs={12}>
									<div style={{ margin: 'auto', width: '113px', paddingTop: '10px' }}>
										<button style={CommonStyle.btn} className="btn btn-default" onClick={this.handleCancelClick}>
											No
										</button>
										<button style={CommonStyle.btn} type="button" className="btn btn-danger" onClick={this.handleFormSubmit}>
											<span>Yes</span>
										</button>
									</div>
								</Col>
							</Row>
						</form>
					</div>
				</Rodal>
			</span>
		);
	}
}

function mapStateToProps(state) {
	return {
		formValues: getFormValues(formName)(state),
		isValid: isValid(formName)(state),
	};
}

TouchcodeDeleteModal = reduxForm({
	form: formName,
	enableReinitialize: true
})(TouchcodeDeleteModal);

export default connect(mapStateToProps, {
	fetchTouchcode,
	fetchTouchcodesWithExperimental,
	deleteTouchcode,
	newTouchcode,
	clearCurrentTouchcode,
	setCustomerIdEnabled
})(TouchcodeDeleteModal);
