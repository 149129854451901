import { createSelector } from 'reselect';

const getCampaignsForCustomer = createSelector(
	[
		state => state.analytics.campaigns,
		state => state.auth.customerId
	],
	(campaigns, customerId) => {
		if (customerId === undefined || customerId === null) {
			return campaigns;
		}
		return (campaigns || []).filter(campaign => campaign.CustomerId === customerId);
	}
);

export {
	getCampaignsForCustomer
};
