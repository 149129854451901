import React, { Component } from 'react';
import Select from './SelectInputHeightAware';
import CommonStyle from '../../styles/commonStyles';

const style = {
	btn: {
		position: 'absolute',
		top: '-12px',
		right: '20px'
	}
};

class SelectInput extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { props } = this;
		const { meta: { touched, error, warning }, attachedBtn } = props;

		const hasError = touched && error;
		const hasWarning = touched && warning;
		const { control: controlStyles, ...propStylesWithoutControl } = (props.styles || {});
		const customStyles = {
			control: provided => ({
				...provided,
				...(!hasWarning && !hasError ? {} : {
					borderColor: hasWarning ? '#FF9800' : '#f44336'
				}),
				...(controlStyles ? controlStyles() : {})
			}),
			...propStylesWithoutControl
		};

		return (
			<div>
				{attachedBtn && <div style={style.btn}>{attachedBtn}</div>}
				{props.label
					? <label>{props.label}</label>
					: <div />
				}
				<Select
					id={props.id}
					{...props}
					value={props.input.value}
					onChange={value => props.input.onChange(value)}
					onBlur={() => props.input.onBlur(props.input.value)}
					options={props.options}
					styles={customStyles}
				/>
				{touched && ((error && <span style={CommonStyle.textDanger}>{error}</span>)
					|| (warning && <span style={CommonStyle.textWarn}>{warning}</span>))}
			</div>
		);
	}
}

export default SelectInput;
