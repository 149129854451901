import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../util/history';
import BootstrapTable from 'react-bootstrap-table-next';
import { FaPencil, FaPlusCircle } from 'react-icons/lib/fa';
import { Row, Col } from 'react-bootstrap';
import { fetchUsers } from '../../ducks/user.duck';
import { resetPasswordAsAdmin, setCustomerIdEnabled, setRefreshActions, setCustomerId } from '../../ducks/auth.duck';
import { routes } from '../../util/constants';
import { tableCaretRender } from '../../util/tableUtil';
import cs from '../../styles/commonStyles';
import PageHeading from '../utilities/pageHeading';
import PageContainer from '../utilities/pageContainer';
import IconBtn from '../utilities/iconBtn';
import ConfirmModal from '../utilities/confirmModal';
import Spinner from '../utilities/spinner';
import ReactTooltip from 'react-tooltip';

const style = {
	nameLink: {
		...cs.pointer,
		color: 'black'
	}
};

class UserManage extends Component {
	static propTypes = {
		// Redux Store
		users: PropTypes.array,
		// Wrapped Actions
		fetchTargets: PropTypes.func,
		resetPasswordAsAdmin: PropTypes.func
	}

	constructor(props) {
		super(props);

		this.state = {
			sortName: 'Email',
			sortOrder: 'asc'
		};
	}

	componentDidMount() {
		this.props.fetchUsers();
		this.props.setRefreshActions([fetchUsers]);
		this.props.setCustomerIdEnabled(true);
	}

	onSortChange(sortName, sortOrder) {
		let newOrder = sortOrder;
		if (sortName !== this.state.sortName) {
			newOrder = 'asc';
		}

		this.setState({
			sortName,
			sortOrder: newOrder
		});
	}

	handleEditClick(userId, event) {
		event.preventDefault();

		history.push(`${routes.userEdit}/${userId}`);
	}

	handleResetPasswordClick(email, event) {
		event.preventDefault();

		this.props.resetPasswordAsAdmin(email);
	}

	nameFormatter(cell, row, rowIndex, formatExtraData) {
		const { permissions } = formatExtraData;
		const UserModifier = permissions ? permissions.UserModifies : null;
		if (UserModifier) {
			return (
				<div style={cs.splitSection}>
					<div style={cs.clipSection}>
						<a style={style.nameLink} onClick={this.handleEditClick.bind(this, row.Id)} data-tip data-for="userEdit">
							{cell}
						</a>
					</div>
					<div data-tip data-for="userEdit">
						<IconBtn handler={this.handleEditClick.bind(this, row.Id)} icon={<FaPencil />} />
					</div>
					<ReactTooltip id="userEdit" effect="solid" delayShow={500}>
						Edit User Info
					</ReactTooltip>
				</div>
			);
		}
		return (
			<div style={cs.splitSection}>
				<div style={cs.clipSection}>
					{cell}
				</div>
			</div>
		);
	}

	customerFormatter(cell) {
		return cell ? cell : '-';
	}

	roleFormatter(cell) {
		return cell;
	}

	resetPasswordFormatter(cell, row, rowIndex, formatExtraData) {
		const { Email } = row;
		const { permissions } = formatExtraData;
		const UserModifier = permissions ? permissions.UserModifies : null;
		if (UserModifier) {
			return (
				<ConfirmModal
					id="resetPasswordModal"
					title="Reset Password"
					body={
						<span>
							Are you sure you want to reset <strong><em>{Email}</em></strong>'s password?
						</span>
					}
					closeBtnText="No"
					launchBtn={
						<a style={cs.pointer}>Reset Password</a>
					}
					confirmBtn={
						<button className="btn btn-danger" onClick={this.handleResetPasswordClick.bind(this, Email)}>
							Yes
						</button>
					}
					action={this.handleResetPasswordClick.bind(this, Email)}
				/>
			);
		}
		return <div> N/A </div>;
	}

	renderAddButton() {
		const { permissions } = this.props;
		const UserModifier = permissions ? permissions.UserModifies : null;
		if (UserModifier) {
			return (
				<Row>
					<Col style={cs.textRight} xs={12}>
						<Link style={cs.btn} className="btn btn-primary" to={routes.userNew}>
							<FaPlusCircle /> Add User
						</Link>
					</Col>
				</Row>
			);
		}
		return null;
	}

	render() {
		const { users, permissions } = this.props;
		const data = [];
		if (users) {
			users.forEach(user => {
				data.push({ FullName: user.FullName, Customer: (user.Customer === null ? '-' : user.Customer.Name), UserRole: (user.UserRole === null ? null : user.UserRole.Name), Email: user.Email, Id: user.Id });
			});
		}
		return (
			<PageContainer>
				<PageHeading>Users</PageHeading>

				{this.renderAddButton()}

				{!users &&
					<Spinner />
				}

				<Row>
					<Col xs={12}>
						{users && users.length === 0 &&
							<h4 style={cs.textCenter}>There are no users.</h4>
						}
						{users && users.length > 0 &&
							<BootstrapTable
								data={data}
								columns={[{
									dataField: 'FullName',
									text: 'Name',
									formatter: this.nameFormatter.bind(this),
									formatExtraData: { permissions },
									sort: true,
									sortCaret: tableCaretRender
								}, {
									dataField: 'Customer',
									text: 'Customer',
									formatter: this.customerFormatter.bind(this),
									sort: true,
									sortCaret: tableCaretRender
								}, {
									dataField: 'UserRole',
									text: 'Role',
									formatter: this.roleFormatter.bind(this),
									sort: true,
									sortCaret: tableCaretRender
								}, {
									dataField: 'Email',
									text: 'Email',
									sort: true,
									sortCaret: tableCaretRender
								}, {
									isDummyField: true,
									text: 'Reset Password',
									formatter: this.resetPasswordFormatter.bind(this),
									formatExtraData: { permissions }
								}, {
									dataField: 'Id',
									text: 'User Id',
									hidden: true,
									sort: true,
									sortCaret: tableCaretRender
								}]}
								keyField="Id"
								bodyClasses="touchcodeTbody"
								options={{
									sortName: this.state.sortName,
									sortOrder: this.state.sortOrder,
									onSortChange: this.onSortChange.bind(this)
								}}
								striped
								hover
							/>
						}
					</Col>
				</Row>
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	return {
		users: state.user.users,
		permissions: state.userRole.permissions
	};
}

UserManage = connect(mapStateToProps, { fetchUsers, resetPasswordAsAdmin, setCustomerIdEnabled, setRefreshActions, setCustomerId, })(UserManage);

export default UserManage;
