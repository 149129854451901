// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Field, reduxForm, isValid, getFormValues, change, touch } from 'redux-form';
import { FaPencil, FaCheck } from 'react-icons/lib/fa';
import ReactTooltip from 'react-tooltip';
import Rodal from 'rodal';
import CommonStyle, { mergeStyles } from '../../styles/commonStyles';
import { formNames } from '../../util/constants';
import * as validators from '../../util/FormValidators';
import {
	fetchTouchcode,
	fetchTouchcodesWithExperimental,
	updateTouchcode,
	newTouchcode,
	clearCurrentTouchcode
} from '../../ducks/touchcode.duck';
import { setCustomerIdEnabled } from '../../ducks/auth.duck';
import FieldWrapper from '../utilities/fieldWrapper';
import IconBtn from '../utilities/iconBtn';
import Spinner from '../utilities/spinner';
import CancelModal from '../utilities/cancelModal';

import 'rodal/lib/rodal.css';

const formName = formNames.TOUCHCODE_EDIT;
const style = {
	modalStyle: {
		width: '50%',
		textAlign: 'left'
	},
	contentWrapper: {
		padding: '50px'
	},
	heading: {
		marginBottom: '20px'
	},
	btnRow: {
		marginTop: '35px'
	}
};

type Props = {
	touchcodeId: string,
	formValues: Object,
	dispatch: Function,
	handleSubmit: Function,
	launchButton: PropTypes.element,
	touchcode: Object,
	fetchTouchcodesWithExperimental: Function,
	fetchTouchcode: Function,
	updateTouchcode: Function,
	newTouchcode: Function,
	clearCurrentTouchcode: Function,
	setCustomerIdEnabled: Function,
	isNew: boolean
};

const validatePattern = pattern => {
	const invalidError = 'Invalid. Must be in \'(x,y),(x,y),(x,y)...\' format';
	if (pattern == null || pattern == undefined || pattern.length === 0) {
		return 'Required';
	}
	const data = pattern.replace(/\(/g, '').replace(/\)/g, '').replace(/( )/g, '').split(',');
	if (data.length % 2 !== 0 || data.length < 6) {
		return invalidError;
	}
	for (let i = 0; i < data.length; i++) {
		if (isNaN(data[i]) || data[i] === '') {
			return invalidError;
		}
	}
	return undefined;
};

export class TouchcodeEditModal extends Component {
	props: Props;

	constructor(props: Props) {
		super(props);

		this.state = {
			visible: false
		};

		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleCancelClick = this.handleCancelClick.bind(this);
	}

	deFormatPattern(pattern) {
		return '[' + pattern.replace(/\(/g, '').replace(/\)/g, '').replace(/( )/g, '') + ']';
	}

	handleFormSubmit(form: Object) {
		const { isNew, touchcodeId, touchcode } = this.props;

		let isExperimental = 0;
		if (isNew) {
			isExperimental = 1;
		} else {
			isExperimental = touchcode.isExperimental;
		}
		const nTouchcode = {
			Label: form.label ? form.label : '',
			Pattern: this.deFormatPattern(form.coordinates),
			isExperimental: isExperimental
		};

		if (isNew) {
			this.props.newTouchcode(nTouchcode);
		} else {
			this.props.updateTouchcode(touchcodeId, nTouchcode);
		}

		this.hide();
		if (isNew) {
			form.label = '';
			form.coordinates = '';
		}
	}

	handleCancelClick(event: SyntheticEvent): void {
		const { isNew, dispatch } = this.props;
		if (isNew) {
			dispatch(change('touchcodeEdit', 'label', ''));
			dispatch(change('touchcodeEdit', 'coordinates', ''));
		}
		this.hide();
		event.preventDefault();
	}

	handleKeyPress(event: SyntheticEvent) {
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();

			if (!validatePattern(this.props.formValues.coordinates)) {
				this.handleFormSubmit(this.props.formValues);
			} else {
				const { dispatch } = this.props;
				dispatch(touch(formName, 'label'));
				dispatch(touch(formName, 'coordinates'));
			}
		}
	}

	show(): void {
		this.props.setCustomerIdEnabled(false);
		const { isNew } = this.props;
		if (!isNew) {
			this.props.fetchTouchcode(this.props.touchcodeId);
		}
		this.setState({ visible: true });
	}

	hide(): void {
		this.setState({ visible: false });
		this.props.clearCurrentTouchcode();
		this.props.fetchTouchcodesWithExperimental();
		this.props.setCustomerIdEnabled(true);
	}

	render() {
		const { touchcodeId, handleSubmit, touchcode, launchButton, isNew, formValues } = this.props;
		const { visible } = this.state;
		let valid = true;
		if (formValues) {
			valid = validatePattern(formValues.coordinates);
		}
		let initialValues = undefined;
		if (this.props.initialValues) {
			initialValues = this.props.initialValues;
		}
		return (
			<span onKeyPress={this.handleKeyPress}>
				{launchButton ? <span onClick={this.show}>{launchButton}</span> : <IconBtn icon={<FaPencil />} handler={this.show} />}
				<Rodal
					visible={visible}
					height={300}
					showCloseButton={false}
					onClose={this.hide}
					customStyles={style.modalStyle}
				>
					<div style={style.contentWrapper}>
						<h3 style={mergeStyles(style.heading, CommonStyle.textLeft)}>
							{isNew ? 'New' : 'Edit'} Touchcode {!isNew && touchcodeId}
						</h3>
						{(isNew || touchcode) &&
							<form onSubmit={handleSubmit(this.handleFormSubmit)}>
								<Row>
									<Col xs={12} sm={8} md={6} style={CommonStyle.formRow}>
										<div data-tip data-for="touchcodeLabel" style={{ maxWidth: '250px!important' }}>
											<Field name="label" type="text" placeholder="Touchcode Label"
												component={FieldWrapper} label="Label"
											/>
											<ReactTooltip id="touchcodeLabel" effect="solid" delayShow={500} place="top" data-multiline>
												This will allow you to easily identify the Touchcode on the Campaigns Page of the Dashboard
											</ReactTooltip>
										</div>
										<small><em>Optional</em></small>
									</Col>
									{(isNew || touchcode.isExperimental) &&
										<Col xs={12} sm={8} md={6} style={CommonStyle.formRow}>
											<div data-tip data-for="touchcodeCoordinates" style={{ maxWidth: '250px!important' }}>
												<Field name="coordinates" type="text" placeholder="At least 3 sets of points in (x,y) format"
													component={FieldWrapper} label="Code Coordinates"
													validate={[validators.required, validatePattern]}
												/>
												<ReactTooltip id="touchcodeCoordinates" effect="solid" delayShow={500} place="top" data-multiline>
													At least 3 sets of points in (x,y) format
												</ReactTooltip>
											</div>
										</Col>
									}
								</Row>
								<Row>
									<Col xs={12}>
										<div style={CommonStyle.floatRight}>
											<CancelModal
												initialValues={initialValues}
												currentValues={formValues}
												onCancel={this.handleCancelClick}
											/>
											<button style={CommonStyle.btn} type="submit" className="btn btn-primary" disabled={valid}>
												<span><FaCheck /> Save</span>
											</button>
										</div>
									</Col>
								</Row>
							</form>
						}
						{!isNew && !touchcode && <Spinner />}
					</div>
				</Rodal>
			</span>
		);
	}
}

function formatPattern(code) {
	const data = code.split('[')[1].split(']')[0].split(/,[ ]?/);
	let display = '';
	for (let i = 0; i < data.length; i += 2) {
		display += '(' + data[i] + ', ' + data[i + 1] + '), ';
	}
	return display.substring(0, display.length - 2);
}

function mapStateToProps(state) {
	const { currentTouchcode } = state.touchcode;

	let initialValues;

	if (currentTouchcode !== undefined) {
		const { Label, Pattern } = currentTouchcode;
		initialValues = {
			'label': Label,
			'coordinates': formatPattern(Pattern)
		};
	}

	return {
		touchcode: currentTouchcode,
		initialValues: initialValues,
		formValues: getFormValues(formName)(state),
		isValid: isValid(formName)(state),
	};
}

TouchcodeEditModal = reduxForm({
	form: formName,
	enableReinitialize: true
})(TouchcodeEditModal);

export default connect(mapStateToProps, {
	fetchTouchcode,
	fetchTouchcodesWithExperimental,
	updateTouchcode,
	newTouchcode,
	clearCurrentTouchcode,
	setCustomerIdEnabled
})(TouchcodeEditModal);
