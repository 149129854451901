import React, { PureComponent } from 'react';
import { Throttle } from 'react-throttle';
import { createCSV } from '../../util/csvExporter';
import { toastr } from 'react-redux-toastr';
import { MONTHS, DISPLAYMODE } from '../../util/constants';
import { convertMilitaryHourToCivilian } from '../../util/DateUtil';
import { connect } from 'react-redux';
import { fetchEngagementsFromMap, setShouldExportMapData } from '../../ducks/analytics.duck';


class AnalyticsExportButton extends PureComponent {
	constructor(props) {
		super(props);
	}

	componentDidUpdate() {
		const { mapDataToExport, shouldExportMapData } = this.props;
		const hasValidDataToExport = mapDataToExport && mapDataToExport.length > 0;
		if (shouldExportMapData) {
			if (hasValidDataToExport) {
				this.exportMapData();
				toastr.success('Exporting CSV', `Exported ${mapDataToExport.length} engagements`);
			} else {
				toastr.error('No data for this map!', 'No data was exported for this part of the map');
			}
			this.props.setShouldExportMapData(false);
		}
	}

	exportMapData() {
		const { mapDataToExport } = this.props;
		const headers = Object.keys(mapDataToExport[0]);
		const data = mapDataToExport.map(mapData => {
			return headers.map(key => mapData[key] || 'NULL');
		});
		const fileName = this.getMapCSVName();
		createCSV(headers, data, fileName);
	}

	getCampaignNameFromSelectedId() {
		const { campaigns, selectedCampaignId } = this.props;
		const foundCampaign = campaigns.find(campaign => campaign.CampaignKey === selectedCampaignId);
		const campaignName = foundCampaign ? foundCampaign.Name : selectedCampaignId;
		return campaignName === 'all' ? 'all campaigns' : campaignName;
	}

	// Is hooked up to use a lot more params
	// When ready, connect the props to receive map data and uncomment the lines below
	getMapCSVName() {
		// const { match, campaigns, mapDateTimeInfo, selectedCampaignId } = nextProps;
		const { mapDateTimeInfo } = this.props;
		const { displayHour, displayDay, displayMonth, displayYear, displayMode } = mapDateTimeInfo;
		// const { campaignId, countryCode, provinceCode } = match.params;

		const displayHourFormatted = displayHour ? convertMilitaryHourToCivilian(displayHour) : undefined;

		const campaignName = this.getCampaignNameFromSelectedId();

		const monthName = MONTHS[Math.max(0, parseInt(displayMonth) - 1)];

		let dateValues = [];

		// If we aren't on the year display, use the date info
		if (displayMode !== DISPLAYMODE.YEAR) {
			dateValues = [monthName, displayDay, displayHourFormatted, displayYear];
		}

		// Uncomment this when we want to use all of these values
		// const params = ['engagements', campaignName, countryCode, provinceCode, ...dateValues];
		const params = ['engagements', campaignName];
		return params
			.filter(param => typeof (param) !== 'undefined' && param.length > 0)
			.join('_');
	}

	handleExport = () => {
		const { mapDateTimeInfo, selectedCampaignId } = this.props;
		mapDateTimeInfo.campaignId = selectedCampaignId;
		toastr.info(`Please wait, exporting all engagements for ${this.getCampaignNameFromSelectedId()}`);
		this.props.fetchEngagementsFromMap(mapDateTimeInfo, true);
	}

	/**
	 * Determines if the "Export Data" button is visible
	 */
	isVisible = () => {
		const { location } = this.props;
		return location && location.pathname && location.pathname.startsWith('/analytics-overview');
	}

	render() {
		if (this.isVisible()) {
			return (
				<div className="analytics_export_button_holder">
					<Throttle time="2000" handler="onClick">
						<button
							className="exportButton btn btn-primary"
							onClick={this.handleExport}
							data-cy="export-data"
						>
							Export Data
						</button>
					</Throttle>
				</div>
			);
		}
		return null;
	}
}

function mapStateToProps(state) {
	return {
		campaigns: state.analytics.campaigns,
		mapDataToExport: state.analytics.mapDataToExport,
		mapDateTimeInfo: state.analytics.mapDateTimeInfo,
		shouldExportMapData: state.analytics.shouldExportMapData,
		selectedCampaignId: state.analytics.selectedCampaignId,
	};
}

export default connect(mapStateToProps, {
	fetchEngagementsFromMap,
	setShouldExportMapData
})(AnalyticsExportButton);
