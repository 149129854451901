// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchCodeset, clearCurrentCodeset } from '../../ducks/codeset.duck';
import CodesetEditForm from './codesetEditForm';
import PageContainer from '../utilities/pageContainer';
import PageHeading from '../utilities/pageHeading';
import Spinner from '../utilities/spinner';

class CodesetEdit extends Component {
	constructor(props: Props) {
		super(props);
	}

	componentDidMount() {
		const { match } = this.props;
		this.props.fetchCodeset(match.params.id);
	}

	componentWillUnmount() {
		this.props.clearCurrentCodeset();
	}

	render() {
		const { currentCodeset } = this.props;

		return (
			<PageContainer>
				<PageHeading>
					<span>
						Edit Codeset:&nbsp;
						{currentCodeset &&
						<span>
							{currentCodeset.Label || currentCodeset.Id}
						</span>
						}
					</span>
				</PageHeading>

				{currentCodeset && <CodesetEditForm />}
				{!currentCodeset && <Spinner />}
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	return {
		currentCodeset: state.codeset.currentCodeset
	};
}

CodesetEdit = connect(mapStateToProps, { fetchCodeset, clearCurrentCodeset })(CodesetEdit);

export default CodesetEdit;
