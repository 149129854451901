import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import CommonStyle from '../../styles/commonStyles';
import ModalLauncher from '../utilities/modalLauncher';

const style = {
	messageContainer: {
		wordWrap: 'break-word',
		whiteSpace: 'normal'
	}
};

class CampaignEditModal extends Component {
	static propTypes = {
		actionBtn: PropTypes.element,
		campaignName: PropTypes.string,
		campaignId: PropTypes.string,
		title: PropTypes.string,
		message: PropTypes.string,
		launchBtn: PropTypes.element,
		action: PropTypes.func
	};

	constructor(props) {
		super(props);
	}

	render() {
		const { actionBtn, campaignId, title, message, launchBtn, action } = this.props;

		return (
			<ModalLauncher
				name={campaignId}
				launchBtn={launchBtn}
				closeBtn={<a className="btn btn-default">Cancel</a>}
				actionBtn={actionBtn}
				action={action}
				>
				<Row>
					<Col xs={12}>
						<h3 style={CommonStyle.textCenter}>{title}</h3>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div style={style.messageContainer}>{message}</div>
					</Col>
				</Row>
			</ModalLauncher>
		);
	}
}

export default CampaignEditModal;
