// @flow

import React, { Component } from 'react';
import * as validators from '../../util/FormValidators';
import cs from '../../styles/commonStyles';
import { FaCaretUp, FaCaretDown } from 'react-icons/lib/fa';

class CaratDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showContents: false
		};
	}

	isOpen() {
		const { showContents } = this.state;
		return showContents;
	}

	toggleOpen() {
		const { onOpen, onClose } = this.props;
		const { showContents } = this.state;
		this.setState({ showContents: !showContents });
		if (showContents && onClose) onClose();
		if (!showContents && onOpen) onOpen();
	}

	render() {
		const { title } = this.props;
		const { showContents } = this.state;

		return (
			<span>
				<strong onClick={this.toggleOpen.bind(this)} style={ { marginLeft: '15px', cursor: 'pointer' } }>{title} {showContents ? <FaCaretUp/> : <FaCaretDown/> }</strong>
				{showContents && this.props.children}
			</span>
		);
	}
}

export default CaratDropdown;
