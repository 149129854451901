import PropTypes from 'prop-types';
import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import {
	registerField,
	change,
} from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import { formNames } from '../../util/constants';
import { mergeStyles, colorDanger } from '../../styles/commonStyles';
import SearchBox from '../utilities/googleMapSearchbox';

const formName = formNames.ACTION_EDIT;
const style = {
	mapControlsRow: {
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		marginBottom: '10px'
	},
	mapContainer: {
		width: '100%',
		height: '500px'
	},
	box: {
		width: '100px',
		height: '100px',
		backgroundColor: 'blue',
		color: 'white'
	},
	errorMessage: {
		color: colorDanger
	}
};

const rectangleOptions = {
	fillColor: '#000000',
	fillOpacity: 0.15,
	strokeWeight: 3,
	editable: true,
	draggable: true,
	zIndex: 1
};

class ActionEditFormLocation extends Component {
	static propTypes = {
		formName: PropTypes.string
	}

	constructor(props) {
		super(props);

		this.state = {
			bounds: null,
			shape: null,
			touched: false,
			initialized: false
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(registerField(formName, 'location', 'Field'));
	}

	drawInitialRectangle() {
		if (this.state.initialized) { return; }
		const { formInitialValues } = this.props;
		if (!formInitialValues) { return; }
		const { location } = formInitialValues;
		if (!location) { return; }


		const rectangle = new google.maps.Rectangle(rectangleOptions);
		const NE = new google.maps.LatLng(location.NE.lat, location.NE.lon);
		const SW = new google.maps.LatLng(location.SW.lat, location.SW.lon);
		const bounds = new google.maps.LatLngBounds(SW, NE);
		rectangle.setBounds(bounds);
		rectangle.setMap(this.map);
		this.shape = rectangle;

		this.map.panTo(bounds.getCenter());
		this.handleRectangleComplete(rectangle);
		this.drawingManager.setDrawingMode(null);

		this.setState({ initialized: true });
	}

	updateFormValues(shape) {
		const { dispatch } = this.props;

		let location = null;

		if (shape != null) {
			const SWBounds = shape.getBounds().getSouthWest();
			const NEBounds = shape.getBounds().getNorthEast();
			const SW = { lat: SWBounds.lat(), lon: SWBounds.lng() };
			const NE = { lat: NEBounds.lat(), lon: NEBounds.lng() };
			location = { SW, NE };
		}

		dispatch(change(formName, 'location', location));
	}

	handleBoundsChanged(_event) {
		this.updateFormValues(this.state.shape);
	}

	handleRectangleComplete(shape) {
		shape.addListener('bounds_changed', this.handleBoundsChanged.bind(this));
		this.updateFormValues(shape);
		this.setState({ shape: shape });
		if (!this.state.touched) {
			this.setState({ touched: true });
		}
		this.drawingManager.setDrawingMode(null);
	}

	handleDeleteButtonClick(_event) {
		const { shape } = this.state;

		this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.RECTANGLE);

		if (shape != null) {
			shape.setMap(null);
			this.setState({ shape: null });
			this.updateFormValues(null);
		}
	}

	onPlacesChanged(places) {
		if (places.length === 0) {
			return;
		}

		// For each place, get the icon, name and location.
		const bounds = new google.maps.LatLngBounds();
		places.forEach( place => {
			if (!place.geometry) {
				return;
			}

			if (place.geometry.viewport) {
				bounds.union(place.geometry.viewport);
			} else {
				bounds.extend(place.geometry.location);
			}
		});

		this.map.fitBounds(bounds);
	}

	handleGeolocationLoaded(geoLocation) {
		if (geoLocation) {
			this.map.panTo(geoLocation);
			this.map.setZoom(14);
		}
		this.drawingManager = new google.maps.drawing.DrawingManager({
			drawingMode: google.maps.drawing.OverlayType.RECTANGLE,
			drawingControl: false,
			rectangleOptions
		});
		this.drawingManager.setMap(this.map);

		this.drawInitialRectangle();

		google.maps.event.addListener(this.drawingManager, 'rectanglecomplete', this.handleRectangleComplete.bind(this));
	}

	handleGoogleApiLoaded(google) {
		this.map = google.map;

		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(pos => {
				const geoLocation = { lat: pos.coords.latitude, lng: pos.coords.longitude };
				console.log('Geolocation available');
				this.handleGeolocationLoaded(geoLocation);
			}, (err) => {
				console.log('Geolocation API is available, but user rejected access or there was an error', err);
				this.handleGeolocationLoaded(null);
			});
		} else {
			console.log('Geolocation API not available in this browser');
			this.handleGeolocationLoaded(null);
		}
	}

	render() {
		const { shape, touched } = this.state;
		const { formErrors, submitFailed } = this.props;
		const locationError = formErrors && formErrors.location;

		const showError = (touched || submitFailed) && locationError;

		const errorStyle = {};
		if (showError) {
			errorStyle.border = '2px solid red';
		}

		return (
			<Row>
				<Col xs={12}>
					<div style={style.mapControlsRow}>
						<SearchBox placeholder="Search for a Place" onPlacesChanged={this.onPlacesChanged.bind(this)} />
						{showError &&
							<span style={style.errorMessage}>{locationError}</span>
						}
						{shape &&
							<button className="btn btn-danger" onClick={this.handleDeleteButtonClick.bind(this)}>Delete Selection</button>
						}
					</div>
					<div id="mapContainer" style={mergeStyles(style.mapContainer, errorStyle)}>
						<GoogleMapReact
							defaultCenter={{ lat: 32.426320, lng: -28.160956 }}
							defaultZoom={0}
							yesIWantToUseGoogleMapApiInternals
							onGoogleApiLoaded={this.handleGoogleApiLoaded.bind(this)}
						/>
					</div>
				</Col>
			</Row>
		);
	}
}

// ActionEditFormLocation = reduxForm({
// 	form: formName,
// 	destroyOnUnmount: false
// })(ActionEditFormLocation);

// function mapStateToProps(state) {
// 	return {
// 		formErrors: getFormSyncErrors(formName)(state),
// 		submitFailed: hasSubmitFailed(formName)(state),
// 		formInitialValues: getFormInitialValues(formName)(state)
// 	};
// }

// export default connect(mapStateToProps)(ActionEditFormLocation);

export default ActionEditFormLocation;
