// Routes
export const routes = {
	index: '/',
	settings: '/settings',
	home: '/home',
	customerManage: '/customer',
	notFound: '/notFound',
	callback: '/callback',

	touchcodeManage: '/touchcode',
	touchcodeEdit: '/touchcode/edit',

	campaignManage: '/campaign',
	campaignNew: '/campaign/new',
	campaignEdit: '/campaign/edit',

	actionManage: '/action',
	actionNew: '/action/new',
	actionEdit: '/action/edit',

	targetManage: '/target',
	targetNew: '/target/new',
	targetEdit: '/target/edit',

	logout: '/logout',

	userManage: '/user',
	userNew: '/user/new',
	userEdit: '/user/edit',

	codesetManage: '/codeset',
	codesetNew: '/codeset/new',
	codesetEdit: '/codeset/edit',

	analytics: '/analytics-overview',
	analyticsDateTime: '/analytics/dateTime',
	analyticsLocation: '/analytics/location',
	analyticsUser: '/analytics/user',
	authExpired: '/authExpired'

};

export const DISPLAYMODE = {
	DAY: 'day',
	MONTH: 'month',
	YEAR: 'year',
	HOUR: 'hour'
};

export const EDIT_CAMPAIGN_REACTIVATE_ACTION = 'reactivate';

// Campaign status enum
export const statusOptions = {
	'1': 'Active',
	'2': 'Paused',
	'3': 'Archived',
	'4': 'Upcoming',
	'5': 'Saved draft',
};

export const statusOptionsByName = {
	'active': '1',
	'paused': '2',
	'archived': '3',
	'upcoming': '4',
	'saved_draft': '5',
};

export const ADMIN_VIEW = 'AdminView';
export const ANALYTICS_VIEWS = 'AnalyticsViews';
export const ANALYTICS_CREATES = 'AnalyticsCreates';
export const ANALYTICS_MODIFIES = 'AnalyticsModifies';
export const ANALYTICS_DELETES = 'AnalyticsDeletes';
export const CAMPAIGN_VIEWS = 'CampaignViews';
export const CAMPAIGN_CREATES = 'CampaignCreates';
export const CAMPAIGN_MODIFIES = 'CampaignModifies';
export const CAMPAIGN_DELETES = 'CampaignDeletes';
export const CUSTOMER_VIEWS = 'CustomerViews';
export const CUSTOMER_CREATES = 'CustomerCreates';
export const CUSTOMER_MODIFIES = 'AdminModifies';
export const CUSTOMER_DELETES = 'AdminDeletes';
export const USER_VIEWS = 'UserViews';
export const USER_CREATES = 'UserCreates';
export const USER_MODIFIES = 'UserModifies';
export const USER_DELETES = 'UserDeletes';
export const ADMIN = 1;
export const USER = 3;

// Local storage
export const LS = {
	TOKEN: 'token',
	USER_TYPE: 'userType',
	WIP: 'workInProgress'
};

export const RULE_TYPES = {
	TIME: 'time',
	DATE: 'date',
	LOCATION: 'location'
};

export const triggerTypes = {
	NONE: 0,
	DATE: 1,
	TIME: 2,
	LOCATION: 4
};

// Redux Form Names
export const formNames = {
	ACTION_EDIT: 'actionEdit',
	TARGET_EDIT: 'targetEdit',
	TOUCHCODE_EDIT: 'touchcodeEdit',
	USER_EDIT: 'userEdit',
	CUSTOMER_EDIT: 'customerEdit',
	CODESET_EDIT: 'codesetEdit'
};

// Power BI Reports
export const REPORT_TYPE = {
	HOME: 'home'
};

export const reportPages = {
	HOME: 'ReportSection',
	BY_DATE_TIME: 'ReportSection1'
};

export const LOCATION_RESOLUTION = {
	COUNTRY: 'Country',
	PROVINCE: 'Province',
	POSTAL: 'Postal',
	SUBCONTINENT: 'Subcontinent'
};

export const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const REGION_CODES = {};
