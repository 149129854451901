import PropTypes from 'prop-types';
import React, { Component } from 'react';

const style = {
	formContainer: {
		flex: '1',
		display: 'flex',
		flexFlow: 'column',
		justifyContent: 'space-between'
	},
};

class FormContainer extends Component {
	static propTypes = {
		submitHandler: PropTypes.func,
		children: PropTypes.node
	}

	constructor(props) {
		super(props);
	}

	render() {
		const { submitHandler, children } = this.props;
		return (
			<form style={style.formContainer} onSubmit={submitHandler}>
				{children}
			</form>
		);
	}
}

export default FormContainer;
