import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ThreeBounce } from 'better-react-spinkit';
import CommonStyle from '../../styles/commonStyles';

const style = {
	spinner: {
		margin: 'auto'
	}
};

class Spinner extends Component {
	static propTypes = {
		size: PropTypes.number
	}

	constructor(props) {
		super(props);
	}

	render() {
		const { size } = this.props;

		return (
			<ThreeBounce style={CommonStyle.textCenter} style={style.spinner} size={size || 20} />
		);
	}
}

export default Spinner;
