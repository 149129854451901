import { createActionName, BASE_URL } from './index';
import axios from 'axios';
import history from '../util/history';
import { routes } from '../util/constants';

// Actions
const DUCK_NAME = 'decoderType';
const FETCH_DECODER_TYPES = createActionName(DUCK_NAME, 'FETCH_DECODER_TYPES');
const FETCH_DECODER_PARAMETER_TYPES = createActionName(DUCK_NAME, 'FETCH_DECODER_PARAMETER_TYPES');
const FETCH_DECODER_PARAMETER_CONFIGS = createActionName(DUCK_NAME, 'FETCH_DECODER_PARAMETER_CONFIGS');

// Reducer
export default function (state = {}, action = {}) {
	switch (action.type) {
		case FETCH_DECODER_TYPES: {
			return { ...state, 'decoderTypes': action.payload.data };
		}
		case FETCH_DECODER_PARAMETER_TYPES: {
			return { ...state, 'decoderParameterTypes': action.payload.data };
		}
		case FETCH_DECODER_PARAMETER_CONFIGS: {
			return { ...state, 'decoderParameterConfigs': action.payload.data };
		}
		default:
			return state;
	}
}

// Action Creators
export function fetchDecoderTypes() {
	const url = `${BASE_URL}/decoderTypes/`;
	return dispatch => {
		axios.get(url)
			.then(response => {
				dispatch({
					type: FETCH_DECODER_TYPES,
					payload: response
				});
			})
			.catch(err => {
				// TODO: Implement me
			});
	};
}

export function fetchDecoderParameterTypes() {
	return dispatch => {
		axios.get(`${BASE_URL}/decoderTypes/parameter/types/`)
			.then(response => {
				dispatch({
					type: FETCH_DECODER_PARAMETER_TYPES,
					payload: response
				});
			})
			.catch(err => {
				// TODO: Implement me
			});
	};
}

export function fetchDecoderParameterConfigs() {
	return dispatch => {
		axios.get(`${BASE_URL}/decoderTypes/parameter/configs/`)
			.then(response => {
				dispatch({
					type: FETCH_DECODER_PARAMETER_CONFIGS,
					payload: response
				});
			})
			.catch(err => {
				// TODO: Implement me
			});
	};
}