import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import Spinner from '../utilities/spinner';
import cs from '../../styles/commonStyles';

class AnalyticsCounts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			campaignId: null,
		};
	}

	formatEngagementsByUserType(engagementsByUsersType) {
		const data = [0, 0];
		const category = [0, 0, 0];

		for (const val of engagementsByUsersType.Engagements) {
			category[val.GroupCategory] = val.Engagements;
		}

		data[0] = category[0];
		data[1] = category[1] + category[2];
		return data;
	}

	formatEngagementsTotal(engagementsByUsersType) {
		const category = [0, 0, 0];

		for (const val of engagementsByUsersType.Engagements) {
			category[val.GroupCategory] = val.Engagements;
		}

		return category[0] + category[1] + category[2];
	}

	render() {
		const { engagementsByUserType } = this.props;
		if (engagementsByUserType === undefined || engagementsByUserType === null) {
			return (
				<Row>
					<Col style={{ textAlign: 'center', marginTop: '20%' }}> <Spinner /> </Col>
				</Row>
			);
		}
		const totalEngagements = this.formatEngagementsTotal(engagementsByUserType);
		const userTypes = this.formatEngagementsByUserType(engagementsByUserType);

		return (
			<div>
				<Row>
					<Col xs={12}>
						{this.props.totalUsers
							? <h1 style={{ ...cs.textGreen, ...cs.textCenter, fontSize: '5.3em' }}>{userTypes[0] + userTypes[1]} </h1>
							: ' '
						}
						{this.props.totalEngagements
							? <h1 style={{ ...cs.primaryDarkBlue, ...cs.textCenter, fontSize: '5.3em' }} data-cy="total-engagements"> {totalEngagements} </h1>
							: ' '
						}
						{this.props.newUsers
							? <h1 style={{ ...cs.textGreen, ...cs.textCenter, fontSize: '5.3em' }} data-cy="total-new-users">{userTypes[1]}</h1>
							: ' '
						}
						{this.props.returningUsers
							? <h1 style={{ ...cs.textCenter, ...cs.textPrimary, fontSize: '5.3em' }} data-cy="total-returning-users">{this.props.totalReturningUsers}</h1>
							: ' '
						}
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<h3 style={{ ...cs.textCenter, fontSize: '1.0em' }}>
							{this.props.totalUsers
								? 'TOTAL USERS'
								: ' '
							}
							{this.props.totalEngagements
								? 'TOTAL ENGAGEMENTS'
								: ' '
							}
							{this.props.newUsers
								? 'TOTAL NEW USERS'
								: ' '
							}
							{this.props.returningUsers
								? 'TOTAL RETURNING USERS'
								: ' '
							}
						</h3>
					</Col>
				</Row>
			</div >
		);
	}
}

export default AnalyticsCounts;
