import axios from 'axios';
import { getAuthStore } from '../components/auth/auth';

/**
 * Attempts to authorize the client with our current auth status
 * If our token has expired or we get a 401 back from the server, re-authorize
 * This will bring us back to the login screen as needed or retry the request with our new JWT
 * @param {*} response
 * @param {*} error
 */
const tryAuthorization = (response, error) => {
	return new Promise((resolve, reject) => {
		// If we have a response, resolve right away
		if (response) {
			return resolve(response);
		}

		const authStore = getAuthStore();
		const { authObj } = authStore;
		const { isAuthenticated, verifySession, getJWTHeader } = authObj;
		if (authObj) {
			if (!isAuthenticated() || (error && error.response && error.response.status === 401)) {
				verifySession()
					.then(newSession => {
						// After new session is set, grab new token and retry the request
						const newAuthHeader = getJWTHeader();
						if (error.config && newAuthHeader) {
							error.config.headers.Authorization = newAuthHeader;

							// Retry the oringal request again with new token
							axios.request(error.config)
								.then(resolve)
								.catch(err => {
									// To prevent toastr error when navigating
									if (err.response.status !== 401) {
										reject(err);
									}
								});
						}
					})
					.catch(sessionError => {
						// console.log('We verified a session with err ', sessionError);
						// Might be worth to reject original error
						// Commented out to prevent 401 toastr errors when using certain features such as map navigation
						// reject(error);
					});
			}
		} else if (error) {
			return reject(error);
		}
	});
};

const onSuccess = response => {
	return tryAuthorization(response);
};

const onFailed = error => {
	return tryAuthorization(undefined, error);
};

axios.interceptors.request.use(
	config => {
		return onSuccess(config);
	},
	error => {
		return onFailed(error);
	}
);

axios.interceptors.response.use(
	response => {
		return onSuccess(response);
	},
	error => {
		return onFailed(error);
	}
);
