import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues, isValid, touch } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import history from '../../util/history';
import { FaCheck, FaArrowCircleLeft, FaArrowCircleRight, FaTimesCircle } from 'react-icons/lib/fa';
import { formNames, routes } from '../../util/constants';
import * as validators from '../../util/FormValidators';
import cs from '../../styles/commonStyles';
import FieldWrapper from '../utilities/fieldWrapper';
import FormContainer from '../utilities/formContainer';
import { setRefreshActions, setCustomerIdEnabled, setCustomerId } from '../../ducks/auth.duck.js';
import { updateCodeset, fetchCodesetCodes, clearCodesets, fetchCodesetCodesForExport, fetchCodesNotInCodeset, reduceUpdate, addCodeToCodeset, deleteCodeFromCodeset, clearUpdate, newCodeset, clearNewCodesetId, addCodesToCodeset, deleteCodesFromCodeset } from '../../ducks/codeset.duck.js';
import Spinner from '../utilities/spinner';
import { fetchCampaignsByCodeset, clearCampaignsByCodeset } from '../../ducks/campaign.duck';
import { fetchCustomers } from '../../ducks/customer.duck';
import  CommonStyle, { mergeStyles } from '../../styles/commonStyles';
import fileDownload from 'js-file-download';
import { fetchTouchcodesWithExperimental } from '../../ducks/touchcode.duck';
import ScrollingPagedBootstrapTable from '../utilities/ScrollingPagedBootstrapTable';
import { toastr } from 'react-redux-toastr';
import { getRelativeLCA } from '../utilities/minimumTheoreticalDist';
import { CodesetDeleteModal } from './codesetDeleteModal';
import CancelModal from '../utilities/cancelModal';

const formName = formNames.CODESET_EDIT;

class CodesetEditForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			newCodes: new Set(),
			deletedCodes: new Set(),
			codesUpdated: 0,
			RLCA: undefined,
			loading: false
		};
	}

	componentDidMount() {
		const { currentCodeset, isNew } = this.props;
		this.props.setCustomerId(null);
		this.props.setCustomerIdEnabled(false);
		this.props.setRefreshActions([fetchTouchcodesWithExperimental]);
		this.props.fetchTouchcodesWithExperimental();
		if (!isNew) {
			this.props.fetchCodesNotInCodeset(currentCodeset.Id);
			this.props.fetchCampaignsByCodeset(currentCodeset.Id);
			this.props.fetchCodesetCodes(currentCodeset.Id);
			this.props.fetchCodesetCodesForExport(currentCodeset.Id);
		} else {
			this.props.fetchCodesNotInCodeset(undefined);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevState || !this.state) {
			return;
		}
		if (prevState.codesUpdated !== this.state.codesUpdated || !this.state.RLCA || (!prevProps.currentCodesetCodes && this.props.currentCodesetCodes)) {
			this.setState({ RLCA: getRelativeLCA(this.getTouchcodesForLCA()).toFixed(4) });
			this.forceUpdate();
		}
	}

	componentWillUnmount() {
		this.props.clearCampaignsByCodeset();
		this.props.clearUpdate();
	}

	handleFormSubmit(form) {
		const { isNew, currentCodeset, permissions } = this.props;
		const { deletedCodes, newCodes } = this.state;
		const isCampaignCreator = permissions ? permissions.CampaignCreates : null;
		const { label } = form;

		const codeset = {
			Label: label,
		};
		this.setState({ loading: true });
		if (isNew && isCampaignCreator) {
			this.props.newCodeset(codeset);
			this.handleNewCodesetCreated();
		} else if (isCampaignCreator) {
			let expected = 1;
			this.props.updateCodeset(codeset, currentCodeset.Id);
			if (deletedCodes.size > 0) {
				this.props.deleteCodesFromCodeset(Array.from(deletedCodes), currentCodeset.Id);
				expected++;
			}
			if (newCodes.size > 0) {
				this.props.addCodesToCodeset(Array.from(newCodes), currentCodeset.Id);
				expected++;
			}
			const completeUpdate = i => {
				if (this.props.codesetUpdated >= i) {
					this.setState({ loading: false });
					this.props.clearUpdate();
					this.props.clearCodesets();
					history.push(routes.codesetManage);
					toastr.success('Success', 'Codeset updated');
				} else {
					setTimeout(completeUpdate, 100, i);
				}
			};
			completeUpdate(expected);
		}
	}

	handleNewCodesetCreated() {
		const { newCodesetLabel, newCodesetId, isNew } = this.props;
		const { newCodes } = this.state;
		if (newCodesetLabel && newCodesetId) {
			// Need to handle adding codes
			if (newCodes.size > 0) {
				this.props.addCodesToCodeset(Array.from(newCodes), newCodesetId);
			}
			const completeCreation = () => {
				if (this.props.codesetUpdated >= 1) {
					this.setState({ loading: false });
					this.props.clearCodesets();
					this.props.clearUpdate();
					this.props.clearNewCodesetId();
					history.push(routes.codesetManage);
					isNew ? toastr.success('Success', `"${newCodesetLabel}" has been successfully created`) : '';
				} else {
					setTimeout(completeCreation, 100);
				}
			};
			completeCreation();

		} else {
			setTimeout(this.handleNewCodesetCreated.bind(this), 100);
		}
	}

	handleExport(event: SyntheticEvent): void {
		const { currentCodeset, customers, codesForExport } = this.props;

		let env = 'pdn';
		if (window.location.href.includes('dev')) {
		 	env = 'dev';
		} else if (window.location.href.includes('qa')) {
			env = 'qa';
		} else if (window.location.href.includes('stg')) {
			env = 'stg';
		}
		const exportJson = {
			_id: currentCodeset.Id,
			label: currentCodeset.Label,
			default_decoding_config: currentCodeset.DefaultDecodingConfig,
			environment: env,
			exported_version: 1.0,
			codes: [],
			customers: []
		};
		const codeIds = [];
		codesForExport.forEach(c => {
			const code = {
				id: c.CodeId,
				pattern: c.Pattern,
				is_deleted: c.isDeleted,
				is_experimental: c.isExperimental,
				labels: []
			};
			const label = c.Label ? {
				label: c.Label,
				customer_id: c.CustomerId
			} : null;
			if (codeIds.includes(c.CodeId)) {
				exportJson.codes.forEach(existingCode => {
					if (existingCode.id === c.CodeId) {
						label ? existingCode.labels.push(label) : null;
					}
				});
			} else {
				codeIds.push(c.CodeId);
				label ? code.labels.push(label) : null;
				exportJson.codes.push(code);
			}
		});

		customers.forEach(c => {
			const customer = {
				name: c.Name,
				id: c.Id,
				is_deleted: c.isDeleted,
				modified: c.Modified
			};
			exportJson.customers.push(customer);
		});

		const fileContents = JSON.stringify(exportJson, null, 2);
		fileDownload(fileContents, currentCodeset.Label + '.json');
		event.preventDefault();
	}

	handleKeyPress(event) {
		if (event.key === 'Enter') {
			event.preventDefault();

			const { dispatch, formValues } = this.props;

			if (this.props.isValid) {
				this.handleFormSubmit(formValues);
			} else {
				dispatch(touch(formName, 'label'));
			}
		}
	}

	onRowSelect() {
		this.forceUpdate();
	}

	getCurrentCodes() {
		const { isNew, currentCodesetCodes } = this.props;
		const data = [];
		if (!isNew) {
			currentCodesetCodes.forEach(c => {
				data.push(c);
			});
		}
		if (this.state) {
			const { newCodes, deletedCodes } = this.state;
			const existingCodes = new Set();
			data.forEach(c => {
				existingCodes.add(c.CodeId);
			});
			if (newCodes.size > 0) {
				Array.from(newCodes).sort((a, b) => { return parseInt(a, 10) > parseInt(b, 10) ? 1 : -1; }).forEach(c => {
					if (!existingCodes.has(c)) {
						data.push({ CodeId: c });
						existingCodes.add(c);
					}
				});
			}
			for (let i = 0; i < data.length; i++) {
				if (deletedCodes.has(data[i].CodeId)) {
					data.splice(i, 1);
					i--;
				}
			}
			return data.sort((a, b) => { return a.CodeId > b.CodeId ? 1 : -1; });
		}
		return currentCodesetCodes;
	}

	getNonCurrentCodes() {
		const { isNew, codesNotInCodeset } = this.props;
		let newCodes;
		if (this.state) newCodes = this.state.newCodes;
		const data = [];
		codesNotInCodeset.forEach(c => {
			if (!newCodes || !newCodes.has(c.Id)) {
				data.push(c);
			}
		});
		if (isNew) {
			return data;
		}
		let deletedCodes;
		if (this.state) deletedCodes = this.state.deletedCodes;
		if (deletedCodes) {
			const iterator = deletedCodes.values();
			let dc = undefined;
			while ((dc = iterator.next().value)) {
				data.push({ Id: dc });
			}
			data.sort((a, b) => { return a.Id > b.Id ? 1 : -1; });
		}
		return data;
	}

	getTouchcodesForLCA() {
		const { currentCodesetCodes, codesNotInCodeset } = this.props;
		if (this.state) {
			const { deletedCodes, newCodes } = this.state;
			const data = [];
			if (currentCodesetCodes) {
				currentCodesetCodes.forEach(c => {
					if (!deletedCodes.has(c.CodeId)) {
						data.push(c);
					}
				});
			}
			if (codesNotInCodeset) {
				codesNotInCodeset.forEach(c => {
					if (newCodes.has(c.Id)) {
						data.push(c);
					}
				});
			}
			return data;
		}
		return [];
	}

	handleDeleteCodes() {
		this.handleAddDeleteCodes(this.refs.currentCodesInCodeset, 'delete');
	}

	handleAddCodes() {
		this.handleAddDeleteCodes(this.refs.codesNotCurrentlyInCodeset, 'add');
	}

	handleAddDeleteCodes(scrollingPagedTable, action) {
		const { newCodes, deletedCodes } = this.state;
		const { codesNotInCodeset, currentCodesetCodes, isNew } = this.props;
		const codes = scrollingPagedTable.getSelected();
		scrollingPagedTable.clearSelected();
		const preExistingCodes = new Set();
		const possibleCodes = new Set();
		codesNotInCodeset ? codesNotInCodeset.forEach(c => {
			possibleCodes.add(c.Id);
		}) : undefined;
		currentCodesetCodes ? currentCodesetCodes.forEach(c => {
			preExistingCodes.add(c.CodeId);
		}) : undefined;
		if (codes.size > 0) {
			const it = codes.values();
			let code = undefined;
			while ((code = it.next().value)) {
				const codei = parseInt(code, 10);
				if (action === 'delete') {
					if (newCodes.has(codei)) {
						newCodes.delete(codei);
					}
					if (isNew || preExistingCodes.has(codei)) {
						deletedCodes.add(codei);
					}
				} else if (action === 'add') {
					if (deletedCodes.has(codei)) {
						deletedCodes.delete(codei);
					}
					if (isNew || possibleCodes.has(codei)) {
						newCodes.add(codei);
					}
				} else {
					console.error('Invalid action in handleAddDeleteCodes from codesetEditForm');
				}
			}
			this.setState({ codesUpdated: this.state.codesUpdated + 1 });
		}

		this.forceUpdate();
 	}

	handleCancelClick(event) {
		history.push(routes.codesetManage);
		if (event) {
			event.preventDefault();
		}
	}

	render() {
		const { formValues, initialValues, handleSubmit, isNew, permissions, campaignsByCodeset, currentCodesetCodes, currentCodeset, codesNotInCodeset } = this.props;
		const bsResponsive = { xs: 12, sm: 6, md: 4, lg: 4 };
		const isCampaignCreator = permissions ? permissions.CampaignCreates : null;
		const isCampaignViewer = permissions ? permissions.CampaignViews : null;
		if (!isCampaignViewer) {
			return (<Spinner size={12} />);
		}
		let valid = false;
		if (formValues && formValues.label && formValues.label.length <= 20) {
			valid = true;
		}
		// Handle display for disabled v.s. enabled add/delete buttons
		const deleteStyle = { margin: '72px 25px 0px 25px', cursor: 'not-allowed', color: '#999999' };
		const addStyle = { margin: '142px 25px 0px 25px', cursor: 'not-allowed', color: '#999999' };
		if (this.refs.codesNotCurrentlyInCodeset && this.refs.codesNotCurrentlyInCodeset.getSelected().size > 0) {
			addStyle.cursor = 'pointer';
			addStyle.color = '#000511';
		}
		if (this.refs.currentCodesInCodeset && this.refs.currentCodesInCodeset.getSelected().size > 0) {
			deleteStyle.cursor = 'pointer';
			deleteStyle.color = '#000511';
		}

		if ((!currentCodesetCodes && !isNew) || !codesNotInCodeset || (!currentCodeset && !isNew) || (this.state && this.state.loading)) {
			return (<Spinner />);
		}

		return (
			<FormContainer submitHandler={handleSubmit(this.handleFormSubmit.bind(this))}>
				<div onKeyPress={this.handleKeyPress.bind(this)}>
					<Row style={cs.formRow}>
							<Col {...bsResponsive}>
							<Field
								name="label"
								component={FieldWrapper}
								type="text"
								placeholder="Codeset Label"
								label="Codeset Label"
								validate={[validators.required, validators.charLimit20]}
							/>
						</Col>
					</Row>
					{!isNew &&
					<Row>
					<strong>Associated Campaigns</strong>
					{campaignsByCodeset ?
						(<div>
							{campaignsByCodeset.length > 0 &&
								<div>
									<ul>
										{campaignsByCodeset.map(c =>
											<li key={c.Id}>
												{c.Name} : <strong>{c.CampaignState.Name}</strong> - <em>{c.Customer.Name}</em>
											</li>
										)}
									</ul>
								</div>
							}
							{campaignsByCodeset.length === 0 &&
								<div style={CommonStyle.textInfo}>
									<h5>No campaigns currently use this codeset</h5>
								</div>
							}
						</div>) : <div><Spinner size={12} /></div>
					}
					</Row> }
				{isNew && <Row style={{ height: '20px' }}/>}
					<Row style={{ marginLeft: '0px', height: '550px' }}>
					<div style={{ width: '590px', height: '450px' }}>
						<div style={CommonStyle.floatLeft}>
							<ScrollingPagedBootstrapTable
								ref="codesNotCurrentlyInCodeset"
								data={this.getNonCurrentCodes()}
								label="Available Codes"
								fields={['Id']}
								keyField="Id"
								onRowSelect={this.onRowSelect.bind(this)}
							/>
						</div>
						<div style={{ float: 'left', height: '100%' }}>
								<FaArrowCircleRight onClick={this.handleAddCodes.bind(this)} style={addStyle} size={40}/>
								<br/>
								<FaArrowCircleLeft onClick={this.handleDeleteCodes.bind(this)} style={deleteStyle} size={40}/>
						</div>
						<div style={CommonStyle.floatRight}>
							<ScrollingPagedBootstrapTable
								ref="currentCodesInCodeset"
								data={this.getCurrentCodes()}
								label={isNew ? 'New Codeset' : 'Codes within "' + currentCodeset.Label + '"'}
								fields={['CodeId']}
								keyField="CodeId"
								onRowSelect={this.onRowSelect.bind(this)}
							/>
						</div>
					</div>
					<span style={{ left: '550px', position: 'absolute', width: '250px' }}> Minimum Theoretical Distance (for&nbsp;codeset): {this.state ? this.state.RLCA : '1.0000'} </span>
					</Row>
					<Row style={{ marginLeft: 'auto' }}>
						{isCampaignCreator &&
						<div style={{ float: 'right', marginRight: '20px' }}>
							{isNew &&
								<CancelModal
									initialValues={{ ...initialValues, currentCodesetCodes: currentCodesetCodes ? currentCodesetCodes : [] }}
									currentValues={{ ...formValues, currentCodesetCodes: this.getCurrentCodes() }}
									onCancel={this.handleCancelClick.bind(this)}
								/>
							}
							{!isNew &&
							<span>
								<CodesetDeleteModal
									launchButton={<span style={mergeStyles(cs.button, { marginRight: '5px' })} className="btn btn-danger">
													<span><FaTimesCircle /> Delete</span>
												</span>}
									codeset={currentCodeset}
								/>
								<button style={mergeStyles(cs.btn, { backgroundColor: '#073763', borderColor: '#073763', width: '73px' })} className="btn btn-primary" onClick={this.handleExport.bind(this)}>
									<span>Export</span>
								</button>
							</span>
							}
							<button style={cs.btn} className="btn btn-primary" type="submit" disabled={!valid}>
								<span><FaCheck /> Save</span>
							</button>
						</div>
						}
					</Row>
				</div>
			</FormContainer>
		);
	}
}

function mapStateToProps(state) {
	const { currentCodeset } = state.codeset;

	let initialValues;
	if (currentCodeset) {
		const { Label } = currentCodeset;

		initialValues = {
			label: Label,
		};
	}

	return {
		currentCodeset: currentCodeset,
		permissions: state.userRole.permissions,
		campaignsByCodeset: state.campaign.campaignsByCodeset,
		currentCodesetCodes: state.codeset.currentCodesetCodes,
		codesForExport: state.codeset.codesForExport,
		formValues: getFormValues(formName)(state),
		isValid: isValid(formName)(state),
		customers: state.customer.customers,
		codesNotInCodeset: state.codeset.codesNotInCodeset,
		newCodesetLabel: state.codeset.newCodesetLabel,
		newCodesetId: state.codeset.newCodesetId,
		codesetUpdated: state.codeset.update,
		initialValues
	};
}

CodesetEditForm = reduxForm({
	form: formName
})(CodesetEditForm);

CodesetEditForm = connect(mapStateToProps, {
	setCustomerIdEnabled,
	setCustomerId,
	updateCodeset,
	fetchCampaignsByCodeset,
	clearCampaignsByCodeset,
	fetchCodesetCodes,
	fetchCodesetCodesForExport,
	fetchCustomers,
	setRefreshActions,
	fetchTouchcodesWithExperimental,
	fetchCodesNotInCodeset,
	addCodeToCodeset,
	deleteCodeFromCodeset,
	newCodeset,
	clearNewCodesetId,
	clearUpdate,
	reduceUpdate,
	addCodesToCodeset,
	deleteCodesFromCodeset,
	clearCodesets
})(CodesetEditForm);

export default CodesetEditForm;
