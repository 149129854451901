import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col, Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import { Chart } from 'react-google-charts';
import { connect } from 'react-redux';
import _ from 'lodash';
import { routes } from '../../util/constants';
import { fetchCountries, fetchProvinces, fetchPostals, fetchTopCountries, fetchTopProvinces, fetchTopPostals, clearLocationAnalytics, fetchCampaignsInDw, setNewAnalyticsLocation, fetchMapEngagements } from '../../ducks/analytics.duck';
import { setCustomerId, setCustomerIdEnabled } from '../../ducks/auth.duck';
import history from '../../util/history';
import cs from '../../styles/commonStyles';
import { primaryDarkBlue, primaryPurple } from '../../styles/commonStyles';
import Spinner from '../utilities/spinner';
import { toastr } from 'react-redux-toastr';

const colorHigh = primaryDarkBlue;
const colorLow = primaryPurple;

class AnalyticsLocation extends Component {
	static propTypes = {
		params: PropTypes.object,
		// Redux Store
		locationAnalytics: PropTypes.array,
		campaigns: PropTypes.array,
		// Wrapped Actions
		fetchCountries: PropTypes.func,
		fetchProvinces: PropTypes.func,
		fetchPostals: PropTypes.func,
		fetchCampaignsInDw: PropTypes.func,
		fetchCampaign: PropTypes.func,
		fetchTopCountries: PropTypes.func,
		fetchTopProvinces: PropTypes.func,
		fetchTopPostals: PropTypes.func,
		clearLocationAnalytics: PropTypes.func
	};

	constructor(props) {
		super(props);

		this.state = {
			options: {
				region: 'world',
				colorAxis: {
					// light pink to dark purple
					colors: [
						'#e3cbee',
						'#cea1e5',
						'#b877dc',
						'#9e4ad2',
						'#8200c8',
					]
				},
				magnifyingGlass: { enable: true, zoomFactor: 11.0 },
				resolution: 'country',
				chartArea: { left: 0, top: 0 },
				displayMode: 'regions',
				legend: 'none'
			},
			maxEngagements: 0,
			minEngagements: 0,
		};

		this.chartEvents = [{
			eventName: 'ready',
			callback: ({ chartWrapper }) => {
				const chart = chartWrapper.getChart();
				google.visualization.events.addListener(chart, 'regionClick', this.handleRegionClick.bind(this));
				google.visualization.events.addListener(chart, 'error', this.handleMapError.bind(this));
			}
		}];
	}

	static getDerivedStateFromProps(props) {
		if (!props.locationAnalytics) {
			return null;
		}
		// Deep copy locationAnalytics
		const locationAnalytics = JSON.parse(JSON.stringify(props.locationAnalytics));
		// Remove the first element and grab only the engagement values
		const engagements = _.tail(locationAnalytics).map(la => la[1]);

		if (_.isEmpty(engagements)) {
			return null;
		}
		return {
			maxEngagements: engagements.reduce((a, b) => Math.max(a, b)),
			minEngagements: engagements.reduce((a, b) => Math.min(a, b))
		};
	}

	componentDidMount() {
		const removeDeleted = true;
		this.props.updateAnalyticsRefreshActions([() => fetchCampaignsInDw(removeDeleted)]);
		this.props.fetchCampaignsInDw(removeDeleted);
		// Reset the map view
		this.updateMapLocation({ countryCode: undefined, provinceCode: undefined, zipCode: undefined }, true);
	}

	getCampaignsPrimaryCountry() {
		const { campaigns, selectedCampaignId } = this.props;
		let campaignData;
		if (campaigns) {
			campaignData = campaigns.find(campaign => campaign.CampaignKey === selectedCampaignId);
		}
		return campaignData && campaignData.Location ? campaignData.Location : undefined;
	}

	componentDidUpdate(prevProps) {
		// AnalyticsLocation is the country, region, zipcode
		// LocationAnalytics is the engagements for the campaign in that location
		// Update if the location has changed (to refresh) or if our campaign is different

		if (this.props.campaigns != null && prevProps.campaigns != null && this.props.campaigns.length !== prevProps.campaigns.length) {
			this.props.match.params.campaignId = 'all';
			this.updateMapLocation(this.props.analyticsLocation, true);
		}

		if (prevProps.selectedCampaignId !== this.props.selectedCampaignId) {
			const primaryCountry = this.getCampaignsPrimaryCountry();
			this.updateMapLocation({ countryCode: primaryCountry, provinceCode: undefined, zipCode: undefined }, true);
		} else if (!_.isEqual(prevProps.analyticsLocation, this.props.analyticsLocation)) {
			this.onMapLocationUpdated();
		} else if (prevProps.customerId !== this.props.customerId) {
			const primaryCountry = this.getCampaignsPrimaryCountry();
			this.updateMapLocation({ countryCode: primaryCountry, provinceCode: undefined, zipCode: undefined }, true);
			this.onMapLocationUpdated();
		}
	}

	getMapRegion(regionCodeArray) {
		if (regionCodeArray && regionCodeArray.length > 0) {
			return regionCodeArray.join('-');
		}
		return 'world';
	}

	getMapResolution(region, regionCodeArray) {
		const isUS = regionCodeArray && regionCodeArray[0] && regionCodeArray[0] === 'US';
		if (isUS) {
			return 'provinces';
		}
		return 'countries';
	}

	getMapDisplayMode(region, regionCodeArray) {
		if (regionCodeArray && regionCodeArray.length >= 2) {
			return 'markers';
		}
		return 'regions';
	}

	/**
	 * Call to trigger a map update
	 * @param {*} location
	 */
	updateMapLocation = (location, forceUpdate) => {
		if (this.props.isMapUpdating && forceUpdate !== true) {
			toastr.light('Please wait before navigating again');
			return;
		}

		const { selectedCampaignId } = this.props;
		const locationCopy = { ...location, selectedCampaignId };

		if (locationCopy && locationCopy.countryCode !== 'US') {
			locationCopy.countryCode = undefined;
		}

		Object.keys(location).forEach(key => {
			if (typeof locationCopy[key] === 'undefined') {
				locationCopy[key] = '';
			}
		});

		const { countryCode, provinceCode } = locationCopy;

		const regionCodeArray = [countryCode, provinceCode]
			.filter(code => code.length > 0);

		const region = this.getMapRegion(regionCodeArray);
		const resolution = this.getMapResolution(region, regionCodeArray);
		const displayMode = this.getMapDisplayMode(region, regionCodeArray);

		const optionsCopy = { ...this.state.options };

		optionsCopy.region = region;
		optionsCopy.resolution = resolution;
		optionsCopy.displayMode = displayMode;

		this.setState(
			{
				// Updates the map
				options: optionsCopy,
			},
			() => {
				this.props.setNewAnalyticsLocation(locationCopy);
			}
		);
	}

	/**
	 * Called when the map location has been received by the store & will actually transition
	 * @param {*} nextProps
	 */
	onMapLocationUpdated() {
		const { countryCode, provinceCode, zipCode } = this.props.analyticsLocation;
		const { isMapUpdating } = this.props;
		let { selectedCampaignId } = this.props;

		if (!selectedCampaignId || selectedCampaignId.length === 0) {
			selectedCampaignId = 'all';
		}

		if (isMapUpdating) {
			return;
		}

		const allParams = [selectedCampaignId, countryCode, provinceCode, zipCode];
		const mapRoute = allParams.filter(param => param.length > 0).join('/');
		history.push(`${routes.analytics}/${mapRoute}`);
		this.props.clearLocationAnalytics();
		this.fetchDataForParams();
	}

	fetchDataForParams() {
		const { selectedCampaignId, analyticsLocation } = this.props;
		const campaignParam = selectedCampaignId === 'all' ? null : selectedCampaignId;
		this.props.fetchMapEngagements(analyticsLocation, campaignParam);
	}

	handleRegionClick(event) {
		const { region } = event;
		const loc = region.split('-');

		const { isAuthenticated, renewSession } = this.props.auth;
		if (isAuthenticated()) {
			renewSession();
		}

		// TC-1209 don't allow navigation to another region
		if (loc && loc[0] && loc[0] !== 'US') {
			return;
		}

		this.updateMapLocation({ countryCode: loc[0], provinceCode: loc[1], zipCode: undefined });
	}

	/**
	 * Checks if we have a valid country when swapping campaigns
	 * If not, defaults to the world
	 * @param {*} error
	 */
	handleMapError(error) {
		if (error && error.message === 'Requested map does not exist.') {
			this.updateMapLocation({ countryCode: undefined, provinceCode: undefined, zipCode: undefined }, true);
		}
	}

	handleWorldClick(event) {
		event.preventDefault();

		const { isAuthenticated, renewSession } = this.props.auth;
		if (isAuthenticated()) {
			renewSession();
		}

		this.updateMapLocation({ countryCode: undefined, provinceCode: undefined, zipCode: undefined });
	}

	handleCountryClick(event) {
		event.preventDefault();
		const { analyticsLocation } = this.props;

		const { isAuthenticated, renewSession } = this.props.auth;
		if (isAuthenticated()) {
			renewSession();
		}

		this.updateMapLocation({ countryCode: analyticsLocation.countryCode, provinceCode: undefined, zipCode: undefined });
	}

	calculateColor(engagements) {
		const { maxEngagements, minEngagements } = this.state;
		const minColor = colorLow.substring(1);
		const maxColor = colorHigh.substring(1);

		if (engagements < minEngagements || engagements > maxEngagements) {
			return '#000000';
		}

		// Normalize the value
		let ratio;
		if (maxEngagements - minEngagements <= 0) {
			ratio = 1;
		} else {
			ratio = (engagements - minEngagements) / (maxEngagements - minEngagements);
		}

		const hex = h => { const x = h.toString(16); return (x.length === 1) ? `0${x}` : x; };
		const parseColor = (c1, c2, start, end, rat) => {
			return Math.ceil(parseInt(c1.substring(start, end), 16) * (1 - rat) + parseInt(c2.substring(start, end), 16) * rat);
		};

		const r = hex(parseColor(minColor, maxColor, 0, 2, ratio));
		const g = hex(parseColor(minColor, maxColor, 2, 4, ratio));
		const b = hex(parseColor(minColor, maxColor, 4, 6, ratio));

		return `#${r}${g}${b}`;
	}

	renderEngagementsList(engagements) {
		if (_.isEmpty(engagements)) {
			return <div style={cs.textCenter}><em>No data</em></div>;
		}
		return (
			<ul>
				{
					engagements.map(e => {
						let key;
						if (!e.PostalCode) {
							key = e.Region;
						} else if (!e.Region && !e.PostalCode) {
							key = e.CountryCode;
						} else if (e.PostalCode) {
							key = e.PostalCode;
						}
						if (key === undefined) {
							return null;
						}
						if (key) {
							return (
								<li key={key}>
									<div className="engagementCount">
										<Col md={1}>
											<div className="colorBlock" style={{ backgroundColor: `${this.calculateColor(e.EngagementCount)}` }} />
										</Col>
										<Col md={3}>
											{e.EngagementCount}
										</Col>
									</div>
									<br />
								</li>
							);
						}
						return null;
					})
				}
			</ul>
		);
	}

	formatCampaigns(campaigns) {
		const formattedCampaigns = campaigns && campaigns.map(
			c => { return { value: c.CampaignKey, label: c.Name }; }
		);
		return _.sortBy(formattedCampaigns, 'label');
	}

	render() {
		const {
			locationAnalytics,
			selectedCampaignId,
			analyticsLocation
		} = this.props;

		const countryCode = analyticsLocation && analyticsLocation.countryCode;
		const provinceCode = analyticsLocation && analyticsLocation.provinceCode;

		return (
			<div className="analyticsLocation" key={this.props.key} data-cy="engagements-by-location">
				<Col xs={3}>
					<Breadcrumb>
						{countryCode || selectedCampaignId ?
							<BreadcrumbItem href="#" onClick={this.handleWorldClick.bind(this)} data-cy="engagements-by-location-world-breadcrumb">
								World
							</BreadcrumbItem> :
							<BreadcrumbItem active data-cy="engagements-by-location-world-breadcrumb">
								World
							</BreadcrumbItem>
						}
						{countryCode && provinceCode &&
							<BreadcrumbItem href="#" onClick={this.handleCountryClick.bind(this)} data-cy="engagements-by-location-country-breadcrumb">
								{countryCode}
							</BreadcrumbItem>
						}
						{countryCode && !provinceCode &&
							<BreadcrumbItem active data-cy="engagements-by-location-country-breadcrumb">
								{countryCode}
							</BreadcrumbItem>
						}
						{provinceCode &&
							<BreadcrumbItem active data-cy="engagements-by-location-province-breadcrumb">
								{provinceCode}
							</BreadcrumbItem>
						}
					</Breadcrumb>
				</Col>
				<Col xs={6}>
					<h3 style={{ ...cs.textCenter }}>ENGAGEMENTS BY LOCATION</h3>
				</Col>
				{
					!locationAnalytics &&
					<div>
						<Row>
							<Col xs={12}>
								<Spinner />
							</Col>
						</Row>
					</div>
				}
				{
					locationAnalytics &&
					<div >
						<Row style={{ height: '670px' }}>
							<Col xs={12} className="chartWrapper">
								<Chart
									height={'80%'}
									width={'100%'}
									chartType="GeoChart"
									options={this.state.options}
									graph_id="GeoChart"
									data={locationAnalytics}
									chartEvents={this.chartEvents}
								/>
							</Col>
						</Row>
						{/* <Row>
							<Col xs={12}>
								<div className="engagementsWrapper">
									{topEngagements && this.renderEngagementsList(topEngagements)}
									{!topEngagements &&
										<div className="spinnerContainer">
											<Spinner />
										</div>
									}
								</div>
							</Col>
						</Row> */}
					</div>
				}
			</div >

		);
	}
}

function mapStateToProps(state) {
	return {
		locationAnalytics: state.analytics.locationAnalytics,
		topEngagements: state.analytics.topEngagements,
		campaigns: state.analytics.campaigns,
		analyticsLocation: state.analytics.analyticsLocation,
		isMapUpdating: state.analytics.isMapUpdating,
		selectedCampaignId: state.analytics.selectedCampaignId,
		customerId: state.auth.customerId
	};
}

export default connect(mapStateToProps, {
	fetchCountries,
	fetchProvinces,
	fetchPostals,
	fetchTopCountries,
	fetchTopProvinces,
	fetchTopPostals,
	setCustomerId,
	setCustomerIdEnabled,
	fetchCampaignsInDw,
	clearLocationAnalytics,
	setNewAnalyticsLocation,
	fetchMapEngagements
})(AnalyticsLocation);
