import React, { Component } from 'react';
import { connect } from 'react-redux';
import fileDownload from 'js-file-download';
import { getCampaignForExport, removeCampaignForExport } from '../../ducks/campaign.duck';
const style = {
	exportButton: {
		color: 'white',
		backgroundColor: '#093864',
		height: '27px',
		padding: '3px 10px',
	}
};

class CampaignExportButton extends Component {
	constructor(props) {
		super(props);

		this.state = {
			exporting: false,
		};
	}

	export(event) {
		const { campaignId } = this.props;

		event.preventDefault();
		this.props.getCampaignForExport(campaignId);
		setTimeout(this.downloadCampaignForExport.bind(this), 100);
		this.setState({ exporting: true });
	}

	downloadCampaignForExport() {
		const { campaignsForExport, campaignId } = this.props;

		if (campaignsForExport && campaignsForExport[campaignId]) {
			const campaignForExport = campaignsForExport[campaignId];
			let env = 'pdn';
			if (window.location.href.includes('dev')) {
				env = 'dev';
			} else if (window.location.href.includes('qa')) {
				env = 'qa';
			} else if (window.location.href.includes('stg')) {
				env = 'stg';
			}
			const exportJson = {
				Env: env,
				ExportedVersion: 1.0,
				...campaignForExport,
			};

			const fileContents = JSON.stringify(exportJson, null, 2);
			fileDownload(fileContents, campaignForExport.Name + '.json');
			this.props.removeCampaignForExport(campaignId);
			this.setState({ exporting: false });
		} else {
			setTimeout(this.downloadCampaignForExport.bind(this), 100);
		}
	}

	render() {
		const { exporting } = this.state;
		const { permissions } = this.props;

		const { AdminView } = permissions;
		if (!AdminView) {
			return <div />;
		}

		return (
			<button className="btn btn-default" style={style.exportButton} onClick={this.export.bind(this)} disabled={exporting}>Export</button>
		);
	}
}

function mapStateToProps(state) {
	return {
		campaignsForExport: state.campaign.campaignsForExport,
		permissions: state.userRole.permissions,
	};
}

CampaignExportButton = connect(mapStateToProps, {
	getCampaignForExport,
	removeCampaignForExport,
})(CampaignExportButton);

export default CampaignExportButton;
