// @flow

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment-timezone';
import _ from 'lodash';
import { Calendar } from './datePickerHelpers';

type Props = {
	id: ?string,
	style: ?string,
	placeholder: ?string,
	endOfDay: ?boolean,
	// tz: string,
	value: ?string,
	onChange: ?Function,
	onTouch: ?Function,
};
export type CalendarDate = {
	date: number,
	outside: boolean
};
export type CalendarWeek = Array<CalendarDate>;
export type CalendarMonth = Array<CalendarWeek>;

class DatePickerInput extends Component {
	inputInstance: HTMLInputElement;
	handleDocumentClick: Function;
	determineDropUp: Function;

	state: {
		isCalOpen: boolean,
		touched: boolean,
		dropUp: boolean,
		displayDate: moment,
		value: string
	}

	constructor(props: Props) {
		super(props);

		const init = props.value;
		const value = init || '';

		this.state = {
			isCalOpen: false,
			touched: false,
			dropUp: false,
			value
		};

		this.handleDocumentClick = this.handleDocumentClick.bind(this);
		this.determineDropUp = this.determineDropUp.bind(this);
	}

	componentDidMount(): void {
		const { value: initial } = this.props;
		if (initial) {
			this.props.changeDisplayDate(moment.parseZone(initial), true);
		}
		this.determineDropUp();
		document.addEventListener('click', this.handleDocumentClick, false);
		document.addEventListener('touchend', this.handleDocumentClick, false);
		window.addEventListener('resize', this.determineDropUp);
		window.addEventListener('scroll', this.determineDropUp);
	}

	componentWillUnmount(): void {
		document.removeEventListener('click', this.handleDocumentClick, false);
		document.removeEventListener('touchend', this.handleDocumentClick, false);
		window.removeEventListener('resize', this.determineDropUp);
		window.removeEventListener('scroll', this.determineDropUp);
	}

	determineDropUp(_event: ?SyntheticEvent): void {
		const node = ReactDOM.findDOMNode(this.inputInstance);

		if (!node) return;

		const windowHeight = window.innerHeight;
		const menuHeight = 286;
		const instOffsetWithMenu = node.getBoundingClientRect().bottom + menuHeight;

		this.setState({
			dropUp: instOffsetWithMenu >= windowHeight,
		});
	}

	handleDocumentClick(event: SyntheticEvent): void {
		const { isCalOpen } = this.state;
		const { onClose } = this.props;
		if (!ReactDOM.findDOMNode(this).contains(event.target)) {
			if (isCalOpen) {
				this.handleTouch();
				if (onClose) onClose();
			}
			this.setState({ isCalOpen: false });
		}
	}

	handleTouch(): void {
		const { onTouch } = this.props;
		if (!this.state.touched && onTouch) {
			onTouch();
			this.setState({ touched: true });
		}
	}

	handleMouseDown(_event: SyntheticEvent): void {
		const { isCalOpen } = this.state;
		const { onClose } = this.props;
		if (isCalOpen) {
			this.handleTouch();
			if (onClose) onClose();
		}

		// Document level click events are not being fired
		// for some reason when the input is click, so
		// generate and fire a click event
		document.dispatchEvent(new CustomEvent('click'));

		this.setState({ isCalOpen: !isCalOpen });
	}

	handleBlur(event: SyntheticEvent): void {
		this.handleTouch();

		event.preventDefault();
	}

	handleDateClick(day: CalendarDate, event: SyntheticEvent): void {
		event.preventDefault();
		const { date, outside } = day;
		const { displayDate } = this.state;
		const { onClose } = this.props;

		if (outside) {
			if (date > 15) {
				this.decrementMonth();
			} else {
				this.incrementMonth();
			}
			return;
		}

		this.handleTouch();
		this.props.changeDisplayDate(moment(this.props.displayDate).date(date), true);
		this.setState({ isCalOpen: false });
		if (onClose) onClose(moment(this.props.displayDate).date(date));
		this.setValue(moment(displayDate).date(date));
	}

	handleClearClick(event: SyntheticEvent): void {
		const { onClear } = this.props;
		event.preventDefault();
		this.setValue(null);
		if (onClear) onClear();
	}

	getMonthDates(month: number, year: number): CalendarMonth {
		const monthDates: CalendarMonth = [];
		const firstWeek: CalendarWeek = [];
		const lastWeek: CalendarWeek = [];
		const current = moment({ year, month });

		const prevMonth = moment(current).subtract(1, 'month');
		const prevMonthLastDate = moment(prevMonth).endOf('month').date();

		const thisMonth = moment(current);
		const startDay = moment(thisMonth).date(1).day();
		const lastDate = moment(thisMonth).endOf('month').date();
		let currentDate = 1;

		// Previous month
		for (let i = startDay - 1; i >= 0; i--) {
			firstWeek[i] = {
				date: prevMonthLastDate - ( (startDay - 1) - i),
				outside: true
			};
		}

		// First week of this month
		for (let i = startDay; i < 7; i++) {
			firstWeek[i] = {
				date: currentDate,
				outside: false
			};

			currentDate++;
		}
		monthDates.push(firstWeek);

		// This month
		while (lastDate - currentDate >= 7) {
			const currentWeek = [];
			for (let i = 0; i < 7; i++) {
				currentWeek.push({
					date: currentDate,
					outside: false
				});
				currentDate++;
			}
			monthDates.push(currentWeek);
		}

		// Last week of this month
		while (currentDate <= lastDate) {
			lastWeek.push({
				date: currentDate,
				outside: false
			});

			currentDate++;
		}

		let currentNextMonthDate = 1;

		// Next month's dates
		for (currentNextMonthDate; lastWeek.length < 7; currentNextMonthDate++) {
			lastWeek.push({
				date: currentNextMonthDate,
				outside: true
			});
		}
		monthDates.push(lastWeek);

		if (monthDates.length < 6) {
			const extraWeek = [];
			for (currentNextMonthDate; extraWeek.length < 7; currentNextMonthDate++) {
				extraWeek.push({
					date: currentNextMonthDate,
					outside: true
				});
			}
			monthDates.push(extraWeek);
		}

		return monthDates;
	}

	decrementMonth(): void {
		this.setState({ displayDate: moment(this.state.displayDate).subtract(1, 'M') });
		this.props.changeDisplayDate(moment(this.state.displayDate).subtract(1, 'M'));
	}

	incrementMonth(): void {
		this.props.changeDisplayDate(moment(this.state.displayDate).add(1, 'M'));
		this.setState({ displayDate: moment(this.state.displayDate).add(1, 'M') });
	}

	minutesToOffset(value: number): string {
		const hour = Math.floor(Math.abs(value) / 60);
		const minute = Math.abs(value) % 60;

		return `GMT${value < 0 ? '-' : '+'}${hour}:${_.padStart(minute, 2, '0')}`;
	}

	getValue(): string {
		const { value } = this.state;
		const {
			onChange,
		} = this.props;
		const passedValue = this.props.value;

		if (onChange) {
			if (!passedValue) {
				return '';
			}
			return passedValue;
		}
		return value;
	}

	setValue(value: ?moment): void {
		const { onChange, endOfDay } = this.props;

		let newValue = value;

		if (endOfDay && value) {
			newValue = value.endOf('day');
		}

		if (onChange != null) {
			onChange(newValue ? newValue.format() : '');
		} else {
			this.setState({ value: newValue ? newValue.format() : '' });
		}
	}

	render() {
		const { id, placeholder, style, displayDate } = this.props;
		const { isCalOpen, dropUp } = this.state;

		const value = this.getValue();

		const inputValue = value ? moment.parseZone(value).format('MMMM Do, YYYY') : '';
		const dropdownClass = dropUp ? 'dropup' : 'dropdown';
		let selectedDate = 0;

		if (value && moment.parseZone(value).month() === displayDate.month()) {
			selectedDate = moment.parseZone(value).date();
		}

		const calendarMonth = this.getMonthDates(displayDate.month(), displayDate.year());

		return (
			<div className="dateTimePicker">
				<input
					ref={inst => (this.inputInstance = inst)}
					className="dateInput"
					style={style}
					id={id}
					value={inputValue}
					readOnly
					placeholder={placeholder}
					type="text"
					onMouseDown={this.handleMouseDown.bind(this)}
					onBlur={this.handleBlur.bind(this)}
				/>
				{value && <div onClick={this.handleClearClick.bind(this)} className="clearButton">×</div>}
				{isCalOpen &&
					<div className={`${dropdownClass}`}>
						<table>
							<thead>
								<tr className="calendarTitleRow">
									<th className="navArrow" onClick={this.decrementMonth.bind(this)}><div className="triangleLeft" /></th>
									<th colSpan="5" className="calendarTitle">{displayDate.format('MMMM')} {displayDate.format('YYYY')}</th>
									<th className="navArrow" onClick={this.incrementMonth.bind(this)}><div className="triangleRight" /></th>
								</tr>
								<tr>
									<th>Su</th>
									<th>Mo</th>
									<th>Tu</th>
									<th>We</th>
									<th>Th</th>
									<th>Fr</th>
									<th>Sa</th>
								</tr>
							</thead>
							<Calendar calendarMonth={calendarMonth} selectedDate={selectedDate} dateClickHandler={this.handleDateClick.bind(this)} />
						</table>
					</div>
				}
			</div>
		);
	}
}

export default DatePickerInput;
