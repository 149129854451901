import PropTypes from 'prop-types';
import React, { Component } from 'react';

const style = {
	pageContainer: {
		display: 'flex',
		flexFlow: 'column',
		height: '100%',
		margin: '2%',
	}
};

class PageContainer extends Component {
	static propTypes = {
		children: PropTypes.node
	}

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div style={style.pageContainer}>
				{this.props.children}
			</div>
		);
	}
}

export default PageContainer;
