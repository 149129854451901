import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchCustomers } from '../../ducks/customer.duck';
import { setCustomerIdEnabled, setCustomerId } from '../../ducks/auth.duck.js';
import PageHeading from '../utilities/pageHeading';
import PageContainer from '../utilities/pageContainer';
import Spinner from '../utilities/spinner';
import { Row, Col } from 'react-bootstrap';
import CommonStyle from '../../styles/commonStyles.js';
import CustomerEditModal from './customerEditModal';
import { tableCaretRender } from '../../util/tableUtil';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ReactTooltip from 'react-tooltip';

const style = {
	newCustomerBtnRow: {
		textAlign: 'right',
		margin: '0px 0px 20px 0px'
	}
};

class CustomerManage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sortName: 'Name',
			sortOrder: 'asc'
		};
	}

	componentDidMount() {
		this.props.fetchCustomers();
		this.props.setCustomerId(null);
		this.props.setCustomerIdEnabled(false);
	//	this.props.dispatch(fetchCustomers());
	}

	onSortChange(sortName, sortOrder) {
		let newOrder = sortOrder;
		if (sortName !== this.state.sortName) {
			newOrder = 'asc';
		}

		this.setState({
			sortName,
			sortOrder: newOrder
		});
	}

	nameColumnFormatter(cell, row, rowIndex, formatExtraData) {
		const { permissions } = formatExtraData;
		const CustomerModifier = permissions ? permissions.CustomerModifies : null;

		return (
			<div style={CommonStyle.splitSection}>
				<div style={CommonStyle.clipSection}>{cell}</div>
				{CustomerModifier &&
				<div data-tip data-for="customerEdit">
					<CustomerEditModal customerId={row.Id.toString()} />
					<ReactTooltip id="customerEdit" effect="solid" delayShow={500}>
						Edit Customer Info
					</ReactTooltip>
				</div>
				}
			</div>
		);
	}

	render() {
		const { customers } = this.props;
		const { permissions } = this.props;
		const CustomerCreator = permissions ? permissions.CustomerCreates : null;

		return (
			<PageContainer>
				<PageHeading>Customers</PageHeading>
				{CustomerCreator &&
				<Row style={style.newCustomerBtnRow}>
					<Col xs={12}>
						<CustomerEditModal customerId="newCustomer" isNew />
					</Col>
				</Row>
				}

				{!customers &&
					<Spinner />
				}

				<Row>
					<Col xs={12}>
						{customers && customers.length === 0 &&
							<h4 style={CommonStyle.textCenter}>You haven't created any customers yet.</h4>
						}
						{customers && customers.length > 0 &&
							<BootstrapTable
								data={customers}
								columns={[{
									dataField: 'Name',
									text: 'Customer Name',
									formatter: this.nameColumnFormatter.bind(this),
									formatExtraData: { permissions },
									sort: true,
									sortCaret: tableCaretRender,
									filter: textFilter({ delay: 250 })
								}, {
									dataField: 'Id',
									text: 'Customer Id',
									hidden: true,
									sort: true,
									sortCaret: tableCaretRender,
								}]}
								keyField="Id"
								bodyClasses="touchcodeTbody"
								options={{
									sortName: this.state.sortName,
									sortOrder: this.state.sortOrder,
									onSortChange: this.onSortChange.bind(this)
								}}
								filter={filterFactory()}
								striped
								hover
							/>
						}
					</Col>
				</Row>
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	return {
		customers: state.customer.customers,
		permissions: state.userRole.permissions
	};
}

CustomerManage = connect(mapStateToProps, { fetchCustomers, setCustomerIdEnabled, setCustomerId })(CustomerManage);

export default CustomerManage;
