// @flow

import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import CommonStyle, { mergeStyles } from '../../styles/commonStyles';

class ScrollingPagedBootstrapTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 0,
			dragging: false,
			perScroll: 3,
			maxPage: 0,
			selectedRows: new Set(),
			updatingSelection: false
		};

		this.handleMouseMove = this.handleMouseMove.bind(this);
		this.handleMouseUp = this.handleMouseUp.bind(this);
		this.handleRowClick = this.handleRowClick.bind(this);
		this.handleMouseScroll = this.handleMouseScroll.bind(this);
	}

	static getDerivedStateFromProps(props, state) {
		const { data } = props;
		const { perScroll, maxPage, page } = state;
		if (!data) {
			return null;
		}

		const newState = {};

		if (data.length < 300) {
			if (perScroll !== 1) {
				newState.perScroll = 1;
			}
		} else {
			for (let i = 3; i > 0; i--) {
				if (data.length % i === 0) {
					if (perScroll === i) {
						break;
					}
					newState.perScroll = i;
				}
			}
		}

		const newMaxPage = parseInt((data.length - 10) / perScroll, 10);
		if (data.length < 10) {
			if (maxPage !== 0) {
				newState.maxPage = 0;
			}
		} else if (maxPage !== newMaxPage) {
			newState.maxPage = newMaxPage;
		}

		const updatedMaxPage = (newState.maxPage !== undefined ? newState.maxPage : maxPage);
		if (page > updatedMaxPage) {
			newState.page = updatedMaxPage;
		}
		if (page < 0) {
			newState.page = 0;
		}

		return newState;
	}

	componentDidMount() {
		const { label } = this.props;
		document.addEventListener('mousemove', this.handleMouseMove, false);
		document.addEventListener('mouseup', this.handleMouseUp, false);
		document.getElementById('scrollingPagedTable' + label).addEventListener('mousedown', this.handleRowClick, false);
		this.refs.scrollEventElement.addEventListener('wheel', this.handleMouseScroll, false);
	}

	componentWillUnmount() {
		const { label } = this.props;
		document.removeEventListener('mousemove', this.handleMouseMove, false);
		document.removeEventListener('mouseup', this.handleMouseUp, false);
		document.getElementById('scrollingPagedTable' + label).removeEventListener('mousedown', this.handleRowClick, false);
		this.refs.scrollEventElement.removeEventListener('wheel', this.handleMouseScroll, false);
	}

	componentDidUpdate() {
		const { onRowSelect } = this.props;
		this.formatSelectedHighlight();
		if (this.state.updatingSelection) {
			if (onRowSelect) onRowSelect();
			this.setState({ updatingSelection: false });
		}
	}

	getSelected() {
		return this.state.selectedRows;
	}

	clearSelected() {
		this.setState({ selectedRows: new Set(), updatingSelection: true });
	}

	incrementPage() {
		const { page, maxPage } = this.state;
		const newPage = (page === maxPage ? maxPage : page + 1);
		this.setState({ page: newPage });
	}

	decrementPage() {
		const { page } = this.state;
		const newPage = (page === 0 ? page : page - 1);
		this.setState({ page: newPage });
	}

	scrollByYCoordinate(yCord) {
		const { label } = this.props;
		const { maxPage } = this.state;
		if ( maxPage > 0 ) {
			let element = document.getElementById('scrollArea' + label);
			const scrollHeight = parseInt(document.getElementById('scrollBar' + label).clientHeight, 10);
			let offset = scrollHeight / 2;
			while (element) {
				offset += (element.offsetTop - element.scrollTop + element.clientTop);
				element = element.offsetParent;
			}
			let newPage = parseInt((yCord  - offset) / ((373 - scrollHeight) / maxPage), 10);
			newPage = Math.max(0, Math.min(newPage, maxPage));
			this.setState({ page: newPage });
		}
	}

	handleMouseScroll(event) {
		const { maxPage } = this.state;
		if (maxPage === 0) {
			return;
		}
		if (event.deltaY > 0) {
			for (let i = 0; i < event.deltaY / 100; i++) {
				this.incrementPage();
			}
		} else {
			for (let i = 0; i < -1 * event.deltaY / 100; i++) {
				this.decrementPage();
			}
		}
		event.preventDefault();
	}

	handleMouseMove(event) {
		const { dragging } = this.state;
		if (dragging) {
			this.scrollByYCoordinate(event.clientY);
			event.preventDefault();
		}
	}

	handleMouseDown(event) {
		this.setState({ dragging: true });
		this.scrollByYCoordinate(event.clientY);
	}

	handleMouseUp() {
		this.setState({ dragging: false });
	}

	handleRowClick(event) {
		const { onRowSelect } = this.props;
		const { selectedRows } = this.state;
		let element = event.target;
		if (element.childElementCount > 1) {
			return;
		}
		while (element.childElementCount > 0) {
			element = element.childNodes[0];
		}
		if (element.childNodes[0]) {
			const cell = element.childNodes[0].nodeValue;
			if (selectedRows.has(cell)) {
				selectedRows.delete(cell);
			} else {
				selectedRows.add(cell);
			}
			this.setState({ selectedRows }, () => {
				if (onRowSelect) {
					onRowSelect();
				}
			});
		}
	}

	handleDeselectAll(event) {
		this.setState({ selectedRows: new Set(), updatingSelection: true });
		event.preventDefault();
		this.forceUpdate();
	}

	handleSelectAll(event) {
		const { onRowSelect, data, fields  } = this.props;
		const { selectedRows } = this.state;
		data.forEach(entry => {
			fields.forEach(field => {
				selectedRows.add(`${entry[field]}`);
			});
		});
		event.preventDefault();
		if (onRowSelect) {
			onRowSelect();
		}
		this.forceUpdate();
	}

	pageData(data) {
		const { page, perScroll, selectedRows } = this.state;
		const { keyField } = this.props;
		const pagedData = [];
		for (let i = perScroll * page; i < 10 + perScroll * page; i++) {
			if (data && i < data.length) {
				const row = data[i];
				pagedData.push({ ...row, isSelected: selectedRows.has(`${row[keyField]}`) });
			}
		}
		return pagedData;
	}

	columnFormatter(cell, row) {
		const selectedClass = row.isSelected ? 'selectedRow' : 'deselectedRow';
		return (
			<div className={selectedClass} style={mergeStyles(CommonStyle.splitSection, { height: '17.5px' })}>
				<div style={CommonStyle.clipSection}>{cell}</div>
			</div>
		);
	}

	formatSelectedHighlight() {
		const { label } = this.props;
		const table = document.getElementById('scrollingPagedTable' + label);
		if (table) {
			const deselectedRows = table.getElementsByClassName('deselectedRow');
			for (let i = 0; i < deselectedRows.length; i++) {
				deselectedRows[i].parentElement.style.border = 'solid rgba(0,0,0,0)';
				deselectedRows[i].parentElement.style.borderWidth = '3px 3px 0px 3px';
			}
			const selectedRows = table.getElementsByClassName('selectedRow');
			for (let i = 0; i < selectedRows.length; i++) {
				selectedRows[i].parentElement.style.border = 'solid black 3px';
			}
		}
	}

	render() {
		const { data, label, fields, keyField } = this.props;
		const { page, maxPage } = this.state;
		const scrollHeight = document.getElementById('scrollBar' + label) ? parseInt(document.getElementById('scrollBar' + label).clientHeight, 10) : (322 / maxPage);

		return (
			<span>
				<div style={{ float: 'right', overflow: 'hidden', height: '410px' }}>
					<div ref="scrollEventElement">
						<div style={{ float: 'left' }}>
							<strong>{label}</strong>
							<div id={'scrollingPagedTable' + label} style={{ marginTop: '10px', height: '410px', width: '230px', overflowY: 'auto' }}>
								<BootstrapTable
									data={this.pageData(data)}
									columns={fields.map(field => ({
										dataField: field,
										text: '',
										sort: true,
										formatter: this.columnFormatter.bind(this),
										headerStyle: { display: 'none' }
									}))}
									keyField={keyField}
									bodyClasses="touchcodeTbody"
									striped
									hover
								/>
							</div>
						</div>
						{maxPage > 0 &&
						<div id={'scrollArea' + label} onMouseDown={ this.handleMouseDown.bind(this) } style={{ height: '373px', width: '20px', backgroundColor: '#F1F1F1', float: 'right', margin: '30px 0px 0px 0px' }}>
							<div id={'scrollBar' + label}  style={{ height: `${(322 - 10 * maxPage)}px`, minHeight: '20px', width: '20px', backgroundColor: '#C1C1C1', top: `${page * ((373 - scrollHeight) / maxPage)}px`, position: 'relative' }}/>
						</div>}
					</div>
				</div>
				<div style={{ paddingLeft: '30px' }}>
					<span>
						<button onClick={this.handleSelectAll.bind(this)} className="btn btn-default" style={{ marginRight: '10px' }}>Select All</button>
					</span>
					<span>
						<button onClick={this.handleDeselectAll.bind(this)} className="btn btn-default">Deselect All</button>
					</span>
				</div>
			</span>
		);
	}
}

export default ScrollingPagedBootstrapTable;
