/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
// @flow
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createActionName, BASE_URL } from './index';
import history from '../util/history';
import { routes } from '../util/constants';

// Actions
const DUCK_NAME = 'analytics';
const FETCH_ENGAGEMENTS_BY_HOUR = createActionName(DUCK_NAME, 'FETCH_ENGAGEMENTS_BY_HOUR');
const FETCH_ENGAGEMENTS_BY_DAY = createActionName(DUCK_NAME, 'FETCH_ENGAGEMENTS_BY_DAY');
const FETCH_ENGAGEMENTS_BY_MONTH = createActionName(DUCK_NAME, 'FETCH_ENGAGEMENTS_BY_MONTH');
const FETCH_ENGAGEMENTS_BY_YEAR = createActionName(DUCK_NAME, 'FETCH_ENGAGEMENTS_BY_YEAR');
const CLEAR_ANALYTICS_GRAPH = createActionName(DUCK_NAME, 'CLEAR_ANALYTICS_GRAPH');
const CLEAR_ENGAGEMENTS = createActionName(DUCK_NAME, 'CLEAR_ENGAGEMENTS');
const CLEAR_ENGAGEMENTS_BY_DATE = createActionName(DUCK_NAME, 'CLEAR_ENGAGEMENTS_BY_DATE');
const FETCH_LIVE_CAMPAIGNS = createActionName(DUCK_NAME, 'FETCH_LIVE_CAMPAIGNS');
const CLEAR_LIVE_CAMPAIGNS = createActionName(DUCK_NAME, 'CLEAR_LIVE_CAMPAIGNS');
const FETCH_CAMPAIGNS = createActionName(DUCK_NAME, 'FETCH_CAMPAIGNS');
const FETCH_ANALYTICS_COUNTRY = createActionName(DUCK_NAME, 'FETCH_ANALYTICS_COUNTRY');
const FETCH_ANALYTICS_PROVINCE = createActionName(DUCK_NAME, 'FETCH_ANALYTICS_PROVINCE');
const FETCH_ANALYTICS_POSTAL = createActionName(DUCK_NAME, 'FETCH_ANALYTICS_POSTAL');
const FETCH_ANALYTICS_TOP_COUNTRIES = createActionName(DUCK_NAME, 'FETCH_ANALYTICS_TOP_COUNTRIES');
const FETCH_ANALYTICS_BOTTOM_COUNTRIES = createActionName(DUCK_NAME, 'FETCH_ANALYTICS_BOTTOM_COUNTRIES');
const FETCH_ANALYTICS_TOP_PROVINCES = createActionName(DUCK_NAME, 'FETCH_ANALYTICS_TOP_PROVINCES');
const FETCH_ANALYTICS_BOTTOM_PROVINCES = createActionName(DUCK_NAME, 'FETCH_ANALYTICS_BOTTOM_PROVINCES');
const FETCH_ANALYTICS_TOP_POSTALS = createActionName(DUCK_NAME, 'FETCH_ANALYTICS_TOP_POSTALS');
const FETCH_ANALYTICS_BOTTOM_POSTALS = createActionName(DUCK_NAME, 'FETCH_ANALYTICS_BOTTOM_POSTALS');
const FETCH_CURRENT_MAP_DATA = createActionName(DUCK_NAME, 'FETCH_CURRENT_MAP_DATA');
const FETCH_ENGAGEMENTS = createActionName(DUCK_NAME, 'FETCH_ENGAGEMENTS');
const FETCH_ENGAGEMENTS_BY_USER_TYPE = createActionName(DUCK_NAME, 'FETCH_ENGAGEMENTS_BY_USER_TYPE');
const FETCH_ENGAGEMENTS_BY_DEVICE_IDENTITY = createActionName(DUCK_NAME, 'FETCH_ENGAGEMENTS_BY_DEVICE_IDENTITY');
const FETCH_ENGAGEMENTS_BY_OS = createActionName(DUCK_NAME, 'FETCH_ENGAGEMENTS_BY_OS');
const CLEAR_ENGAGEMENTS_BY_USER_TYPE = createActionName(DUCK_NAME, 'CLEAR_ENGAGEMENTS_BY_USER_TYPE');
const CLEAR_ENGAGEMENTS_BY_DEVICE_IDENTITY = createActionName(DUCK_NAME, 'CLEAR_ENGAGEMENTS_BY_DEVICE_IDENTITY');
const CLEAR_LOCATION_ANALYTICS = createActionName(DUCK_NAME, 'CLEAR_LOCATION_ANALYTICS');
const SET_ANALYTICS_LOCATION = createActionName(DUCK_NAME, 'SET_ANALYTICS_LOCATION');
const IS_MAP_UPDATING = createActionName(DUCK_NAME, 'IS_MAP_UPDATING');
const SET_MAP_DATETIME_INFO = createActionName(DUCK_NAME, 'SET_MAP_DATETIME_INFO');
const SET_SHOULD_EXPORT_MAP_DATA = createActionName(DUCK_NAME, 'SET_SHOULD_EXPORT_MAP_DATA');
const SET_CAMPAIGN_ID = createActionName(DUCK_NAME, 'SET_CAMPAIGN_ID');

const keyNames = {
	COUNTRY: 'CountryCode',
	REGION: 'Region',
	POSTAL: 'PostalCode'
};

function cleanEngagementsData(data, keyName) {
	return data
		.filter(value => value[keyName] && value[keyName] !== 'Unknown')
		.slice(0, 5);
}

// Reducer
export default (state = {}, action) => {
	switch (action.type) {
		case FETCH_ENGAGEMENTS_BY_HOUR: {
			return { ...state, engagementsByHour: action.payload.data };
		}
		case FETCH_ENGAGEMENTS_BY_DAY: {
			return { ...state, engagementsByDay: action.payload.data };
		}
		case FETCH_ENGAGEMENTS_BY_MONTH: {
			return { ...state, engagementsByMonth: action.payload.data };
		}
		case FETCH_ENGAGEMENTS_BY_YEAR: {
			return { ...state, engagementsByYear: action.payload.data };
		}
		case FETCH_ENGAGEMENTS: {
			return { ...state, engagements: action.payload.data };
		}
		case FETCH_ENGAGEMENTS_BY_USER_TYPE: {
			const { engagements, selectedCampaignId } = action.payload;
			return { ...state, engagementsByUserType: engagements.data, selectedCampaignId: selectedCampaignId };
		}
		case FETCH_ENGAGEMENTS_BY_DEVICE_IDENTITY: {
			const { engagements, selectedCampaignId } = action.payload;
			return { ...state, engagementsByDeviceIdentity: engagements.data, selectedCampaignId: selectedCampaignId };
		}
		case FETCH_ENGAGEMENTS_BY_OS: {
			return { ...state, engagementsByOS: action.payload.data };
		}
		case CLEAR_ANALYTICS_GRAPH: {
			return { ...state, engagements: action.payload.data };
		}
		case CLEAR_ENGAGEMENTS: {
			return { ...state, engagementsByHour: null, engagementsByYear: null, engagementsByDay: null, engagementsByMonth: null };
		}
		case CLEAR_ENGAGEMENTS_BY_DEVICE_IDENTITY: {
			return { ...state, engagementsByDeviceIdentity: null };
		}
		case CLEAR_ENGAGEMENTS_BY_USER_TYPE: {
			return { ...state, engagementsByUserType: null };
		}
		case CLEAR_ENGAGEMENTS_BY_DATE: {
			return { ...state, engagementsByDay: null, engagementsByMonth: null, engagementsByHour: null, engagementsByYear: null };
		}
		case FETCH_LIVE_CAMPAIGNS: {
			return { ...state, liveCampaigns: action.payload.data };
		}
		case CLEAR_LIVE_CAMPAIGNS: {
			return { ...state, liveCampaigns: null };
		}
		case FETCH_CAMPAIGNS: {
			return { ...state, campaigns: action.payload.data };
		}
		case FETCH_ANALYTICS_COUNTRY: {
			const data = [['Country', 'Engagements']];

			for (const i in action.payload.data.Engagements) {
				data.push([action.payload.data.Engagements[i].GroupCategory, action.payload.data.Engagements[i].Engagements]);
			}
			return { ...state, locationAnalytics: data };
		}
		case FETCH_ANALYTICS_PROVINCE: {
			const data = [['Province', 'Engagements']];

			for (const i in action.payload.data.Engagements) {
				data.push([action.payload.data.Engagements[i].GroupCategory, action.payload.data.Engagements[i].Engagements]);
			}
			return { ...state, locationAnalytics: data };
		}
		case FETCH_ANALYTICS_POSTAL: {
			const data = [['Postal', 'Engagements']];

			for (const i in action.payload.data.Engagements) {
				data.push([action.payload.data.Engagements[i].GroupCategory, action.payload.data.Engagements[i].Engagements]);
			}
			return { ...state, locationAnalytics: data };
		}
		case FETCH_ANALYTICS_TOP_COUNTRIES: {
			const data = cleanEngagementsData(action.payload.data, keyNames.COUNTRY);
			return { ...state, topEngagements: data };
		}
		case FETCH_ANALYTICS_BOTTOM_COUNTRIES: {
			const data = cleanEngagementsData(action.payload.data, keyNames.COUNTRY);
			return { ...state, bottomEngagements: data };
		}
		case FETCH_ANALYTICS_TOP_PROVINCES: {
			const data = cleanEngagementsData(action.payload.data, keyNames.REGION);
			return { ...state, topEngagements: data };
		}
		case FETCH_ANALYTICS_BOTTOM_PROVINCES: {
			const data = cleanEngagementsData(action.payload.data, keyNames.REGION);
			return { ...state, bottomEngagements: data };
		}
		case FETCH_ANALYTICS_TOP_POSTALS: {
			const data = cleanEngagementsData(action.payload.data, keyNames.POSTAL);
			return { ...state, topEngagements: data };
		}
		case FETCH_ANALYTICS_BOTTOM_POSTALS: {
			const data = cleanEngagementsData(action.payload.data, keyNames.POSTAL);
			return { ...state, bottomEngagements: data };
		}
		case CLEAR_LOCATION_ANALYTICS: {
			return { ...state, locationAnalytics: null, topEngagements: null, bottomEngagements: null };
		}
		case SET_ANALYTICS_LOCATION: {
			return { ...state, analyticsLocation: action.payload };
		}
		case IS_MAP_UPDATING: {
			return { ...state, isMapUpdating: action.payload };
		}
		case FETCH_CURRENT_MAP_DATA: {
			return { ...state, mapDataToExport: action.payload.response.data, shouldExportMapData: action.payload.shouldExport };
		}
		case SET_MAP_DATETIME_INFO: {
			return { ...state, mapDateTimeInfo: action.payload };
		}
		case SET_SHOULD_EXPORT_MAP_DATA: {
			return { ...state, shouldExportMapData: action.payload };
		}
		case SET_CAMPAIGN_ID: {
			return { ...state, selectedCampaignId: action.payload }
		}
		default:
			return state;
	}
};

// Action Creators
export function clearAnalyticsGraph(dateType) {
	return {
		type: CLEAR_ANALYTICS_GRAPH,
		payload: dateType
	};
}

export function fetchEngagementsByYear(campaign) {
	if (campaign == null) {
		campaign = 'all';
	}

	return dispatch => {
		axios.get(`${BASE_URL}/new-analytics/engagements/year/${campaign}`)
			.then(response => {
				dispatch({
					type: FETCH_ENGAGEMENTS_BY_YEAR,
					payload: response
				});
			})
			.catch(err => {
				if (err.response && err.response.status === 401) {
					history.push(routes.authExpired);
				} else {
					toastr.error('Error', `Could not display analytics for the given Campaign: ${err}`);
				}
			});
	};
}

export function fetchEngagementsByOS(campaignId) {
	return dispatch => {
		axios.get(`${BASE_URL}/new-analytics/engagements/os/${campaignId || 'all'}`)
			.then(response => {
				dispatch({
					type: FETCH_ENGAGEMENTS_BY_OS,
					payload: response
				});
			})
			.catch(err => {
				if (err.response && err.response.status === 401) {
					history.push(routes.authExpired);
				} else {
					toastr.error('Error', `Could not display analytics for the given Campaign: ${err}`);
				}
			});
	};
}


export const fetchEngagementsByUserType = (() => {
	let cancelr;
	const campaignCache = {};
	const allCampaignsForCustomerCache = {};

	return campaignId => {
		campaignId = campaignId || 'all';
		if (cancelr) {
			cancelr.cancel();
		}
		cancelr = axios.CancelToken.source();

		return async (dispatch, getState) => {
			const { auth } = getState();
			const customerId = auth.customerId || 'all';
			let engagements;

			if (campaignId === 'all' && allCampaignsForCustomerCache[customerId]) {
				engagements = allCampaignsForCustomerCache[customerId];
			} else if (campaignId !== 'all' && campaignCache[campaignId]) {
				engagements = campaignCache[campaignId];
			} else {
				try {
					const response = await axios.get(`${BASE_URL}/new-analytics/engagements/user-type/${campaignId}`, { cancelToken: cancelr.token });
					engagements = response;
					if (campaignId === 'all') {
						allCampaignsForCustomerCache[customerId] = engagements;
					} else {
						campaignCache[campaignId] = engagements;
					}
				} catch (err) {
					if (axios.isCancel(err)) {
						console.log('First request canceled', err.message);
					} else if (err.response && err.response.status === 401) {
						history.push(routes.authExpired);
					} else {
						toastr.error('Error', `Could not display analytics for the given Campaign: ${err}`);
					}
					return;
				}
			}

			dispatch({
				type: FETCH_ENGAGEMENTS_BY_USER_TYPE,
				payload: { engagements: engagements, selectedCampaignId: campaignId }
			});
		};
	};
})();

export const fetchEngagementsByDeviceIdentity = (() => {
	let cancelr;
	const campaignCache = {};
	const allCampaignsForCustomerCache = {};

	return campaignId => {
		campaignId = campaignId || 'all';
		if (cancelr) {
			cancelr.cancel();
		}
		cancelr = axios.CancelToken.source();

		return async(dispatch, getState) => {
			const { auth } = getState();
			const customerId = auth.customerId || 'all';
			let engagements;

			if (campaignId === 'all' && allCampaignsForCustomerCache[customerId]) {
				engagements = allCampaignsForCustomerCache[customerId];
			} else if (campaignId !== 'all' && campaignCache[campaignId]) {
				engagements = campaignCache[campaignId];
			} else {
				try {
					const response = await axios.get(`${BASE_URL}/new-analytics/engagements/device-identity/${campaignId}`, { cancelToken: cancelr.token });
					engagements = response;
					if (campaignId === 'all') {
						allCampaignsForCustomerCache[customerId] = engagements;
					} else {
						campaignCache[campaignId] = engagements;
					}
				} catch (err) {
					if (axios.isCancel(err)) {
						console.log('First request canceled', err.message);
					} else if (err.response && err.response.status === 401) {
						history.push(routes.authExpired);
					} else {
						toastr.error('Error', `Could not display analytics for the given Campaign: ${err}`);
					}
					return;
				}
			}

			dispatch({
				type: FETCH_ENGAGEMENTS_BY_DEVICE_IDENTITY,
				payload: { engagements: engagements, selectedCampaignId: campaignId }
			});
		};
	};
})();

export function fetchEngagementsByMonth(year, campaign) {
	return dispatch => {
		axios.get(`${BASE_URL}/new-analytics/engagements/month/${campaign || 'all'}?year=${year}`)
			.then(response => {
				dispatch({
					type: FETCH_ENGAGEMENTS_BY_MONTH,
					payload: response
				});
			})
			.catch(err => {
				if (err.response && err.response.status === 401) {
					history.push(routes.authExpired);
				} else {
					toastr.error('Error', `Could not display analytics for the given Campaign: ${err}`);
				}
			});
	};
}

export function fetchEngagementsByDay(month, year, campaign) {
	return dispatch => {
		axios.get(`${BASE_URL}/new-analytics/engagements/day/${campaign || 'all'}?month=${month}&year=${year}`)
			.then(response => {
				dispatch({
					type: FETCH_ENGAGEMENTS_BY_DAY,
					payload: response
				});
			})
			.catch(err => {
				if (err.response && err.response.status === 401) {
					history.push(routes.authExpired);
				} else {
					toastr.error('Error', `Could not display analytics for the given Campaign: ${err}`);
				}
			});
	};
}

export function fetchEngagementsByHour(day, month, year, campaign) {
	return dispatch => {
		axios.get(`${BASE_URL}/new-analytics/engagements/hour/${campaign || 'all'}?day=${day}&month=${month}&year=${year}`)
			.then(response => {
				dispatch({
					type: FETCH_ENGAGEMENTS_BY_HOUR,
					payload: response
				});
			})
			.catch(err => {
				if (err.response && err.response.status === 401) {
					history.push(routes.authExpired);
				} else {
					toastr.error('Error', `Could not display analytics for the given Campaign: ${err}`);
				}
			});
	};
}

/* export function fetchEngagements(campaign_id, hour, day, month, year, country, region, postal, user_id, device_type, os_type, returning) {
	if (!campaign_id) { campaign_id = ' '; }
	if (!hour) { hour = ' '; }
	if (!day) { day = ' '; }
	if (!month) { month = ' '; }
	if (!year) { year = ' '; }
	if (!country) { country = ' '; }
	if (!region) { region = ' '; }
	if (!postal) { postal = ' '; }
	if (!user_id) { user_id = ' '; }
	if (!device_type) { device_type = ' '; }
	if (!os_type) { os_type = ' '; }
	if (!returning) { returning = ' '; }

	return dispatch => {
		axios.get(`${BASE_URL}/analytics/engagements-by-campaign/${campaign_id}`)
			.then(response => {
				dispatch({
					type: FETCH_ENGAGEMENTS,
					payload: response
				});
			})
			.catch(err => {
				if (err.response && err.response.status === 401) {
					history.push(routes.authExpired);
				} else {
					toastr.error('Error', 'Could not display analytics for the given Campaign');
				}
			});
	};
}*/

export function fetchLiveCampaigns() {
	return dispatch => {
		axios.get(`${BASE_URL}/new-analytics/live-campaigns`)
			.then(response => {
				dispatch({
					type: FETCH_LIVE_CAMPAIGNS,
					payload: response
				});
			})
			.catch(err => {
				if (err.response && err.response.status === 401) {
					history.push(routes.authExpired);
				} else {
					toastr.error('Error', `Could not display analytics for the given Campaign: ${err}`);
				}
			});
	};
}

export function fetchCampaignsInDw() {
	return dispatch => {
		axios.get(`${BASE_URL}/analytics/campaigns`)
			.then(response => {
				dispatch({
					type: FETCH_CAMPAIGNS,
					payload: response
				});
			})
			.catch(err => {
				if (err.response && err.response.status === 401) {
					history.push(routes.authExpired);
				} else {
					toastr.error('Error', `Could not display analytics for the given Campaign: ${err}`);
				}
			});
	};
}

export function clearEngagementsByUserType() {
	return {
		type: CLEAR_ENGAGEMENTS_BY_USER_TYPE
	};
}

export function clearEngagementsByDeviceIdentity() {
	return {
		type: CLEAR_ENGAGEMENTS_BY_DEVICE_IDENTITY
	};
}

export function clearEngagements() {
	return {
		type: CLEAR_ENGAGEMENTS
	};
}

export function clearEngagementsByDate() {
	return {
		type: CLEAR_ENGAGEMENTS_BY_DATE
	};
}

export function clearLiveCampaigns() {
	return {
		type: CLEAR_LIVE_CAMPAIGNS
	};
}

export function fetchCountries(campaignId) {
	return dispatch => {
		axios.get(`${BASE_URL}/new-analytics/engagements/country/${campaignId || 'all'}`)
			.then(response => {
				dispatch({
					type: FETCH_ANALYTICS_COUNTRY,
					payload: response
				});
			})
			.catch(err => {
				if (err.response && err.response.status === 401) {
					history.push(routes.authExpired);
				} else {
					toastr.error('Error', `Could not display analytics for the given Campaign: ${err}`);
				}
			});
	};
}

export function fetchProvinces(countryCode, campaignId) {
	return dispatch => {
		axios.get(`${BASE_URL}/new-analytics/engagements/region/${campaignId || 'all'}?country=${countryCode}`)
			.then(response => {
				dispatch({
					type: FETCH_ANALYTICS_PROVINCE,
					payload: response
				});
			})
			.catch(err => {
				if (err.response && err.response.status === 401) {
					history.push(routes.authExpired);
				} else {
					toastr.error('Error', `Could not display analytics for the given Campaign: ${err}`);
				}
			});
	};
}

export function fetchPostals(provinceCode, countryCode, campaignId) {
	return dispatch => {
		axios.get(`${BASE_URL}/new-analytics/engagements/postal/${campaignId || 'all'}?country=${countryCode}&region=${provinceCode}`)
			.then(response => {
				dispatch({
					type: FETCH_ANALYTICS_POSTAL,
					payload: response
				});
			})
			.catch(err => {
				if (err.response && err.response.status === 401) {
					history.push(routes.authExpired);
				} else {
					toastr.error('Error', `Could not display analytics for the given Campaign: ${err}`);
				}
			});
	};
}

export function fetchTopCountries(campaignId) {
	return dispatch => {
		axios.get(`${BASE_URL}/new-analytics/top-country/${campaignId || ''}`)
			.then(response => {
				dispatch({
					type: FETCH_ANALYTICS_TOP_COUNTRIES,
					payload: response
				});
			})
			.catch(err => {
				if (err.response && err.response.status === 401) {
					history.push(routes.authExpired);
				} else {
					toastr.error('Error', `Could not display analytics for the given Campaign: ${err}`);
				}
			});
	};
}

export function fetchBottomCountries(campaignId) {
	return dispatch => {
		axios.get(`${BASE_URL}/new-analytics/bottom-country/${campaignId || ''}`)
			.then(response => {
				dispatch({
					type: FETCH_ANALYTICS_BOTTOM_COUNTRIES,
					payload: response
				});
			})
			.catch(err => {
				if (err.response && err.response.status === 401) {
					history.push(routes.authExpired);
				} else {
					toastr.error('Error', `Could not display analytics for the given Campaign: ${err}`);
				}
			});
	};
}

export function fetchTopProvinces(countryCode, campaignId) {
	return dispatch => {
		axios.get(`${BASE_URL}/new-analytics/top-region/${countryCode}/${campaignId || ''}`)
			.then(response => {
				dispatch({
					type: FETCH_ANALYTICS_TOP_PROVINCES,
					payload: response
				});
			})
			.catch(err => {
				if (err.response && err.response.status === 401) {
					history.push(routes.authExpired);
				} else {
					toastr.error('Error', `Could not display analytics for the given Campaign: ${err}`);
				}
			});
	};
}

export function fetchBottomProvinces(countryCode, campaignId) {
	return dispatch => {
		axios.get(`${BASE_URL}/new-analytics/bottom-region/${countryCode}/${campaignId || ''}`)
			.then(response => {
				dispatch({
					type: FETCH_ANALYTICS_BOTTOM_PROVINCES,
					payload: response
				});
			})
			.catch(err => {
				if (err.response && err.response.status === 401) {
					history.push(routes.authExpired);
				} else {
					toastr.error('Error', `Could not display analytics for the given Campaign: ${err}`);
				}
			});
	};
}

export function fetchTopPostals(provinceCode, campaignId) {
	return dispatch => {
		axios.get(`${BASE_URL}/new-analytics/top-postal/${provinceCode}/${campaignId || ''}`)
			.then(response => {
				dispatch({
					type: FETCH_ANALYTICS_TOP_POSTALS,
					payload: response
				});
			})
			.catch(err => {
				if (err.response && err.response.status === 401) {
					history.push(routes.authExpired);
				} else {
					toastr.error('Error', `Could not display analytics for the given Campaign: ${err}`);
				}
			});
	};
}

export function fetchBottomPostals(provinceCode, campaignId) {
	return dispatch => {
		axios.get(`${BASE_URL}/new-analytics/bottom-postal/${provinceCode}/${campaignId || ''}`)
			.then(response => {
				dispatch({
					type: FETCH_ANALYTICS_BOTTOM_POSTALS,
					payload: response
				});
			})
			.catch(err => {
				if (err.response && err.response.status === 401) {
					history.push(routes.authExpired);
				} else {
					toastr.error('Error', `Could not display analytics for the given Campaign: ${err}`);
				}
			});
	};
}

/**
 * Handles map updating. Gets all data back before updating
 * This fixes an issue where unwanted data will be shown if navigating too fast
 * @param {*} locationData
 * @param {*} campaignId
 */
export function fetchMapEngagements(locationData, campaignId) {
	const { countryCode, provinceCode, zipCode } = locationData;
	const axiosCalls = [];
	const dispatchTypes = [];
	return dispatch => {
		dispatch({
			type: IS_MAP_UPDATING,
			payload: true
		});

		if (!countryCode) {
			axiosCalls.push(axios.get(`${BASE_URL}/new-analytics/engagements/country/${campaignId || 'all'}`));
			axiosCalls.push(axios.get(`${BASE_URL}/new-analytics/top-country/${campaignId || ''}`));

			dispatchTypes.push(FETCH_ANALYTICS_COUNTRY, FETCH_ANALYTICS_TOP_COUNTRIES);
		} else if (countryCode && !provinceCode) {
			axiosCalls.push(axios.get(`${BASE_URL}/new-analytics/engagements/region/${campaignId || 'all'}?country=${countryCode}`));
			axiosCalls.push(axios.get(`${BASE_URL}/new-analytics/top-region/${countryCode}/${campaignId || ''}`));

			dispatchTypes.push(FETCH_ANALYTICS_PROVINCE, FETCH_ANALYTICS_TOP_PROVINCES);
		} else if (provinceCode) {
			axiosCalls.push(axios.get(`${BASE_URL}/new-analytics/engagements/postal/${campaignId || 'all'}?country=${countryCode}&region=${provinceCode}`));
			axiosCalls.push(axios.get(`${BASE_URL}/new-analytics/top-postal/${countryCode}-${provinceCode}/${campaignId || ''}`));

			dispatchTypes.push(FETCH_ANALYTICS_POSTAL, FETCH_ANALYTICS_TOP_POSTALS);
		}

		// Only update when all requests have been made
		// All calls will be in the order we call them so this is okay
		axios.all(axiosCalls)
			.then(response => {
				dispatchTypes.forEach((type, index) => {
					dispatch({
						type: type,
						payload: response[index]
					});
				});
			})
			.catch(err => {
				if (err.response && err.response.status === 401) {
					history.push(routes.authExpired);
				} else {
					toastr.error('Error', `Could not display analytics for the given Campaign: ${campaignId}, ${err}`);
				}
			})
			.finally(() => {
				dispatch({
					type: IS_MAP_UPDATING,
					payload: false
				});
			});
	};
}

export function fetchEngagementsFromMap(mapData, shouldExport) {
	const shouldExportData = shouldExport || false;
	const queryCampaignId = mapData.campaignId || 'all';
	const keysToMap = [
		'countryCode',
		'provinceCode',
		'displayDay',
		'displayMonth',
		'displayYear',
		'displayMode',
		'displayHour',
		'postal',
		'user_id',
		'device_type',
		'os_type'
	];

	const queryParams = keysToMap.map(key => `${key}=${mapData[key]}`);
	const queryString = queryParams
		.filter(string => string.length > 0)
		.join('&');

	return dispatch => {
		axios.get(`${BASE_URL}/new-analytics/engagements-from/${queryCampaignId}?${queryString}`)
			.then(response => {
				dispatch({
					type: FETCH_CURRENT_MAP_DATA,
					payload: { shouldExport: shouldExportData, response }
				});
			})
			.catch(err => {
				if (err.response && err.response.status === 401) {
					history.push(routes.authExpired);
				}
			});
	};
}

export function updateGraphDateTimeInfo(mapDateTime) {
	return dispatch => {
		dispatch({
			type: SET_MAP_DATETIME_INFO,
			payload: mapDateTime
		});
	};
}

export function setShouldExportMapData(shouldExport) {
	return dispatch => {
		dispatch({
			type: SET_SHOULD_EXPORT_MAP_DATA,
			payload: shouldExport
		});
	};
}

export function clearLocationAnalytics() {
	return {
		type: CLEAR_LOCATION_ANALYTICS
	};
}

export function setNewAnalyticsLocation(location) {
	return dispatch => {
		dispatch({
			type: SET_ANALYTICS_LOCATION,
			payload: location
		});
	};
}

export function setSelectedCampaignId(campaignId) {
	return dispatch => {
		dispatch({
			type: SET_CAMPAIGN_ID,
			payload: campaignId,
		})
	}
}
