import { createActionName, BASE_URL } from './index';
import axios from 'axios';
import history from '../util/history';
import { routes } from '../util/constants';
import { toastr } from 'react-redux-toastr';

// Actions
const DUCK_NAME = 'touchcode';
const FETCH_TOUCHCODE = createActionName(DUCK_NAME, 'FETCH_TOUCHCODE');
const FETCH_TOUCHCODES = createActionName(DUCK_NAME, 'FETCH_TOUCHCODES');
const FETCH_TOUCHCODES_WITH_EXPERIMENTAL = createActionName(DUCK_NAME, 'FETCH_TOUCHCODES_WITH_EXPERIMENTAL');
const UPDATE_TOUCHCODE = createActionName(DUCK_NAME, 'UPDATE_TOUCHCODE');
const DELETE_TOUCHCODE = createActionName(DUCK_NAME, 'DELETE_TOUCHCODE');
const NEW_TOUCHCODE = createActionName(DUCK_NAME, 'NEW_TOUCHCODE');
const CLEAR_CURRENT_TOUCHCODE = createActionName(DUCK_NAME, 'CLEAR_CURRENT_TOUCHCODE');
const CLEAR_IMPORTED_CODES = createActionName(DUCK_NAME, 'CLEAR_IMPORTED_CODES');
const CLEAR_TOUCHCODES = createActionName(DUCK_NAME, 'CLEAR_TOUCHCODES');
const IMPORT_CODES = createActionName(DUCK_NAME, 'IMPORT_CODES');
const IMPORT_LABELS = createActionName(DUCK_NAME, 'IMPORT_LABELS');
// Reducer
export default function(state = {}, action = {}) {
	switch (action.type) {
		case FETCH_TOUCHCODES: {
			return { ...state, touchcodes: action.payload.data };
		}
		case FETCH_TOUCHCODE: {
			return { ...state, currentTouchcode: action.payload.data };
		}
		case FETCH_TOUCHCODES_WITH_EXPERIMENTAL: {
			return { ...state, touchcodes: action.payload.data };
		}
		case UPDATE_TOUCHCODE: {
			return state;
		}
		case NEW_TOUCHCODE: {
			return state;
		}
		case DELETE_TOUCHCODE: {
			return { ...state, currentTouchcode: undefined };
		}
		case CLEAR_CURRENT_TOUCHCODE: {
			return { ...state, currentTouchcode: undefined };
		}
		case IMPORT_CODES: {
			return { ...state, importedTouchcodes: action.payload.data.touchcodes };
		}
		case CLEAR_IMPORTED_CODES: {
			return { ...state, importedTouchcodes: undefined };
		}
		case CLEAR_TOUCHCODES: {
			return { ...state, touchcodes: undefined };
		}
		case IMPORT_LABELS: {
			return state;
		}
		default:
			return state;
	}
}

// Action Creators
export function fetchTouchcodes() {
	return dispatch => {
		axios.get(`${BASE_URL}/touchcode`)
			.then(response => {
				dispatch({
					type: FETCH_TOUCHCODES,
					payload: response
				});
			})
			.catch(err => {
				// TODO: Implement me
			});
	};
}

export function fetchTouchcodesWithExperimental() {
	return dispatch => {
		axios.get(`${BASE_URL}/touchcode/experimental`)
			.then(response => {
				dispatch({
					type: FETCH_TOUCHCODES,
					payload: response
				});
			})
			.catch(err => {
				// TODO: Implement me
			});
	};
}

export function fetchTouchcode(touchcodeId) {
	return dispatch => {
		axios.get(`${BASE_URL}/touchcode/${touchcodeId}`)
			.then(response => {
				dispatch({
					type: FETCH_TOUCHCODE,
					payload: response
				});
			})
			.catch(err => {
				// TODO: Implement me
			});
	};
}

export function updateTouchcode(touchcodeId, touchcode) {
	return dispatch => {
		axios.put(`${BASE_URL}/touchcode/${touchcodeId}`, touchcode)
			.then(response => {
				dispatch({
					type: UPDATE_TOUCHCODE,
					payload: response
				});
				toastr.success('Success', 'Touchcode updated');
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to update Touchcode.');
				}
			});
	};
}

export function newTouchcode(touchcode) {
	return dispatch => {
		axios.put(`${BASE_URL}/touchcode/new`, touchcode)
			.then(response => {
				dispatch({
					type: NEW_TOUCHCODE,
					payload: response
				});
				toastr.success('Success', 'Touchcode ' + response.data.Id + ' created');
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to create Touchcode.');
				}
			});
	};
}


export function deleteTouchcode(touchcodeId) {
	return dispatch => {
		axios.put(`${BASE_URL}/touchcode/delete`, { touchcodeId: touchcodeId })
			.then(response => {
				dispatch({
					type: DELETE_TOUCHCODE,
					payload: response
				});
				toastr.success('Success', 'Touchcode removed');
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to delete Touchcode');
				}
			});
	};
}

export function clearCurrentTouchcode() {
	return {
		type: CLEAR_CURRENT_TOUCHCODE
	};
}

export function importCodes(codes) {
	return dispatch => {
		axios.post(`${BASE_URL}/touchcode/import/fromCodeset`, codes)
			.then(response => {
				dispatch({
					type: IMPORT_CODES,
					payload: response
				});
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to import Touchcodes.');
				}
			});
	};
}

export function importLabels(codeLabels) {
	return dispatch => {
		axios.put(`${BASE_URL}/touchcode/import/labels`, codeLabels)
			.then(response => {
				dispatch({
					type: IMPORT_LABELS,
					payload: response
				});
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to import labels.');
				}
			});
	};
}

export function clearImportedCodes() {
	return {
		type: CLEAR_IMPORTED_CODES
	};
}

export function clearTouchcodes() {
	return {
		type: CLEAR_TOUCHCODES
	};
}
