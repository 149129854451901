import React, { Component } from 'react';
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { fetchEngagementsByOS, clearEngagementsByDate, clearAnalyticsGraph, fetchCampaignsInDw, fetchEngagementsByHour, fetchEngagementsByDay, fetchEngagementsByMonth, fetchEngagementsByYear, updateGraphDateTimeInfo } from '../../ducks/analytics.duck';
import { setCustomerId, setCustomerIdEnabled } from '../../ducks/auth.duck';
import moment from 'moment';
import Spinner from '../utilities/spinner';
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import fileDownload from 'js-file-download';
import AnalyticsDateTimeBarGraph from './analyticsDateTimeBarGraph';
import { DISPLAYMODE, MONTHS } from '../../util/constants.js';
import { toastr } from 'react-redux-toastr';
import { convertHourToMilitary } from '../../util/DateUtil';

const formName = 'analyticsDateTimeForm';
const todaysYear = JSON.stringify(new Date().getFullYear());

class AnalyticsDateTime extends Component {
	constructor(props) {
		super(props);

		this.state = {
			displayDay: undefined,
			displayMonth: undefined,
			displayYear: todaysYear,
			previousMode: {
				[DISPLAYMODE.YEAR]: {
					Date: todaysYear
				}
			},
			displayMode: DISPLAYMODE.MONTH,
			selectedCampaign: this.props.match.params.campaignId || this.props.selectedCampaign,
			customerId: this.props.customerId
		};
		this.displayObjectKeys = ['displayHour', 'displayDay', 'displayMonth', 'displayYear', 'displayMode'];
	}

	static getDerivedStateFromProps(props, state) {
		if (props.selectedCampaign !== state.selectedCampaign || props.customerId !== state.customerId) {
			return {
				displayDay: undefined,
				displayMonth: undefined,
				displayYear: todaysYear,
				previousMode: {
					[DISPLAYMODE.YEAR]: {
						Date: todaysYear
					}
				},
				displayMode: DISPLAYMODE.MONTH,
				selectedCampaign: props.selectedCampaign || null,
				customerId: props.customerId
			};
		}
		return null;
	}

	componentDidMount() {
		const { selectedCampaign } = this.props;
		this.props.setCustomerIdEnabled(true);
		this.props.fetchCampaignsInDw();
		this.props.fetchEngagementsByYear();
		this.props.fetchEngagementsByMonth(todaysYear, selectedCampaign);
		this.props.updateAnalyticsRefreshActions([
			fetchCampaignsInDw,
			() => fetchEngagementsByMonth(todaysYear, selectedCampaign),
			() => fetchEngagementsByYear(selectedCampaign)
		]);

		this.dispatchMapDateTime();
	}

	componentDidUpdate(prevProps, prevState) {
		const { params } = this.props.match;

		const changedDisplayKeys = this.displayObjectKeys.filter(key => this.state[key] !== prevState[key]);
		const changedParamKeys = Object.keys(params).filter(key => params[key] !== prevProps.match.params[key]);

		const didDisplayChange = changedDisplayKeys.length > 0;
		const didParamsChange = changedParamKeys.length > 0;
		const didParamLengthChange = Object.keys(params).length !== Object.keys(prevProps.match.params).length;

		if (didDisplayChange || didParamsChange || didParamLengthChange) {
			this.dispatchMapDateTime();
		}

		if (prevProps.selectedCampaign !== this.props.selectedCampaign || prevProps.customerId !== this.props.customerId) {
			this.handleCampaignChange(this.props.selectedCampaign);
		}
	}

	dispatchMapDateTime() {
		const propsObj = this.props;
		const stateObj = this.state;

		const { params } = propsObj.match;
		const mapDateTimeObj = { ...params };

		this.displayObjectKeys.forEach(key => {
			mapDateTimeObj[key] = stateObj[key];
		});
		this.props.updateGraphDateTimeInfo(mapDateTimeObj);
	}

	handleHourClick(data, _index) {
		const displayHour = data.Date || data.value || this.state.previousMode.day.Date;

		const { isAuthenticated, renewSession } = this.props.auth;
		if (isAuthenticated()) {
			renewSession();
		}

		toastr.light(`You selected ${displayHour}`);

		const convertedHour = convertHourToMilitary(displayHour);

		this.setState({
			displayHour: convertedHour.toString(),
		});
	}

	handleDayClick(data, _index) {
		// If a bar on the graph is clicked, data.Date is used. If the axis is clicked, data.value.
		const displayDay = data.Date || data.value || this.state.previousMode.day.Date;
		const previousMode = this.handlePreviousMode(data, this.state.displayMode);
		const displayMode = DISPLAYMODE.HOUR;

		const { isAuthenticated, renewSession } = this.props.auth;
		if (isAuthenticated()) {
			renewSession();
		}

		this.props.fetchEngagementsByHour(displayDay, this.state.displayMonth, this.state.displayYear, this.state.selectedCampaign);

		this.setState({
			displayDay,
			displayMode,
			previousMode,
			displayHour: undefined,
		});
	}

	handleMonthClick(data, _index) {
		const displayMonth = data.Date || data.value || this.state.previousMode.month.Date;
		const previousMode = this.handlePreviousMode(data, this.state.displayMode);
		const displayMode = DISPLAYMODE.DAY;

		const { isAuthenticated, renewSession } = this.props.auth;
		if (isAuthenticated()) {
			renewSession();
		}

		this.props.fetchEngagementsByDay(displayMonth, this.state.displayYear, this.state.selectedCampaign);

		this.setState({
			displayMode,
			displayMonth,
			displayDay: undefined,
			displayHour: undefined,
			previousMode,
		});
	}

	handleYearClick(data, _index) {
		const displayYear = data.Date || data.value || this.state.previousMode.year.Date;
		const previousMode = this.handlePreviousMode(data, this.state.displayMode);
		const displayMode = DISPLAYMODE.MONTH;

		const { isAuthenticated, renewSession } = this.props.auth;
		if (isAuthenticated()) {
			renewSession();
		}

		this.props.fetchEngagementsByMonth(displayYear, this.state.selectedCampaign);

		this.setState({
			displayMode,
			displayDay: undefined,
			displayHour: undefined,
			displayYear,
			previousMode,
		});
	}

	handleAllYearsClick(_index) {
		const displayMode = DISPLAYMODE.YEAR;

		const { isAuthenticated, renewSession } = this.props.auth;
		if (isAuthenticated()) {
			renewSession();
		}

		this.props.fetchEngagementsByYear(this.state.selectedCampaign);

		this.setState({
			displayMode,
			displayDay: undefined,
			displayMonth: undefined,
			displayHour: undefined,
			previousMode: {},
		});
	}

	handlePreviousMode(data, displayMode) {
		const previousMode = !this.state.previousMode.displayMode ? { ...this.state.previousMode, [displayMode]: data } : { ...this.state.previousMode };
		return previousMode;
	}

	handleCampaignChange(selectedCampaign) {
		this.props.clearEngagementsByDate();
		this.props.fetchEngagementsByMonth(todaysYear, selectedCampaign);
	}

	handleExport() {
		const { engagementsByYear, engagementsByMonth, engagementsByDay, engagementsByHour } = this.props;
		let engagements = null;
		if (engagementsByHour) {
			engagements = engagementsByHour;
		} else if (engagementsByDay) {
			engagements = engagementsByDay;
		} else if (engagementsByMonth) {
			engagements = engagementsByMonth;
		} else if (engagementsByYear) {
			engagements = engagementsByYear;
		}

		if (engagements) {
			fileDownload(this.objectToCsv(engagements), 'engagements.csv');
		}
	}

	objectToCsv(items) {
		let csv = [];
		csv.push('Engagements, Date');
		for (const key in items) {
			const item = items[key].engagements;
			item.forEach(i => {
				csv.push(i.Engagements + ', ' + i.Date);
			});
		}
		csv = csv.join('\r\n');
		return csv;
	}

	getTimePeriodArray(startDate, endDate, key, scaleUnit) {
		const result = {};
		while (startDate.isBefore(endDate)) {
			result[startDate.format(key)] = { Engagements: 0 };
			startDate.add(1, scaleUnit);
		}
		result[startDate.format(key)] = { Engagements: 0 };

		return result;
	}

	getYearsArray(startDate, endDate) {
		return this.getTimePeriodArray(startDate, endDate, 'YYYY', 'M');
	}

	getDaysArray(month, year) {
		const start = moment().year(year).month(month).date(1);
		const end = moment().year(year).month(month + 1).date(1);
		return this.getTimePeriodArray(start, end, 'DD', 'd');
	}

	switchEngagementVisablity(engagementVisible) {
		const tempEngagementVisible = !engagementVisible;
		return tempEngagementVisible;
	}

	findYearsStart() {
		const campaignID = this.state.selectedCampaign;
		const engagements = this.props.campaigns;
		let yearsSinceStart = new Date().getFullYear();

		for (const key in engagements) {
			if (!campaignID || campaignID === 'all' || campaignID === engagements[key].CampaignKey) {
				const value = engagements[key].StartDate;
				if (typeof value == 'string' && yearsSinceStart > value.slice(0, 4)) {
					yearsSinceStart = value.slice(0, 4);
				}
			}
		}
		return new Date().getFullYear() - yearsSinceStart;
	}

	formatYearData() {
		const start = moment();
		const end = moment();
		const years = this.findYearsStart();
		const engagementsByYear = this.props.engagementsByYear;
		start.subtract(years, 'Y');

		const data = this.getYearsArray(start, end);

		if (engagementsByYear) {
			for (const i in engagementsByYear.Engagements) {
				if (data[engagementsByYear.Engagements[i].GroupCategory] !== undefined) {
					data[engagementsByYear.Engagements[i].GroupCategory].Engagements = engagementsByYear.Engagements[i].Engagements;
				}
			}
			return this.formatGraphData(data);
		}

		return null;
	}

	formatMonthData() {
		const engagementsByMonth = this.props.engagementsByMonth;
		const data = {};

		for (let i = 1; i < 13; i++) {
			let month = '' + i;
			if (month.length === 1) {
				month = '0' + month;
			}
			data[month] = { Engagements: 0 };
		}

		if (engagementsByMonth) {
			for (const i in engagementsByMonth.Engagements) {
				let index = '' + engagementsByMonth.Engagements[i].GroupCategory;
				if (index.length === 1) {
					index = '0' + index;
				}

				data[index] = { Engagements: engagementsByMonth.Engagements[i].Engagements };
			}
			return this.formatGraphData(data);
		}

		return null;
	}

	formatDayData() {
		const engagementsByDay = this.props.engagementsByDay;
		const data = this.getDaysArray(Number(this.state.displayMonth - 1), this.state.displayYear);

		if (engagementsByDay) {
			for (const i in engagementsByDay.Engagements) {
				let index = '' + engagementsByDay.Engagements[i].GroupCategory;
				if (index.length === 1) {
					index = '0' + index;
				}
				data[index] = { Engagements: engagementsByDay.Engagements[i].Engagements };
			}
			return this.formatGraphData(data);
		}

		return null;
	}

	formatHourData() {
		const engagementsByHour = this.props.engagementsByHour;
		const data = {};
		for (let i = 0; i < 24; i++) {
			let hour = i;
			let noon = 'AM';

			if (i === 12) {
				hour = 12;
				noon = 'PM';
			}
			if (i > 12) {
				hour = i - 12;
				noon = 'PM';
			}
			if (i === 0) {
				hour = 12;
			}

			data[i] = { Date: hour, Engagements: 0, AMPM: noon };
		}

		if (engagementsByHour) {
			for (const i in engagementsByHour.Engagements) {
				data[engagementsByHour.Engagements[i].GroupCategory].Engagements = engagementsByHour.Engagements[i].Engagements;
			}
			return this.formatGraphData(data);
		}

		return null;
	}

	formatGraphData(data) {
		const rv = [];

		if (data[1] && data[1].AMPM) {
			for (const key in data) {
				rv.push({ Date: key, Engagements: data[key].Engagements, AMPM: data[key].AMPM });
			}
			const sortedEngagements = rv.sort((a, b) => {
				return Number(a.Date) < Number(b.Date) ? -1 : 1;
			});
			for (const i in sortedEngagements) {
				if (sortedEngagements[i].AMPM === 'PM' && sortedEngagements[i].Date > 12) {
					sortedEngagements[i].Date = Math.abs(sortedEngagements[i].Date - 12);
				}
				if (sortedEngagements[i].Date === '0') {
					sortedEngagements[i].Date = 12;
				}
				sortedEngagements[i].Date = sortedEngagements[i].Date + sortedEngagements[i].AMPM;
			}
			return sortedEngagements;
		}

		for (const key in data) {
			rv.push({ Date: key, Engagements: data[key].Engagements });
		}

		return rv.sort((a, b) => {
			return Number(a.Date) < Number(b.Date) ? -1 : 1;
		});
	}

	getEnding(num) {
		switch (num) {
			case 1:
			case 21:
			case 31:
				return 'st';
			case 2:
			case 22:
				return 'nd';
			case 3:
			case 23:
				return 'rd';
			default:
				return 'th';
		}
	}

	render() {
		const { engagementsByMonth } = this.props;

		if (engagementsByMonth === undefined || engagementsByMonth === null) {
			return (
				<Row>
					<Col style={{ textAlign: 'center', marginTop: '20%' }}> <Spinner /> </Col>
				</Row>
			);
		}

		const { displayMode, displayDay, displayMonth, displayYear } = this.state;

		const formattedEngagementsByYear = this.formatYearData();
		const formattedEngagementsByMonth = engagementsByMonth && this.formatMonthData();
		const formattedEngagementsByDay = displayMonth !== undefined && this.formatDayData();
		const formattedEngagementsByHour = displayDay !== undefined && this.formatHourData();

		return (
			<div data-cy="engagements-by-date">
				<Row>
					<Col md={12} >
						<Breadcrumb >
							{(displayMode === DISPLAYMODE.MONTH || displayMode === DISPLAYMODE.DAY || displayMode === DISPLAYMODE.HOUR) &&
								<Breadcrumb.Item onClick={this.handleAllYearsClick.bind(this)} data-cy="engagements-by-date-years-breadcrumb">
									{DISPLAYMODE.YEAR + 's'}
								</Breadcrumb.Item>
							}
							{(displayMode === DISPLAYMODE.DAY || displayMode === DISPLAYMODE.HOUR) &&
								<Breadcrumb.Item onClick={this.handleYearClick.bind(this)} data-cy="engagements-by-date-months-breadcrumb">
									{DISPLAYMODE.MONTH + 's'}
								</Breadcrumb.Item>
							}
							{displayMode === DISPLAYMODE.HOUR &&
								<Breadcrumb.Item onClick={this.handleMonthClick.bind(this)} data-cy="engagements-by-date-days-breadcrumb">
									{DISPLAYMODE.DAY + 's'}
								</Breadcrumb.Item>
							}
						</Breadcrumb>
					</Col>
				</Row>
				<Row>
					<Col md={12}>
						{displayMode === DISPLAYMODE.YEAR &&
							<AnalyticsDateTimeBarGraph
								data={formattedEngagementsByYear}
								title={'ENGAGEMENTS BY YEAR'}
								xAxisLabel={'Years'}
								onClick={this.handleYearClick.bind(this)}
							/>
						}
						{displayMode === DISPLAYMODE.MONTH &&
							<AnalyticsDateTimeBarGraph
								data={formattedEngagementsByMonth}
								title={`ENGAGEMENTS BY MONTH IN ${displayYear}`}
								xAxisLabel={'Months'}
								onClick={this.handleMonthClick.bind(this)}

							/>

						}
						{displayMode === DISPLAYMODE.DAY &&
							<AnalyticsDateTimeBarGraph
								data={formattedEngagementsByDay}
								title={`ENGAGEMENTS BY DAY IN ${MONTHS[Number(displayMonth - 1)]}, ${displayYear}`}
								xAxisLabel={'Days'}
								onClick={this.handleDayClick.bind(this)}
							/>
						}
						{displayMode === DISPLAYMODE.HOUR &&
							<AnalyticsDateTimeBarGraph
								data={formattedEngagementsByHour}
								title={`ENGAGEMENTS BY ${MONTHS[Number(displayMonth - 1)]} ${Number(displayDay) + this.getEnding(Number(displayDay))}, ${displayYear}`}
								xAxisLabel={'Time'}
								onClick={this.handleHourClick.bind(this)}
							/>
						}
					</Col>
				</Row>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		engagementsByOS: state.analytics.engagementsByOS,
		campaigns: state.analytics.campaigns,
		formValues: getFormValues(formName)(state),
		engagementsByYear: state.analytics.engagementsByYear,
		engagementsByMonth: state.analytics.engagementsByMonth,
		engagementsByDay: state.analytics.engagementsByDay,
		engagementsByHour: state.analytics.engagementsByHour,
		customerId: state.auth.customerId
	};
}

const WrappedComponent = reduxForm({
	form: formName
})(AnalyticsDateTime);

export default connect(mapStateToProps, {
	clearAnalyticsGraph,
	clearEngagementsByDate,
	fetchCampaignsInDw,
	setCustomerId,
	setCustomerIdEnabled,
	fetchEngagementsByOS,
	fetchEngagementsByYear,
	fetchEngagementsByMonth,
	fetchEngagementsByDay,
	fetchEngagementsByHour,
	updateGraphDateTimeInfo

})(WrappedComponent);
