import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Row, Col } from 'react-bootstrap';
import { fetchTargets } from '../../ducks/target.duck';
import { setRefreshActions, setCustomerIdEnabled } from '../../ducks/auth.duck';
import { tableCaretRender } from '../../util/tableUtil';
import CommonStyle from '../../styles/commonStyles.js';
import TargetEditModal from './targetEditModal';
import PageHeading from '../utilities/pageHeading';
import PageContainer from '../utilities/pageContainer';
import Spinner from '../utilities/spinner';
import ReactTooltip from 'react-tooltip';

const style = {
	newTargetBtnRow: {
		textAlign: 'right',
		margin: '0px 0px 20px 0px'
	}
};

class TargetManage extends Component {
	static propTypes = {
		// Redux Store
		targets: PropTypes.array,
		// Wrapped Actions
		fetchTargets: PropTypes.func,
		setRefreshActions: PropTypes.func
	}

	constructor(props) {
		super(props);

		this.state = {
			sortName: 'Name',
			sortOrder: 'asc'
		};
	}

	componentDidMount() {
		this.props.setRefreshActions([fetchTargets]);
		this.props.fetchTargets();
		this.props.setCustomerIdEnabled(true);
	}

	onSortChange(sortName, sortOrder) {
		let newOrder = sortOrder;
		if (sortName !== this.state.sortName) {
			newOrder = 'asc';
		}

		this.setState({
			sortName,
			sortOrder: newOrder
		});
	}

	nameColumnFormatter(cell, row) {
		return (
			<div style={CommonStyle.splitSection}>
				<div style={CommonStyle.clipSection}>{cell}</div>
				<div data-tip data-for="targetEdit">
					<TargetEditModal targetId={row.Id.toString()} />
					<ReactTooltip id="targetEdit" effect="solid" delayShow={500} place="left">
						Edit Target
					</ReactTooltip>
				</div>
			</div>
		);
	}

	render() {
		const { targets, params } = this.props;
		let createNew;
		let returnLocation;

		if (params) {
			createNew = params.createNew;
			returnLocation = params.returnLocation;
		}

		const autoOpen = (createNew === 'true');

		return (
			<PageContainer>
				<PageHeading>Targets</PageHeading>

				<Row style={style.newTargetBtnRow}>
					<Col xsOffset={10} xs={2} data-tip data-for="targetNew">
						<TargetEditModal targetId={'newTarget'} autoOpen={autoOpen} returnLocation={returnLocation} isNew />
						<ReactTooltip id="targetNew" effect="solid" delayShow={500} place="left">
							A Target provides Actions with a landing page for a user's activated Touchcode.
						</ReactTooltip>
					</Col>
				</Row>

				{!targets &&
					<Spinner />
				}

				<Row>
					<Col xs={12}>
						{targets && targets.length === 0 &&
							<h4 style={CommonStyle.textCenter}>You haven't created any targets yet.</h4>
						}
						{targets && targets.length > 0 &&
							<BootstrapTable
								data={targets}
								columns={[{
									dataField: 'Name',
									text: 'Target Name',
									formatter: this.nameColumnFormatter.bind(this),
									sort: true,
									sortCaret: tableCaretRender,
									filter: textFilter({ delay: 250 })
								}, {
									dataField: 'Id',
									text: 'Target Id',
									hidden: true,
									sort: true,
									sortCaret: tableCaretRender,
								}, {
									dataField: 'WebDestination',
									text: 'Website Destination',
									sort: true,
									sortCaret: tableCaretRender
								}, {
									dataField: 'AppDestination',
									text: 'App Destination',
									sort: true,
									sortCaret: tableCaretRender
								}]}
								keyField="Id"
								bodyClasses="touchcodeTbody"
								options={{
									sortName: this.state.sortName,
									sortOrder: this.state.sortOrder,
									onSortChange: this.onSortChange.bind(this)
								}}
								filter={filterFactory()}
								striped
								hover
							/>
						}
					</Col>
				</Row>
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	return {
		targets: state.target.targets,
	};
}

TargetManage = connect(mapStateToProps, {
	fetchTargets,
	setRefreshActions,
	setCustomerIdEnabled
})(TargetManage);

export default TargetManage;
