/* eslint-disable no-undef */
import history from '../../util/history';
import auth0 from 'auth0-js';
import { LS } from '../../util/constants';
import axios from 'axios';
import { setAuth0Session } from '../../ducks/auth.duck';
import promisify from 'util.promisify';

/**
 * Gets the authorization store outside of a redux component
 */
export const getAuthStore = () => {
	if (window && window.store && window.store.getState) {
		const state = window.store.getState();
		return state.auth;
	}
	return undefined;
};

export default class auth {
	accessToken;
	idToken;
	expiresAt;
	profile;

	constructor(data) {
		this.login = this.login.bind(this);
		this.logout = this.logout.bind(this);
		this.handleAuthentication = this.handleAuthentication.bind(this);
		this.isAuthenticated = this.isAuthenticated.bind(this);
		this.getAccessToken = this.getAccessToken.bind(this);
		this.getIdToken = this.getIdToken.bind(this);
		this.renewSession = this.renewSession.bind(this);

		this.auth0 = new auth0.WebAuth({
			domain: data.auth0_domain,
			clientID: data.auth0_client_ID,
			redirectUri: data.auth0_callback_URL,
			responseType: 'token id_token',
			audience: data.auth0_audience,
			scope: 'openid access:dashboard'
		});
	}

	login = () => {
		return this.auth0.authorize();
	}

	handleAuthentication() {
		this.auth0.parseHash((err, authResult) => {
			if (authResult && authResult.accessToken && authResult.idToken) {
				this.setSession(authResult);
				history.replace('/home');
			} else if (err) {
				history.replace('/authExpired');
			}
		});
	}

	/**
	 * Checks our session with a promise.
	 * If we do not have a valid session, it will redirect us accordingly
	 * If we do have a valid session, it will setup our new session
	 */
	verifySession = () => {
		return new Promise((resolve, reject) => {
			this.auth0.checkSession({}, (error, authResult) => {
				if (authResult && authResult.accessToken && authResult.idToken) {
					this.setSession(authResult);
					resolve(authResult);
					return;
				} else if (error) {
					reject(error);
				}
				this.logout();
				this.onUnauthedError(error);
			});
		});
	}

	getAccessToken() {
		return this.accessToken;
	}

	getIdToken() {
		return this.idToken;
	}

	getJWTHeader = () => {
		const { authResult } = this;
		if (authResult) {
			return 'Bearer ' + authResult.accessToken;
		}
		return undefined;
	}

	setSession(authResult) {
		this.authResult = authResult;
		const token = 'Bearer ' + authResult.accessToken;
		localStorage.setItem(LS.TOKEN, token);
		axios.defaults.headers.common.Authorization = token;

		this.expiresAt = authResult.idTokenPayload.exp * 1000;
		this.accessToken = authResult.accessToken;
		this.profile = authResult.idTokenPayload;
		this.idToken = authResult.idToken;

		if (window.store && authResult) {
			window.store.dispatch(setAuth0Session(authResult));
		}
	}

	/**
	 * Deprecated, should use verifysession to handle logout / redirects
	 */
	renewSession() {
		/* this.auth0.checkSession({}, (err, authResult) => {
			if (authResult && authResult.accessToken && authResult.idToken) {
				this.setSession(authResult);
				return true;
			} else if (err) {
				this.logout();
			}
			return false;
		});*/
	}

	logout() {
		this.accessToken = null;
		this.idToken = null;
		this.expiresAt = 0;
	}

	/**
	 * Called when we received an authorization error from verifySession
	 */
	onUnauthedError = error => {
		const authStore = getAuthStore();
		const { sessionObj } = authStore;
		// If we have a session object stored, we were already logged in
		if (error && error.error === 'login_required' && sessionObj) {
			history.replace('/authExpired');
		} else {
			this.login();
		}
		return false;
	}


	async silentAuth() {
		const checkSession = promisify(this.auth0.checkSession).bind(this.auth0);
		const authResult = await checkSession({});
		this.setSession(authResult);
	}

	/**
	 * Returns if our ID token hasn't expired
	 * If it has, verifysession will make a new one
	 * Or redirect us if it can't
	 */
	isAuthenticated = () => {
		if (!this.expiresAt) {
			this.verifySession()
				.catch(() => this.login());
			return false;
		}
		const expiresAt = this.expiresAt;
		const isAuthed = (new Date().getTime() < expiresAt);

		if (!isAuthed) {
			this.verifySession()
				.catch(() => { });
		}

		return isAuthed;
	}
}
