// @flow

import React, { Component } from 'react';
import UserEditForm from './userEditForm';
import PageContainer from '../utilities/pageContainer';
import PageHeading from '../utilities/pageHeading';

type Props = {

};

class UserNew extends Component {
	constructor(props: Props) {
		super(props);
	}

	render() {
		return (
			<PageContainer>
				<PageHeading>
					Add User
				</PageHeading>

				<UserEditForm isNew />
			</PageContainer>
		);
	}
}

export default UserNew;
