import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import ModalLauncher from './modalLauncher';

const style = {
	bodyText: {
		whiteSpace: 'normal'
	}
};

class ConfirmModal extends Component {
	static propTypes = {
		title: PropTypes.string,
		body: PropTypes.any,
		launchBtn: PropTypes.element,
		confirmBtn: PropTypes.element,
		closeBtn: PropTypes.element,
		closeBtnText: PropTypes.string
	}

	constructor(props) {
		super(props);
	}

	render() {
		const { title, body, launchBtn, confirmBtn, closeBtn, closeBtnText, action } = this.props;

		return (
			<ModalLauncher name={title}
				launchBtn={launchBtn}
				actionBtn={confirmBtn}
				action={action}
				closeBtn={closeBtn || (
					<button className="btn btn-default">
						{closeBtnText || 'Close'}
					</button> )
				}>
				<Row>
					<Col xs={12} style={ { textAlign: 'left' } }>
						<h3 style={ { marginTop: '0px', marginBottom: '30px' } }>{title}</h3>
					</Col>
				</Row>
				<Row>
					<Col xs={12} style={ { textAlign: 'left', marginBottom: '40px' } }>
						<div style={style.bodyText}>{body}</div>
					</Col>
				</Row>
			</ModalLauncher>
		);
	}
}

export default ConfirmModal;
