// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../util/history';
import { Field, reduxForm, getFormValues, isValid, change, touch, reset } from 'redux-form';
import { Row, Col, InputGroup, FormControl, ControlLabel, Nav, NavItem } from 'react-bootstrap';
import { FaCheck, FaClipboard, FaPencil, FaAsterisk, FaPlusCircle, FaTimesCircle } from 'react-icons/lib/fa';
import CancelModal from '../utilities/cancelModal';
import _ from 'lodash';
import moment from 'moment-timezone';
import {
	updateCurrentCampaignCodeset,
	clearCurrentCampaignTouchcodes,
	setSelectedTouchcodes,
	fetchCampaignsByCodeset,
	clearCampaignsByCodeset,
	updateCampaign,
	createCampaign,
	deleteCampaign,
	clearWorkInProgress,
	saveWorkInProgress,
	clearCodesetForm,
	clearSelectedTouchcodes,
	clearDefaultTargets,
	updateParameterValue,
	updateSelectedDecoderParameters,
	removeSelectedDecoderParameter
} from '../../ducks/campaign.duck';
import {
	fetchCodesets,
	fetchCodesetLabels,
	clearCodesetLabels
} from '../../ducks/codeset.duck';
import { fetchDecoderTypes, fetchDecoderParameterTypes, fetchDecoderParameterConfigs } from '../../ducks/decoderType.duck.js';
import { fetchCustomers } from '../../ducks/customer.duck';
import { clearActions, fetchActionsByDest } from '../../ducks/action.duck.js';
import { setCustomerIdEnabled, setCustomerId, setRefreshActions } from '../../ducks/auth.duck.js';
import { routes, statusOptionsByName } from '../../util/constants';
import * as validators from '../../util/FormValidators';
import CommonStyle, { mergeStyles, colorDanger } from '../../styles/commonStyles';
import TouchcodeActionsInput from '../touchcode/tabledTouchcodeActionsInput';
import FieldWrapper from '../utilities/fieldWrapper';
import FormContainer from '../utilities/formContainer';
import DeleteCampaignModal from '../utilities/deleteCampaignModal';
import ConfirmModal from '../utilities/confirmModal';
import SelectInput from '../utilities/selectInput';
import SelectInputHeightAware from '../utilities/SelectInputHeightAware';
import Spinner from '../utilities/spinner';
import DateRangePicker from '../utilities/dateRangePicker';
import CampaignDecoderConfigEdit from './campaignDecoderConfigEdit';
import PageContainer from '../utilities/pageContainer';
import ReactTooltip from 'react-tooltip';
import CaratDropdown from '../utilities/caratDropdown';
import { Button } from 'react-bootstrap/lib/InputGroup';
import DemoSiteEditForm from './demoSiteEditForm';
import { DemoSiteFormValues } from './demoSiteEditForm';
const formName = 'campaignEdit';

const style = {
	copyInput: {
		backgroundColor: 'white'
	},
	copyMessage: {
		position: 'absolute',
		right: '0%',
		marginRight: '15px'
	},
	optionsLink: {
		cursor: 'pointer'
	}
};

type CampaignFormValues = {
	name: string,
	customer: { label: string, value: string },
	type: ReactSelectOption,
	startDate: ?string,
	endDate: ?string,
	timezone: string,
	location: ?string,
	codeset: ?ReactSelectOption,
	website: ?string,
	demoSiteEnabled: bool
};

type WorkInProgress = {
	formValues: CampaignFormValues,
	touchcodes: TouchcodeActionMap
};

type Props = {
	// From Parent
	campaign: Campaign,
	isNew: boolean,
	isReactivation: boolean,
	// Redux Form
	handleSubmit: Function,
	formValues: CampaignFormValues,
	formIsValid: boolean,
	// Redux Store
	campaignsByCodeset: Array<Campaign>,
	codesets: Array<Codeset>,
	initialValues: CampaignFormValues,
	customers: Array<Customer>,
	decoderTypes: Object,
	decoderParameters: Object,
	dispatch: Function,
	workInProgress: WorkInProgress,
	// Wrapped Actions
	createCampaign: Function,
	updateCampaign: Function,
	deleteCampaign: Function,
	updateCurrentCampaignCodeset: Function,
	clearCurrentCampaignTouchcodes: Function,
	fetchCampaignsByCodeset: Function,
	clearCampaignsByCodeset: Function,
	setSelectedTouchcodes: Function,
	fetchDecoderTypes: Function,
	fetchDecoderParameterTypes: Function,
	fetchDecoderParameterConfigs: Function,
	fetchCodesets: Function,
	fetchCodesetLabels: Function,
	clearCodesetLabels: Function,
	fetchCustomers: Function,
	clearWorkInProgress: Function,
};

export class CampaignEditForm extends Component {
	props: Props;

	state: {
		codesetValue: string,
		codesetError: string,
		copyMessage: string
	};

	constructor(props: Props) {
		super(props);

		let codesetvalue = '';

		if (!props.isNew && props.campaign.Codeset) {
			codesetvalue = props.campaign.Codeset.Id.toString();
		}
		this.state = {
			codesetValue: codesetvalue,
			codesetError: '',
			copyMessage: '',
			showEditCampaignCodeset: false,
			showEditDemoSite: false,
			oldFormValues: undefined,
			campaignCodesetUpdated: false,
			selectedConfiguration: 1,
			configurationCount: 1,
			fetchedDecodingConfig: false,
			updatedDecodingConfig: undefined,
			isUnMounted: false
		};
	}

	componentDidMount(): void {
		const { campaign, isReactivation, permissions, initialValues } = this.props;
		const isAdminViewer = permissions ? permissions.AdminView : null;

		// we need to set the customer id before fetching any other data that might be scoped by it
		if (isAdminViewer) {
			this.props.setRefreshActions([clearCodesetLabels, clearCurrentCampaignTouchcodes, clearCampaignsByCodeset, clearCodesetForm]);
			if (this.props.isNew) {
				this.props.setCustomerIdEnabled(true);
			} else {
				this.props.setCustomerId(campaign.CustomerId);
			}
		} else {
			this.props.setCustomerId(campaign.CustomerId);
		}

		this.props.fetchCodesets();
		this.props.fetchDecoderTypes();
		this.props.fetchDecoderParameterTypes();
		this.props.fetchDecoderParameterConfigs();
		if (initialValues.type) {
			this.props.fetchActionsByDest(initialValues.type.value);
		}

		const { Id, Customer, Codeset } = campaign;
		if (this.state.codesetValue && Codeset != null) {
			this.props.fetchCodesetLabels(Codeset.Id, Customer.Id);
			this.props.fetchCampaignsByCodeset(Codeset.Id, Id);
		}

		// Set off validation for end date if this is a reactivation
		if (isReactivation) {
			this.props.dispatch(touch(formName, ['endDate']));
		}

		// Clear work in progress
		if (this.props.workInProgress) {
			this.props.clearWorkInProgress(campaign.Id || 'newCampaign');
		}
	}

	componentWillUnmount() {
		this.setState({ isUnMounted: true });
	}

	componentDidUpdate() {
		if (!this.props.isNew && this.props.campaign.DecodingConfig && !this.state.fetchedDecodingConfig) {
			const decodingConfigJson = JSON.parse(this.props.campaign.DecodingConfig);
			let count = 0;
			if (decodingConfigJson.decoders) {
				count += decodingConfigJson.decoders.length;
			}
			if (decodingConfigJson.decoder_type) {
				count++;
			}
			count = Math.max(1, count);
			this.setState({ configurationCount: count, fetchedDecodingConfig: true });
		} else if (this.props.isNew && this.props.codesets && this.props.customerId && ['', null, undefined].includes(this.state.codesetValue)) {
			// set default codeset value
			for (const codeset of this.props.codesets) {
				if (codeset.Label === 'Approved Codes') {
					const codesetId = codeset.Id.toString();
					this.setState({ codesetValue: codesetId });
					this.props.updateCurrentCampaignCodeset(codeset);
					this.props.fetchCodesetLabels(codesetId, this.props.customerId);
					this.props.dispatch(change(formName, 'codeset', codesetId));
					this.props.fetchCampaignsByCodeset(codesetId);
					break;
				}
			}
		}

		if (this.props.clearForm && this.state.codesetValue !== null) {
			this.setState({ codesetValue: null });
			this.props.dispatch(change(formName, 'codeset', null));
			this.props.clearCodesetForm(false);
		}
	}

	handleFormSubmit(form: CampaignFormValues): void {
		this.createOrUpdateCampaign(form, true);
	}

	handleSaveClick(event: SyntheticEvent): void {
		this.createOrUpdateCampaign(this.props.formValues, false);
		event.preventDefault();
	}

	handleCopyIdClick(event: SyntheticEvent): void {
		const idInput: HTMLInputElement = (document.querySelector('#campaignIdInput'): any);
		if (idInput) {
			idInput.select();
			document.execCommand('copy');
			idInput.blur();
			this.setState({ copyMessage: 'Copied to clipboard' });
			setTimeout(() => this.setState({ copyMessage: '' }), 4000);
		}
		event.preventDefault();
	}

	adjustDate(date: string, timezone: string) {
		const newDate = moment.parseZone(date);
		const offset = moment.tz.zone(timezone).offset(newDate.valueOf());
		newDate.utcOffset(-offset, true);
		return newDate;
	}

	CampaignCodesetIsValid() {
		const { formValues } = this.props;
		let valid = true;
		if (formValues.timeout && (formValues.timeout < 0.5 || formValues.timeout > 60)) {
			valid = false;
		}
		return valid && this.refs.actionsInput.isValid();
	}



	createOrUpdateCampaign(formValues: CampaignFormValues, launch: boolean): void {
		const { campaign, isNew, codesets } = this.props;
		const { name, type, startDate, endDate, timezone, location, codeset, circulationquantity, website } = formValues;
		const { updatedDecodingConfig } = this.state;
		let decodingConfig = updatedDecodingConfig;
		if (!updatedDecodingConfig) {
			if (isNew) {
				let codesetOptions;
				if (codesets && formValues.codeset) {
					for (let i = 0; i < codesets.length; i++) {
						if (Number(formValues.codeset) === codesets[i].Id) {
							codesetOptions = JSON.parse(codesets[i].DefaultDecodingConfig);
						}
					}
				}
				decodingConfig = codesetOptions;
			} else {
				decodingConfig = JSON.parse(campaign.DecodingConfig);
			}
		}

		const emptyKeys = {};
		const emptySelectedTouchcodes = _.difference(campaign.selectedTouchcodes, Object.keys(campaign.DefaultTargets));
		emptySelectedTouchcodes.forEach(tc => { emptyKeys[tc] = -1; });

		let touchcodes = campaign.Touchcodes;
		if (Array.isArray(touchcodes)) {
			touchcodes = touchcodes.reduce((accum, touchcodeValues, touchcodeId) => {
				if (touchcodeValues !== null && touchcodeValues !== undefined) {
					if (Array.isArray(touchcodeValues)) {
						accum[touchcodeId] = touchcodeValues;
					} else if (typeof touchcodeValues === 'object') {
						for (const actualTouchcodeId of Object.keys(touchcodeValues)) {
							accum[actualTouchcodeId] = touchcodeValues[actualTouchcodeId];
						}
					}
				}
				return accum;
			}, {});
		}
		const includeDemoSite = formValues.demoSiteEnabled;
		const newCampaign = {
			Name: name,
			Container: type.value,
			Location: location,
			StartDate: startDate ? this.adjustDate(startDate, timezone) : null,
			EndDate: endDate ? this.adjustDate(endDate, timezone) : null,
			TimeZone: timezone,
			CodesetId: codeset,
			CampaignStateId: campaign.CampaignState ? campaign.CampaignState.Id : 1,
			Touchcodes: touchcodes,
			DefaultTargets: { ...campaign.DefaultTargets, ...emptyKeys },
			SelectedTouchcodes: campaign.selectedTouchcodes,
			DecodingConfig: JSON.stringify(decodingConfig),
			DistributionNumber: circulationquantity,
			Website: website,
			DemoSites: includeDemoSite ? campaign.DemoSites : []
		};

		if (launch) {
			if (moment(startDate).isSameOrBefore(moment(), 'day')) {
				newCampaign.CampaignStateId = statusOptionsByName.active;
			} else {
				newCampaign.CampaignStateId = statusOptionsByName.upcoming;
			}
		} else {
			newCampaign.CampaignStateId = campaign.CampaignState ? campaign.CampaignState.Id : statusOptionsByName.saved_draft;
		}

		if (isNew) {
			this.props.createCampaign(newCampaign, routes.campaignManage);
		} else {
			this.props.updateCampaign(newCampaign, campaign.Id, routes.campaignManage);
		}
	}

	handleDeleteClick(event: SyntheticEvent): void {
		const { campaign } = this.props;
		this.props.deleteCampaign(campaign.Id, campaign.Name, routes.campaignManage);
		if (event) {
			event.preventDefault();
		}
	}

	handleCancelClick(event: SyntheticEvent): void {
		history.push(routes.campaignManage);
		if (event) {
			event.preventDefault();
		}
	}

	handleTypeChange(value) {
		if (value == null || Array.isArray(value)) {
			return;
		}
		this.props.clearActions();
		this.props.fetchActionsByDest(value.value);
		this.props.clearSelectedTouchcodes();
		this.props.clearDefaultTargets();
		ReactTooltip.hide();
	}

	handleCodesetChange(value: Object): void {
		if (value == null || Array.isArray(value)) {
			return;
		}

		const id = value.value;
		this.setState({ codesetValue: id, codesetError: '' });

		const { codesets, customerId, isNew, campaign } = this.props;

		this.props.clearCurrentCampaignTouchcodes();
		this.props.setSelectedTouchcodes([]);

		const newCodeset = codesets.filter(c => {
			return c.Id.toString() === id;
		})[0];

		this.props.clearCampaignsByCodeset();
		this.props.updateCurrentCampaignCodeset(newCodeset);
		this.props.clearCodesetLabels();

		if (isNew && customerId) {
			this.props.fetchCodesetLabels(id, customerId);
		} else if (campaign.Customer.Id) {
			this.props.fetchCodesetLabels(id, campaign.Customer.Id);
		}

		this.props.dispatch(change(formName, 'codeset', id));

		this.props.fetchCampaignsByCodeset(id);

		event.preventDefault();
		ReactTooltip.hide();
	}

	handleKeyPress(saveEnabled: boolean, launchEnabled: boolean, event: SyntheticEvent) {
		if (event.key === 'Enter') {
			event.preventDefault();

			if (saveEnabled && this.props.formIsValid) {
				this.createOrUpdateCampaign(this.props.formValues, launchEnabled);
			} else {
				this.props.dispatch(touch(formName, 'customer'));
				this.props.dispatch(touch(formName, 'name'));
				this.props.dispatch(touch(formName, 'type'));
				this.props.dispatch(touch(formName, 'location'));
				this.props.dispatch(touch(formName, 'timeout'));
				if (!this.props.campaign.Codeset.Id) {
					this.setState({ codesetError: 'Required' });
				}
			}
		}
	}

	handleCampaignCodesetKeyPress(event) {
		if (event.key === 'Enter') {
			if (event) event.preventDefault();
			const elements = document.querySelectorAll('input, button');
			const index = Array.prototype.indexOf.call(elements, document.activeElement);
			if (elements && elements[index + 1]) elements[index + 1].focus();
		}
	}

	touchStartDate() {
		this.props.dispatch(touch(formName, 'startDate'));
	}

	touchEndDate() {
		this.props.dispatch(touch(formName, 'endDate'));
	}

	handleEditDemoClick(e) {
		this.setState({showEditDemoSite: true});
		this.props.setTitle("Edit Demo Site");
	}

	handleEditDemoSubmit(formValues: DemoSiteFormValues) {
		const {campaign} = this.props;
		this.setState({showEditDemoSite: false});
		this.props.campaign.DemoSites[0] = formValues;
		this.props.campaign.DemoSites[0].CampaignId = campaign.Id;
		this.props.setTitle(undefined);
	}

	handleEditDemoCancel() {
		this.setState({showEditDemoSite: false});
		this.props.setTitle(undefined);
	}

	handleEditCampaignCodesetClick(e) {
		const { configurationCount } = this.state;
		const { formValues, codesets } = this.props;
		e.preventDefault();
		this.setState({ showEditCampaignCodeset: true, oldFormValues: formValues, selectedConfiguration: 1, oldConfigurationCount: configurationCount });
		let label = '';
		if (codesets) {
			codesets.forEach(c => {
				if (c.Id === this.state.codesetValue) {
					label = c.Label;
				}
			});
		}
		this.props.setTitle('Edit Campaign Codeset: ' + label);
	}

	handleCampaignCodesetCancel() {
		const { oldFormValues, oldConfigurationCount } = this.state;
		const { dispatch } = this.props;
		dispatch(reset('campaignEdit'));
		for (const field in oldFormValues) {
			dispatch(change('campaignEdit', field, oldFormValues[field]));
		}
		this.setState({ showEditCampaignCodeset: false, selectedConfiguration: 1, configurationCount: oldConfigurationCount });
		this.props.setTitle(undefined);
	}

	handleCampaignCodesetUpdate(event) {
		const { initialCampaignCodesetData } = this.state;
		this.refs.actionsInput.update();
		event.preventDefault();
		const data = this.getCurrentCampaignCodesetData();
		if (!_.isEqual(data, initialCampaignCodesetData)) {
			this.setState({ campaignCodesetUpdated: true });
		}
		this.setState({ initialCampaignCodesetData: undefined, showEditCampaignCodeset: false, selectedConfiguration: 1, updatedDecodingConfig: this.refs.decoderConfigEdit.getDecodingConfig() });

		this.props.setTitle(undefined);
	}

	renderCodesetOptions() {
		const { codesets } = this.props;

		if (!codesets) { return null; }

		return (
			codesets.map(c => {
				const label = (c.Label === null) ? c.Id : c.Label;
				return {
					label: String(label), value: String(c.Id)
				};
			})
		);
	}

	renderCustomerDisplay() {
		let customerToDisplay;
		const { customerId, customers, campaign, permissions } = this.props;
		const isAdminViewer = permissions ? permissions.AdminView : null;

		if (isAdminViewer) {
			customers && customers.map(customer => {
				if (customer.Id === customerId) {
					customerToDisplay = customer;
				}
			});
			return (<strong style={mergeStyles(CommonStyle.fontLarge, { fontFamily: '"Raleway", "Helvetica", "Arial", sans-serif' })}>Customer: {customerToDisplay ? customerToDisplay.Name : null} </strong>);
		}
		return <strong style={mergeStyles(CommonStyle.fontLarge, { fontFamily: '"Raleway", "Helvetica", "Arial", sans-serif' })}> Customer: {campaign.Customer ? campaign.Customer.Name : null} </strong>;
	}

	handleDecoderConfigurationSelect(key) {
		const { configurationCount } = this.state;
		if (key === configurationCount + 1) {
			this.setState({ configurationCount: configurationCount + 1, selectedConfiguration: key });
		} else {
			this.setState({ selectedConfiguration: key });
		}
	}

	getCurrentCampaignCodesetData() {
		let data = undefined;
		if (this.refs.decoderConfigEdit) {
			data = this.refs.decoderConfigEdit.getDecodingConfig();
		}
		if (this.refs.actionsInput) {
			if (data) {
				data = { ...data, codes: this.refs.actionsInput.renderSelectedTouchcodes() };
			} else {
				data = { codes: this.refs.actionsInput.renderSelectedTouchcodes() };
			}
		}
		return data;
	}

	setInitialCampaignCodesetData() {
		const { initialCampaignCodesetData } = this.state;
		const data = this.getCurrentCampaignCodesetData();
		if (data && !initialCampaignCodesetData) {
			this.setState({ initialCampaignCodesetData: data });
		}
	}

	renderTitle(enabled, title) {
		const { permissions } = this.props;
		const isCampaignModifier = permissions ? permissions.CampaignModifies : null;
		if (isCampaignModifier) {
			return enabled ? title : <div>Fill out required fields</div>;
		}
		return <div>You do not have permission to save or edit campaigns</div>;
	}

	deleteConfiguration(configurationId) {
		const { selectedConfiguration, configurationCount } = this.state;
		if (this.refs.decoderConfigEdit) {
			this.refs.decoderConfigEdit.deleteConfiguration(configurationId);
		}
		if (configurationCount > 1) {
			if (selectedConfiguration === configurationCount) {
				this.setState({ selectedConfiguration: configurationCount - 1 });
			}
			this.setState({ configurationCount: configurationCount - 1 });
		}
	}

	renderDecoderConfigurationTabs() {
		const { configurationCount } = this.state;
		let { selectedConfiguration } = this.state;
		if (selectedConfiguration > configurationCount) {
			selectedConfiguration = configurationCount;
		}
		const tabs = [];
		for (let i = 1; i <= configurationCount; i++) {
			tabs.push(
				<NavItem key={i} eventKey={i}>Configuration {i}
					<ConfirmModal
						launchBtn={<FaTimesCircle size={20} style={{ color: '#F54437', cursor: 'pointer' }} />}
						title={'Are you sure you want to delete this configuration?'}
						body={'All data related to this configuration will be lost.'}
						confirmBtn={
							<div className="btn btn-danger" onClick={this.deleteConfiguration.bind(this, i)}>
								Yes
							</div>}
						closeBtnText={'No'}
					/>
				</NavItem>
			);
		}
		return (
			<Nav
				className="decoderConfigTabs"
				bsStyle="tabs"
				activeKey={selectedConfiguration}
				onSelect={this.handleDecoderConfigurationSelect.bind(this)}
			>
				{tabs}
				{configurationCount < 5 &&
					<NavItem className="addDecoderConfig" eventKey={configurationCount + 1}> <FaPlusCircle size={20} style={{ color: '#03A9F4' }} /> </NavItem>
				}
			</Nav>
		);
	}

	getDemoUrl() {
		if(this.state == null || this.props == null) {
			return "";
		}
		let {campaign, formValues} = this.props;
		if(formValues == null) {
			return "";
		}
		let {demoSiteEnabled} = formValues;
		if(!demoSiteEnabled) {
			return "";
		}
		if(campaign.DemoSites.length == 0 || campaign.DemoSites[0] == null || campaign.DemoSites[0].CampaignTag == null) {
			return "";
		}
		return `https://try.tc/${campaign.DemoSites[0].CampaignTag}`;
	}

	render() {
		const { handleSubmit, campaign, campaignsByCodeset, isNew, permissions, formIsValid, formValues, initialValues, isReactivation, customerId, codesets } = this.props;
		const { codesetError, codesetValue, copyMessage, showEditCampaignCodeset, campaignCodesetUpdated, selectedConfiguration, configurationCount, updatedDecodingConfig, initialCampaignCodesetData, showEditDemoSite } = this.state;
		const isAdminViewer = permissions ? permissions.AdminView : null;
		const isCampaignDeleter = permissions ? permissions.CampaignDeletes : null;
		const isCampaignModifier = permissions ? permissions.CampaignModifies : null;
		const launchTitle = (
			<span>
				<div>The Campaign's status will be set to "Upcoming"</div>
				<div>if the Start Date has not been reached. If the Start</div>
				<div>Date has been reached, the Campaign's status will be</div>
				<div>"Active" and it will be immediately available to users.</div>
			</span>
		);
		const saveTitle = (
			<span>
				<div>The Campaign's status will be set to "Saved</div>
				<div>Draft" and will not yet be available for use.</div>
			</span>
		);

		// Determine whether or not to enable save and launch buttons {
		let saveEnabled = false;
		if (formValues) {
			const { name, type, location, codeset, circulationquantity } = formValues;
			saveEnabled = (name !== undefined && type !== undefined && location !== undefined && codeset !== undefined && circulationquantity !== undefined && isCampaignModifier) ? true : false;
		}

		let hasDefaultTarget = false;
		if (campaign) {
			const { DefaultTargets, selectedTouchcodes } = campaign;
			const defaultTargetsLength = Object.keys(DefaultTargets).length;
			const touchcodesLength = selectedTouchcodes.length;
			if (defaultTargetsLength > 0 && defaultTargetsLength >= touchcodesLength) {
				hasDefaultTarget = true;
			}
		}
		const launchEnabled = formIsValid && hasDefaultTarget && isCampaignModifier;
		// }

		// Change the way the UI is displayed if the campaign state is active or upcoming {
		let isActive = false;
		if (campaign && campaign.CampaignState) {
			isActive = /(active|upcoming)/.test(_.lowerCase(campaign.CampaignState.Name));
		}
		// }

		const hasTouchcodes = campaign && campaign.selectedTouchcodes.length > 0;

		const codesetStyle = {};
		if (codesetError) {
			codesetStyle.control = provided => ({ ...provided, borderColor: colorDanger });
		}

		const bsResponsiveLeft = {
			xs: 12,
			sm: 8,
			md: 8,
			lg: 8
		};
		const bsResponsiveRight = {
			xs: 12,
			sm: 4,
			md: 4,
			lg: 4
		};
		const demoUrl = {
			overflowWrap: "anywhere"
		}

		const codesetOptions = this.renderCodesetOptions();
		const codesetValueSelectOption = codesetOptions && codesetOptions.length ? codesetOptions.find(option => option.value === codesetValue) : null;

		if (!codesets) {
			return (
				<Spinner />
			);
		}
		if (isAdminViewer && customerId == null) {
			return (
				<PageContainer>
					<Row>
						<Col xs={12}>
							<h4>Select a customer from the dropdown.</h4>
						</Col>
					</Row>
				</PageContainer>
			);
		}
		if(showEditDemoSite) {
			if(this.props.campaign.DemoSites == null) {
				this.props.campaign.DemoSites = [];
			}
			const demoSite = this.props.campaign.DemoSites[0];
			return (
				<DemoSiteEditForm initialValues={demoSite} 
				onSubmit={this.handleEditDemoSubmit.bind(this)}
				onCancel={this.handleEditDemoCancel.bind(this)}
				campaignId={this.props.campaign.Id}/>
			)
		}
		if (showEditCampaignCodeset) {
			const data = this.getCurrentCampaignCodesetData();
			setTimeout(this.setInitialCampaignCodesetData.bind(this), 1);
			return (
				<FormContainer submitHandler={this.handleCampaignCodesetUpdate.bind(this)}>
					<div onKeyPress={this.handleCampaignCodesetKeyPress.bind(this)}>
						{this.renderCustomerDisplay()}
						{campaign &&
							<Row>
								{this.renderDecoderConfigurationTabs()}
								<CampaignDecoderConfigEdit
									ref="decoderConfigEdit"
									dispatch={this.props.dispatch}
									decodingConfig={updatedDecodingConfig}
									isNew={isNew}
									codeset={campaign.Codeset}
									configurationId={selectedConfiguration > 0 && selectedConfiguration <= configurationCount ? selectedConfiguration : configurationCount}
									maxConfigurationId={configurationCount}
								/>
							</Row>
						}
						<Row style={{ marginTop: '20px' }}>
							{campaign && <TouchcodeActionsInput ref="actionsInput" campaign={campaign} refreshParent={() => { this.forceUpdate(); }} />}
						</Row>
						<div>
							<Row style={{ marginTop: '10px' }}>
								<div style={CommonStyle.floatLeft}>
									<CancelModal
										initialValues={initialCampaignCodesetData}
										currentValues={data}
										onCancel={this.handleCampaignCodesetCancel.bind(this)}
									/>
								</div>
								<div style={CommonStyle.floatRight} >
									<button
										id="updateButton"
										disabled={this.refs.actionsInput ? !this.CampaignCodesetIsValid() : true}
										style={CommonStyle.btn}
										className="btn btn-primary"
										type="submit"
									>
										Update
									</button>
								</div>
							</Row>
						</div>
					</div>
				</FormContainer>
			);
		}
		return (
			<FormContainer submitHandler={handleSubmit(this.handleFormSubmit.bind(this))}>
				<div onKeyPress={this.handleKeyPress.bind(this, saveEnabled, launchEnabled)}>
					{isReactivation &&
						<Row style={CommonStyle.formRow}>
							<Col xs={12}>
								<em style={CommonStyle.textDanger}>The End Date has expired and must be changed in order to reactivate the Campaign</em>
							</Col>
						</Row>
					}
					{this.renderCustomerDisplay()}

					<Row>
						<Col {...bsResponsiveLeft}>
							<Row>
								{!isNew && campaign &&
									<Col xs={12} style={CommonStyle.formRow}>
										<ControlLabel>Campaign ID</ControlLabel>
										<InputGroup>
											<FormControl style={style.copyInput} className="form-control" id="campaignIdInput" readOnly value={campaign.Id} />
											<InputGroup.Addon onClick={this.handleCopyIdClick.bind(this)} className="input-group-addon-button">
												<FaClipboard />
											</InputGroup.Addon>
										</InputGroup>
										{copyMessage && <div style={mergeStyles(CommonStyle.textSuccess, style.copyMessage)}>{copyMessage}</div>}
									</Col>
								}
								<Col xs={12} style={CommonStyle.formRow}>
									<Field id="nameInput"
										name="name" type="text" placeholder="Campaign Name"
										component={FieldWrapper} label="Name"
										validate={[validators.required, validators.charLimit50]}
									/>
								</Col>
								{isAdminViewer &&
									<Col>
										<Col xs={6} style={CommonStyle.formRow} data-tip data-for="typeInput">
											<Field
												id="typeInput"
												name="type"
												type="select"
												component={SelectInput}
												label="Type"
												isClearable={false}
												placeholder="Select a Type"
												validate={[validators.required]}
												onChange={this.handleTypeChange.bind(this)}
												options={[
													{ label: 'Website', value: 'website' },
													{ label: 'App', value: 'app' },
													{ label: 'Both', value: 'both' }
												]}
											/>
											<ReactTooltip id="typeInput" place="right" effect="solid" delayShow={500}>
												<div>This sets the Campaign to be available on a Mobile Application, Website or Both.</div>
												{!isNew && <div><strong>Note: If you alter the Campaign's Type after Actions and Default Targets</strong></div>}
												{!isNew && <div><strong>are assigned to Touchcodes, the Actions and Default Targets will be removed</strong></div>}
												{!isNew && <div><strong>and new Actions and Default Targets must be assigned with the new Type.</strong></div>}
											</ReactTooltip>
										</Col>
										<Col xs={6} style={CommonStyle.formRow} data-tip data-for="circulationInput">
											<Field id="circulationquantityInput"
												name="circulationquantity" type="text" placeholder="Quantity in Circulation"
												component={FieldWrapper} label="Quantity in Circulation"
												validate={[validators.required]}
												normalize={validators.onlyNumbers}
											/>
											<ReactTooltip id="circulationInput" place="right" effect="solid" delayShow={500}>
												<div>This represents the quantity of stock distributed to the </div>
												<div>Customer that is in circulation. This number is used </div>
												<div>to calculate the Engagement Rate that is displayed </div>
												<div>on the Home Page and Analytics by Date & Time Page.</div>
											</ReactTooltip>
										</Col>
									</Col>
								}
								{isAdminViewer &&
									<Col xs={12} style={CommonStyle.formRow} data-tip data-for="locationInput">
										<Field id="locationInput"
											name="location" type="text" placeholder="Campaign Location"
											component={FieldWrapper} label="Location"
											validate={[validators.required]}
										/>
										<ReactTooltip id="locationInput" place="right" effect="solid" delayShow={500}>
											<div>This identifies the location/region in which the Campaign will be used.</div>
										</ReactTooltip>
									</Col>
								}
								{!isAdminViewer && campaign &&
									<Col xs={12} style={CommonStyle.formRow} data-tip data-for="circulationInput">
										<strong>Quantity in Circulation: {campaign.DistributionNumber} </strong>
										<ReactTooltip id="circulationInput" place="right" effect="solid" delayShow={500}>
											<div>This represents the quantity of stock distributed to the </div>
											<div>Customer that is in circulation. This number is used </div>
											<div>to calculate the Engagement Rate that is displayed </div>
											<div>on the Home Page and Analytics by Date & Time Page.</div>
										</ReactTooltip>
									</Col>
								}
								{isAdminViewer &&
									<Col xs={12} data-tip data-for="codesetInput">
										<label>Codeset {campaignCodesetUpdated && <span style={{ fontSize: '11px' }}><FaAsterisk style={{ color: '#03A9F4' }} size={20} /> Unsaved Changes </span>}</label>
										<SelectInputHeightAware
											id="codesetInput"
											styles={codesetStyle}
											value={codesetValueSelectOption}
											onChange={this.handleCodesetChange.bind(this)}
											options={codesetOptions}
											isClearable={false}
											placeholder="Select a Codeset"
											title={hasTouchcodes ? 'Remove all actions to change codeset' : null}
											isDisabled={!isNew}
										/>
										{codesetError &&
											<div style={CommonStyle.textDanger}>
												{codesetError}
											</div>
										}
										<ReactTooltip id="codesetInput" place="right" effect="solid" delayShow={500}>
											<div>A Codeset consists of a grouping of Touchcodes that can be used</div>
											<div>by the Customer for the Campaign. Once assigned, Touchcodes</div>
											<div>will become available in the Touchcode(s) drop-down menu.</div>
										</ReactTooltip>
									</Col>
								}
							</Row>
						</Col>
						<Col {...bsResponsiveRight}>
							{(isNew || formValues) &&
								<DateRangePicker
									formName="campaignEdit"
									dispatch={this.props.dispatch}
									startDateValue={formValues && formValues.startDate}
									endDateValue={formValues && formValues.endDate}
									timezone={formValues && formValues.timezone}
								/>
							}
							{isAdminViewer && 
							<Col xs={4}>
								<Field id="demoSiteCheck" 
									name="demoSiteEnabled" type="checkbox"
									component={FieldWrapper} label="Demo Site?"
								/>
							</Col>}
							<Col xs={8}>
								<div style={demoUrl}>
									<label htmlFor="demoUrl">Website</label><br/>
									<a id="demoUrl" href={this.getDemoUrl()}>
										{this.getDemoUrl()}
									</a>
								</div>
							</Col>
						</Col>
					</Row>

					{isAdminViewer &&
						<Row style={CommonStyle.formRow}>
							{formValues && formValues.codeset &&
									<div data-tip data-for="editCampaignCodeset" style={{ marginBottom: '15px', marginRight: '10px', display: 'inline' }}>
										<button disabled={formValues.type === undefined} onClick={this.handleEditCampaignCodesetClick.bind(this)} className="btn btn-primary"> <FaPencil /> Edit Campaign Codeset </button>
										{!formValues.type &&
											<ReactTooltip id="editCampaignCodeset" place="right" effect="solid" delayShow={500} data-multiline>
												Please select a Type
											</ReactTooltip>
										}
									</div>
							}
							{formValues &&
								<span data-tip data-for="demositeEditTooltip">
									<button
									id="demositeEditButton"
									className="btn btn-primary"
									disabled={!formValues.demoSiteEnabled}
									onClick={this.handleEditDemoClick.bind(this)}> <FaPencil /> Edit Demo Site</button>	
									{!formValues.demoSiteEnabled &&
										<ReactTooltip id="demositeEditTooltip" place="right" effect='solid' delayShow={500} data-multiline>
											Please enable demo site usage
										</ReactTooltip>
									}
								</span>

							}


							<br />
							<hr style={{ borderTopColor: 'black', marginTop: '40px' }} />
							{!isNew && campaignsByCodeset && campaignsByCodeset.length > 0 &&
								<Col {...bsResponsiveLeft}>
									<CaratDropdown title="Associated Campaigns">
										{campaignsByCodeset ?
											(<div>
												{campaignsByCodeset.length > 0 &&
													<div style={{ border: '1px solid black', maxHeight: '160px', overflowY: 'scroll' }}>
														<ul>
															{campaignsByCodeset.map((c: Campaign) =>
																<li key={c.Id}>
																	{c.Name} : <strong>{c.CampaignState.Name}</strong> - <em>{c.Customer.Name}</em>
																</li>
															)}
														</ul>
													</div>
												}
												{campaignsByCodeset.length === 0 &&
													<div style={CommonStyle.textSuccess}>
														<h5>No other campaigns currently use this codeset</h5>
													</div>
												}
											</div>) : <div><Spinner size={12} /></div>
										}
									</CaratDropdown>
								</Col>}
						</Row>
					}
				</div>

				<div>
					<Row>

						{!isNew &&
							<div style={CommonStyle.floatLeft}>
								{campaign &&
									<DeleteCampaignModal
										campaignName={campaign.Name}
										action={this.handleDeleteClick.bind(this)}
										title={isCampaignDeleter ? null : 'You do not have permission to delete campaigns'}
										disabled={!isCampaignDeleter}
									/>
								}
							</div>
						}
						<span style={CommonStyle.floatRight} data-tip data-for="launchBtn">
							<button
								id="launchBtn"
								disabled={!launchEnabled}
								style={CommonStyle.btn}
								className="btn btn-primary"
								type="submit"
							>
								<FaCheck /> Save
							</button>
							<ReactTooltip id="launchBtn" effect="solid" place="left" delayShow={500}>
								{this.renderTitle(launchEnabled, launchTitle)}
							</ReactTooltip>
						</span>
						{!isActive &&
							<span style={CommonStyle.floatRight} data-tip data-for="saveDraft">
								{!launchEnabled ? (
									<button id="saveBtnInvalid"
										className="btn btn-primary"
										style={CommonStyle.btn}
										onClick={this.handleSaveClick.bind(this)}
										disabled={!saveEnabled}
									>
										Save as Draft
									</button>
								) : (
									<ConfirmModal
										title="Save Draft"
										body="This campaign appears ready for launch, would you rather launch it instead?"
										launchBtn={<button id="saveBtnValid" className="btn btn-primary" style={CommonStyle.btn} > Save as Draft</button>}
										confirmBtn={
											<button className="btn btn-primary" onClick={handleSubmit(this.handleFormSubmit.bind(this))}>Yes</button>
										}
										closeBtn={
											<button className="btn btn-default" onClick={this.handleSaveClick.bind(this)}>
												No
											</button>
										}
									/>
								)}
								<ReactTooltip id="saveDraft" place="left" effect="solid" delayShow={500}>
									{this.renderTitle(saveEnabled, saveTitle)}
								</ReactTooltip>
							</span>
						}
						<div style={CommonStyle.floatRight}>
							<CancelModal
								initialValues={{ ...initialValues, updatedDecodingConfig: false }}
								currentValues={{ ...formValues, updatedDecodingConfig: updatedDecodingConfig || false }}
								onCancel={this.handleCancelClick.bind(this)}
							/>
						</div>
					</Row>
				</div>
			</FormContainer>
		);
	}
}

function mapStateToProps(state) {
	const { workInProgress, currentCampaign } = state.campaign;
	let initialValues;
	let decodingConfig = null;
	const currentCampaignId = currentCampaign.id || 'newCampaign';
	if (workInProgress && currentCampaign && workInProgress[currentCampaignId]) {
		const { formValues, touchcodes } = workInProgress[currentCampaignId];
		initialValues = formValues;
		currentCampaign.Touchcodes = touchcodes;
	} else if (currentCampaign !== undefined && currentCampaign.Id) {
		const { CustomerId, Name, Container, StartDate, EndDate, TimeZone, Location, Codeset, DecodingConfig, DistributionNumber, Website } = currentCampaign;
		const tz = TimeZone || moment.tz.guess();
		const isDemoSiteChecked = currentCampaign.DemoSites.length > 0;
		decodingConfig = JSON.parse(DecodingConfig);
		initialValues = {
			'customer': CustomerId,
			'name': Name,
			'type': Container ? { label: _.capitalize(Container), value: Container } : { label: 'Website', value: 'website' },
			'startDate': StartDate || '',
			'endDate': EndDate || '',
			'timezone': tz,
			'location': Location,
			'codeset': Codeset.Id || '',
			'timeout': (decodingConfig && decodingConfig.timeout) ? (decodingConfig.timeout / 60) : 5,
			'circulationquantity': DistributionNumber,
			'website': Website,
			'demoSiteEnabled': isDemoSiteChecked
		};
		let conf = 1;
		if (decodingConfig && decodingConfig.decoder_type) {
			initialValues['threshold' + conf] = decodingConfig.threshold ? decodingConfig.threshold : null;
			initialValues['minFrameCt' + conf] = decodingConfig.min_frames ? decodingConfig.min_frames : null;
			let label = undefined;
			state.decoderType.decoderTypes.forEach(d => {
				if (d.id === parseInt(decodingConfig.decoder_type, 10)) {
					label = d.Name;
				}
			});
			if (!label) {
				initialValues['decoderType' + conf] = { label: 'Default Decoder', value: 0 };
			} else {
				initialValues['decoderType' + conf] = { label: label, value: decodingConfig.decoder_type };
			}
			conf++;
		}
		if (decodingConfig && decodingConfig.decoders) {
			decodingConfig.decoders.forEach(d => {
				initialValues['threshold' + conf] = d.threshold ? d.threshold : null;
				initialValues['minFrameCt' + conf] = d.min_frames ? d.min_frames : null;
				let label = undefined;
				state.decoderType.decoderTypes.forEach(t => {
					if (t.id === parseInt(d.decoder_type, 10)) {
						label = t.Name;
					}
				});
				if (!label) {
					initialValues['decoderType' + conf] = { label: 'Default Decoder', value: 0 };
				} else {
					initialValues['decoderType' + conf] = { label: label, value: d.decoder_type };
				}
				conf++;
			});
		}
	} else {
		const tz = moment.tz.guess();
		const today = moment.tz(tz).startOf('day');
		initialValues = {
			'timezone': tz,
			'startDate': today.format(),
			'endDate': today.add(2, 'y').endOf('day').format(),
			'type': 'both',
			'circulationquantity': 1
		};
	}
	const decoderParameterConfigs = [];
	if (state.decoderType.decoderParameterTypes && state.decoderType.decoderParameterConfigs) {
		state.decoderType.decoderParameterTypes.forEach(t => {
			decoderParameterConfigs[t.id] = [];
		});
		state.decoderType.decoderParameterConfigs.forEach(c => {
			decoderParameterConfigs[c.Type][c.Id] = c;
		});
	}

	return {
		campaign: currentCampaign,
		initialValues: initialValues,
		campaignsByCodeset: state.campaign.campaignsByCodeset,
		codesets: state.codeset.codesets,
		customers: state.customer.customers,
		formIsValid: isValid(formName)(state),
		formValues: getFormValues(formName)(state),
		workInProgress: workInProgress,
		customerId: state.auth.customerId,
		clearForm: state.campaign.clearForm,
		permissions: state.userRole.permissions,
		decoderConfig: decodingConfig,
		decoderTypes: state.decoderType.decoderTypes,
		decoderParameterTypes: state.decoderType.decoderParameterTypes,
		decoderParameterConfigs: decoderParameterConfigs,
	};
}

const WrappedComponent = reduxForm({
	form: formName
})(CampaignEditForm);

export default connect(mapStateToProps, {
	updateCampaign,
	createCampaign,
	deleteCampaign,
	updateCurrentCampaignCodeset,
	clearCurrentCampaignTouchcodes,
	fetchCampaignsByCodeset,
	clearCampaignsByCodeset,
	setSelectedTouchcodes,
	fetchCodesets,
	fetchDecoderTypes,
	fetchDecoderParameterTypes,
	fetchDecoderParameterConfigs,
	updateSelectedDecoderParameters,
	removeSelectedDecoderParameter,
	updateParameterValue,
	fetchCodesetLabels,
	clearCodesetLabels,
	fetchCustomers,
	clearWorkInProgress,
	setCustomerIdEnabled,
	setCustomerId,
	setRefreshActions,
	clearCodesetForm,
	clearSelectedTouchcodes,
	clearDefaultTargets,
	clearActions,
	fetchActionsByDest
})(WrappedComponent);
