import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../util/history';
import moment from 'moment';
import v from 'voca';
import { fetchCampaign, amendCampaign, clearCurrentCampaign, clearFetchCampaignError } from '../../ducks/campaign.duck';
import { setCustomerId, setCustomerIdEnabled } from '../../ducks/auth.duck';
import { createStateUpdateParam } from '../../util/campaignUtil';
import { routes, statusOptionsByName, EDIT_CAMPAIGN_REACTIVATE_ACTION } from '../../util/constants.js';
import { FaGlobe } from 'react-icons/lib/fa';
import CampaignEditForm from './campaignEditForm';
import PageHeading from '../utilities/pageHeading';
import PageContainer from '../utilities/pageContainer';
import IconBtn from '../utilities/iconBtn';
import CampaignExportButton from './campaignExportButton';
import PauseIconBtnModal from '../utilities/pauseIconBtnModal';
import ArchiveIconBtnModal from '../utilities/archiveIconBtnModal';
import ResumeIconBtnModal from '../utilities/resumeIconBtnModal';
import ReactivateIconBtnModal from '../utilities/reactivateIconBtnModal';
import Spinner from '../utilities/spinner';
import { fetchDecoderTypes } from '../../ducks/decoderType.duck.js';
import TestResultsModal from './testResultsModal';


class CampaignEdit extends Component {
	static propTypes = {
		// React Router
		params: PropTypes.object,
		// Redux Store
		campaign: PropTypes.object,
		// Wrapped actions
		fetchCampaign: PropTypes.func,
		clearCurrentCampaign: PropTypes.func,
		amendCampaign: PropTypes.func,
		clearFetchCampaignError: PropTypes.func
	}

	constructor(props) {
		super(props);
		this.state = { title: undefined };
	}

	componentDidMount() {
		this.props.setCustomerIdEnabled(false);
		this.props.fetchDecoderTypes();
		const campaignId = this.props.match.params.id;
		this.props.fetchCampaign(campaignId);
	}

	componentDidUpdate() {
		if (this.props.campaignError) {
			history.push(routes.notFound);
			this.props.clearFetchCampaignError();
		}
	}

	componentWillUnmount() {
		this.props.setCustomerIdEnabled(true);
		this.props.clearCurrentCampaign();
	}

	handleAnalyticsClick(event) {
		history.push(`${routes.analyticsLocation}/${this.props.campaign.Id}`);
		event.preventDefault();
	}

	handlePauseClick() {
		const campaignId = this.props.match.params.id;
		const param = createStateUpdateParam(statusOptionsByName.paused);
		this.props.amendCampaign(param, campaignId, null, fetchCampaign.bind(null, campaignId));
		// this.props.fetchCampaign(campaignId);
	}

	handleResumeClick() {
		const campaignId = this.props.match.params.id;
		const param = createStateUpdateParam(statusOptionsByName.active);
		this.props.amendCampaign(param, campaignId, null, fetchCampaign.bind(null, campaignId));
		// this.props.fetchCampaign(campaignId);
	}

	handleArchiveClick() {
		const campaignId = this.props.match.params.id;
		const param = createStateUpdateParam(statusOptionsByName.archived);
		this.props.amendCampaign(param, campaignId, null, fetchCampaign.bind(null, campaignId));
		// this.props.fetchCampaign(campaignId);
	}

	handleReactivateClick() {
		const { campaign } = this.props;
		const campaignId = this.props.match.params.id;

		if (!campaign.EndDate || moment().utc().isSameOrAfter(campaign.EndDate)) {
			const param = createStateUpdateParam(statusOptionsByName.saved_draft);
			this.props.amendCampaign(param, campaignId, `${routes.campaignEdit}/${campaignId}/${EDIT_CAMPAIGN_REACTIVATE_ACTION}`);
		} else {
			const param = createStateUpdateParam(statusOptionsByName.active);
			this.props.amendCampaign(param, campaignId, null, fetchCampaign.bind(null, campaignId));
		}
	}

	renderIconSection() {
		const { campaign } = this.props;

		if (!campaign || !campaign.Id) {
			return null;
		}

		const { Id, Name, Touchcodes, DecodingConfig, CodesetId } = campaign;
		const icons = [];
		switch (v.lowerCase(campaign.CampaignState.Name)) {
			case 'active':
				icons.push(<CampaignExportButton key="exportButton" campaignId={Id} />);
				icons.push(<TestResultsModal
					codeset={'' + CodesetId}
					codes={Object.keys(Touchcodes)}
					decodingConfig={DecodingConfig}
					key="testButton"
				/>);
				icons.push(<IconBtn key="1" handler={this.handleAnalyticsClick.bind(this)} icon={<FaGlobe />} />);
				icons.push(<PauseIconBtnModal key="2" handler={this.handlePauseClick.bind(this)} campaignId={Id} name={Name} />);
				icons.push(<ArchiveIconBtnModal key="3" handler={this.handleArchiveClick.bind(this)} campaignId={Id} name={Name} />);
				break;
			case 'paused':
				icons.push(<CampaignExportButton key="exportButton" campaignId={Id} />);
				icons.push(<TestResultsModal
					codeset={'' + CodesetId}
					codes={Object.keys(Touchcodes)}
					decodingConfig={DecodingConfig}
					key="testButton"
				/>);
				icons.push(<IconBtn key="1" handler={this.handleAnalyticsClick.bind(this)} icon={<FaGlobe />} />);
				icons.push(<ResumeIconBtnModal key="2" handler={this.handleResumeClick.bind(this)} campaignId={Id} name={Name} />);
				break;
			case 'archived':
				icons.push(<CampaignExportButton key="exportButton" campaignId={Id} />);
				icons.push(<TestResultsModal
					codeset={'' + CodesetId}
					codes={Object.keys(Touchcodes)}
					decodingConfig={DecodingConfig}
					key="testButton"
				/>);
				icons.push(<IconBtn key="1" handler={this.handleAnalyticsClick.bind(this)} icon={<FaGlobe />} />);
				icons.push(<ReactivateIconBtnModal key="2" handler={this.handleReactivateClick.bind(this)} campaignId={Id} name={Name} />);
				break;
			default:
				break;
		}

		return icons;
	}

	setTitle(newTitle) {
		this.setState({ title: newTitle });
	}
	render() {
		const { campaign, match, decoderTypes } = this.props;
		const { title } = this.state;
		let isReactivation;

		if (match) {
			isReactivation = match.params.action === EDIT_CAMPAIGN_REACTIVATE_ACTION;
		}

		return (
			<PageContainer>
				{title === undefined ?
					<PageHeading iconSection={<div>{!isReactivation && this.renderIconSection()}</div>}>
						Edit Campaign
					</PageHeading>
					:
					<PageHeading>
						{title}
					</PageHeading>
				}
				{campaign && decoderTypes && <CampaignEditForm campaign={campaign} isReactivation={isReactivation} setTitle={this.setTitle.bind(this)} />}
				{!campaign && <Spinner />}
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	return {
		campaign: state.campaign.currentCampaign,
		campaignError: state.campaign.campaignError,
		decoderTypes: state.decoderType.decoderTypes,
	};
}

CampaignEdit = connect(mapStateToProps, {
	fetchCampaign,
	clearCurrentCampaign,
	amendCampaign,
	clearFetchCampaignError,
	setCustomerId,
	setCustomerIdEnabled,
	fetchDecoderTypes
})(CampaignEdit);

export default CampaignEdit;
