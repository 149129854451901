import React, { Component } from 'react';
import ActionEditForm from './actionEditForm';
import PageContainer from '../utilities/pageContainer';
import PageHeading from '../utilities/pageHeading';

class ActionNew extends Component {
	constructor(props) {
		super(props);

		this.state = {
			confirmNavigation: false
		};
	}

	render() {
		return (
			<PageContainer>
				<PageHeading>
					Create a New Action
				</PageHeading>

				<ActionEditForm isNew />
			</PageContainer>
		);
	}
}

export default ActionNew;
