// @flow
import React from 'react';
import type { CalendarDate, CalendarMonth } from './datePickerInput';

type DateCellProps = {
	day: CalendarDate,
	selectedDate: number,
	onClick: Function
};

export const DateCell = ({ day, selectedDate, onClick }: DateCellProps) => {
	const { outside, date } = day;

	const classes = [];
	classes.push(outside ? 'dateOutside' : '');
	classes.push(selectedDate === date && !outside ? 'selectedDate' : '');

	return (
		<td className={classes.join(' ')} onClick={onClick.bind(null, day)}>
			{date}
		</td>
	);
};

type CalendarProps = {
	calendarMonth: CalendarMonth,
	selectedDate: number,
	dateClickHandler: Function
};

export const Calendar = ({ calendarMonth, selectedDate, dateClickHandler }: CalendarProps) => {
	return (
		<tbody>
			{calendarMonth.map((week, i) => {
				return (
					<tr key={i}>
						{week.map((day, j) =>
							<DateCell selectedDate={selectedDate} key={j} day={day} onClick={dateClickHandler} />
						)}
					</tr>
				);
			})}
		</tbody>
	);
};
