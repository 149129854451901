import React, { Component } from 'react';
import { Row, Col, FormControl, Checkbox } from 'react-bootstrap';
import ModalLauncher from '../utilities/modalLauncher';
import _ from 'lodash';

const style = {
	bodyText: {
		whiteSpace: 'normal'
	},
	parameterContainer: {
		display: 'inline-block',
		padding: '8px'
	},
	parameterTextBoxLabel: {
		float: 'left',
		padding: '8px'
	},
	parameterTextBox: {
		width: '100px',
		display: 'inline-block'
	}
};

class EditParameterModal extends Component {
	constructor(props) {
		super(props);

		const initialState = {};
		const parameterKey = Object.keys(props.parameterConfig)[0];
		initialState.parameterName = this.convertKeyToName(parameterKey);

		const values = props.values || [];
		const optionKeys = Object.keys(props.parameterConfig[parameterKey]);
		optionKeys.forEach((p, parameterIndex) => {
			let optionValue = props.parameterConfig[parameterKey][p].default;
			if (values[parameterIndex] !== null) {
				optionValue = values[parameterIndex];
			}
			initialState[`${p}_value`] = optionValue;
		});

		this.state = initialState;

		this.handleCancelClick = this.handleCancelClick.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (!_.isEqual(prevProps.values, this.props.values)) {
			const values = this.props.values || [];
			const parameterKey = Object.keys(this.props.parameterConfig)[0];
			const parameterName = this.convertKeyToName(parameterKey);
			this.setState({ parameterName });
			const optionKeys = Object.keys(this.props.parameterConfig[parameterKey]);
			optionKeys.forEach((p, parameterIndex) => {
				let optionValue = this.props.parameterConfig[parameterKey][p].default;
				if (values[parameterIndex] !== null) {
					optionValue = values[parameterIndex];
				}
				this.setState({ [p + '_value']: optionValue });
			});
			this.props.parameterChangeHandler(values);
		}
	}

	convertKeyToName(key) {
		return key.split('_').map(_.capitalize).join(' ');
	}

	getNewParameterConfig(key, newValue) {
		const parameterConfig = this.getParameterConfig(this.props.parameterConfig);
		const parameters = parameterConfig[Object.keys(parameterConfig)[0]];
		let keyIndex = 0;
		parameters.forEach((p, i) => {
			if (Object.keys(p)[0] === key) {
				keyIndex = i;
			}
		});
		parameters[keyIndex] = { [parameters[keyIndex]]: newValue };
		return parameterConfig;
	}

	handleElementValueChange(e, key) {
		this.setState({ [key + '_value']: e.target.value });
		this.props.parameterChangeHandler(this.getNewParameterConfig(key, e.target.value));
	}

	renderTextBox(element, key) {
		return (
			<div style={style.parameterContainer} key={key}>
				<div style={style.parameterTextBoxLabel}>
					{this.convertKeyToName(key)}
				</div>
				<FormControl
					style={style.parameterTextBox}
					type="text"
					value={this.state[key + '_value']}
					onChange={e => this.handleElementValueChange(e, key)}
				/>
			</div>
		);
	}

	handleCheckBoxChange(checkBox, key) {
		this.setState({ [key + '_value']: checkBox.target.checked });
		this.props.parameterChangeHandler(this.getNewParameterConfig(key, checkBox.target.checked));
	}

	renderCheckBox(element, key) {
		return (
			<Checkbox key={key} onChange={c => this.handleCheckBoxChange(c, key)} checked={this.state[key + '_value']}>
				{this.convertKeyToName(key)}
			</Checkbox>
		);
	}

	renderElement(element, key) {
		if (element.element_type === 'textbox') {
			return this.renderTextBox(element, key);
		} else if (element.element_type === 'checkbox') {
			return this.renderCheckBox(element, key);
		}
		return (<div key={key}>{Object.keys(element)[0]}</div>);
	}

	renderParameterOptions(config) {
		if (config) {
			const options = config[Object.keys(config)[0]];
			const optionKeys = Object.keys(options);
			const elements = [];
			optionKeys.forEach(key => {
				elements.push(this.renderElement(options[key], key));
			});
			return (<div>
				{
					elements.map(e => {
						return (e);
					})
				}
			</div>);
		}
		return (<div />);
	}

	getParameterConfig(parameterConfig) {
		let parameterValues = null;
		let parameter = null;
		if (parameterConfig) {
			parameter = Object.keys(parameterConfig)[0];
			const options = Object.keys(parameterConfig[parameter]);
			parameterValues = [];
			options.forEach(p => {
				parameterValues.push({ [p]: this.state[p + '_value'] });
			});
		}
		return { [parameter]: parameterValues };
	}

	handleCancelClick() {
		let parameterValues = null;
		const options = Object.keys(this.state);
		const name = Object.keys(this.props.parameterConfig);
		parameterValues = [];
		if (this.props.values) {
			options.forEach((p, i) => {
				if (i === 0) {
					parameterValues.push({ [p]: name[0] });
				}
				if (i !== 0) {
					parameterValues.push({ [p]: this.props.values[i - 1] });
					this.state[p] = this.props.values[i - 1];
				}
			});
		}
		this.props.parameterChangeHandler(parameterValues);
	}

	handleSave() {
		const { confirmFunc } = this.props;
		if (confirmFunc) {
			confirmFunc();
		}

		const options = Object.keys(this.state);
		if (this.props.values) {
			options.forEach((p, i) => {
				if (i !== 0) {
					this.props.values[i - 1] = this.state[p];
				}
			});
		}
	}

	render() {
		const { parameterConfig, launchBtn, confirmBtn, confirmFunc } = this.props;
		return (
			<ModalLauncher name={this.state.parameterName}
				launchBtn={launchBtn}
				actionBtn={confirmFunc ?
					<button className="btn btn-danger" onClick={this.handleSave.bind(this)}>{'Save'}</button> : <div>{confirmBtn}</div>}
				closeBtn={
					<button onClick={this.handleCancelClick.bind(this)} className="btn btn-default">
						{'Cancel'}
					</button>
				}>
				<Row>
					<Col xs={12}>
						<h3>{this.state.parameterName}</h3>
					</Col>
				</Row>
				{this.renderParameterOptions(parameterConfig)}
				<Row>
					<Col xs={12}>
						<div style={style.bodyText}>{name}</div>
					</Col>
				</Row>
			</ModalLauncher>
		);
	}
}

export default EditParameterModal;
