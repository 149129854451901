import React, { Component } from 'react';
import FormContainer from '../utilities/formContainer';
import {Row, Col} from 'react-bootstrap';
import FieldWrapper from '../utilities/fieldWrapper';
import { change, Field, formValues, getFormValues, isValid, reduxForm } from 'redux-form';
import CommonStyle, { mergeStyles, colorDanger } from '../../styles/commonStyles';
import history from '../../util/history';
import { routes } from '../../util/constants';
import { connect } from 'react-redux';
import axios from 'axios';
import { BASE_URL } from '../../ducks';
import FileUpload from '../utilities/fileUpload';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { toastr } from 'react-redux-toastr';

export type DemoSiteFormValues = {
    CampaignTag: String,
    Brand: String,
    CampaignId: String,
    CoBrandLogo: String,
    LogoColor: String,
    MessageInsert: String,
    Message: String,
    MessageColor: String,
    Product: String,
    PrimaryColor: String,
    SuccessMessage: String,
    SuccessSecondaryMessage: String,
    SuccessMessageColor: String,
    SuccessTimeout: Number,
    Background: String,
    LogoUrl: String,
    Url: String 
}
const formName = 'demoSiteEdit';
const WHITE_TOUCHCODE_LOGO_URL = "https://tc-media.azureedge.net/logos/icon-white.png";
const GRADIENT_TOUCHCODE_LOGO_URL = "https://tc-media.azureedge.net/logos/icon-grad.png";
const isNumber = value => !value || _.isFinite(value) ? undefined : "Must contain only numbers";
const isAlphaNumeric = value => new RegExp('^[a-zA-Z0-9]+$').test(value) ? undefined : "Must contain only numbers and letters";
const notNull = value => value ? undefined : "Required";
type Props = {
    initialValues: DemoSiteFormValues
}

export class DemoEditFormRaw extends Component {
    constructor(props: Props) {
        super(props);
        this.initializeValues(props.initialValues);
        this.state = {
            submitDisabled: false
        }
    }
    initializeValues(initialValues: DemoSiteFormValues) {
        const {dispatch} = this.props;
        initialValues = initialValues || {};
        if(!initialValues.Background) {
            dispatch(change(formName, "Background", "lightgrey"));
        }
        if(!initialValues.PrimaryColor) {
            dispatch(change(formName, "PrimaryColor", "darkblue"));
        }
        if(!initialValues.MessageColor) {
            dispatch(change(formName, "MessageColor", "lightblue"));
        }
        if(!initialValues.Message) {
            dispatch(change(formName, "Message", "Touch card firmly against screen to unlock your authentic Touchcode experience"));
        }
        if(!initialValues.SuccessMessageColor) {
            dispatch(change(formName, "SuccessMessageColor", "limegreen"))
        }
    }

    handleLogoUpload(file: string) {
        this.handleFileUpload(file, "LogoUrl");
    }
    handleUrlUpload(file: string) {
        this.handleFileUpload(file, "Url");
    }
    handleCobrandUpload(file: string) {
        this.handleFileUpload(file, "CoBrandLogo");
    }
    async handleFileUpload(fileUrl: String, field: String) {
        const {dispatch} = this.props;
        dispatch(change(formName, field, fileUrl));
    }

    async submissionCheck(formValues: DemoSiteFormValues) {
        const {onSubmit, campaignId} = this.props;
        if(_.isEmpty(formValues)) {
            onSubmit(formValues);
        }
        this.setState({submitDisabled: true});
        const checkResult = await axios.get(`${BASE_URL}/campaign/demoSiteExists/${campaignId}/${formValues.CampaignTag}`);
        this.setState({submitDisabled: false});
        if(checkResult.status != 200) {
            toastr.error("Error checking for duplicate campaign tag");
            return;
        }
        else {
            if(!checkResult.data.found) {
                onSubmit(formValues);
            }
            else {
                toastr.error("That campaign tag already exists!");
            }
        }
    }

    validateCampaignTag(tag: string) {
        if(!tag) {
            return false;
        }
        const regex = new RegExp('^[a-zA-Z0-9]+$')
        return regex.test(tag);
    }

    render() {
        const {handleSubmit, dispatch, onCancel} = this.props;
        const {submitDisabled} = this.state;
        const formValues = this.props.formValues || {};
        const whiteLogo = <img src={WHITE_TOUCHCODE_LOGO_URL} width={64} height={64}/>
        const gradientLogo = <img src={GRADIENT_TOUCHCODE_LOGO_URL} width={64} height={64}/>
        const bsResponsiveLeft = {
			xs: 12,
			sm: 6,
			md: 6,
			lg: 6
		};
		const bsResponsiveRight = {
			xs: 12,
			sm: 6,
			md: 6,
			lg: 6
		};
        const logoColorBar = {
            display: "flex",
            flexFlow: "row",
            justifyContent: "space-evenly",
            gap: "20px"
        }
        return (
            <FormContainer submitHandler={handleSubmit(this.submissionCheck.bind(this))}>
                <Row className='demoEditRow'>
                    <Col {...bsResponsiveLeft}>
                        <Row>
                            <Col xs={12} style={CommonStyle.formRow}>
								<Field id="campaignTagInput"
                                name="CampaignTag" type="text" placeholder="URL Path"
                                validate={[notNull, isAlphaNumeric]}
                                component={FieldWrapper} label="Try.tc Path"/>
							</Col>
                            <h2>Scanning Page</h2>
                            <Col xs={6} style={CommonStyle.formRow}>
								<Field id="backgroundInput"
                                name="Background" type="colorPicker" placeholder="Background"
                                onChange={((color) => dispatch(change(formName, "Background", color)))}
                                component={FieldWrapper} label="Background Color"/>
							</Col>
                            <Col xs={6} style={CommonStyle.formRow}>
								<Field id="primaryColorInput"
                                name="PrimaryColor" type="colorPicker" placeholder="#ffffff"
                                onChange={((color) => dispatch(change(formName, "PrimaryColor", color)))}
                                component={FieldWrapper} label="Header Color"/>
							</Col>
                            <Col xs={6} style={CommonStyle.formRow}>
                                <div>
                                    <label>Touchcode Logo</label>
                                    <div style={logoColorBar}>
                                        <Field label={whiteLogo} id="logoColorWhiteButton" name="LogoColor" type="radio" value={"white"} component={FieldWrapper}/>
                                        <Field label={gradientLogo} id="logoColorGradientButton" name="LogoColor" type="radio" value={"grad"} component={FieldWrapper}/>
                                    </div>
                                </div>
							</Col>
                            <Col xs={6} style={CommonStyle.formRow}>
								<Field id="coBrandLogoInput"
                                name="CoBrandLogo" type="hidden" placeholder="Co-Brand Logo"
                                component={FieldWrapper} label="Co-Brand Logo" readOnly={true}/>
                                <FileUpload onUpload={this.handleCobrandUpload.bind(this)} preSelectedFileUrl={formValues.CoBrandLogo}/>
							</Col>
                            <Col xs={12} style={CommonStyle.formRow}>
								<Field id="messageInput"
                                name="Message" type="text" placeholder="Message"
                                component={FieldWrapper} label="Message"/>
							</Col>
                            <Col xs={12} style={CommonStyle.formRow}>
								<Field id="messageColorInput"
                                name="MessageColor" type="colorPicker" placeholder="#ffffff"
                                onChange={((color) => dispatch(change(formName, "MessageColor", color)))}
                                component={FieldWrapper} label="Message Color"/>
							</Col>
                            <Col xs={6} style={CommonStyle.formRow}>
								<Field id="UrlInput"
                                name="Url" type="hidden" placeholder="URL"
                                component={FieldWrapper} label="Scanning Hint" readOnly={true}/>
                                <FileUpload onUpload={this.handleUrlUpload.bind(this)} preSelectedFileUrl={formValues.Url}/>
							</Col>
                            <Col xs={6} style={CommonStyle.formRow}>
								<Field id="logoUrlInput"
                                name="LogoUrl" type="hidden" placeholder="Logo Url"
                                component={FieldWrapper} label="Logo"/>
                                <FileUpload onUpload={this.handleLogoUpload.bind(this)} preSelectedFileUrl={formValues.LogoUrl}/>
							</Col>
                        </Row>

                    </Col>
                    <Col {...bsResponsiveRight}>
                        <img src="https://tc-media.azureedge.net/demositeExamples/ExampleLanding.png"/>
                    </Col>
                </Row>
                <Row className='demoEditRow'>
                    <Col {...bsResponsiveLeft}>
                        <Row>
                            <h2>Success Page</h2>
                            <Col xs={12} style={CommonStyle.formRow}>
                                <Field id="successMessageColorInput"
                                name="SuccessMessageColor" type="colorPicker" placeholder="#ffffff"
                                onChange={((color) => dispatch(change(formName, "SuccessMessageColor", color)))}
                                component={FieldWrapper} label="Success Message Background"/>
                            </Col>
                            <Col xs={12} style={CommonStyle.formRow}>
                                <Field id="successMessageInput"
                                name="SuccessMessage" type="text" placeholder="Success Message"
                                component={FieldWrapper} label="Success Message"/>
                            </Col>
                            <Col xs={12} style={CommonStyle.formRow}>
                                <Field id="successSecondaryMessageInput"
                                name="SuccessSecondaryMessage" type="text" placeholder="Success Secondary Message"
                                component={FieldWrapper} label="Success Secondary Message"/>
                            </Col>
                            <Col xs={12} style={CommonStyle.formRow}>
                                <Field id="successTimeoutInput"
                                name="SuccessTimeout" type="text" placeholder="0"
                                validate={[isNumber]}
                                component={FieldWrapper} label="Success Timeout"/>
                            </Col>
                        </Row>
                    </Col>
                    <Col {...bsResponsiveRight}>
                        <img src="https://tc-media.azureedge.net/demositeExamples/ExampleSuccess.png"/>
                    </Col>
                </Row>
                <Row>
                    {formValues &&
                        <span data-tip data-for="submitTooltip">
                            <button type="submit" style={CommonStyle.btn}
                            disabled={submitDisabled}
                            className="btn btn-success">Submit</button>
                            {submitDisabled &&
                                <ReactTooltip id="submitTooltip" place="top" effect='solid' delayShow={500} data-multiline>
                                    Please choose a valid campaign tag
                                </ReactTooltip>
                            }
                        </span>
                    }
                    <button type="button" style={CommonStyle.btn}
                                className="btn btn-secondary" onClick={onCancel}>Cancel</button>
                </Row>
            </FormContainer>
        )
    }
}

function mapStateToProps(state) {
    return {
        formIsValid: isValid(formName)(state),
		formValues: getFormValues(formName)(state),
    }
}

const DemoEditForm = reduxForm({
	form: formName
})(DemoEditFormRaw);

export default connect(mapStateToProps, null)(DemoEditForm);