// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, change } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import { fetchDecoderTypes, fetchDecoderParameterTypes, fetchDecoderParameterConfigs } from '../../ducks/decoderType.duck.js';
import * as validators from '../../util/FormValidators';
import CommonStyle from '../../styles/commonStyles';
import FieldWrapper from '../utilities/fieldWrapper';
import SelectInput from '../utilities/selectInput';
import Select from '../utilities/SelectInputHeightAware';
import { getTheoreticalMinimum } from '../utilities/minimumTheoreticalDist';
import CampaignSelectedParameter from './campaignSelectedParameter';
import ReactTooltip from 'react-tooltip';


export class CampaignDecoderConfigEdit extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedParametersFetched: false,
			selectedDecoderParameters: {}
		};
	}

	componentDidMount() {
		this.props.fetchDecoderTypes();
		this.props.fetchDecoderParameterTypes();
		this.props.fetchDecoderParameterConfigs();
	}

	componentDidUpdate() {
		const { decoderParameterConfigs } = this.props;
		if (decoderParameterConfigs) {
			if (decoderParameterConfigs.length !== 0) {
				if (!this.state.selectedParametersFetched && (this.props.decodingConfig || this.props.campaign.DecodingConfig)) {
					const decodingConfig = this.props.decodingConfig || JSON.parse(this.props.campaign.DecodingConfig);
					const newParameterConfig = [];
					let id = 1;
					if (decodingConfig.parameters) {
						decodingConfig.parameters.forEach(p => {
							if (!newParameterConfig[p.Type]) {
								newParameterConfig[p.Type] = [];
							}
							const decoderParameterConfig = this.props.decoderParameterConfigs[p.Type][p.Id];
							const parameterWithConfig = { ...p, Config: decoderParameterConfig.Config };
							newParameterConfig[p.Type].push(parameterWithConfig);
							this.handleParameterUpdate(parameterWithConfig, id);
							id++;
						});
					}
					if (decodingConfig.decoders) {
						decodingConfig.decoders.forEach(d => {
							if (d.parameters) {
								d.parameters.forEach(p => {
									if (!newParameterConfig[p.Type]) {
										newParameterConfig[p.Type] = [];
									}
									const decoderParameterConfig = this.props.decoderParameterConfigs[p.Type][p.Id];
									const parameterWithConfig = { ...p, Config: decoderParameterConfig.Config };
									newParameterConfig[p.Type].push(parameterWithConfig);
									this.handleParameterUpdate(parameterWithConfig, id);
								});
							}
							id++;
						});
					}
					this.setState({ selectedParametersFetched: true });
				}
			}
		}
	}

	selectedParametersToDecodingConfig(selectedParameters) {
		if (selectedParameters) {
			const decodingConfigParameters = [];
			selectedParameters.forEach(s => {
				s.forEach(p => {
					decodingConfigParameters.push(
						{
							Id: p.Id,
							Type: p.Type,
							values: p.values
						}
					);
				});
			});
			return decodingConfigParameters;
		}
		return [];
	}

	handleParameterSelectChange(value) {
		const { decoderParameterConfigs, configurationId } = this.props;
		const parameter = decoderParameterConfigs[value.parameterType][value.parameterId];
		this.handleParameterUpdate(parameter, configurationId);
	}

	handleParameterUpdate(parameter, configurationId) {
		const { selectedDecoderParameters } = this.state;
		const configId = configurationId || (this.props ? this.props.configurationId : 1);
		const selectedDecoderParametersForConfig = selectedDecoderParameters[configId] || [];
		const parameterTypes = selectedDecoderParametersForConfig[parameter.Type] || [];
		selectedDecoderParametersForConfig[parameter.Type] = [...parameterTypes, parameter];
		selectedDecoderParameters[configId] = selectedDecoderParametersForConfig;
		this.forceUpdate();
	}

	renderParameterOptions(id, selectedDecoderParameters) {
		const parameter = this.props.decoderParameterConfigs[id];
		const options = [];
		parameter.forEach(p => {
			const config = JSON.parse(p.Config);
			const keys = Object.keys(config);
			const label = keys[0].split('_').map(_.capitalize).join(' ');
			let parameterExists = false;
			if (selectedDecoderParameters && selectedDecoderParameters[p.Type]) {
				selectedDecoderParameters[p.Type].forEach(s => {
					if (p.Id === s.Id) {
						parameterExists = true;
					}
				});
			}
			if (!parameterExists) {
				options.push({ label: label, parameterType: id, parameterId: p.Id });
			}
		});
		return options;
	}

	removeSelectedParameterHandler(parameter) {
		const { selectedDecoderParameters } = this.state;
		const { configurationId } = this.props;

		const selectedDecoderParametersForConfig = selectedDecoderParameters[configurationId] || [];
		const newSelection = selectedDecoderParametersForConfig[parameter.Type].filter(s => s.Id !== parameter.Id);
		selectedDecoderParameters[configurationId][parameter.Type] = [...newSelection];
		this.forceUpdate();
	}

	getDecodingConfig() {
		const { maxConfigurationId, formValues, codesets } = this.props;
		const { selectedDecoderParameters } = this.state;

		let codesetOptions;
		if (codesets && formValues.codeset) {
			for (let i = 0; i < codesets.length; i++) {
				if (Number(formValues.codeset) === codesets[i].Id) {
					codesetOptions = JSON.parse(codesets[i].DefaultDecodingConfig);
				}
			}
		}

		let timeout = (codesetOptions && codesetOptions.timeout) || 300;
		if (formValues.timeout && !isNaN(Number(formValues.timeout))) {
			timeout = Number(formValues.timeout) * 60;
		}
		const decodingConfig = {
			decoders: [],
			timeout: timeout
		};

		for (let i = 1; i <= maxConfigurationId; i++) {
			const config = {
				decoder_type: undefined,
				threshold: undefined,
				min_frames: undefined,
				parameters: undefined,
			};
			const decoderType = formValues['decoderType' + i];
			const threshold = formValues['threshold' + i];
			const minFrames = formValues['minFrameCt' + i];

			const parameters = this.selectedParametersToDecodingConfig(selectedDecoderParameters[i] || []);
			let defaultDecoderType;
			let defaultThreshold;
			let defaultMinFrames;
			let defaultParameters;

			if (codesetOptions) {
				defaultDecoderType = codesetOptions.decoder_type || '0';
				defaultThreshold = codesetOptions.threshold || '0.02';
				defaultMinFrames = codesetOptions.minFrames || '5';
				defaultParameters = codesetOptions.parameters || [];
			} else {
				defaultDecoderType = '0';
				defaultThreshold = '0.02';
				defaultMinFrames = '5';
				defaultParameters = [];
			}

			config.decoder_type = decoderType ? decoderType.value.toString() : defaultDecoderType;
			config.threshold = threshold ? threshold : defaultThreshold;
			config.min_frames = minFrames ? minFrames : defaultMinFrames;
			config.parameters = parameters ? parameters : defaultParameters;
			decodingConfig.decoders.push(config);
		}
		return decodingConfig;
	}

	deleteConfiguration(configurationId) {
		const { maxConfigurationId, formValues, dispatch } = this.props;
		const { selectedDecoderParameters } = this.state;
		if (configurationId > maxConfigurationId || configurationId < 1) {
			return false;
		}
		if (configurationId === 1) {
			dispatch(change('campaignEdit', 'timeout', null));
		}
		for (let i = configurationId; i <= maxConfigurationId; i++) {
			selectedDecoderParameters[i] = selectedDecoderParameters[i + 1];
			dispatch(change('campaignEdit', 'decoderType' + i, formValues['decoderType' + (i + 1)] || null));
			dispatch(change('campaignEdit', 'threshold' + i, formValues['threshold' + (i + 1)] || null));
			dispatch(change('campaignEdit', 'minFrameCt' + i, formValues['minFrameCt' + (i + 1)] || null));
		}
		return true;
	}

	updateParameterValue(parameter) {
		const { selectedDecoderParameters } = this.state;
		const { configurationId } = this.props;
		for (const key in parameter) {
			const selectedDecoderParametersForConfig = _.cloneDeep(selectedDecoderParameters[configurationId]) || [];
			const selectedDecoderParametersByType = selectedDecoderParametersForConfig[parameter[key].Type] || [];
			for (const d in selectedDecoderParametersByType) {
				if (selectedDecoderParametersByType[d].Id === parameter[key].Id) {
					selectedDecoderParametersByType[d].values = parameter[key].values;
					selectedDecoderParameters[configurationId][parameter[key].Type] = selectedDecoderParametersByType;
				}
			}
		}
		this.forceUpdate();
	}

	renderSelectedParameters(parameters) {
		if (parameters) {
			return (
				parameters.map(p => {
					return (
						<CampaignSelectedParameter
							key={p.Id}
							parameter={p}
							removeSelectedParameterHandler={this.removeSelectedParameterHandler.bind(this)}
							parameterSaveHandler={this.updateParameterValue.bind(this)}
						/>
					);
				})
			);
		}
		return (<div />);
	}

	renderParameterSelect(parameterTypes, selectedParameters) {
		const { configurationId } = this.props;
		if (!parameterTypes) {
			return (<div />);
		}
		return (
			<div>
				{
					parameterTypes.map(p => {
						const name = `${configurationId}parameterType${p.Id}`;
						return (
							<Row key={p.Id} style={{ ...CommonStyle.formRow, marginLeft: 'auto', marginTop: '15px' }}>
								<label htmlFor={name}>{p.Name}</label>
								<Select
									value={null}
									name={name}
									isClearable={false}
									placeholder={`Select ${p.Name}`}
									options={this.renderParameterOptions(p.Id, selectedParameters)}
									onChange={value => {
										this.handleParameterSelectChange(value);
									}}
								/>
								{selectedParameters && this.renderSelectedParameters(selectedParameters[p.Id])}
							</Row>
						);
					})
				}
			</div>
		);
	}

	renderDecoderTypes() {
		const { decoderTypes } = this.props;
		return (
			decoderTypes.map(t => {
				return {
					label: String(t.Name), value: t.Id.toString()
				};
			})
		);
	}

	render() {
		const { permissions, codeset, configurationId } = this.props;
		const { selectedDecoderParameters } = this.state;
		const isAdminViewer = permissions ? permissions.AdminView : null;

		let codesetOptions;
		if (isAdminViewer && codeset) {
			codesetOptions = JSON.parse(codeset.DefaultDecodingConfig);
		}

		let defaultMinFrames = 'N/A';
		let defaultThreshold = '0.02';
		if (codesetOptions) {
			if (codesetOptions.decoders) {
				let position = 0;
				if (codesetOptions.decoders.length > configurationId) {
					position = configurationId - 1;
				}
				defaultThreshold = codesetOptions.decoders[position].threshold.toString();
				defaultMinFrames = codesetOptions.decoders[position].min_frames.toString();
			} else {
				if (codesetOptions.threshold) {
					defaultThreshold = codesetOptions.threshold.toString();
				}
				if (codesetOptions.min_frames) {
					defaultMinFrames = codesetOptions.min_frames.toString();
				}
			}
		}

		return (
			<div style={{ border: '1px solid black' }}>
				{isAdminViewer &&
					<Row>
						<Col xs={12} className="campaignDecoderConfigCol">
							<Row style={{ ...CommonStyle.formRow, marginLeft: 'auto' }}>
								<Field
									id="typeInput"
									name={'decoderType' + configurationId}
									type="select"
									component={SelectInput}
									label="Decoder Type"
									isClearable={false}
									placeholder="Select a Decoder"
									options={this.renderDecoderTypes()}
								/>
							</Row>
							<Row style={{ ...CommonStyle.formRow, marginLeft: 'auto' }}>
								<Col xs={9} style={{ paddingLeft: '0px' }} data-tip data-for="timeoutModification">
									<Field
										id="timeoutInput"
										name={'timeout'}
										type="text"
										component={FieldWrapper} label={'Decoder Timeout in Minutes'}
										validate={[validators.minTimeOut, validators.maxTimeOut]}
										placeholder={'Enter timeout value in minutes'}
										normalize={validators.onlyDecimal}
										{...configurationId !== 1 ? { input: { disabled: true } } : undefined}
									/>
									{configurationId !== 1 &&
										<ReactTooltip id="timeoutModification" place="right" effect="solid" delayShow={500}>
											Timeout can only be modified on Configuration 1
										</ReactTooltip>
									}
								</Col>
								<Col xs={3} style={{ marginTop: '33px' }}>
									<i>Default: 5 </i>
								</Col>
							</Row>
							<Row style={{ ...CommonStyle.formRow, marginLeft: 'auto' }}>
								<Col xs={9} style={{ paddingLeft: '0px' }}>
									<Field id="thresholdInput"
										label={'Threshold'}
										name={'threshold' + configurationId}
										type="text"
										component={FieldWrapper}
										validate={[validators.required, validators.charLimit50]}
										placeholder={'Enter Threshold'}
										normalize={validators.onlyDecimal}
										validate={[]}
									/>
								</Col>
								<Col xs={3} style={{ marginTop: '33px' }}>
									<i>Default: {defaultThreshold} </i>
								</Col>
							</Row>

							<font size="2"> <b>
								<ul style={{ paddingLeft: '20px', marginTop: '0px' }}>
									<i>
										<li>Minimum Theoretical Distance (for codeset): {getTheoreticalMinimum(this.props.campaign, 'codeset')} </li>
										<li>Minimum Theoretical Distance (as implemented): {getTheoreticalMinimum(this.props.campaign, 'selected')}</li>
									</i>
								</ul>
							</b> </font>
						</Col>
						<Col className="campaignDecoderConfigCol">
							<Row style={{ ...CommonStyle.formRow, marginLeft: 'auto' }}>
								<Col xs={9} style={{ paddingLeft: '0px' }}>
									<Field id="minFrameCtInput"
										name={'minFrameCt' + configurationId}
										type="text"
										component={FieldWrapper} label={'Minimum Frame Count'}
										validate={[validators.required, validators.charLimit50]}
										placeholder={'Enter Min. Frame Count'}
										normalize={validators.onlyNumbers}
										validate={[]}
									/>
								</Col>
								<Col xs={3} style={{ marginTop: '33px' }}>
									<i>Default: {defaultMinFrames} </i>
								</Col>
							</Row>
							{this.renderParameterSelect(this.props.decoderParameterTypes, selectedDecoderParameters[configurationId])}
						</Col>
					</Row>}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { workInProgress, currentCampaign } = state.campaign;
	const currentCampaignId = currentCampaign.id || 'newCampaign';
	if (workInProgress && currentCampaign && workInProgress[currentCampaignId]) {
		const { touchcodes } = workInProgress[currentCampaignId];
		currentCampaign.Touchcodes = touchcodes;
	}

	const decoderParameterConfigs = [];
	if (state.decoderType.decoderParameterTypes && state.decoderType.decoderParameterConfigs) {
		state.decoderType.decoderParameterTypes.forEach(t => {
			decoderParameterConfigs[t.id] = [];
		});
		state.decoderType.decoderParameterConfigs.forEach(c => {
			decoderParameterConfigs[c.Type][c.Id] = c;
		});
	}

	return {
		campaign: currentCampaign,
		codesets: state.codeset.codesets,
		permissions: state.userRole.permissions,
		decoderTypes: state.decoderType.decoderTypes,
		decoderParameterTypes: state.decoderType.decoderParameterTypes,
		decoderParameterConfigs: decoderParameterConfigs,
		formValues: getFormValues('campaignEdit')(state),
	};
}

export default connect(mapStateToProps, {
	fetchDecoderTypes,
	fetchDecoderParameterTypes,
	fetchDecoderParameterConfigs,
}, null, { forwardRef: true })(CampaignDecoderConfigEdit);
