import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchSettings } from '../../ducks/settings.duck';
import CommonStyle from '../../styles/commonStyles';
import PageHeading from './pageHeading';
import PageContainer from './pageContainer';
import Spinner from './spinner';

class Settings extends Component {
	static propTypes = {
		// Redux Store
		settings: PropTypes.object,
		// Wrapped Actions
		fetchSettings: PropTypes.func
	}

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.fetchSettings();
	}

	render() {
		const { settings } = this.props;

		return (
            <PageContainer>
				<PageHeading>Settings</PageHeading>
				{!settings && <Spinner />}
				{settings &&
                    <div style={{margin:'1%'}}>
						{settings.status === 'success' &&
							<div>
								<h4>Build Number</h4>
								<p>{settings.buildNumber}</p>
								<h4>Release Number</h4>
								<p>{settings.releaseNumber}</p>
							</div>
						}
						{settings.status === 'error' &&
							<h4 style={CommonStyle.textDanger}>
								Error fetching settings.
							</h4>
						}
					</div>
				}
            </PageContainer>
		);
	}
}

function mapStateToProps(state) {
	return {
		settings: state.settings
	};
}

export default connect(mapStateToProps, { fetchSettings })(Settings);
