import axios from 'axios';
import history from '../util/history';
import { toastr } from 'react-redux-toastr';
import { createActionName, BASE_URL } from './index';
import { routes } from '../util/constants';

// Actions
const DUCK_NAME = 'user';
const FETCH_USERS = createActionName(DUCK_NAME, 'FETCH_USERS');
const CLEAR_USERS = createActionName(DUCK_NAME, 'CLEAR_USERS');
const FETCH_USER = createActionName(DUCK_NAME, 'FETCH_USER');
const CREATE_USER = createActionName(DUCK_NAME, 'CREATE_USER');
const UPDATE_USER = createActionName(DUCK_NAME, 'UPDATE_USER');
const DELETE_USER = createActionName(DUCK_NAME, 'DELETE_USER');
const CLEAR_CURRENT_USER = createActionName(DUCK_NAME, 'CLEAR_CURRENT_USER');
const FETCH_ME = createActionName(DUCK_NAME, 'FETCH_ME');
const EMAIL_EXISTS = createActionName(DUCK_NAME, 'EMAIL_EXISTS');
const CLEAR_EMAIL_EXISTS = createActionName(DUCK_NAME, 'CLEAR_EMAIL_EXISTS');
// Reducer
export default function (state = {}, action = {}) {
	switch (action.type) {
		case FETCH_USERS: {
			return { ...state, users: action.payload.data };
		}
		case FETCH_USER: {
			return { ...state, currentUser: action.payload.data };
		}
		case FETCH_ME: {
			return { ...state, me: action.payload.data };
		}
		case EMAIL_EXISTS: {
			return { ...state, emailExists: action.payload.data || false };
		}
		case CREATE_USER: {
			return state;
		}
		case UPDATE_USER: {
			return state;
		}
		case DELETE_USER: {
			return state;
		}
		case CLEAR_CURRENT_USER: {
			return { ...state, currentUser: null };
		}
		case CLEAR_USERS: {
			return { ...state, users: undefined };
		}
		case CLEAR_EMAIL_EXISTS: {
			return { ...state, emailExists: undefined };
		}
		default:
			return state;
	}
}

// Action Creators
export function fetchUsers() {
	return dispatch => {
		axios.get(`${BASE_URL}/user`)
			.then(response => {
				dispatch({
					type: FETCH_USERS,
					payload: response
				});
			})
			.catch(err => {
				// TODO: Implement me
			});
	};
}

export function fetchUser(userId) {
	return dispatch => {
		axios.get(`${BASE_URL}/user/${userId}`)
			.then(response => {
				dispatch({
					type: FETCH_USER,
					payload: response
				});
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to find user.');
				}
			});
	};
}

export function fetchMe() {
	return dispatch => {
		axios.get(`${BASE_URL}/user/me`)
			.then(response => {
				dispatch({
					type: FETCH_ME,
					payload: response
				});
			})
			.catch(() => {
			});
	};
}

export function createUser(user, to) {
	return dispatch => {
		axios.post(`${BASE_URL}/user`, user)
			.then(response => {
				dispatch({
					type: CREATE_USER,
					payload: response
				});
				toastr.success('Success', 'User created');
				if (to) { history.push(to); }
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to create user.');
				}
			});
	};
}

export function emailExists(value) {
	if (value === false) {
		return ({
			type: EMAIL_EXISTS,
			payload: false
		});
	}
	return dispatch => {
		axios.post(`${BASE_URL}/user/emailExists`, { email: value })
			.then(response => {
				dispatch({
					type: EMAIL_EXISTS,
					payload: response
				});
			})
			.catch(err => {
				// TODO: Implement me
			});
	};
}

export function updateUser(user, userId, auth0Id, to) {
	return dispatch => {
		axios
			.put(`${BASE_URL}/user/${userId}`, { user, auth0Id })
			.then(response => {
				dispatch({
					type: UPDATE_USER,
					payload: response
				});
				toastr.success('Success', 'User updated');
				if (to) history.push(to);
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to update user.');
				}
			});
	};
}

export function deleteUser(userId, auth0Id, to) {
	const data = { Auth0Id: auth0Id };
	return dispatch => {
		axios
			.delete(`${BASE_URL}/user/${userId}`, { data })
			.then(response => {
				dispatch({
					type: DELETE_USER,
					payload: response
				});
				toastr.success('Success', 'User deleted');
				if (to) history.push(to);
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to delete user.');
				}
			});
	};
}

export function clearCurrentUser() {
	return {
		type: CLEAR_CURRENT_USER
	};
}

export function clearUsers() {
	return {
		type: CLEAR_USERS
	};
}

export function clearEmailExists() {
	return {
		type: CLEAR_EMAIL_EXISTS
	};
}
