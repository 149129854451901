import React, { Component } from 'react';
import { Field, touch } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import * as validators from '../../util/FormValidators';
import { formNames } from '../../util/constants';
import CommonStyle from '../../styles/commonStyles';
import TimeInputWrapper from '../utilities/timeInputWrapper';

const formName = formNames.ACTION_EDIT;

class ActionEditFormTime extends Component {
	constructor(props) {
		super(props);
	}

	touchStartTime() {
		this.props.dispatch(touch(formName, 'startTime'));
	}

	touchEndTime() {
		this.props.dispatch(touch(formName, 'endTime'));
	}

	render() {
		const bsResponsive = {
			xs: 12,
			sm: 6,
			md: 4,
			lg: 4
		};

		return (
			<div>
				<Row style={CommonStyle.formRow}>
					<Col {...bsResponsive}>
						<Field
							name="startTime"
							label="Start Time"
							touch={this.touchStartTime.bind(this)}
							placeholder="Select a Start Time"
							component={TimeInputWrapper}
							validate={[validators.required]}
						/>
					</Col>
				</Row>

				<Row style={CommonStyle.formRow}>
					<Col {...bsResponsive}>
						<Field
							name="endTime"
							label="End Time"
							touch={this.touchEndTime.bind(this)}
							placeholder="Select an End Time"
							component={TimeInputWrapper}
							validate={[validators.required]}
						/>
					</Col>
				</Row>
			</div>
		);
	}
}

export default ActionEditFormTime;
