
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Rodal from 'rodal';
import CommonStyle, { mergeStyles } from '../../styles/commonStyles';
import {
	importCodes,
	clearImportedCodes,
	importLabels
} from '../../ducks/touchcode.duck';
import {
	newCodeset,
	clearNewCodesetId,
	addCodeToCodeset
} from '../../ducks/codeset.duck';
import {
	importCustomers,
	clearImportedCustomers,
	fetchCustomers
} from '../../ducks/customer.duck';
import { setCustomerIdEnabled } from '../../ducks/auth.duck';
import Spinner from '../utilities/spinner';
import { toastr } from 'react-redux-toastr';
import CancelModal from '../utilities/cancelModal';

import 'rodal/lib/rodal.css';

const style = {
	modalStyle: {
		width: '50%',
		textAlign: 'left'
	},
	contentWrapper: {
		padding: '50px',
		maxWidth: '550px'
	},
	heading: {
		marginBottom: '20px'
	},
	btnRow: {
		marginTop: '35px'
	}
};

type Props = {
	formValues: Object,
	dispatch: Function,
	handleSubmit: Function,
	launchButton: PropTypes.element,
	newCodeset: Function,
	clearNewCodesetId: Function,
	setCustomerIdEnabled: Function,
	importCustomers: Function,
	clearImportedCustomers: Function,
	importCodes: Function,
	clearImportedCodes: Function,
	addCodeToCodeset: Function,
	importLabels: Function
};

export class CodesetImportModal extends Component {
	props: Props;

	constructor(props: Props) {
		super(props);

		this.state = {
			importing: false,
			visible: false
		};

		this.handleFileSelect = this.handleFileSelect.bind(this);
		this.handleCancelClick = this.handleCancelClick.bind(this);
		this.handleImportFileLoad = this.handleImportFileLoad.bind(this);
		this.handleImportedDataLink = this.handleImportedDataLink.bind(this);
		this.handleImportError = this.handleImportError.bind(this);
		this.handleImportClick = this.handleImportClick.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
	}

	handleFileSelect(event) {
		let file = document.getElementById('importFileInput').value;
		file = file.split('\\');
		file = file[file.length - 1];
		if (file === '') {
			document.getElementById('importDefaultFileText').style.display = 'block';
			document.getElementById('importFileText').style.display = 'none';
		} else {
			document.getElementById('importDefaultFileText').style.display = 'none';
			document.getElementById('importFileText').innerHTML = file;
			document.getElementById('importFileText').style.display = 'block';
		}
		this.forceUpdate();
		event.preventDefault();
	}

	handleCancelClick(event): void {
		this.hide();
		event.preventDefault();
	}

	handleImportFileLoad(e) {
		const content = e.target.result;
		// Do file reading/ importing
		try {
			let json = undefined;
			try {
				json = JSON.parse(content);
			} catch (error) {
				this.handleImportError('Invalid JSON Format', 'The codeset file you are trying to import is not valid json.');
				return;
			}
			if (!json || !json.exported_version || !json._id || !json.label || !json.default_decoding_config || !json.codes || !json.customers) {
				this.handleImportError('The imported codeset file is corrupted', 'The codeset file you are trying to import has been incorrectly modified. Please try again.');
				return;
			}
			if (json.exported_version > 1.001 ) {
				this.handleImportError('There is a version mis-match between environments', 'The codeset you are importing is unable to parse due to a dashboard environment version mis-match. Please contact the system Administrator.');
				return;
			}
			// Create new codeset for import
			const codeset = {
				Label: json.label,
				DefaultDecodingConfig: json.default_decoding_config
			};

			const customers = [];

			// Prep Import/Get Ids of Codes
			const codes = [];
			const customerIds = new Set();
			json.codes.forEach(c => {
				if (!c.pattern || !c.id) {
					this.handleImportError('The imported codeset file is corrupted', 'The codeset file you are trying to import has been incorrectly modified. Please try again.');
					return;
				}
				if (c.labels) { // Get a set of the needed customer ids so we only import customers used by this codeset
					c.labels.forEach(l => {
						if (l && l.customer_id) {
							customerIds.add(l.customer_id);
						}
					});
				}
				const code = {
					Pattern: c.pattern,
					isDeleted: c.is_deleted || false,
					isExperimental: c.is_experimental || true,
					oldId: c.id
				};
				codes.push(code);
			});


			// Prep Import of Needed Customers
			json.customers.forEach(c => {
				if (!c.name || !c.id) {
					this.handleImportError('The imported codeset file is corrupted', 'The codeset file you are trying to import has been incorrectly modified. Please try again.');
					return;
				}
				if (customerIds.has(c.id)) {
					const customer = {
						Name: c.name,
						isDeleted: c.is_deleted || false,
						oldId: c.id
					};
					customers.push(customer);
				}
			});

			this.props.newCodeset(codeset);
			this.props.importCustomers(customers);
			this.props.importCodes(codes);

			this.handleImportedDataLink(json);
		} catch (error) {
			this.handleImportError('Unexpected Error', 'An unexpected error has occurred.');
		}
	}

	handleImportedDataLink(json) {
		const { handleSubmit, newCodesetId, importedCustomers, importedCodes } = this.props;
		try {
			if (newCodesetId && importedCustomers && importedCodes) {
				// Add codes to codeset
				importedCodes.forEach(c => {
					this.props.addCodeToCodeset(c.Id, newCodesetId);
				});

				// Add labels to codes
				const labels = [];
				json.codes.forEach(code => {
					if (code.labels) {
						let newCodeId = code.id;
						importedCodes.forEach(importedCode => {
							if (importedCode.oldId === code.id) {
								newCodeId = importedCode.Id;
							}
						});
						code.labels.forEach(l => {
							if (l.label && l.customer_id) {
								const entry = {
									CustomerId: undefined,
									CodeId: newCodeId,
									Label: l.label
								};
								importedCustomers.forEach(importedCustomer => {
									if (importedCustomer.oldId === l.customer_id) {
										entry.CustomerId = importedCustomer.Id;
									}
								});
								labels.push(entry);
							}
						});
					}
				});
				this.props.importLabels(labels);
				this.props.fetchCustomers();
				this.hide();
				this.setState({ importing: false });
				if (handleSubmit) setTimeout(handleSubmit, 1000);

				toastr.success('Success', 'Codeset Imported');
			} else {
				setTimeout(this.handleImportedDataLink, 500, json);
			}
		} catch (error) {
			this.handleImportError('Unexpected Error', 'An unexpected error has occurred.');
		}
	}

	handleImportError(title, message) {
		const { handleSubmit } = this.props;
		this.setState({ importing: false });
		this.hide();
		if (handleSubmit) setTimeout(handleSubmit, 1000);

		toastr.error(title, message, { timeOut: 6000 });
	}

	handleImportClick(event) {
		if (window.File && window.FileReader && window.FileList && window.Blob) {
			const file = document.getElementById('importFileInput').files[0];
			if (file) {
				const reader = new FileReader();
				reader.onload = this.handleImportFileLoad;
				reader.readAsText(file);
				this.setState({ importing: true });
			}
		}
		event.preventDefault();
	}

	handleKeyPress() {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	}

	show() {
		this.setState({ visible: true });
	}

	hide() {
		this.props.clearNewCodesetId();
		this.props.clearImportedCustomers();
		this.props.clearImportedCodes();
		this.setState({ visible: false });
	}

	render() {
		const { launchButton } = this.props;
		const { importing, visible } = this.state;

		let valid = false;
		if (document.getElementById('importFileInput') && document.getElementById('importFileInput').files[0]) {
			valid = true;
		}

		return (
			<span onKeyPress={this.handleKeyPress}>
				{launchButton ? <span onClick={this.show}>{launchButton}</span> : <button onClick={this.show} style={mergeStyles(CommonStyle.btn, { backgroundColor: '#FFE599', borderColor: '#070707', color: '#070707' })} className="btn btn-primary" ><span> Import Codeset </span></button>}
				<Rodal
					visible={visible}
					height={300}
					showCloseButton={false}
					onClose={this.hide}
					customStyles={style.modalStyle}
				>
					{importing && <div style={mergeStyles(style.contentWrapper, { width: '180px', margin: 'auto' })}> <Spinner/> </div> }
					{!importing &&
					<div style={style.contentWrapper}>
						 <div style={{ textAlign: 'left', marginLeft: '-15px' }}>
							<h3 style={mergeStyles(style.heading)}>
								Import Codeset From:
							</h3>
						</div>
						<Row style={{ textOverflow: 'ellipsis' }}>
							<div style={{ float: 'left', width: '70%', height: '34px', border: 'black solid 2px', borderRight: 'none' }}>
								<div style={{ margin: '6px 0px 6px 5px' }}>
									<span id="importDefaultFileText"><i>Choose json File...</i></span>
									<span id="importFileText"/>
								</div>
							</div>
							<label style={{ backgroundColor: '#999999', border: 'black solid 2px', height: '34px', width: '30%', cursor: 'pointer' }}>
								<div style={{ textAlign: 'center', margin: '5px 0px 5px 0px' }}>
									Browse...
								</div>
								<input id="importFileInput"type="file" style={{ display: 'none' }} onChange={this.handleFileSelect}/>
							</label>
						</Row>
						<Row style={{ width: '190px', margin: '70px -32px 0px auto' }}>
							<Col xs={12} style={{ width: '85px' }}>
								<CancelModal
									initialValues={{ valid: false }}
									currentValues={{ valid: valid }}
									onCancel={this.handleCancelClick}
								/>
							</Col>
							<Col xs={12} style={{ width: '85px' }}>
								<button style={mergeStyles(CommonStyle.btn,  { backgroundColor: '#FFE599', borderColor: '#070707', color: '#070707' })} type="submit" className="btn btn-primary" onClick={this.handleImportClick} disabled={!valid}>
									<span>Import</span>
								</button>
							</Col>
						</Row>
					</div> }
				</Rodal>
			</span>
		);
	}
}
function mapStateToProps(state) {
	return ({
		newCodesetId: state.codeset.newCodesetId,
		importedCustomers: state.customer.importedCustomers,
		importedCodes: state.touchcode.importedTouchcodes
	});
}

export default connect(mapStateToProps, {
	importCodes,
	clearImportedCodes,
	newCodeset,
	clearNewCodesetId,
	setCustomerIdEnabled,
	importCustomers,
	clearImportedCustomers,
	addCodeToCodeset,
	importLabels,
	fetchCustomers
})(CodesetImportModal);
