import { createActionName, BASE_URL } from './index';
import history from '../util/history';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { LS, routes } from '../util/constants';

// Actions
const DUCK_NAME = 'auth';
// Export this to be used in index.js
export const AUTH_USER = createActionName(DUCK_NAME, 'AUTH_USER');
export const UNAUTH_USER = createActionName(DUCK_NAME, 'UNAUTH_USER');
const AUTH_ERROR = createActionName(DUCK_NAME, 'AUTH_ERROR');
const RESET_ERROR = createActionName(DUCK_NAME, 'RESET_ERROR');
const FORGOT_ERROR = createActionName(DUCK_NAME, 'FORGOT_ERROR');
const SET_TOKEN_VALIDITY = createActionName(DUCK_NAME, 'SET_TOKEN_VALIDITY');
const SET_CUSTOMER_ID = createActionName(DUCK_NAME, 'SET_CUSTOMER_ID');
const SET_CUSTOMER_ID_ENABLED = createActionName(DUCK_NAME, 'SET_CUSTOMER_ID_ENABLED');
const SET_REFRESH_ACTIONS = createActionName(DUCK_NAME, 'SET_REFRESH_ACTIONS');
const FETCH_AUTH0_DATA = createActionName(DUCK_NAME, 'FETCH_AUTH0_DATA');
const SET_AUTH_OBJ = createActionName(DUCK_NAME, 'SET_AUTH_OBJ');
const SET_AUTH0_SESSION = createActionName(DUCK_NAME, 'SET_AUTH0_SESSION');

const initialState = {
	customerIdEnabled: true,
	refreshActions: []
};

// Reducer
export default (state = initialState, action = {}) => {
	switch (action.type) {
		case AUTH_USER: {
			return { ...state, error: '', authenticated: true, userType: action.userType };
		}
		case UNAUTH_USER: {
			return { ...state, authenticated: false, userType: null };
		}
		case AUTH_ERROR: {
			return { ...state, error: action.payload };
		}
		case RESET_ERROR: {
			return { ...state, resetError: action.message };
		}
		case FORGOT_ERROR: {
			return { ...state, forgotError: action.message };
		}
		case FETCH_AUTH0_DATA: {
			return {
				...state,
				auth0_domain: action.AUTH0_DOMAIN,
				auth0_client_ID: action.AUTH0_CLIENT_ID,
				auth0_callback_URL: action.AUTH0_CALLBACK_URL,
				auth0_audience: action.AUTH0_AUDIENCE
			};
		}
		case SET_TOKEN_VALIDITY: {
			return { ...state, tokenError: action.tokenError };
		}
		case SET_CUSTOMER_ID: {
			return { ...state, customerId: action.value };
		}
		case SET_CUSTOMER_ID_ENABLED: {
			return { ...state, customerIdEnabled: action.value };
		}
		case SET_REFRESH_ACTIONS: {
			return { ...state, refreshActions: action.refreshActions };
		}
		case SET_AUTH_OBJ: {
			return { ...state, authObj: action.authObj };
		}
		case SET_AUTH0_SESSION: {
			return { ...state, sessionObj: action.sessionObj };
		}
		default:
			return state;
	}
};

// Action Creators
export function authError(error) {
	return {
		type: AUTH_ERROR,
		payload: error
	};
}

export function pingDW() {
	return () => {
		// axios.post(`${BASE_URL}/auth/pingdw`, { })
		// 	.catch(() => {
		// 		dispatch(authError('Unable to start DW'));
		// 	});
	};
}

// no longer need this function but keeping it incase we need it in the future
export function fetchAuth0Data(callback) {
	return dispatch => {
		axios.get('api/v1/auth0/auth0-data')
			.then(response => {
				const { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_CALLBACK_URL, AUTH0_AUDIENCE } = response.data;
				dispatch({
					type: FETCH_AUTH0_DATA,
					status: 'success',
					AUTH0_DOMAIN,
					AUTH0_CLIENT_ID,
					AUTH0_CALLBACK_URL,
					AUTH0_AUDIENCE
				});
				callback.bind(this)();
			})
			.catch(() => {
				history.push(routes.authExpired);
				dispatch({
					type: FETCH_AUTH0_DATA,
					status: 'error'
				});
			});
	};
}

export function resetPasswordAsAdmin(Email) {
	return dispatch => {
		axios.post(`${BASE_URL}/auth/resetPassword`, { Email })
			.then(_response => {
				toastr.success('Success', `You have successfully sent a Reset Password link to ${Email}`);
			})
			.catch(err => {
				toastr.error('Password reset error', err.response.data);
				dispatch({ type: FORGOT_ERROR, message: err.response.data });
			});
	};
}

export function signOut() {
	localStorage.removeItem(LS.TOKEN);
	localStorage.removeItem(LS.USER_TYPE);
	axios.interceptors.request.handlers = [];

	return { type: UNAUTH_USER };
}

export function setCustomerId(value) {
	// Reset handlers because request.use() will just
	// append the new handler, not replace the old one
	axios.interceptors.request.handlers = [];
	axios.interceptors.request.use(config => {
		config.params = { CustomerId: value };
		return config;
	}, err => {
		return Promise.reject(err);
	});

	return {
		type: SET_CUSTOMER_ID,
		value
	};
}

export function setCustomerIdEnabled(value) {
	return {
		type: SET_CUSTOMER_ID_ENABLED,
		value
	};
}

export function setRefreshActions(refreshActions) {
	return {
		type: SET_REFRESH_ACTIONS,
		refreshActions
	};
}

/**
 * Sets the global auth object. Contains all authorization functions
 * @param {*} authObj
 */
export function setGlobalAuthObj(authObj) {
	return {
		type: SET_AUTH_OBJ,
		authObj
	};
}

/**
 * Stores our auth0 session object when set
 * @param {*} sessionObj
 */
export function setAuth0Session(sessionObj) {
	return {
		type: SET_AUTH0_SESSION,
		sessionObj
	};
}
