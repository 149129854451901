// @flow

export default class Time {
	value: string;

	constructor(value: string) {
		const valid = this.validate(value);
		this.value = valid ? value : '12:00am';
	}

	validate(value: string): boolean {
		if (value == null) {
			return false;
		}

		if (!value.match(/\d{2}:\d{2}(am|pm)/)) {
			return false;
		}

		const hour = parseInt(value.substring(0, 2), 10);
		const minute = parseInt(value.substring(4, 6), 10);

		if (hour < 1 || hour > 12) {
			return false;
		}

		if (minute < 0 || minute > 59) {
			return false;
		}

		return true;
	}

	// Default mod operator doesn't handle negative values as intended
	safeMod(n: number, m: number): number {
		return ((n % m) + m) % m;
	}

	padLeft(value: number): string {
		return String(value).length === 1 ? `0${value}` : `${value}`;
	}

	hour(value?: number): string {
		if (value != null) {
			if (value < 1 || value > 12) {
				throw new Error('Invalid value for hour.');
			}
			this.value = this.padLeft(value) + this.value.substring(2);
			return this.value;
		}
		return this.value.substring(0, 2);
	}

	addHour(value: number): string {
		const hour = parseInt(this.hour(), 10);
		const newHour = this.safeMod((hour + value), 12);

		if (newHour === 0) {
			return this.hour(12);
		}

		return this.hour(newHour);
	}

	subtractHour(value: number): string {
		return this.addHour(value * -1);
	}

	minute(value?: number): string {
		if (value != null) {
			if (value < 0 || value > 59) {
				throw new Error('Invalid value for minute.');
			}
			this.value = this.hour() + ':' + this.padLeft(value) + this.value.substring(5);
			return this.value;
		}

		return this.value.substring(3, 5);
	}

	addMinute(value: number): string {
		const minute = parseInt(this.minute(), 10);
		const newMinute = this.safeMod((minute + value), 60);

		return this.minute(newMinute);
	}

	subtractMinute(value: number): string {
		return this.addMinute(value * -1);
	}

	meridiem(value?: string): string {
		if (value != null) {
			if (!value.match(/(am|pm)/)) {
				throw new Error('Invalid value for meridiem.');
			}

			this.value = this.value.substring(0, 5) + value;
			return this.value;
		}

		return this.value.substring(5, 7);
	}

	flipMeridiem(): string {
		if (this.meridiem() === 'am') {
			return this.meridiem('pm');
		}

		return this.meridiem('am');
	}

	toString(): string {
		return this.value;
	}
}
