import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

const style = {
	input: {
		width: '300px',
		minWidth: '200px'
	}
};

export default class SearchBox extends React.Component {
	static propTypes = {
		placeholder: PropTypes.string,
		onPlacesChanged: PropTypes.func
	}

	handleKeyDown(event) {
		if (event.keyCode === 13) {
			event.preventDefault();
			event.stopPropagation();
		}
	}

	render() {
		return (
			<div>
				<label>Search</label>
					<input
						onKeyDown={this.handleKeyDown.bind(this)}
						style={style.input}
						className="form-control"
						ref="input"
						placeholder={this.props.placeholder}
						type="text"
					/>
			</div>
		);
	}

	onPlacesChanged = () => {
		if (this.props.onPlacesChanged) {
			this.props.onPlacesChanged(this.searchBox.getPlaces());
		}
	}

	componentDidMount() {
		const input = ReactDOM.findDOMNode(this.refs.input);
		this.searchBox = new google.maps.places.SearchBox(input);
		this.searchBoxListener = this.searchBox.addListener('places_changed', this.onPlacesChanged);
	}

	componentWillUnmount() {
		google.maps.event.removeListener(this.searchBoxListener);
	}
}
