import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select, { components } from 'react-select';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import { Icon } from 'semantic-ui-react';
import _ from 'lodash';
import { fetchCampaignsInDw } from '../../ducks/analytics.duck';
import { fetchCustomers } from '../../ducks/customer.duck';
import { setCustomerId, setRefreshActions } from '../../ducks/auth.duck';
import { fetchMe } from '../../ducks/user.duck.js';
import { fetchPermissions } from '../../ducks/userRole.duck.js';
import { signOut } from '../../ducks/auth.duck';
import { getCampaignsForCustomer } from '../../selectors/analytics';
import AnalyticsExportButton from '../analytics/analyticsExportButton';
import { statusOptions } from '../../util/constants';

const customSelectStyles = {
	control: provided => ({
		...provided,
		background: 'none',
		border: 'none',
		color: 'white'
	}),
	singleValue: provided => ({
		...provided,
		color: 'white'
	}),
	placeholder: provided => ({
		...provided,
		color: 'white'
	})
};

const CampaignOption = ({ innerProps, ...props }) => (
	<components.Option {...props} innerProps={{ ...innerProps, 'data-campaign-state': props.data.state }} />
);

class TopNavbar extends Component {
	static propTypes = {
		// From Parent
		location: PropTypes.object,
		// Redux Store
		customerId: PropTypes.number,
		customerEnabled: PropTypes.bool,
		userInterface: PropTypes.object,
		// Wrapped Actions
		fetchCustomers: PropTypes.func,
		setCustomerId: PropTypes.func,
	}

	constructor(props) {
		super(props);
		this.props.fetchPermissions();
		this.state = {};

		this.handleCustomerChange = this.handleCustomerChange.bind(this);
	}

	componentDidMount() {
		this.props.fetchMe();
		this.props.fetchCampaignsInDw();
	}

	componentDidUpdate(prevProps) {
		const { campaigns, customerId, selectedCampaignOption, customers, permissions } = this.props;

		if (!customers && permissions && permissions.AdminView) {
			this.props.fetchCustomers();
		}

		// if the newly selected customer doesn't own the selected campaign, deselect the campaign
		if (prevProps.customerId !== customerId && !!selectedCampaignOption) {
			if (!campaigns.map(c => c.CampaignKey).includes(selectedCampaignOption)) {
				this.props.handleCampaignChange(null);
			}
		}
	}

	handleCustomerChange(value) {
		this.props.setCustomerId(value ? value.value : null);
		this.props.refreshActions.forEach(refreshAction => this.props.dispatch(refreshAction()));
	}

	renderCampaignOptions() {
		const { campaigns } = this.props;
		const options = campaigns && campaigns.map(campaign => {
			return { label: `${campaign.Name}`, value: `${campaign.CampaignKey}`, state: statusOptions[`${campaign.CampaignStateId}`] };
		});
		return _.sortBy(options, i => { return i.label.toLowerCase(); });
	}

	render() {
		const { permissions, customers, customerId, customerEnabled, campaigns, location: { pathname } } = this.props;
		const isAdminViewer = permissions ? permissions.AdminView : null;
		const customerOptions = isAdminViewer && customers ? customers.map(c => ({ label: c.Name, value: c.Id })) : [];
		const selectedCustomerOption = isAdminViewer && customers && customerId ? customerOptions.find(option => option.value === customerId) : null;

		const campaignOptions = this.renderCampaignOptions();
		const fullSelectedCampaignOption = campaignOptions.find((option) => option.value === this.props.selectedCampaignOption) ?? null;

		return (
			<div >
				<Navbar fluid className="topNav">
					<Navbar.Brand >
						<Icon
							name="sidebar"
							data-cy="toggle-sidebar"
							onClick={this.props.handleMenuClick.bind(this)}
						/>
					</Navbar.Brand>
					<Nav bsStyle="pills" pullLeft>
						<NavItem className="right-align">
							{campaigns && /analytics./i.test(pathname)
								? <div className="top-nav-selector">
									<Select
										className="campaign-selection"
										placeholder="Select Campaign"
										value={fullSelectedCampaignOption}
										options={campaignOptions}
										onChange={this.props.handleCampaignChange}
										styles={customSelectStyles}
										components={{ Option: CampaignOption }}
										isClearable
									/>
								</div>
								: <div />
							}
						</NavItem>
						<NavItem>
							{isAdminViewer && customers &&
								<div className="top-nav-selector">
									<Select
										className="viewForCustomerSelect"
										placeholder="Select Customer"
										isDisabled={!customerEnabled}
										value={selectedCustomerOption}
										width="200px"
										onChange={this.handleCustomerChange}
										options={customerOptions}
										styles={customSelectStyles}
										isClearable
									/>
								</div>
							}
						</NavItem>
					</Nav>
					{isAdminViewer && <AnalyticsExportButton location={this.props.location} />}
				</Navbar>
			</div >
		);
	}
}

function mapStateToProps(state) {
	return {
		customers: state.customer.customers,
		customerId: state.auth.customerId,
		customerEnabled: state.auth.customerIdEnabled,
		refreshActions: state.auth.refreshActions,
		currentUser: state.user.me,
		permissions: state.userRole.permissions,
		campaigns: getCampaignsForCustomer(state),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		...bindActionCreators({
			fetchCampaignsInDw,
			fetchCustomers,
			fetchMe,
			fetchPermissions,
			setCustomerId,
			setRefreshActions,
			signOut
		}, dispatch)
	};
}

TopNavbar = connect(mapStateToProps, mapDispatchToProps)(TopNavbar);

export default TopNavbar;
