import React, { Component } from 'react';
import { FaGlobe, FaCircle, FaMobile } from 'react-icons/lib/fa';
import CommonStyle from '../../styles/commonStyles.js';
import { routes } from '../../util/constants.js';
import ReactTooltip from 'react-tooltip';
import IconBtn from '../utilities/iconBtn';
import _ from 'lodash';
import PauseIconBtnModal from '../utilities/pauseIconBtnModal';
import ArchiveIconBtnModal from '../utilities/archiveIconBtnModal';
import ResumeIconBtnModal from '../utilities/resumeIconBtnModal';
import ReactivateIconBtnModal from '../utilities/reactivateIconBtnModal';
import { statusOptionsByName } from '../../util/constants';
import { createStateUpdateParam } from '../../util/campaignUtil';
import history from '../../util/history';
import moment from 'moment-timezone';
import { EDIT_CAMPAIGN_REACTIVATE_ACTION } from '../../util/constants.js';

const style = {
	active: {
		color: '#33B643'
	},
	paused: {
		color: '#D89224'
	},
	upcoming: {
		color: '#AF84CA'
	},
	archived: {
		color: '#5C3C0A'
	},
	savedDraft: {
		color: '#18289F'
	},
	deleted: {
		color: '#ff0000'
	},
	default: {
		color: 'black'
	},
	bold: {
		fontWeight: 'bold'
	},
	iconBtn: {
		fontSize: '14px',
		padding: '2px 6px',
		margin: '0px 5px'
	},
	hidden: {
		visibility: 'hidden'
	},
	advancedButton: {
		color: 'white',
		backgroundColor: '#093864',
		height: '27px',
		padding: '3px 10px',
	}
};


class CondensedCampaignIcons extends Component {
	constructor(props) {
		super(props);

		this.iconBtn = this.iconBtn.bind(this);

		this.state = { toggled: false };
	}

	iconBtn(icon, handler, key, tooltip) {
		return (
			<span key={key} data-tip data-for={key}>
				<IconBtn
					key={key}
					handler={handler}
					icon={icon}
				/>
				<ReactTooltip id={key} effect="solid" delayShow={500}>
					<div>{tooltip}</div>
				</ReactTooltip>
			</span>
		);
	}

	archiveIconBtn(campaignId, name, key) {
		return (
			<span data-tip data-for="archive" key={key}>
				<ArchiveIconBtnModal
					campaignId={campaignId}
					name={name}
					key={key}
					handler={this.handleArchiveClick.bind(this, campaignId)}
				/>
				<ReactTooltip id="archive" effect="solid" delayShow={500}>
					<div>The Campaign will no longer be available to users but can be reactivated at any time and the status will change to "Archived."</div>
				</ReactTooltip>
			</span>
		);
	}

	pauseIconBtn(campaignId, name, key) {
		return (
			<span data-tip data-for="pause" key={key}>
				<PauseIconBtnModal
					campaignId={campaignId}
					name={name}
					key={key}
					handler={this.handlePauseClick.bind(this, campaignId)}
				/>
				<ReactTooltip id="pause" effect="solid" delayShow={500}>
					<div>The Campaign will no longer be available to users but can be resumed at any time and the status will change to "Paused."</div>
				</ReactTooltip>
			</span>
		);
	}

	resumeIconBtn(campaignId, name, key) {
		return (
			<span data-tip data-for="resume" key={key}>
				<ResumeIconBtnModal
					campaignId={campaignId}
					name={name}
					key={key}
					handler={this.handleResumeClick.bind(this, campaignId)}
				/>
				<ReactTooltip id="resume" effect="solid" delayShow={500}>
					<div>The Campaign will immediately become available to users and the status will change to "Active."</div>
				</ReactTooltip>
			</span>
		);
	}

	repeatIconBtn(campaignId, name, key) {
		return (
			<span data-tip data-for="reactivate" key={key}>
				<ReactivateIconBtnModal
					campaignId={campaignId}
					name={name}
					key={key}
					handler={this.handleReactivateClick.bind(this, campaignId)}
				/>
				<ReactTooltip id="reactivate" effect="solid" delayShow={500}>
					<div>The Campaign will immediately become available to users and the status will change to "Active."</div>
				</ReactTooltip>
			</span>
		);
	}

	handlePauseClick(campaignId) {
		const param = createStateUpdateParam(statusOptionsByName.paused);
		this.props.amendCampaign(param, campaignId, null, this.props.reloadCampaigns);
		ReactTooltip.hide();
	}

	handleResumeClick(campaignId) {
		const param = createStateUpdateParam(statusOptionsByName.active);
		this.props.amendCampaign(param, campaignId, null, this.props.reloadCampaigns);
		ReactTooltip.hide();
	}

	handleArchiveClick(campaignId) {
		const param = createStateUpdateParam(statusOptionsByName.archived);
		this.props.amendCampaign(param, campaignId, null, this.props.reloadCampaigns);
		ReactTooltip.hide();
	}

	handleReactivateClick(campaignId) {
		const campaign = _.find(this.props.campaigns, c => c.Id === campaignId);

		if (!campaign.EndDate || moment().utc().isSameOrAfter(campaign.EndDate)) {
			const param = createStateUpdateParam(statusOptionsByName.saved_draft);
			this.props.amendCampaign(param, campaignId, `${routes.campaignEdit}/${campaignId}/${EDIT_CAMPAIGN_REACTIVATE_ACTION}`);
		} else {
			const param = createStateUpdateParam(statusOptionsByName.active);
			this.props.amendCampaign(param, campaignId, null, this.props.reloadCampaigns);
		}
		ReactTooltip.hide();
	}

	handleAnalyticsClick(campaignId) {
		history.push(`${routes.analytics}/${campaignId}`);
	}

	handleDemoCreationClick(campaignId) {
		const { resourceGroup } = this.props;
		window.open(`https://touchcode-${resourceGroup}-example.azurewebsites.net/phone.html?campaignId=${campaignId}`);
	}

	handleEditClick(campaignId) {
		history.push(`${routes.campaignEdit}/${campaignId}`);
	}

	hiddenIconBtn(key) {
		return (
			<button key={key} className="btn btn-default" style={{ ...style.hidden, ...style.iconBtn }}><FaCircle /></button>
		);
	}

	show(event) {
		event.preventDefault();
		this.setState({ toggled: true });
	}

	render() {
		const isCampaignModifier = this.props.permissions ? this.props.permissions.CampaignModifies : null;
		const isAnalyticsViewer = this.props.permissions ? this.props.permissions.AnalyticsViews : null;
		const demoTooltip = 'A demo webapp for this campaign will be opened in a new tab.';
		const analyticsTooltip = 'View Analytics Page.';

		const campaignId = this.props.Id;
		const cell = this.props.cell.Name;
		const name = this.props.name;
		const toggled = this.state.toggled;
		const icons = [];

		if (toggled) {
			switch (cell) {
				case 'Active':
					if (isAnalyticsViewer || isCampaignModifier) {
						icons.push(this.iconBtn(<FaGlobe />, this.handleAnalyticsClick.bind(this, campaignId), '1', analyticsTooltip));
						icons.push(this.iconBtn(<FaMobile />, this.handleDemoCreationClick.bind(this, campaignId), '5', demoTooltip));
					}
					if (isCampaignModifier) {
						icons.push(this.pauseIconBtn(campaignId, name, '3', this));
					}
					break;
				case 'Paused':
					icons.push(this.hiddenIconBtn('5'));
					if (isAnalyticsViewer) {
						icons.push(this.iconBtn(<FaGlobe />, this.handleAnalyticsClick.bind(this, campaignId), '1', analyticsTooltip));
					}
					if (isCampaignModifier) {
						icons.push(this.resumeIconBtn(campaignId, name, '3'));
						icons.push(this.hiddenIconBtn('4'));
					}
					break;
				case 'Archived':
					if (isAnalyticsViewer) {
						icons.push(this.iconBtn(<FaGlobe />, this.handleAnalyticsClick.bind(this, campaignId), '1', analyticsTooltip));
					}
					if (isCampaignModifier) {
						icons.push(this.hiddenIconBtn('3'));
						icons.push(this.repeatIconBtn(campaignId, name, '4'));
					}
					break;
				case 'Upcoming':
					icons.push(this.hiddenIconBtn('1'));
					icons.push(this.hiddenIconBtn('2'));
					icons.push(this.hiddenIconBtn('3'));
					icons.push(this.hiddenIconBtn('4'));
					icons.push(this.hiddenIconBtn('5'));
					break;
				default:
					break;
			}
		} else {
			icons.push(<button className="btn btn-default" key="advanced" style={style.advancedButton} onClick={this.show.bind(this)}>Advanced</button>);
		}

		return (
			<span style={CommonStyle.floatRight} key={campaignId}>
				{icons}
			</span>
		);
	}
}

export default CondensedCampaignIcons;
