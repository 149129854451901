import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field, reduxForm, isValid, getFormValues, touch } from 'redux-form';
import { FaPencil, FaTimesCircle, FaCheck, FaPlusCircle } from 'react-icons/lib/fa';
import Rodal from 'rodal';
import CommonStyle, { mergeStyles } from '../../styles/commonStyles';
import { formNames } from '../../util/constants';
import * as validators from '../../util/FormValidators';
import FieldWrapper from '../utilities/fieldWrapper';
import IconBtn from '../utilities/iconBtn';
import ConfirmModal from '../utilities/confirmModal';
import Spinner from '../utilities/spinner';
import {
	fetchCustomers,
	fetchCustomer,
	createCustomer,
	updateCustomer,
	deleteCustomer,
	clearCurrentCustomer
} from '../../ducks/customer.duck';
import CancelModal from '../utilities/cancelModal';

import 'rodal/lib/rodal.css';

const formName = formNames.CUSTOMER_EDIT;
const style = {
	modalStyle: {
		width: '60%'
	},
	contentWrapper: {
		padding: '50px'
	},
	heading: {
		marginBottom: '20px'
	},
	btnRow: {
		marginTop: '35px'
	}
};

export class CustomerEditModal extends Component {
	static propTypes = {
		// From parents
		isNew: PropTypes.bool,
		autoOpen: PropTypes.bool,
		returnLocation: PropTypes.string,
		CustomerId: PropTypes.string,
		// Redux Form
		handleSubmit: PropTypes.func,
		// Redux Store
		customer: PropTypes.object,
		formValues: PropTypes.object,
		// Wrapped Actions
		dispatch: PropTypes.func,
		createCustomer: PropTypes.func,
		fetchCustomer: PropTypes.func,
		fetchCustomers: PropTypes.func,
		updateCustomer: PropTypes.func,
		deleteCustomer: PropTypes.func,
		clearCurrentCustomer: PropTypes.func
	};

	constructor(props) {
		super(props);

		this.state = {
			visible: false
		};

		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.handleCancelClick = this.handleCancelClick.bind(this);
		this.handleDeleteClick = this.handleDeleteClick.bind(this);
		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
	}

	componentDidMount() {
		if (this.props.autoOpen) {
			this.show();
		}
	}

	handleFormSubmit(form) {
		const { customerId, isNew } = this.props;
		const { name, modified } = form;
		// const to = returnLocation ? `${routes.campaignEdit}/${returnLocation}` : null;

		const newCustomer = {
			Name: name,
			Modified: modified
		};

		if (isNew) {
			this.props.createCustomer(newCustomer);
		} else {
			this.props.updateCustomer(newCustomer, customerId);
		}

		this.hide();
	}

	handleDeleteClick(event) {
		this.props.deleteCustomer(this.props.customerId);
		if (event) {
			event.preventDefault();
		}
		this.hide();
	}

	handleCancelClick(event) {
		if (event) {
			event.preventDefault();
		}

		this.hide();
	}

	handleKeyPress(event) {
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();

			if (this.props.isValid) {
				this.handleFormSubmit(this.props.formValues);
			} else {
				const { dispatch } = this.props;
				dispatch(touch(formName, 'name'));
				dispatch(touch(formName, 'modified'));
			}
		}
	}

	show() {
		const { customerId, isNew } = this.props;
		if (!isNew) {
			this.props.fetchCustomer(customerId);
		}
		this.setState({ visible: true });
	}

	hide() {
		this.props.reset();
		this.props.clearCurrentCustomer();
		this.props.fetchCustomers();
		this.setState({ visible: false });
	}

	render() {
		const { handleSubmit, customer, isNew, initialValues, formValues } = this.props;
		const { visible } = this.state;

		const bsResponsive = {
			xs: 12,
			md: 6
		};

		return (
			<span onKeyPress={this.handleKeyPress}>
				{isNew &&
					<button className="btn btn-primary" onClick={this.show}><FaPlusCircle /> New Customer</button>
				}
				{!isNew &&
					<IconBtn icon={<FaPencil />} handler={this.show} />
				}
				<Rodal
					visible={visible}
					height={300}
					showCloseButton={false}
					onClose={this.hide}
					customStyles={style.modalStyle}
				>
					<div style={style.contentWrapper}>
						<h3 style={mergeStyles(style.heading, CommonStyle.textLeft)}>
							{isNew && 'Create Customer'}
							{!isNew && 'Edit Customer'}
						</h3>

						<form onSubmit={handleSubmit(this.handleFormSubmit)}>
							{((!isNew && customer) || isNew) &&
								<div>
									<Row style={CommonStyle.textLeft}>
										<Col {...bsResponsive}>
											<Field name="name" type="text" placeholder="Customer Name"
												component={FieldWrapper} label="Name"
												validate={[validators.required]}
											/>
										</Col>
									</Row>

									<Row style={style.btnRow}>
										<Col xs={12}>
											<div style={CommonStyle.splitSection}>
												<div style={CommonStyle.clipSection}>
													{!isNew &&
														<ConfirmModal
															title="Delete"
															body="You are about to delete this customer. Are you sure?"
															closeBtnText="No"
															launchBtn={
																<button style={CommonStyle.btn} className="btn btn-danger">
																	<FaTimesCircle /> Delete
																</button>
															}
															confirmBtn={
																<button style={CommonStyle.btn} className="btn btn-danger" onClick={this.handleDeleteClick}>
																	Yes
																</button>
															}
															action={this.handleDeleteClick}
														/>
													}
												</div>
												<div>
													<CancelModal
														initialValues={initialValues}
														currentValues={formValues}
														onCancel={this.handleCancelClick}
													/>
													<button style={CommonStyle.btn} type="submit" className="btn btn-primary">
														{customer ? (
															<span><FaCheck /> Save</span>
														) : (
															<span>Create Customer</span>
														)}
													</button>
												</div>
											</div>
										</Col>
									</Row>
								</div>
							}
							{!isNew && !customer &&
								<div style={CommonStyle.textCenter}>
									<Spinner />
								</div>
							}
						</form>
					</div>
				</Rodal>
			</span>
		);
	}
}

function mapStateToProps(state) {
	const { currentCustomer } = state.customer;

	let initialValues;

	if (currentCustomer != null) {
		const { Name, Modified } = currentCustomer;
		initialValues = {
			'name': Name,
			'modified': Modified
		};
	}

	return {
		customer: currentCustomer,
		initialValues: initialValues,
		formValues: getFormValues(formName)(state),
		isValid: isValid(formName)(state)
	};
}

CustomerEditModal = reduxForm({
	form: formName,
	enableReinitialize: true
})(CustomerEditModal);

export default connect(mapStateToProps, {
	fetchCustomers,
	fetchCustomer,
	createCustomer,
	updateCustomer,
	deleteCustomer,
	clearCurrentCustomer
})(CustomerEditModal);
