export function getTheoreticalMinimum(campaign, type) {
    if (type == 'codeset' ) {
        return getRelativeLCA(campaign.Codeset.Touchcodes).toFixed(4);
    }
   
    if (campaign.selectedTouchcodes.length < 2) {
        return 'N/A'
    }

    let touchcodes = new Array();
    for (let i = 0; i < campaign.selectedTouchcodes.length; i++) {
        let id = campaign.selectedTouchcodes[i];
        let tc = campaign.Codeset.Touchcodes.find((element) => {
            return element.Id == id;
        });
        touchcodes.push(tc);
    }
    return getRelativeLCA(touchcodes).toFixed(4);   
}

export function getRelativeLCA(touchcodes) {

    let minimumDistance = 1.0;
    let processedFrames = processFrames(touchcodes);

    // compare these sorted lengths/distances (frames) between all points
    for (let i = 0; i < processedFrames.length - 1; i++) {
        for (let j = i + 1; j < processedFrames.length; j++) {
            const dist = compareProcessedFrames(processedFrames[i], processedFrames[j]);
            if (dist < minimumDistance)
                minimumDistance = dist;
            if (minimumDistance < 0.00005) {
                return 0;
            }
        }
    }

    return minimumDistance;
}


function processFrames(touchcodes) {
    let minimumDistance = 1.0;
    let processedFrames = new Array();

    // get the sorted lengths of each touchcode and total distance
    for (let i = 0; i < touchcodes.length; i++) {

        let points = new Array();

        // the coordinates are stored as a string, we need to parse it
        let str;
        if (touchcodes[i] !== undefined) {
            str = touchcodes[i]["Pattern"];
        }
        
        if (str) {
            str = str.replace("[", "");
            str = str.replace("]", "");
            const coordinates = str.split(",");
    
            for (let j = 0; j < coordinates.length; j += 2) {
                const pt = {
                    x: coordinates[j] = parseFloat(coordinates[j]),
                    y: coordinates[j + 1] = parseFloat(coordinates[j + 1])
                }
                points.push(pt);
            }
    
            let sortedArray = new Array();
            let totalDistance = 0;
    
    
            for (let j = 0; j < points.length - 1; j++) {
                for (let k = j + 1; k < points.length; k++) {
                    const distance = getDistanceBetweenPoints(points[j], points[k]);
                    sortedArray.push(distance);
                    totalDistance += distance;
                }
            }
    
            sortedArray.sort();
            const processedFrame = { codeId: touchcodes[i]["Id"], sortedArray: sortedArray, totalDistance: totalDistance };
    
            processedFrames.push(processedFrame);
        }
    }

    return processedFrames;
}


function getDistanceBetweenPoints(point1, point2) {

    const distance = Math.sqrt(Math.pow((point1.x - point2.x), 2) +
        Math.pow((point1.y - point2.y), 2));

    return distance;
}


function compareProcessedFrames(frame1, frame2) {

    let comparativeDistance = 0.0;

    for (let i = 0; i < frame1.sortedArray.length; i++) {
        const thisDist = frame1.sortedArray[i] / frame1.totalDistance;
        const otherDist = frame2.sortedArray[i] / frame2.totalDistance;
        comparativeDistance += Math.abs(thisDist - otherDist);
    }

    return comparativeDistance;
}


export function sortByLCA(campaign) {

    // bring in data from props
    const touchcodes = campaign.Codeset.Touchcodes;
    const selectedTouchcodeIds = campaign.selectedTouchcodes;
    const selectedTouchcodes = new Array();

    let availableTouchcodes = touchcodes.slice(0);

    for (let i = 0; i < selectedTouchcodeIds.length; i++) {
        for (let j = 0; j < availableTouchcodes.length; j++) {
            if (availableTouchcodes[j]["Id"] == selectedTouchcodeIds[i]) {
                selectedTouchcodes.push(availableTouchcodes[j]);
                availableTouchcodes.splice(j,1);
            }
        }
    }

    // compare current set of touchcodes to each individual touchcode
    let touchcodeLCAList = new Array();

    for (let i = 0; i < availableTouchcodes.length; i++) {
        let testList = selectedTouchcodes.slice(0);
        testList.push(availableTouchcodes[i]);

        const entry = {
            touchcode: availableTouchcodes[i],
            rLCA: getRelativeLCA(testList)
        };

        touchcodeLCAList.push(entry);
    }

    // array of sorted touchcodes
    let returnList = new Array();

    // sort [selection]
    while (touchcodeLCAList.length > 0) {
        let maxLCA = touchcodeLCAList[0].rLCA;
        let maxIndex = 0;

        // find min
        for (let i = 1; i < touchcodeLCAList.length; i++) {
            if (touchcodeLCAList[i].rLCA > maxLCA) {
                maxLCA = touchcodeLCAList[i].rLCA;
                maxIndex = i;
            }
        }
        
        returnList.push(touchcodeLCAList[maxIndex].touchcode);
        touchcodeLCAList.splice(maxIndex, 1);
    }

    return returnList;
}

export function sortCodesByLCA(campaign, selectedCodeIds) {

    // bring in data from props
    const touchcodes = campaign.Codeset.Touchcodes;
    const selectedTouchcodeIds = selectedCodeIds;
    const selectedTouchcodes = new Array();

    let availableTouchcodes = touchcodes.slice(0);

    for (let i = 0; i < selectedTouchcodeIds.length; i++) {
        for (let j = 0; j < availableTouchcodes.length; j++) {
            if (availableTouchcodes[j]["Id"] == selectedTouchcodeIds[i]) {
                selectedTouchcodes.push(availableTouchcodes[j]);
                availableTouchcodes.splice(j,1);
            }
        }
    }

    // compare current set of touchcodes to each individual touchcode
    let touchcodeLCAList = new Array();

    for (let i = 0; i < availableTouchcodes.length; i++) {
        let testList = selectedTouchcodes.slice(0);
        testList.push(availableTouchcodes[i]);

        const entry = {
            touchcode: availableTouchcodes[i],
            rLCA: getRelativeLCA(testList)
        };

        touchcodeLCAList.push(entry);
    }

    // array of sorted touchcodes
    let returnList = new Array();

    // sort [selection]
    while (touchcodeLCAList.length > 0) {
        let maxLCA = touchcodeLCAList[0].rLCA;
        let maxIndex = 0;

        // find min
        for (let i = 1; i < touchcodeLCAList.length; i++) {
            if (touchcodeLCAList[i].rLCA > maxLCA) {
                maxLCA = touchcodeLCAList[i].rLCA;
                maxIndex = i;
            }
        }
        
        returnList.push(touchcodeLCAList[maxIndex].touchcode);
        touchcodeLCAList.splice(maxIndex, 1);
    }

    return returnList;
}


export function maximizeLCA(campaign) {

    const touchcodes = campaign.Codeset.Touchcodes;

    // process all frames to get their LCA's.
    let processedFrames = processFrames(touchcodes);

    // this will be a backwards sequential list holding our touchcodes
    let sortedList = new Array();


    // this will hold our comparative data in the form of { .this, .other, .LCA }
    let comparativeLCAData = new Array();

    // get the data for comparative analysis. we need the code Id's for each item 
    // we are evaluating as well as the relative LCA between them
    for (let i = 0; i < processedFrames.length - 1; i++) {
        for (let j = i + 1; j < processedFrames.length; j++) {
            const entry = {
                this: processedFrames[i]["codeId"],
                other: processedFrames[j]["codeId"],
                LCA: compareProcessedFrames(processedFrames[i], processedFrames[j])
            };

            comparativeLCAData.push(entry);
        }
    }

    // this list will hold our comparative sums in the form of { .Id, .sum }
    let comparativeSums = new Array();

    // now that we have compared every frame, we now have the data to sort the list.
    // this algorithm works backwards, finding the worst code, removing all sums using
    // the worst code, until there are 2 left (the top 2 codes which will be interchangeable).
    // we must sort by worst because better values do not get revealed until worse ones
    // are removed. 
    for (let i = 0; i < touchcodes.length; i++) {
        let codeSum = new Object();
        codeSum.Id = touchcodes[i]["Id"];

        // get all comparisons this card has
        codeSum.values = comparativeLCAData.filter(e => (e["this"] == codeSum.Id || e["other"] == codeSum.Id));

        // get it's min rLCA and the code it shares it with
        codeSum.minLCA = Number.POSITIVE_INFINITY;
        for (let j = 0; j < codeSum.values; j++) {
            if (codeSum.minLCA > codeSum.values[j]["LCA"]) {
                codeSum.minLCA = codeSum.values[j]["LCA"];
                if (codeSum.Id == codeSum.values[j]["this"]) {
                    codeSum.other = codeSum.values[j]["other"]
                }
                else {
                    codeSum.other = codeSum.values[j]["this"];
                }
            }
        }

        /*codeSum.minLCA = codeSum.values.reduce(function(prev, curr) {
            return prev.LCA < curr.LCA ? prev : curr;
        })*/

        // get sum of all the LCA data for that single code
        codeSum.sum = codeSum.values.map(function(data) { return data["LCA"]; }).reduce(function(total, num){ return total + num; }, 0);

        comparativeSums.push(codeSum);
    }

    const numberOfLoops = comparativeSums.length;

    for (let i = 0; i < numberOfLoops; i++) {

        // get smallest relative LCA in list
        let worstCode = comparativeSums.reduce(function(prev, curr) {
            return prev.minLCA < curr.minLCA ? prev : curr;
        });

        // pick the worst code
        let otherWorst = comparativeSums.filter(e => e.Id == worstCode.Other);
        if (worstCode.sum > otherWorst.Sum) {
            worstCode = otherWorst;
        }

        // add to our sorted list
        for (let i = 0; i < touchcodes.length; i++) {
            if (touchcodes[i]["Id"] == worstCode.Id) {
                sortedList.push(touchcodes[i]);
                break;
            }
        }

        comparativeSums = comparativeSums.filter(e => e.Id != worstCode.Id);
  
        // remove all references of the worst code and recalculate sums
        comparativeSums = comparativeSums.map(function(data) { 
            return {
                Id: data.Id,
                values: data.values.filter(e => (e["this"] != worstCode.Id && e["other"] != worstCode.Id)),
                sum: data.values.map(function(data) { return data["LCA"]; }).reduce(function(total, num) { return total + num; }, 0)
            };
        });

        //for (let j = 0; j < comparativeSums.length; j++) {
        //    comparativeSums[j]["values"] = comparativeSums[j]["values"].filter(e => (e.other != worstCode.Id) && (e.this != worstCode.Id));
        //    comparativeSums[j]["sum"] = comparativeSums[j]["values"].map(function(data){ return data["LCA"]; }).reduce(function(total, num){ return total + num; }, 0);
        //}
    }

    sortedList = sortedList.reverse();
    let newList = new Array();

    for (let i = 0; i < sortedList.length; i++) {
        newList.push(sortedList[i]);
        let d = getRelativeLCA(newList);
    }

    return sortedList;    
}