import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import { colorDanger, colorWarn } from '../../styles/commonStyles';
import MediaUploaderModal from '../campaign/mediaUploaderModal';

const PREVIEW_WIDTH_HEIGHT = 64;

class FileUpload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pickedFile: null
        }
    }

    handleUpload(fileUrl: String) {
        const {onUpload} = this.props;
        this.setState({pickedFile: fileUrl});
        onUpload(fileUrl);
    }

    render() {
        const {pickedFile} = this.state;
        const {onUpload, preSelectedFileUrl} = this.props;
        const row = {
            display: "flex",
    		flexFlow: "row wrap",
    		marginTop: "10px",
    		gap: "5px"
        }
        const fileButton = {
            height: "fit-content",
            alignSelf: "flex-end"
        }
        const img = {
            objectFit: "contain"
        }
        return(
            <div style={row}>
                <MediaUploaderModal onSubmit={this.handleUpload.bind(this)}/>
                {(pickedFile != null || preSelectedFileUrl != null) &&
                    <img style={img} src={pickedFile || preSelectedFileUrl} width={PREVIEW_WIDTH_HEIGHT} height={PREVIEW_WIDTH_HEIGHT}/>
                }
            </div>

        )
    }
}

export default FileUpload;