import React from 'react';
import { Switch, Route, Router } from 'react-router-dom';
import { routes } from './util/constants';
import history from './util/history';

import Dashboard from './components/dashboard';
import NotFound from './components/utilities/notFound';
import CampaignManage from './components/campaign/campaignManage';
import CampaignNew from './components/campaign/campaignNew';
import CampaignEdit from './components/campaign/campaignEdit';
import ActionManage from './components/action/actionManage';
import ActionNew from './components/action/actionNew';
import ActionEdit from './components/action/actionEdit';
import TargetManage from './components/target/targetManage';
import TouchcodeManage from './components/touchcode/touchcodeManage';
import Analytics from './components/analytics/analytics';
import CustomerManage from './components/customer/customerManage';
import UserManage from './components/user/userManage';
import UserNew from './components/user/userNew';
import UserEdit from './components/user/userEdit';
import Settings from './components/utilities/settings';
import CodesetManage from './components/codeset/codesetManage';
import CodesetNew from './components/codeset/codesetNew';
import CodesetEdit from './components/codeset/codesetEdit';
import Callback from './components/auth/callback';
import AuthExpired from './components/auth/authExpired';
import Logout from './components/auth/logout';

const style = {
	content: {
		height: '100%',
		width: '100%',
		zIndex: '1'
	}
};

const {
	index, home, settings,
	callback, authExpired,
	campaignManage, campaignNew, campaignEdit,
	actionManage, actionNew, actionEdit,
	targetManage, touchcodeManage, analytics,
	customerManage, codesetManage, codesetEdit, codesetNew,
	userManage, notFound, logout
} = routes;

const handleAuthentication = (auth, { location }) => {
	if (/access_token|id_token|error/.test(location.hash)) {
		auth.handleAuthentication();
	}
};

const AuthRoute = ({ component: Component, auth, ...rest }) => (
	<Route {...rest}
		render={props => (
			<Dashboard {...props} auth={auth}>
				<Component {...props} auth={auth} />
			</Dashboard>
		)}
	/>
);

export const makeMainRoutes = (inputData, authObj) => {
	const auth = authObj;

	return (
		<Router history={history}>
			<div style={style.content}>
				<Route path={logout} render={() => { return <Logout auth={auth} />; }} />
				<Route path={authExpired} render={() => { return <AuthExpired auth={auth} />; }} />
				<Route path={callback} render={props => { handleAuthentication(auth, props); return <Callback {...props} />; }} />
				<Switch>
					<AuthRoute exact path={index} component={Analytics} auth={auth} />
					<AuthRoute path={home} component={Analytics} auth={auth} />
					<AuthRoute path={settings} component={Settings} auth={auth} />
					<AuthRoute path={touchcodeManage} component={TouchcodeManage} auth={auth} />
					<AuthRoute path={notFound} component={NotFound} auth={auth} />
					<AuthRoute path={customerManage} component={CustomerManage} auth={auth} />
					<AuthRoute exact path={targetManage} component={TargetManage} auth={auth} />
					<AuthRoute exact path={`${targetManage}/:createNew/:returnLocation`} component={TargetManage} auth={auth} />
					<AuthRoute exact path={userManage} component={UserManage} auth={auth} />
					<AuthRoute exact path={'/user/new'} component={UserNew} auth={auth} />
					<AuthRoute exact path={'/user/edit/:id'} component={UserEdit} auth={auth} />
					<AuthRoute exact path={actionManage} component={ActionManage} auth={auth} />
					<AuthRoute exact path={actionNew} component={ActionNew} auth={auth} />
					<AuthRoute exact path={`${actionEdit}/:id`} component={ActionEdit} auth={auth} />
					<AuthRoute exact path={campaignManage} component={CampaignManage} auth={auth} />
					<AuthRoute exact path={campaignNew} component={CampaignNew} auth={auth} />
					<AuthRoute exact path={`${campaignEdit}/:id`} component={CampaignEdit} auth={auth} />
					<AuthRoute exact path={`${campaignEdit}/:id/:action`} component={CampaignEdit} auth={auth} />
					<AuthRoute exact path={codesetManage} component={CodesetManage} auth={auth} />
					<AuthRoute path={codesetNew} component={CodesetNew} auth={auth} />
					<AuthRoute path={`${codesetEdit}/:id`} component={CodesetEdit} auth={auth} />

					<AuthRoute exact path={`${analytics}/:campaignId/:countryCode/:provinceCode`} component={Analytics} auth={auth} />
					<AuthRoute exact path={`${analytics}/:campaignId/:countryCode`} component={Analytics} auth={auth} />
					<AuthRoute exact path={`${analytics}/:campaignId?`} component={Analytics} auth={auth} />
				</Switch>
			</div>
		</Router>
	);
};
