import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createActionName, BASE_URL } from './index';
import history from '../util/history';
import { routes } from '../util/constants';

// Actions
const DUCK_NAME = 'userRole';
const FETCH_USER_ROLES = createActionName(DUCK_NAME, 'FETCH_USER_ROLES');
export const CLEAR_USER_ROLES_FORM = createActionName(DUCK_NAME, 'CREATE_USER_ROLES_FORM');
const FETCH_PERMISSIONS = createActionName(DUCK_NAME, 'FETCH_PERMISSIONS');

// Reducer
export default function (state = {}, action = {}) {
	switch (action.type) {
		case FETCH_USER_ROLES: {
			return { ...state, userRoles: action.payload.data };
		}
		case FETCH_PERMISSIONS: {
			return { ...state, permissions: action.payload.data };
		}
		default:
			return state;
	}
}

function notifyError(dispatch, message) {
	toastr.error('Error', message);
}

// Action Creators
export function clearUserRolesForm() {
	return {
		type: CLEAR_USER_ROLES_FORM
	};
}

export function fetchUserRoles() {
	return dispatch => {
		axios.get(`${BASE_URL}/userRole`)
			.then(response => {
				dispatch({
					type: FETCH_USER_ROLES,
					payload: response
				});
			})
			.catch(err => {
				notifyError(dispatch, err.response.data);
			});
	};
}

export function fetchPermissions() {
	return dispatch => {
		axios.get(`${BASE_URL}/userRole/fetchPermissions`)
			.then(response => {
				dispatch({
					type: FETCH_PERMISSIONS,
					payload: response
				});
			})
			.catch(err => {
				// TODO: Implement me
			});
	};
}
