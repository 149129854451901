export const mergeStyles = (...styles) => {
	return Object.assign({}, ...styles);
};

export const primaryLightBlue = '#009CDE';
export const primaryDarkBlue = '#1F2A44';
export const primaryGreen = '#78BE20';
export const primaryOrange = '#de4600';
export const primaryPurple = '#9000de';
export const primaryGradient = ' linear-gradient(to bottom left, #009CDE, #78BE20 75%)';
export const colorSuccess = '#4CAF50';
export const colorInfo = '#009688';
export const colorWarn = '#FF9800';
export const colorDanger = '#f44336';
export const colorWeak = '#777777';


const globalStyle = {
	floatLeft: {
		float: 'left'
	},
	floatRight: {
		float: 'right'
	},
	textLeft: {
		textAlign: 'left'
	},
	textCenter: {
		textAlign: 'center'
	},
	textRight: {
		textAlign: 'right'
	},
	textPrimary: {
		color: primaryLightBlue
	},
	textGreen: {
		color: primaryGreen
	},
	textSuccess: {
		color: colorSuccess
	},
	textInfo: {
		color: colorInfo
	},
	textWarn: {
		color: colorWarn
	},
	textDanger: {
		color: colorDanger
	},
	textWeak: {
		color: colorWeak
	},
	textBlend: {
		color: 'rgba(0, 0, 0, .75)'
	},
	formRow: {
		marginTop: '10px',
		marginBottom: '10px'
	},
	pointer: {
		cursor: 'pointer'
	},
	btn: {
		margin: '5px'
	},
	listReset: {
		listStyle: 'none',
		padding: '0'
	},
	autoMargin: {
		margin: 'auto'
	},
	fullHeight: {
		height: '100%'
	},
	splitSection: {
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between'
	},
	clipSection: {
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	tableBody: {
		background: 'rgba(217, 226, 230, 0.55)'
	},
	modalHeading: {
		marginTop: '20px',
		marginBottom: '20px'
	},
	fontLarge: {
		fontSize: 'large'
	}

};

export default globalStyle;
