
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Rodal from 'rodal';
import CommonStyle, { mergeStyles } from '../../styles/commonStyles';
import {
	deleteCodeset,
	clearDeleted,
	clearCodesets
} from '../../ducks/codeset.duck';
import { FaTimesCircle } from 'react-icons/lib/fa';
import { toastr } from 'react-redux-toastr';
import history from '../../util/history';
import {  routes } from '../../util/constants';

import 'rodal/lib/rodal.css';

const style = {
	modalStyle: {
		width: '50%',
		textAlign: 'left'
	},
	contentWrapper: {
		padding: '50px'
	},
	heading: {
		marginBottom: '20px'
	},
	btnRow: {
		marginTop: '35px'
	}
};

export class CodesetDeleteModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			visible: false
		};

		this.handleCancelClick = this.handleCancelClick.bind(this);
		this.handleDeleteClick = this.handleDeleteClick.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
	}

	handleCancelClick(event) {
		this.hide();
		event.preventDefault();
	}

	handleDeleteClick(event) {
		const { codeset } = this.props;
		this.props.deleteCodeset(codeset.Id);
		this.reapDeleting();
		event.preventDefault();
	}

	reapDeleting() {
		const { handleDelete, deleted, codeset } = this.props;
		if (deleted) {
			if (handleDelete) handleDelete();
			this.hide();
			this.props.clearDeleted();
			this.props.clearCodesets();
			history.push(routes.codesetManage);
			toastr.success('Success', `"${codeset.Label}" has been successfully deleted.`);
		} else {
			setTimeout(this.reapDeleting.bind(this), 100);
		}
	}

	handleKeyPress(event) {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	}

	show() {
		this.setState({ visible: true });
	}

	hide() {
		this.setState({ visible: false });
	}

	render() {
		const { launchButton, codeset } = this.props;
		const { visible } = this.state;

		return (
			<span onKeyPress={this.handleKeyPress}>
				{launchButton ? <span onClick={this.show}>{launchButton}</span> : <button onClick={this.show} style={mergeStyles(CommonStyle.btn, { backgroundColor: '#FFE599', borderColor: '#070707', color: '#070707' })} className="btn btn-danger" ><span> Delete </span></button>}
				<Rodal
					visible={visible}
					height={300}
					onClose={this.hide}
					showCloseButton={false}
					customStyles={style.modalStyle}
				>
					<div style={style.contentWrapper}>
						<div style={{ textAlign: 'left' }}>
							<h3 style={mergeStyles(style.heading)}>
								Delete Codeset
							</h3>
						</div>
						<div>
							All data related to "{codeset.Label}" will be lost. Are you sure you want to delete this Codeset?
						</div>
						<Row style={{ margin: '15px 0px 0px auto' }}>
							<Col xs={12} style={{ float: 'left', width: '110px', marginTop: '5px', padding: '0px' }}>
								<span onClick={this.handleDeleteClick}>
									<span style={CommonStyle.button} className="btn btn-danger"><span><FaTimesCircle /> Delete</span></span>
								</span>
							</Col>
							<Col xs={12} style={{ width: '85px', float: 'right' }}>
								<button style={CommonStyle.btn} className="btn btn-default" onClick={this.handleCancelClick}>
									Cancel
								</button>
							</Col>
						</Row>
					</div>
				</Rodal>
			</span>
		);
	}
}
function mapStateToProps(state) {
	return {
		deleted: state.codeset.deleted
	};
}

CodesetDeleteModal = connect(mapStateToProps, {
	deleteCodeset,
	clearDeleted,
	clearCodesets
})(CodesetDeleteModal);

export default CodesetDeleteModal;
