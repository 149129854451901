import React, { Component } from 'react';
import CommonStyle, { colorDanger, colorWarn } from '../../styles/commonStyles';
import DatePickerInput from './datePickerInput';

class DatePickerInputWrapper extends Component {
	constructor(props) {
		super(props);
	}

	updateDisplayDate(date) {
		this.refs.datePicker.setState({ displayDate: date });
	}

	render() {
		const { props } = this;
		const { label, meta: { touched, error, warning } } = props;

		const errorStyle = {
			borderColor: colorDanger
		};

		const warnStyle = {
			borderColor: colorWarn
		};

		let style = {};

		if (touched) {
			if (error) {
				style = errorStyle;
			}

			if (warning) {
				style = warnStyle;
			}
		}

		return (
			<div>
				<label>{label}</label>
				<DatePickerInput
					ref="datePicker"
					style={style}
					{...props}
					value={props.input.value}
					onChange={value => props.input.onChange(value)}
				/>
				{touched && ((error && <span style={CommonStyle.textDanger}>{error}</span>)
					|| (warning && <span style={CommonStyle.textWarn}>{warning}</span>))}
			</div>
		);
	}
}

export default DatePickerInputWrapper;
