import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FaClose } from 'react-icons/lib/fa';
import Rodal from 'rodal';

import 'rodal/lib/rodal.css';
const DEFAULT_HEIGHT = 300;
const style = {
	actionbtnWrapper: {
		display: 'inline-block',
		margin: '5px',
		float: 'left'
	},
	closebtnWrapper: {
		display: 'inline-block',
		margin: '5px',
		float: 'right'
	},
	container: {
		padding: '3em',
		textAlign: 'center'
	},
	closeXWrapper: {
		textAlign: 'right',
		fontSize: '15px'
	},
	closeX: {
		cursor: 'pointer'
	}
};

class ModalLauncher extends Component {
	static propTypes = {
		name: PropTypes.string,
		closeBtn: PropTypes.element,
		actionBtn: PropTypes.element,
		launchBtn: PropTypes.element,
		children: PropTypes.node,
		showX: PropTypes.bool,
		doClose: PropTypes.bool,
		action: PropTypes.func
	};

	constructor(props) {
		super(props);

		this.state = {
			visible: false
		};

		this.toggleDialog = this.toggleDialog.bind(this);
		this.hide = this.hide.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
	}

	toggleDialog(event) {
		this.setState({ visible: !this.state.visible });

		if (event) {
			event.preventDefault();
		}
	}

	hide() {
		this.setState({ visible: false });
	}

	handleKeyPress(event) {
		const { action } = this.props;

		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
			if (action) {
				action();
			}
		}
	}

	render() {
		const { actionBtn, launchBtn, closeBtn, children, showX, width } = this.props;
		const { visible } = this.state;
		const height = this.props.height || DEFAULT_HEIGHT;
		return (
			<span onKeyPress={this.handleKeyPress}>
				<span onClick={this.toggleDialog}>{launchBtn}</span>
				<Rodal
					className="touchcodeModal"
					visible={visible}
					height={height}
					width={width}
					showCloseButton={false}
					onClose={this.hide}
				>
					<div style={style.container}>
						{showX &&
							<div style={style.closeXWrapper}>
								<span style={style.closeX} onClick={this.toggleDialog}><FaClose /></span>
							</div>
						}
						<div>
							{children}
						</div>
						<div style={ { marginBottom: '20px' } }>
							<div style={style.closebtnWrapper}>
								{closeBtn ? <span onClick={this.toggleDialog}>{closeBtn}</span> :
									<button className="btn btn-default" onClick={this.toggleDialog}>Close</button>
								}
							</div>
							<div style={style.actionbtnWrapper}>
								{actionBtn ? <span onClick={this.toggleDialog}>{actionBtn}</span> : null}
							</div>
						</div>
					</div>
				</Rodal>
			</span>
		);
	}
}

export default ModalLauncher;
