import PropTypes from 'prop-types';
import React, { Component } from 'react';
import update from 'immutability-helper';
import { getFormValues } from 'redux-form';
import history from '../../util/history';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import DragAndDropContainer from '../utilities/dragAndDropContainer';
import { updateCurrentCampaignTouchcode, saveWorkInProgress } from '../../ducks/campaign.duck';
import { fetchTargets, fetchTargetsByDest } from '../../ducks/target.duck';
import { routes } from '../../util/constants';
import CommonStyle from '../../styles/commonStyles';
import Select from '../utilities/SelectInputHeightAware';
import Spinner from '../utilities/spinner';

const style = {
	container: {
		display: 'flex',
		justifyContent: 'space-around',
		paddingTop: '20px'
	},
	targetSection: {
		textAlign: 'left',
		position: 'relative'
	},
	newTargetBtn: {
		position: 'absolute',
		bottom: '100%',
		right: '0',
		marginBottom: '-2rem'
	}
};

const targetSelectCustomStyles = {
	container: provided => ({
		...provided,
		position: 'relative'
	})
};

class DragAndDrop extends Component {
	static propTypes = {
		// Redux Store
		dispatch: PropTypes.func,
		targets: PropTypes.array,
		campaign: PropTypes.object,
		// Wrapped Actions
		updateCurrentCampaignTouchcode: PropTypes.func,
		addToast: PropTypes.func,
		fetchTargets: PropTypes.func,
		// From Parent
		selectedActions: PropTypes.array,
		allActions: PropTypes.array,
		touchcodeId: PropTypes.string,
		shouldSaveActions: PropTypes.bool,
		setShouldSaveActions: PropTypes.func,
		defaultTarget: PropTypes.number,
		setDefaultTarget: PropTypes.func,
		closeError: PropTypes.bool
	};

	constructor(props) {
		super(props);
		const selectedActions = props.selectedActions.map(actionId => {
			return props.allActions.filter(obj => obj.Id === actionId || obj.id === actionId)[0];
		});

		const selectedActionIdSet = new Set(props.selectedActions);
		const remainingActions = props.allActions.filter(action => action !== undefined && !selectedActionIdSet.has(action.Id));

		const left = remainingActions.map(action => {
			return { id: action.Id, text: action.Name };
		});
		const right = selectedActions.filter(action => action !== undefined).map(action => {
			return { id: action.Id, text: action.Name };
		});

		this.state = {
			left,
			right,
			updated: false
		};
	}

	componentDidMount() {
		const { type } = this.props.formValues;
		const destinationType = type == null ? null : type.value;
		this.props.fetchTargetsByDest(destinationType);
	}

	componentDidUpdate() {
		if (this.props.setSelectedActions && this.state.updated) {
			const rightArray = this.state.right.map(card => {
				return card.id;
			});
			this.setState({ updated: false });
			this.props.setSelectedActions(this.props.touchcodeId, rightArray);
		}
	}

	componentWillUnmount() {
		if (this.props.shouldSaveActions) {
			this.saveActions();
			toastr.success('Success', 'Action Saved');
		}
		if (this.props.setSelectedActions) {
			const rightArray = this.state.right.map(card => {
				return card.id;
			});
			this.props.setSelectedActions(this.props.touchcodeId, rightArray);
		}

		this.props.setShouldSaveActions(false);
	}

	handleNewTargetClick(event) {
		const { formValues, campaign } = this.props;
		this.props.saveWorkInProgress(campaign.Id || 'newCampaign', formValues, campaign.Touchcodes);
		history.push(`${routes.targetManage}/true/${campaign.Id}`);
		event.preventDefault();
	}

	saveActions() {
		const rightArray = this.state.right.map(card => {
			return card.id;
		});

		const { touchcodeId, defaultTarget } = this.props;

		this.props.updateCurrentCampaignTouchcode(touchcodeId, rightArray, defaultTarget);
	}

	getSelected() {
		return this.state.right;
	}

	handleDefaultTargetChange(value) {
		this.props.setDefaultTarget(value.value);
		// this.setState({ defaultTarget: value.value });
	}

	pushCard(id, card) {
		const newCards = {};
		newCards[id] = { $push: [card] };
		this.setState(update(this.state, newCards));
		this.setState({ updated: true });
	}

	removeCard(id, index) {
		const newCards = {};
		newCards[id] = { $splice: [[index, 1]] };
		this.setState(update(this.state, newCards));
		this.setState({ updated: true });
	}

	moveCard(id, dragIndex, hoverIndex) {
		const cards = this.state[id];
		const dragCard = cards[dragIndex];

		const newCards = {};
		newCards[id] = {
			$splice: [
				[dragIndex, 1],
				[hoverIndex, 0, dragCard]
			]
		};

		this.setState(update(this.state, newCards));
		this.setState({ updated: true });
	}

	render() {
		const { allActions, targets, defaultTarget, closeError } = this.props;
		const targetOptions = targets ? targets.map(target => ({ label: target.Name, value: target.Id })) : [];
		const defaultTargetOption = targetOptions.find(targetOption => targetOption.value === defaultTarget);

		return (
			<div style={{ ...style.container }}>
				<Row style={{ width: '100%' }}>
					<div style={style.targetSection}>
						{targets ? (
							<div style={{ padding: '0px 25px' }}>
								<label style={{ marginTop: '5px', width: '25%' }} >Default Target</label>
								<div style={{ width: '75%', float: 'right' }} >
									<Select
										value={defaultTargetOption}
										onChange={this.handleDefaultTargetChange.bind(this)}
										styles={targetSelectCustomStyles}
										placeholder="No Default Selected"
										isClearable={false}
										menuShouldScrollIntoView={false}
										options={targetOptions}
										width="100%"
									/>
									{closeError &&
										<small style={CommonStyle.textDanger}>
											A default Target is required to save.
										</small>
									}
								</div>
							</div>
						) : <Spinner />
						}
					</div>
					<Col xs={6}>
						<h5>Available Actions</h5>
						<DragAndDropContainer id="left"
							list={this.state.left}
							pushCard={this.pushCard.bind(this)}
							removeCard={this.removeCard.bind(this)}
							moveCard={this.moveCard.bind(this)}
							emptyMessage={allActions.length === 0 ? 'You have not created any actions.' : null}
							height={200}
						/>
					</Col>
					<Col xs={6}>
						<h5>Selected Actions</h5>
						<DragAndDropContainer id="right"
							list={this.state.right} isRightColumn
							pushCard={this.pushCard.bind(this)}
							removeCard={this.removeCard.bind(this)}
							moveCard={this.moveCard.bind(this)}
							emptyMessage="Drag &amp; Drop Actions here and prioritize them"
						/>

					</Col>
				</Row>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		targets: state.target.targets,
		campaign: state.campaign.currentCampaign,
		formValues: getFormValues('campaignEdit')(state)
	};
}

function mapDispatchToProps(dispatch) {
	return {
		updateCurrentCampaignTouchcode: (id, array, defaultTarget) => dispatch(updateCurrentCampaignTouchcode(id, array, defaultTarget)),
		saveWorkInProgress: (campaignId, formValues, touchcodes) => dispatch(saveWorkInProgress(campaignId, formValues, touchcodes)),
		fetchTargets: () => dispatch(fetchTargets()),
		fetchTargetsByDest: destType => dispatch(fetchTargetsByDest(destType))
	};
}

DragAndDrop = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DragAndDrop);

export default DragAndDrop;
