import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FaArrowCircleLeft, FaArrowCircleRight, FaPlus } from 'react-icons/lib/fa';
import CommonStyle, { mergeStyles } from '../../styles/commonStyles';
import { setSelectedTouchcodes, removeCurrentCampaignTouchcode } from '../../ducks/campaign.duck';
import { sortCodesByLCA } from '../utilities/minimumTheoreticalDist';
import BootstrapTable from 'react-bootstrap-table-next';
import ActionDND from '../action/actionDND';
import history from '../../util/history';
import { updateCurrentCampaignTouchcode, saveWorkInProgress } from '../../ducks/campaign.duck';
import { routes } from '../../util/constants';

const style = {
	chipWrapper: {
		display: 'inline-block',
		margin: '5px'
	},
	chip: {
		backgroundColor: '#ebf5ff',
		borderTop: '1px solid #b2d7fe',
		borderRight: '1px solid #b2d7fe',
		borderBottom: '1px solid #b2d7fe',
		display: 'inline-block',
		color: '#007eff',
		padding: '3px 6px'
	},
	chipDelete: {
		borderLeft: '1px solid #b2d7fe',
		borderRadius: '3px 0px 0px 3px',
		cursor: 'pointer',
		':hover': {
			backgroundColor: '#b2d7fe'
		}
	},
	chipText: {
		borderRadius: '0px 3px 3px 0px',
		borderLeft: 'none'
	},
	noTouchcodesText: {
		marginTop: '25px',
		marginBottom: '25px'
	},
	actionText: {
		fontSize: '1.2rem',
		marginTop: '0.25rem'
	},
	actionSuccess: {
		marginLeft: '2rem'
	},
	actionDanger: {
		marginLeft: '1rem'
	},
	actionsHeading: {
		margin: '10px',
		fontWeight: 'bold'
	},
	associationRow: {
		margin: '10px 0px'
	}
};

class TouchCodeActionsInput extends Component {
	static propTypes = {
		// From Parent
		campaign: PropTypes.object,
		// Wrapped actions
		setSelectedTouchcodes: PropTypes.func,
		saveWorkInProgress: PropTypes.func,
		// Redux Store
		actions: PropTypes.array,
		codesetLabels: PropTypes.object
	};

	constructor(props) {
		super(props);
		this.state = {
			selectedCode: undefined,
			defaultTargets: {},
			selectedCodesToAdd: [],
			selectedActions: {},
			addedCodes: [],
			removedCodes: [],
			valid: false
		};
	}

	componentDidMount() {
		const { campaign } = this.props;
		const selected = [...Object.keys(campaign.Touchcodes || {}), ...Object.keys(campaign.DefaultTargets)];
		this.props.setSelectedTouchcodes(Array.from(new Set(selected)) || []);
		document.getElementById('addedCodesTable').addEventListener('mousedown', this.handleAddedRowClick.bind(this), false);
		document.getElementById('availableCodesTable').addEventListener('mousedown', this.handleAvailableRowClick.bind(this), false);
	}

	componentDidUpdate(prevProps, prevState) {
		this.formatSelectedHighlight();
		if (prevState.valid !== this.state.valid) {
			if (this.props.refreshParent) this.props.refreshParent();
		}
	}

	handleAddedRowClick(event) {
		const { selectedCode } = this.state;
		let element = event.target;
		if (element.childElementCount > 1) {
			return;
		}
		while (element.childElementCount > 0) {
			element = element.childNodes[0];
		}
		if (element.childNodes[0]) {
			const cell = element.childNodes[0].nodeValue;
			const id = this.getCodeId(cell);
			if (selectedCode === id) {
				this.setState({ selectedCode: undefined });
			} else {
				this.setState({ selectedCode: undefined });
				this.setState({ selectedCode: id });
			}
		}
	}

	handleAvailableRowClick(event) {
		const { selectedCodesToAdd } = this.state;
		let element = event.target;
		if (element.childElementCount > 1) {
			return;
		}
		while (element.childElementCount > 0) {
			element = element.childNodes[0];
		}
		if (element.childNodes[0]) {
			const cell = element.childNodes[0].nodeValue;
			const id = this.getCodeId(cell);
			if (selectedCodesToAdd.includes(id)) {
				selectedCodesToAdd.splice(selectedCodesToAdd.indexOf(id), 1);
			} else {
				selectedCodesToAdd.push(id);
			}
			this.setState({ selectedCodesToAdd: selectedCodesToAdd });
		}
	}

	getCodeId(label) {
		const { codesetLabels } = this.props;
		for (const i in codesetLabels) {
			if (codesetLabels[i] === label) {
				return i;
			}
		}
		return -1;
	}

	isValid() {
		return this.renderSelectedTouchcodes().length > 0 && this.state.valid;
	}

	update() {
		const { defaultTargets, selectedActions, addedCodes, removedCodes } = this.state;
		const { campaign } = this.props;
		this.setState({ selectedCode: undefined });
		const codesToUpdate = [];
		for (const i in defaultTargets) {
			codesToUpdate.push(i);
		}
		for (const i in selectedActions) {
			if (!codesToUpdate.includes(i)) {
				codesToUpdate.push(i);
			}
		}
		addedCodes.forEach(c => {
			this.props.setSelectedTouchcodes([c, ...this.props.campaign.selectedTouchcodes]);
		});

		for (const i in codesToUpdate) {
			const code = codesToUpdate[i];
			let campaignTouchcode;
			if (campaign.Touchcodes) {
				campaignTouchcode = campaign.Touchcodes[code];
			}
			this.props.updateCurrentCampaignTouchcode(code, selectedActions[code] || campaignTouchcode || [], defaultTargets[code] || campaign.DefaultTargets[code]);
		}

		removedCodes.forEach(c => {
			this.props.setSelectedTouchcodes(_.without(this.props.campaign.selectedTouchcodes, c));
			this.props.removeCurrentCampaignTouchcode(c);
		});
	}

	handleTouchcodesSelectChange(value) {
		this.props.setSelectedTouchcodes([value.value, ...this.props.campaign.selectedTouchcodes]);
	}

	setDefaultTarget(value) {
		const { selectedCode, defaultTargets } = this.state;
		defaultTargets[selectedCode] = value;
		this.forceUpdate();
	}

	setSelectedActions(code, values) {
		const { selectedActions } = this.state;
		selectedActions[code] = values;
		this.forceUpdate();
	}

	renderTouchcodesSelectOptions() {
		const { codesetLabels, campaign } = this.props;
		const { addedCodes, removedCodes, selectedCode, selectedCodesToAdd, defaultTargets } = this.state;
		const { Codeset, selectedTouchcodes } = campaign;
		if (!Codeset) {
			return null;
		}

		if (!codesetLabels) {
			return null;
		}
		const selected = this.renderSelectedTouchcodes().map(({ value }) => {
			return value;
		});
		const touchcodeList = sortCodesByLCA(campaign, selected);

		const options = touchcodeList.map(({ Id }) => {
			if ((!_.find(selectedTouchcodes, stc => Id.toString() === stc) || removedCodes.includes(String(Id))) && !addedCodes.includes(String(Id))) {
				const value = String(Id);
				return {
					label: codesetLabels[Id],
					value: value,
					isSelected: selectedCode === value || selectedCodesToAdd.includes(value),
					doesNotHaveDefaultTarget: !defaultTargets[value] && !campaign.DefaultTargets[value]
				};
			}

			return null;
		});

		return options.filter(val => val != null);
	}

	renderSelectedTouchcodes() {
		const { addedCodes, removedCodes, selectedCode, selectedCodesToAdd, defaultTargets } = this.state;
		const { campaign, codesetLabels } = this.props;
		const { selectedTouchcodes } = campaign;
		const rv = [];
		selectedTouchcodes.forEach(c => {
			if (!removedCodes.includes(c)) {
				rv.push({
					label: codesetLabels ? codesetLabels[c] : c,
					value: c,
					isSelected: selectedCode === c || selectedCodesToAdd.includes(c),
					doesNotHaveDefaultTarget: !defaultTargets[c] && !campaign.DefaultTargets[c]
				});
			}
		});
		addedCodes.forEach(c => {
			rv.push({
				label: codesetLabels ? codesetLabels[c] : c,
				value: c,
				isSelected: selectedCode === c || selectedCodesToAdd.includes(c),
				doesNotHaveDefaultTarget: !defaultTargets[c] && !campaign.DefaultTargets[c]
			});
		});
		return rv;
	}

	removeTouchcodeFromSelected(touchcode, event) {
		event.preventDefault();
		this.props.setSelectedTouchcodes(_.without(this.props.campaign.selectedTouchcodes, touchcode));
		this.props.removeCurrentCampaignTouchcode(touchcode);
	}

	handleNewActionClick(event) {
		const { formValues, campaign } = this.props;
		this.props.saveWorkInProgress(campaign.Id || 'newCampaign', formValues, campaign.Touchcodes);
		history.push(routes.actionNew);
		event.preventDefault();
	}

	handleNewTargetClick(event) {
		const { formValues, campaign } = this.props;
		this.props.saveWorkInProgress(campaign.Id || 'newCampaign', formValues, campaign.Touchcodes);
		history.push(`${routes.targetManage}/true/${campaign.Id}`);
		event.preventDefault();
	}

	addCodes() {
		const { selectedCodesToAdd, addedCodes, removedCodes } = this.state;
		const { campaign } = this.props;
		const { selectedTouchcodes } = campaign;
		selectedCodesToAdd.forEach(c => {
			if (removedCodes.includes(c)) {
				removedCodes.splice(removedCodes.indexOf(c), 1);
			}
			if (!addedCodes.includes(c) && !selectedTouchcodes.includes(c)) {
				addedCodes.push(c);
			}
		});
		this.setState({ selectedCodesToAdd: [], addedCodes: addedCodes, removedCodes: removedCodes });
		if (this.props.refreshParent) this.props.refreshParent();
	}

	removeCodes() {
		const { selectedCode, addedCodes, removedCodes } = this.state;
		if (addedCodes.includes(selectedCode)) {
			addedCodes.splice(addedCodes.indexOf(selectedCode), 1);
		}
		if (!removedCodes.includes(selectedCode)) {
			removedCodes.push(selectedCode);
		}
		this.setState({ selectedCode: undefined, addedCodes: addedCodes, removedCodes: removedCodes });
		if (this.props.refreshParent) this.props.refreshParent();
	}

	columnFormatter(cell, row) {
		let selectedClass = row.isSelected ? 'selectedRow' : 'deselectedRow';
		if (row.doesNotHaveDefaultTarget) {
			selectedClass += ' redRow ';
		}
		return (
			<div className={'touchcodeActionsRow ' + selectedClass} style={mergeStyles(CommonStyle.splitSection)}>
				<div style={CommonStyle.clipSection}>{cell}</div>
			</div>
		);
	}

	formatSelectedHighlight() {
		const { valid } = this.state;
		const table = document.getElementById('addedCodesTable');
		if (table) {
			const redRows = table.getElementsByClassName('redRow');
			if (redRows.length > 0) {
				if (valid) {
					this.setState({ valid: false });
				}
			} else {
				if (!valid) {
					this.setState({ valid: true });
				}
			}
		}
	}

	render() {
		const { selectedCode, selectedCodesToAdd } = this.state;
		const { campaign, actions } = this.props;

		const selectedToDisplay = this.renderSelectedTouchcodes();
		const available = this.renderTouchcodesSelectOptions();

		const addStyle = { cursor: 'not-allowed', color: '#999999' };
		const deleteStyle = { marginTop: '102px', cursor: 'not-allowed', color: '#999999' };

		if (selectedCodesToAdd && selectedCodesToAdd.length > 0) {
			addStyle.cursor = 'pointer';
			addStyle.color = '#000511';
		}
		if (selectedCode) {
			deleteStyle.cursor = 'pointer';
			deleteStyle.color = '#000511';
		}

		return (
			<div style={{ width: '100%', height: '371px' }}>
				<div style={{ float: 'left', width: '30%', height: '100%' }}>
					<div style={{ float: 'left', width: '70%', height: '100%' }}>
						<div>Available Touchcodes</div>
						<div id={'availableCodesTable'} style={{ height: '354px', border: '1px solid black', overflowX: 'hidden' }}>
							{available && available.length > 0 &&
								<BootstrapTable
									data={available}
									columns={[{
										dataField: 'label',
										text: 'label',
										formatter: this.columnFormatter.bind(this),
										sort: true,
										headerStyle: { display: 'none' }
									}]}
									keyField="label"
									rowClasses="touchcodeActionsTr"
									bodyClasses="touchcodeTbody touchcodeActionsTbody"
									striped
									hover
								/>
							}
						</div>
					</div>
					<div style={{ float: 'right', width: '30%', height: '100%', padding: '100px 10% 0px 10%' }}>
						<FaArrowCircleRight onClick={this.addCodes.bind(this)} style={addStyle} size={40} />
						<br />
						<FaArrowCircleLeft onClick={this.removeCodes.bind(this)} style={deleteStyle} size={40} />
					</div>
				</div>
				<div style={{ float: 'right', width: '70%', height: '100%' }}>
					<div style={{ float: 'left', width: '30%', height: '100%' }}>
						<div>Touchcodes Added</div>
						<div id={'addedCodesTable'} style={{ height: '354px', border: '1px solid black', overflowX: 'hidden' }}>
							{selectedToDisplay && selectedToDisplay.length > 0 &&
								<BootstrapTable
									data={selectedToDisplay}
									columns={[{
										dataField: 'label',
										text: 'label',
										formatter: this.columnFormatter.bind(this),
										sort: true,
										headerStyle: { display: 'none' }
									}]}
									keyField="label"
									rowClasses="touchcodeActionsTr"
									bodyClasses="touchcodeTbody touchcodeActionsTbody"
									striped
									hover
								/>
							}
						</div>
					</div>
					<div style={{ float: 'right', width: '70%', height: '100%' }}>
						<div>Set Actions</div>
						<div style={{ height: '354px', border: '1px solid black', overflowX: 'hidden' }}>
							{selectedCode && <div style={style.contentWrapper}>
								<Row style={mergeStyles(CommonStyle.textLeft, { padding: '5px 25px' })}>
									<button className="btn btn-primary btn-sm" onClick={this.handleNewActionClick.bind(this)} style={{ marginRight: '10px' }}><FaPlus /> Action</button>
									<button style={style.newTargetBtn} className="btn btn-primary btn-sm" onClick={this.handleNewTargetClick.bind(this)}><FaPlus /> Target</button>
								</Row>
								<Row>
									<Col xs={12} className="text-center">
										{this.state && this.props.formValues.type &&
											<ActionDND
												shouldSaveActions={false}
												setShouldSaveActions={should => this.setState({ shouldSave: should })}
												touchcodeId={selectedCode}
												selectedActions={
													this.state.selectedActions[selectedCode] || (campaign.Touchcodes !== undefined && campaign.Touchcodes[selectedCode]) || campaign.Touchcodes[selectedCode] || []
												}
												allActions={actions}
												defaultTarget={
													this.state.defaultTargets[selectedCode] || campaign.DefaultTargets[selectedCode] || undefined
												}
												setDefaultTarget={this.setDefaultTarget.bind(this)}
												setSelectedActions={this.setSelectedActions.bind(this)}
											/>
										}
										{!this.props.formValues.type &&
											<div>
												You must select a Type in order to assign Actions and a Default Target to Touchcodes
											</div>
										}
									</Col>
								</Row>
							</div>}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		actions: state.action.actions,
		codesetLabels: state.codeset.codesetLabels,
		customerId: state.auth.customerId,
		formValues: getFormValues('campaignEdit')(state)
	};
}

TouchCodeActionsInput = connect(mapStateToProps, {
	setSelectedTouchcodes, removeCurrentCampaignTouchcode, saveWorkInProgress, updateCurrentCampaignTouchcode
}, null, { forwardRef: true })(TouchCodeActionsInput);

export default TouchCodeActionsInput;
