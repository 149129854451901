import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import ModalLauncher from './modalLauncher';
import CommonStyle from '../../styles/commonStyles';
import { FaTimesCircle } from 'react-icons/lib/fa';

const style = {
	bodyText: {
		marginTop: '20px',
		marginBottom: '20px'
	}
};

class DeleteCampaignModal extends Component {
	static propTypes = {
		campaignName: PropTypes.string,
		action: PropTypes.func
	};

	constructor(props) {
		super(props);

		this.state = {
			enableDelete: false,
			nameConfirmValue: ''
		};

		this.nameConfirmChange = this.nameConfirmChange.bind(this);
	}

	nameConfirmChange(e) {
		this.setState({nameConfirmValue: e.target.value});
	}

	render() {
		const { action, campaignName, title, disabled } = this.props;
		const { nameConfirmValue } = this.state;

		const deleteDisabled = this.state.nameConfirmValue !== campaignName;
		const actionProp = deleteDisabled ? null : action;

		return (
				<ModalLauncher
						name="campaignManage"
						launchBtn={<button style={CommonStyle.btn} disabled={disabled} title={title} className="btn btn-danger"> <FaTimesCircle/> Delete</button>}
						closeBtn={<button style={CommonStyle.btn} className="btn btn-default">Cancel</button>}
						action={actionProp}
				>
					<Row style={CommonStyle.textCenter}>
						<h3>Delete {campaignName}?</h3>
						<Row>
							<Col xs={8} xsOffset={2}>
								<div style={style.bodyText}>
									<h5>All data related to this Campaign will be lost.</h5>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={8} xsOffset={2}>
								<small>Type in the name of the Campaign and press delete</small>
							</Col>
						</Row>

						<Col xs={8} xsOffset={2}>
							<div className="input-group">
								<input
									autoFocus
									className="form-control"
									name="nameConfirm" type="text"
									onClick={this.nameConfirmChange}
									onChange={this.nameConfirmChange}
									value={nameConfirmValue} />
								<span className="input-group-btn">
									<button className="btn btn-danger"
										onClick={action}
										disabled={deleteDisabled}
										title={deleteDisabled ? 'Name entered must match campaign name exactly' : null}>
										DELETE
									</button>
								</span>
							</div>
						</Col>
					</Row>
				</ModalLauncher>
		);
	}
}

export default DeleteCampaignModal;
