import fileDownload from 'js-file-download';

const escapeArrayData = data => {
	if (typeof data === 'string' && data.indexOf(',') !== -1) {
		return `\"${data}\"`;
	}
	return data;
};

const arrayToCSV = dataArray => {
	return dataArray.map(dataString => escapeArrayData(dataString)).join(',');
};

export const createCSV = (headerArray, dataRows, fileName) => {
	dataRows.unshift(headerArray);
	const csvArray = dataRows.map(dataArray => arrayToCSV(dataArray));
	const csvString = csvArray.join('\r\n');
	fileDownload(csvString, `${fileName}.csv`);
};
