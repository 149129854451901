
import React, { Component } from 'react';
import { Grid } from 'react-bootstrap';
import { reduxForm, getFormValues } from 'redux-form';
import { fetchCampaignsInDw, clearEngagementsByDeviceIdentity, clearEngagementsByUserType, fetchEngagementsByUserType, fetchEngagementsByDeviceIdentity, fetchEngagementsFromMap, setShouldExportMapData, setSelectedCampaignId } from '../../ducks/analytics.duck';
import { setRefreshActions } from '../../ducks/auth.duck';
import { getCampaignsForCustomer } from '../../selectors/analytics';
import { connect } from 'react-redux';
import _ from 'lodash';
import AnalyticsDateTime from './analyticsDateTime';
import AnalyticsLocation from './analyticsLocation';
import AnalyticsCounts from './analyticsCounts';
import AnalyticsUser from './analyticsUser';
import { WidthProvider, Responsive } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const formName = 'analytics';


class Analytics extends Component {
	constructor(props) {
		super(props);

		this.state = {
			userEngagementRefreshActions: [
				clearEngagementsByUserType,
				() => fetchEngagementsByUserType(),
				clearEngagementsByDeviceIdentity,
				() => fetchEngagementsByDeviceIdentity(),
			],
			dateTimeRefreshActions: [],
			locationRefreshActions: [],
			layoutOptions: {
				isDraggable: false,
				isResizable: false,
				rowHeight: 167.5,
				margin: [20, 20],
				cols: { lg: 3, md: 3, sm: 3, xs: 3, xxs: 3 },
				rows: { lg: 3, md: 3, sm: 3, xs: 3, xxs: 3 },
				autoSize: true,
				measureBeforeMount: true,
				items: 6,
				className: 'layout',
				layouts: {
					lg: [
						{ i: 'an', x: 1, y: 0, w: 1, h: 1 },
						{ i: 'ar', x: 2, y: 0, w: 1, h: 1 },
						{ i: 'at', x: 0, y: 0, w: 1, h: 1 },
						{ i: 'al', x: 1, y: 1, w: 2, h: 4 },
						{ i: 'ad', x: 0, y: 1, w: 1, h: 2 },
						{ i: 'au', x: 0, y: 1, w: 1, h: 2 }
					],
				}
			}
		};

		this.updateDateTimeRefreshActions = this.updateDateTimeRefreshActions.bind(this);
		this.updateLocationRefreshActions = this.updateLocationRefreshActions.bind(this);
	}

	componentDidMount() {
		this.props.fetchCampaignsInDw();
		const selectedCampaign = this.props.match.params.campaignId || null;
		this.props.setSelectedCampaignId(selectedCampaign);
		this.props.fetchEngagementsByUserType(selectedCampaign);
		this.props.fetchEngagementsByDeviceIdentity(selectedCampaign);
		this.props.setRefreshActions([
			...this.state.userEngagementRefreshActions,
			...this.state.dateTimeRefreshActions,
			...this.state.locationRefreshActions
		]);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.selectedCampaign !== this.props.selectedCampaign) {
			this.handleCampaignChange();
		}
	}

	handleCampaignChange() {
		this.props.clearEngagementsByUserType();
		this.props.clearEngagementsByDeviceIdentity();
		const selectedCampaign = this.props.selectedCampaign || null;
		this.props.fetchEngagementsByUserType(selectedCampaign);
		this.props.fetchEngagementsByDeviceIdentity(selectedCampaign);
	}

	updateDateTimeRefreshActions(dateTimeRefreshActions) {
		this.setState({ dateTimeRefreshActions }, () => {
			this.props.setRefreshActions([
				...this.state.userEngagementRefreshActions,
				...this.state.dateTimeRefreshActions,
				...this.state.locationRefreshActions
			]);
		});
	}

	updateLocationRefreshActions(locationRefreshActions) {
		this.setState({ locationRefreshActions }, () => {
			this.props.setRefreshActions([
				...this.state.userEngagementRefreshActions,
				...this.state.dateTimeRefreshActions,
				...this.state.locationRefreshActions
			]);
		});
	}

	renderCampaignOptions() {
		const { campaigns } = this.props;
		const options = campaigns && campaigns.map(campaign => {
			return { label: `${campaign.Name}`, value: `${campaign.CampaignKey}` };
		});
		return _.sortBy(options, i => { return i.label.toLowerCase(); });
	}

	render() {
		let returningUsers = 0;
		if (this.props.engagementsByDeviceIdentity) {
			returningUsers = this.props.engagementsByDeviceIdentity.Engagements.length;
		}

		let campaign = this.props.selectedCampaign;
		if (this.props.match.params.campaignId) {
			campaign = this.props.match.params.campaignId;
		}

		return (
			<Grid fluid>
				<ResponsiveReactGridLayout {...this.state.layoutOptions} >
					<div key="at">
						<AnalyticsCounts {...this.props} totalEngagements={'true'} engagementsByUserType={this.props.engagementsByUserType} />
					</div>
					<div key="ar">
						<AnalyticsCounts {...this.props} returningUsers={'true'} engagementsByUserType={this.props.engagementsByUserType} totalReturningUsers={returningUsers} />
					</div>
					<div key="an">
						<AnalyticsCounts {...this.props} newUsers={'true'} engagementsByUserType={this.props.engagementsByUserType} />
					</div>
					<div key="al" >
						<AnalyticsLocation {...this.props} selectedCampaign={campaign} updateAnalyticsRefreshActions={this.updateLocationRefreshActions} />
					</div>
					<div key="ad" >
						<AnalyticsDateTime {...this.props} selectedCampaign={campaign} updateAnalyticsRefreshActions={this.updateDateTimeRefreshActions} />
					</div>
					<div key="au" className="analyticsUser">
						<AnalyticsUser {...this.props} engagementsByUserType={this.props.engagementsByUserType} totalReturningUsers={returningUsers} />
					</div>
				</ResponsiveReactGridLayout>
			</Grid >
		);
	}
}


function mapStateToProps(state) {
	return {
		campaigns: getCampaignsForCustomer(state),
		formValues: getFormValues(formName)(state),
		engagementsByDeviceIdentity: state.analytics.engagementsByDeviceIdentity,
		engagementsByUserType: state.analytics.engagementsByUserType,
		mapDataToExport: state.analytics.mapDataToExport,
		mapDateTimeInfo: state.analytics.mapDateTimeInfo,
		shouldExportMapData: state.analytics.shouldExportMapData
	};
}

const WrappedComponent = reduxForm({
	form: formName
})(Analytics);

export default connect(mapStateToProps, {
	fetchCampaignsInDw,
	fetchEngagementsByDeviceIdentity,
	clearEngagementsByDeviceIdentity,
	fetchEngagementsByUserType,
	clearEngagementsByUserType,
	setRefreshActions,
	fetchEngagementsFromMap,
	setShouldExportMapData,
	setSelectedCampaignId,
})(WrappedComponent);

