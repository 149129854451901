import PropTypes from 'prop-types';
import React, { Component } from 'react';

const style = {
	iconBtn: {
		fontSize: '14px',
		padding: '2px 6px',
		margin: '0px 5px'
	}
};

class IconModalLauncher extends Component {
	static propTypes = {
		children: PropTypes.node
	}

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<button className="btn btn-primary" style={style.iconBtn}>
				{this.props.children}
			</button>
		);
	}
}

export default IconModalLauncher;
