import _ from 'lodash';
import React, { Component } from 'react';
import ConfirmModal from '../utilities/confirmModal';
import CommonStyle, { mergeStyles, colorDanger } from '../../styles/commonStyles';

class CancelModal extends Component {

	constructor(props) {
		super(props);
	}

	valuesChanged() {
		const { initialValues, currentValues } = this.props;
		if (initialValues && currentValues) {
			return !_.isEqual(initialValues, currentValues);
		}
		if (!initialValues && !currentValues) {
			return false;
		}
		return true;
	}

	render() {
		const { onCancel } = this.props;

		if (this.valuesChanged()) {
			return (
				<ConfirmModal
					id="cancelModalLauncher"
					title="Cancel"
					closeBtnText="No"
					body="The changes made will not be saved. Are you sure you would like to cancel?"
					launchBtn={
						<button id="cancelBtn" className="btn btn-default" style={CommonStyle.btn}>
							Cancel
						</button>
					}
					confirmBtn={
						<button className="btn btn-danger" onClick={onCancel}>Yes</button>
					}
					action={onCancel}
				/>

			);
		}
		return (
			<button className="btn btn-default" style={CommonStyle.btn} onClick={onCancel}>Cancel</button>
		);
	}
}

export default CancelModal;
