import { createActionName, BASE_URL } from './index';
import axios from 'axios';
import history from '../util/history';
import { routes } from '../util/constants';
import { toastr } from 'react-redux-toastr';

const initialState = {
	customers: null,
	currentCustomers: null
};

// Actions
const DUCK_NAME = 'customer';
const FETCH_CUSTOMERS = createActionName(DUCK_NAME, 'FETCH_CUSTOMERS');
const FETCH_CUSTOMER = createActionName(DUCK_NAME, 'FETCH_CUSTOMER');
const IMPORT_CUSTOMERS = createActionName(DUCK_NAME, 'IMPORT_CUSTOMERS');
const CREATE_CUSTOMER = createActionName(DUCK_NAME, 'CREATE_CUSTOMER');
const UPDATE_CUSTOMER = createActionName(DUCK_NAME, 'UPDATE_CUSTOMER');
const DELETE_CUSTOMER = createActionName(DUCK_NAME, 'DELETE_CUSTOMER');
const CLEAR_CURRENT_CUSTOMER = createActionName(DUCK_NAME, 'CLEAR_CURRENT_CUSTOMER');
const CLEAR_IMPORTED_CUSTOMERS = createActionName(DUCK_NAME, 'CLEAR_IMPORTED_CUSTOMERS');

// Reducer
export default function (state = initialState, action) {
	if (state === undefined) {
		state = {
			customers: null,
			currentCustomers: null
		};
	}
	switch (action.type) {
		case FETCH_CUSTOMERS: {
			return { ...state, customers: action.payload.data };
		}
		case FETCH_CUSTOMER: {
			return { ...state, currentCustomer: action.payload.data };
		}
		case IMPORT_CUSTOMERS: {
			return { ...state, importedCustomers: action.payload.data.customers };
		}
		case CREATE_CUSTOMER: {
			return state;
		}
		case UPDATE_CUSTOMER: {
			return state;
		}
		case DELETE_CUSTOMER: {
			return state;
		}
		case CLEAR_CURRENT_CUSTOMER: {
			return { ...state, currentCustomer: null };
		}
		case CLEAR_IMPORTED_CUSTOMERS: {
			return { ...state, importedCustomers: undefined };
		}
		default:
			return state;
	}
}

// Action Creators
export function fetchCustomers() {
	return dispatch => {
		axios.get(`${BASE_URL}/customer`)
			.then(response => {
				dispatch({
					type: FETCH_CUSTOMERS,
					payload: response
				});
			})
			.catch(err => {
				// TODO: Implement me
			});
	};
}

export function fetchCustomer(CustomerId) {
	return dispatch => {
		axios.get(`${BASE_URL}/Customer/${CustomerId}`)
			.then(response => {
				dispatch({
					type: FETCH_CUSTOMER,
					payload: response
				});
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', `Unable to find Customer with Id ${CustomerId}.`);
				}
			});
	};
}

export function createCustomer(Customer) {
	return dispatch => {
		axios.post(`${BASE_URL}/Customer`, Customer)
			.then(response => {
				dispatch({
					type: CREATE_CUSTOMER,
					payload: response
				});
				toastr.success('Success', 'Customer created');
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to create Customer.');
				}
			});
	};
}

export function updateCustomer(Customer, CustomerId) {
	return dispatch => {
		axios.put(`${BASE_URL}/Customer/${CustomerId}`, Customer)
			.then(response => {
				dispatch({
					type: UPDATE_CUSTOMER,
					payload: response
				});
				toastr.success('Success', 'Customer updated');
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to update Customer.');
				}
			});
	};
}


export function importCustomers(Customers) {
	return dispatch => {
		axios.post(`${BASE_URL}/Customer/import/fromCodeset`, Customers)
			.then(response => {
				dispatch({
					type: IMPORT_CUSTOMERS,
					payload: response
				});
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to import Customers.');
				}
			});
	};
}

export function deleteCustomer(CustomerId) {
	return dispatch => {
		axios.delete(`${BASE_URL}/Customer/${CustomerId}`)
			.then(response => {
				dispatch({
					type: DELETE_CUSTOMER,
					payload: response
				});
				toastr.success('Success', 'Customer deleted');
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Delete Failed', 'Cannot delete Customers at this time.');
				}
			});
	};
}

export function clearCurrentCustomer() {
	return {
		type: CLEAR_CURRENT_CUSTOMER
	};
}

export function clearImportedCustomers() {
	return {
		type: CLEAR_IMPORTED_CUSTOMERS
	};
}
