// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues, isValid, touch } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import history from '../../util/history';
import { FaTimesCircleO, FaCheck } from 'react-icons/lib/fa';
import { createUser, updateUser, deleteUser, emailExists, clearEmailExists } from '../../ducks/user.duck';
import { fetchCustomers } from '../../ducks/customer.duck';
import { fetchUserRoles, clearUserRolesForm } from '../../ducks/userRole.duck';
import { formNames, routes } from '../../util/constants';
import * as validators from '../../util/FormValidators';
import cs from '../../styles/commonStyles';
import FieldWrapper from '../utilities/fieldWrapper';
import FormContainer from '../utilities/formContainer';
import Select from '../utilities/selectInput';
import ConfirmModal from '../utilities/confirmModal';
import { setCustomerIdEnabled, setCustomerId, setRefreshActions } from '../../ducks/auth.duck.js';
import ReactTooltip from 'react-tooltip';
import CancelModal from '../utilities/cancelModal';

const formName = formNames.USER_EDIT;

type Props = {
	// From Parent
	isNew: boolean,
	// Redux Form
	handleSubmit: Function,
	// Redux Store
	user: User,
	customers: Array<Customer>,
	// Wrapped Actions
	createUser: Function,
	updateUser: Function,
	deleteUser: Function,
	fetchCustomers: Function,
	fetchUserRoles: Function
};

class UserEditForm extends Component {
	constructor(props: Props) {
		super(props);
	}

	componentDidMount() {
		if (this.props.isNew) {
			this.props.setCustomerIdEnabled(true);
		} else {
			this.props.setCustomerId(this.props.user.CustomerId);
			this.props.setCustomerIdEnabled(false);
		}
		this.props.fetchUserRoles();
		this.props.setRefreshActions([fetchUserRoles, clearUserRolesForm]);
	}

	componentWillUnmount() {
		this.props.clearEmailExists();
	}

	handleFormSubmit(form) {
		const { isNew, user, emailTaken } = this.props;

		const { firstName, lastName, email, userRole } = form;

		const newUser = {
			FirstName: firstName,
			LastName: lastName,
			Email: email,
			UserRoleId: userRole.value
		};
		if (!emailTaken) {
			if (isNew) {
				this.props.createUser(newUser, routes.userManage);
			} else {
				this.props.updateUser(newUser, user.Id, user.Auth0Id, routes.userManage);
			}
		}
	}

	checkEmailExists(value) {
		const { isNew, user } = this.props;
		if (validators.email(value)) {
			this.props.emailExists(false);
			return;
		}
		if (!isNew) {
			if (!user || user.Email === value) {
				this.props.emailExists(false);
				return;
			}
			this.props.emailExists(value);
			return;
		}
		this.props.emailExists(value);
	}

	handleDeleteClick(event) {
		event.preventDefault();
		const { user } = this.props;
		this.props.deleteUser(user.Id, user.Auth0Id, routes.userManage);
	}

	handleCancelClick(event) {
		event.preventDefault();
		history.push(routes.userManage);
	}

	handleKeyPress(event) {
		if (event.key === 'Enter') {
			event.preventDefault();

			const { dispatch, formValues } = this.props;

			if (this.props.isValid) {
				this.handleFormSubmit(formValues);
			} else {
				dispatch(touch(formName, 'customer'));
				dispatch(touch(formName, 'firstName'));
				dispatch(touch(formName, 'lastName'));
				dispatch(touch(formName, 'email'));
				dispatch(touch(formName, 'userRole'));
			}
		}
	}

	render() {
		const { isNew, handleSubmit, user, userRoles, initialValues, formValues, emailTaken } = this.props;

		const bsResponsive = { xs: 12, sm: 6, md: 4, lg: 4 };

		return (
			<FormContainer submitHandler={handleSubmit(this.handleFormSubmit.bind(this))}>
				<div onKeyPress={this.handleKeyPress.bind(this)}>
					<Row style={cs.formRow}>
						{userRoles &&
							<Col {...bsResponsive} data-tip data-for="userRole">
								<Field
									name="userRole"
									component={Select}
									placeholder="Select a Role"
									label="User Role"
									isClearable={false}
									validate={[validators.required]}
									options={
										userRoles.map(role => {
											return { label: role.Name, value: role.Id };
										})
									}
								/>
								<ReactTooltip id="userRole" place="right" effect="solid">
									<div>To create a User for a specific Customer, select that customer</div>
									<div>from the View for Customer dropdown above. To create a T-Ink</div>
									<div>administrator User, the View for Customer dropdown must be clear.</div>
								</ReactTooltip>
							</Col>
						}
					</Row>
					<Row style={cs.formRow}>
						<Col {...bsResponsive}>
							<Field
								name="firstName"
								component={FieldWrapper}
								type="text"
								placeholder="First Name"
								label="First Name"
								validate={[validators.required, validators.charLimit50]}
							/>
						</Col>
					</Row>
					<Row>
						<Col {...bsResponsive}>
							<Field
								name="lastName"
								component={FieldWrapper}
								type="text"
								placeholder="Last Name"
								label="Last Name"
								validate={[validators.required, validators.charLimit50]}
							/>
						</Col>
					</Row>
					<Row style={cs.formRow}>
						<Col {...bsResponsive} data-tip data-for="email">
							<Field
								name="email"
								component={FieldWrapper}
								type="email"
								placeholder="Email"
								label="Email"
								onChange={value => {
									setTimeout(() => {
										if (value && value.currentTarget) {
											this.checkEmailExists(value.currentTarget.defaultValue);
										} else if (formValues) {
											this.checkEmailExists(formValues.email);
										}
									}, 100);
								}}
								validate={[validators.required, validators.email, validators.charLimit50]}
							/>
							{emailTaken &&
								<Col style={{ ...cs.textDanger }}>
									<span>The entered email address already exists. Please enter a new valid email address for the user.</span>
								</Col>
							}
							<ReactTooltip id="email" place="right" effect="solid">
								<div>The entered Email Address will be entered</div>
								<div>on the Login Page for access to the Dashboard</div>
							</ReactTooltip>
						</Col>
					</Row>
				</div>

				<div>
					<Row style={cs.formRow}>
						<Col xs={12}>
							<div style={cs.floatLeft}>
								{!isNew && user &&
									<ConfirmModal
										title="Delete User"
										body={
											<span>
												Are you sure you want to Delete User: <strong>{user.FullName}</strong>?
												</span>
										}
										launchBtn={
											<button style={cs.btn} className="btn btn-danger">Delete</button>
										}
										closeBtnText="No"
										confirmBtn={
											<button className="btn btn-danger" onClick={this.handleDeleteClick.bind(this)}>
												Yes
												</button>
										}
									/>
								}
							</div>
							<div style={cs.floatRight}>
								{
									<CancelModal
										initialValues={initialValues}
										currentValues={formValues}
										onCancel={this.handleCancelClick.bind(this)}
									/>
								}
								<button style={cs.btn} className="btn btn-primary" type="submit">
									{isNew ? <span>Add User</span> : <span><FaCheck /> Save</span>}
								</button>
							</div>
						</Col>
					</Row>
				</div>
			</FormContainer>
		);
	}
}

function mapStateToProps(state) {
	const { currentUser } = state.user;

	let initialValues;

	if (currentUser) {
		const { CustomerId, FirstName, LastName, Email, UserRoleId } = currentUser;
		initialValues = {
			customer: CustomerId,
			firstName: FirstName,
			lastName: LastName,
			email: Email,
			userRole: UserRoleId
		};
	}

	return {
		user: currentUser,
		emailTaken: state.user.emailExists,
		customers: state.customer.customers,
		userRoles: state.userRole.userRoles,
		formValues: getFormValues(formName)(state),
		isValid: isValid(formName)(state),
		customerId: state.auth.customerId,
		initialValues
	};
}

UserEditForm = reduxForm({
	form: formName
})(UserEditForm);

UserEditForm = connect(mapStateToProps, {
	setCustomerIdEnabled,
	setCustomerId,
	createUser,
	updateUser,
	deleteUser,
	fetchCustomers,
	fetchUserRoles,
	setRefreshActions,
	clearUserRolesForm,
	emailExists,
	clearEmailExists
})(UserEditForm);

export default UserEditForm;
