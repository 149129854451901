/* eslint-disable no-console */
import axios from 'axios';
import history from '../util/history';
import { createActionName, BASE_URL } from './index';
import { toastr } from 'react-redux-toastr';
import { routes } from '../util/constants';

// Actions
const DUCK_NAME = 'action';
const FETCH_ACTIONS = createActionName(DUCK_NAME, 'FETCH_ACTIONS');
const FETCH_ACTION = createActionName(DUCK_NAME, 'FETCH_ACTION');
const CREATE_ACTION = createActionName(DUCK_NAME, 'CREATE_ACTION');
const UPDATE_ACTION = createActionName(DUCK_NAME, 'UPDATE_ACTION');
const DELETE_ACTION = createActionName(DUCK_NAME, 'DELETE_ACTION');
const CLEAR_CURRENT_ACTION = createActionName(DUCK_NAME, 'CLEAR_CURRENT_ACTION');
const FETCH_ACTIONS_BY_DEST = createActionName(DUCK_NAME, 'FETCH_ACTIONS_BY_DEST');
const CLEAR_ACTIONS = createActionName(DUCK_NAME, 'CLEAR_ACTIONS');

// Reducer
export default function (state = {}, action = {}) {
	switch (action.type) {
		case FETCH_ACTIONS: {
			return { ...state, actions: action.payload.data };
		}
		case FETCH_ACTION: {
			return { ...state, currentAction: action.payload.data };
		}
		case CLEAR_CURRENT_ACTION: {
			return { ...state, currentAction: undefined };
		}
		case FETCH_ACTIONS_BY_DEST: {
			return { ...state, actions: action.payload.data };
		}
		case CLEAR_ACTIONS: {
			return { ...state, actions: undefined };
		}
		default:
			return state;
	}
}

// Action Creators
export function fetchActions() {
	return dispatch => {
		axios.get(`${BASE_URL}/action`)
			.then(response => {
				dispatch({
					type: FETCH_ACTIONS,
					payload: response
				});
			})
			.catch(err => {
				console.error(err);
			});
	};
}

export function fetchActionsByDest(destType) {
	return dispatch => {
		axios.get(`${BASE_URL}/action/byType/${destType}`)
			.then(response => {
				dispatch({
					type: FETCH_ACTIONS,
					payload: response
				});
			})
			.catch(err => {
				console.error(err);
			});
	};
}

export function clearActions() {
	return {
		type: CLEAR_ACTIONS
	};
}

export function fetchAction(actionId) {
	return dispatch => {
		axios.get(`${BASE_URL}/action/${actionId}`)
			.then(response => {
				dispatch({
					type: FETCH_ACTION,
					payload: response
				});
			})
			.catch(err => {
				console.error(err);
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', `Unable to find Action with Id ${actionId}.`);
				}
			});
	};
}

export function createAction(action, to) {
	return dispatch => {
		axios.post(`${BASE_URL}/action`, action)
			.then(response => {
				dispatch({
					type: CREATE_ACTION,
					payload: response
				});
				toastr.success('Success', 'Action created');
				if (to) history.push(to);
			})
			.catch(err => {
				console.error(err);
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to create action.');
				}
			});
	};
}

export function updateAction(action, id, to) {
	return dispatch => {
		axios.put(`${BASE_URL}/action/${id}`, action)
			.then(response => {
				dispatch({
					type: UPDATE_ACTION,
					payload: response
				});
				toastr.success('Success', 'Action updated');
				if (to) history.push(to);
			})
			.catch(err => {
				console.error(err);
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to update action.');
				}
			});
	};
}

export function deleteAction(action, to) {
	const { Id, Name } = action;
	return dispatch => {
		axios.delete(`${BASE_URL}/action/${Id}`)
			.then(response => {
				dispatch({
					type: DELETE_ACTION,
					payload: response
				});

				toastr.success('Success', `${Name} deleted!`);

				if (to) history.push(to);
			})
			.catch(err => {
				console.error(err);
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to delete action.');
				}
			});
	};
}

export function clearCurrentAction() {
	return {
		type: CLEAR_CURRENT_ACTION
	};
}
