import moment from 'moment';

export const dateFormat = 'YYYY-MM-DD';
export const timeFormat = 'hh:mma';

export const checkValid = date => {
	return moment(date, dateFormat).isValid();
};

export const convertISOToForm = date => {
	const d = moment(date).utc();
	if (d.isValid()) {
		return d.format(dateFormat);
	}

	return date;
};

export const fieldsToTime = (hour, minute, meridiem) => {
	const time = moment(`${hour}:${minute}${meridiem}`, timeFormat);
	return time.format(timeFormat);
};

export const timeToFields = time => {
	const t = moment(time, timeFormat);
	return {
		hour: t.format('h'),
		minute: t.format('mm'),
		meridiem: t.format('a')
	};
};

export const convertHourToMilitary = civilianHour => {
	try {
		const hour = parseInt(civilianHour.match(/(\d)+/)[0]);
		const mod = civilianHour.match(/(\D)+/)[0];
		return mod === 'PM' ? hour + 12 : hour;
	} catch (e) {
		console.error(e);
		return undefined;
	}
};

export const convertMilitaryHourToCivilian = militaryHour => {
	try {
		let hour = parseInt(militaryHour);
		const mod = hour >= 12 && hour !== 24 ? 'PM' : 'AM';

		if (hour === 24) {
			hour = 0;
		} else if (hour > 12) {
			hour = hour - 12;
		}
		return `${hour}${mod}`;
	} catch (e) {
		console.error(e);
		return undefined;
	}
}
