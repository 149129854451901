import Axios from "axios";
import React from "react";
import { Component } from "react";
import { FaCheck } from "react-icons/lib/fa";
import { toastr } from "react-redux-toastr";
import { BASE_URL } from "../../ducks";
import ModalLauncher from "../utilities/modalLauncher";

const PREVIEW_WIDTH_HEIGHT = 64;
const IMAGE_GRID_HEIGHT = "220px";
const NUM_LOGO_ROWS_IN_PICKER = 3;


class MediaUploaderModal extends Component {
    constructor(props: PropTypes) {
        super(props);
        this.state = {
            uploadedFile: null,
            selectedFileUrl: null,
            uploadSuccess: false,
            loading: false,
            loadedImages: []
        }
    }

    handleFileUploadSelection(event) {
        this.setState({uploadedFile: event.target.files[0], uploadSuccess: false});
    }

    async handleFileUpload() {
        let {onSelect} = this.props;
        let {uploadedFile} = this.state;
        let finishedFile = uploadedFile;
        let upload = {
            fileName: finishedFile.name,
            fileData: Buffer.from(await finishedFile.arrayBuffer()).toString("base64")
        }
        let res = await Axios.post(`${BASE_URL}/fileUpload/uploadDemoImage`, upload, {headers: { "Content-Type": "application/json" }});
        if(res.status == 200) {
            this.setState({selectedFileUrl: res.data.url, uploadSuccess: true});
        }
        else {
            console.log(res.statusText);
            toastr.error("Failed", "File upload failed");
        }
    }

    selectExistingImage(url: String) {
        this.setState({selectedFileUrl: url, uploadSuccess: false});
    }

    handleSubmission() {
        const {selectedFileUrl} = this.state;
        const {onSubmit} = this.props;
        onSubmit(selectedFileUrl);
    }

    async componentDidMount() {
        const res = await Axios.get(`${BASE_URL}/fileUpload/getDemoImages`);
        if(res.status != 200) {
            toastr.error("Error", "Error loading existing images");
            return;
        }
        this.setState({
            loading: false,
            loadedImages: res.data.results
        });
    }

    render() {
        const {uploadedFile, selectedFileUrl, uploadSuccess, loading, loadedImages} = this.state;
        const parentColumn = {
            display: "flex",
            flexFlow: "column"
        }
        const imageGrid = {
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gridRowGap: "10px",
            gridColumnGap: "10px",
            overflow: "scroll",
            height: NUM_LOGO_ROWS_IN_PICKER * PREVIEW_WIDTH_HEIGHT
        }
        const imageSelection = {
            cursor: "pointer"
        }
        const selectedImage = {
            border: "2px solid green",
            objectFit: "contain"
        }
        const unselectedImage = {
            objectFit: "contain"
        }
        const fileButton = {
            display: "inline"
        }
        let children = null;
        if(loading) {
            children = (<div>Loading...</div>);
        }
        else {
            const imageSelects = [];
            for(const img of loadedImages) {
                imageSelects.push((
                    <span  key={img} style={imageSelection} onClick={(() => this.selectExistingImage(img)).bind(this)}>
                        <img style={selectedFileUrl == img ? selectedImage : unselectedImage} src={img} width={PREVIEW_WIDTH_HEIGHT} height={PREVIEW_WIDTH_HEIGHT}/>
                    </span>
                ))
            }
            children = (
            <div style={parentColumn}>
                <h2>
                    Please select an existing file
                </h2>
                <div style={imageGrid}>
                    {imageSelects}
                </div>
                <div>
                    <h2>
                    Or upload a new one
                    </h2>
                    <input accept="image/gif, image/png, image/jpeg" style={fileButton} type="file" onChange={this.handleFileUploadSelection.bind(this)}/>
                    <span>
                        <button type="button" disabled={uploadedFile == null || uploadSuccess} className="btn btn-primary" onClick={this.handleFileUpload.bind(this)}>Upload</button>
                        {uploadSuccess && <FaCheck/>}
                    </span>
                </div>
            </div>
            )
        }
        return (
            <ModalLauncher name="Select Media"
                        launchBtn={<button className="btn btn-primary" type="button">Select Media</button>}
                        actionBtn={<button className="btn btn-success" disabled={selectedFileUrl == null} onClick={this.handleSubmission.bind(this)}>Submit</button>}
                        height={500}
                        width={500} >
                {children}
            </ModalLauncher>
        )
    }
}

export default MediaUploaderModal;