import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../util/history';
import { Row, Col } from 'react-bootstrap';
import { FaPencil, FaPlusCircle } from 'react-icons/lib/fa';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { routes } from '../../util/constants.js';
import { tableCaretRender } from '../../util/tableUtil';
import CommonStyle, { mergeStyles } from '../../styles/commonStyles.js';
import PageHeading from '../utilities/pageHeading';
import PageContainer from '../utilities/pageContainer';
import IconBtn from '../utilities/iconBtn';
import Spinner from '../utilities/spinner';
import ReactTooltip from 'react-tooltip';
import { setCustomerIdEnabled, setCustomerId, setRefreshActions } from '../../ducks/auth.duck.js';
import { fetchCodesetsForManage, clearCodesets } from '../../ducks/codeset.duck';
import { fetchCampaigns } from '../../ducks/campaign.duck';
import CodesetImportModal from './codesetImportModal.jsx';


class CodesetManage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sortName: 'Label',
			sortOrder: 'asc'
		};
	}

	componentDidMount() {
		this.props.setRefreshActions([fetchCodesetsForManage]);
		this.props.fetchCodesetsForManage();
		this.props.setCustomerId(null);
		this.props.setCustomerIdEnabled(false);
		// this.props.fetchCampaigns();
	}

	componentWillUnmount() {
		this.props.clearCodesets();
	}

	onSortChange(sortName, sortOrder) {
		let newOrder = sortOrder;
		if (sortName !== this.state.sortName) {
			newOrder = 'asc';
		}

		this.setState({
			sortName,
			sortOrder: newOrder
		});
	}

	nameColumnFormatter(cell, row, rowIndex, formatExtraData) {
		const { permissions } = formatExtraData;
		const isCampaignViewer = permissions ? permissions.CampaignViews : null;
		return (
			<div style={CommonStyle.splitSection}>
				<div style={CommonStyle.clipSection}>{cell}</div>
				{isCampaignViewer &&
				<div data-tip data-for="editButton">
				{this.iconBtn(<FaPencil />, this.handleEditClick.bind(this, row.Id), '1', 'Edit Codeset')}
				</div>
				}
			</div>
		);
	}

	iconBtn(icon, handler, key, tooltip) {
		return (
			<span key={key} data-tip data-for={key}>
				<IconBtn
					key={key}
					handler={handler}
					icon={icon}
					/>
				<ReactTooltip id={key} effect="solid" delayShow={500}>
					<div>{tooltip}</div>
				</ReactTooltip>
			</span>
		);
	}

	handleEditClick(codesetId) {
		history.push(`${routes.codesetEdit}/${codesetId}`);
	}

	refreshPage() {
		this.props.fetchCodesetsForManage();
		this.forceUpdate();
	}

	render() {
		const { codesets, permissions, campaigns } = this.props;
		const isAdminViewer = permissions ? permissions.AdminView : null;
		const isCampaignCreator = permissions ? permissions.CampaignCreates : null;

		if (!isAdminViewer) {
			return (<Spinner size={12} />);
		}

		codesets && codesets.map(codeset => {
			codeset.campaignCount = 0;
			campaigns && campaigns.map(campaign => {
				if (campaign.CodesetId === codeset.Id) {
					codeset.campaignCount++;
				}
			});
		});

		return (
			<PageContainer>
				<PageHeading>
					Codesets
				</PageHeading>

				{codesets === undefined &&
					<Spinner />
				}

				{codesets !== undefined && codesets.length === 0 &&
					<h4 className="text-center">You do not currently have any created Codesets. Please speak with a Touchcode representative to create a new Codeset.</h4>
				}

				{codesets !== undefined && codesets.length > 0 &&
				<div>
					{isCampaignCreator &&
					<Row style={mergeStyles(CommonStyle.formRow, { marginLeft: 'auto', width: '260px' }) }>
						<Col xs={12} style={{ width: '130px' }}>
							<div style={CommonStyle.floatRight}>
								<CodesetImportModal
									handleSubmit={this.refreshPage.bind(this)}
								/>
							</div>
						</Col>
						<Col xs={12} style={{ width: '130px' }}>
							<div style={CommonStyle.floatRight}>
							<Link to={routes.codesetNew} className="btn btn-primary" style={CommonStyle.btn}>
								<FaPlusCircle /> New Codeset
							</Link>
							</div>
						</Col>
					</Row>
				 	}
					<Row>
						<BootstrapTable
							data={codesets}
							columns={[{
								dataField: 'Label',
								text: 'Codeset Label',
								formatter: this.nameColumnFormatter.bind(this),
								formatExtraData: { permissions },
								sort: true,
								sortCaret: tableCaretRender,
								filter: textFilter({ delay: 250 }),
								headerStyle: () => {
									const width = '250px';
									return {
										minWidth: width,
										width: width
									};
								}
							}, {
								dataField: 'codeCount',
								text: '# of Codes in Codeset',
								sort: true,
								sortCaret: tableCaretRender,
								headerStyle: () => {
									const width = '250px';
									return {
										minWidth: width,
										width: width
									};
								}
							}, {
								dataField: 'campaignCount',
								text: '# of Campaigns using this Codeset',
								sort: true,
								sortCaret: tableCaretRender,
								headerStyle: () => {
									const width = '250px';
									return {
										minWidth: width,
										width: width
									};
								}
							}, {
								dataField: 'Id',
								text: 'Codeset ID',
								hidden: true,
								sort: true
							}]}
							keyField="Id"
							bodyClasses="touchcodeTbody"
							options={{
								sortName: this.state.sortName,
								sortOrder: this.state.sortOrder,
								onSortChange: this.onSortChange.bind(this)
							}}
							filter={filterFactory()}
							striped
							hover
						/>
					</Row>
				</div>
				}
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	return {
		codesets: state.codeset.codesets,
		permissions: state.userRole.permissions,
		campaigns: state.campaign.campaigns
	};
}

CodesetManage = connect(mapStateToProps, {
	fetchCodesetsForManage,
	setCustomerIdEnabled,
	setRefreshActions,
	setCustomerId,
	fetchCampaigns,
	clearCodesets
})(CodesetManage);

export default CodesetManage;
