import React from 'react';
import { FaCaretUp, FaCaretDown } from 'react-icons/lib/fa';

export function tableCaretRender(order) {
	if (order === 'asc') {
		return <span> <FaCaretDown /></span>;
	}

	if (order === 'desc') {
		return <span> <FaCaretUp /></span>;
	}

	return null;
}
