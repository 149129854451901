import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { primaryLightBlue, primaryDarkBlue, primaryGreen } from '../../styles/commonStyles';
import { VictoryPie, VictoryLegend, VictoryContainer, VictoryTooltip } from 'victory';
import VictoryChartLabel from '../utilities/victoryChartLabel';
import Spinner from '../utilities/spinner';
import cs from '../../styles/commonStyles';

class AnalyticsUser extends Component {
	constructor(props) {
		super(props);
		VictoryChartLabel.defaultEvents = VictoryTooltip.defaultEvents;
		VictoryChartLabel.propTypes = { text: PropTypes.string };
	}

	formatEngagementsByUserType(engagementsByUsersType) {
		const data = [0, 0];
		const category = [0, 0, 0];

		for (const val of engagementsByUsersType.Engagements) {
			category[val.GroupCategory] = val.Engagements;
		}

		data[1] = category[1] + category[2];
		data[0] = this.props.totalReturningUsers;

		return data;
	}

	render() {
		const { engagementsByUserType } = this.props;
		if (engagementsByUserType === undefined || engagementsByUserType === null) {
			return (
				<Row>
					<Col style={{ textAlign: 'center', marginTop: '20%', paddingBottom: '20%' }}> <Spinner /> </Col>
				</Row>
			);
		}

		const userTypes = this.formatEngagementsByUserType(engagementsByUserType);
		return (
			<div style={{ display: 'inline-block' }} data-cy="new-vs-returning-users">
				<Row>
					<Col xs={12}>
						<h3 style={{ ...cs.textCenter }}>NEW vs RETURNING USERS</h3>
					</Col>
				</Row>
				<Row>
					<Col xs={4}>
						<VictoryLegend x={40} y={125}
							width={200}
							orientation="vertical"
							gutter={20}
							colorScale={[primaryLightBlue, primaryGreen, primaryDarkBlue]}
							data={[{ name: 'Returning' }, { name: 'New' }]}
							containerComponent={<VictoryContainer responsive />}
							style={{
								labels: {
									fontSize: 23
								}
							}}
						/>
					</Col>
					<Col xs={8}>
						<VictoryPie
							width={450}
							height={350}
							innerRadius={80}
							labelRadius={100}
							labels={d => d.y}
							data={[
								{ x: ' ', y: userTypes[0] },
								{ x: ' ', y: userTypes[1] }
							]}
							colorScale={[primaryLightBlue,primaryGreen,  primaryDarkBlue]}
							containerComponent={
								<VictoryContainer responsive />
							}
						/>
					</Col>
				</Row>
			</div>
		);
	}
}

export default AnalyticsUser;
