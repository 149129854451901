// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import fileDownload from 'js-file-download';
import moment from 'moment-timezone';
import ReactTooltip from 'react-tooltip';
import Rodal from 'rodal';
import { runTests, verifyFile, clearTestResults } from '../../ducks/test.duck.js';
import CommonStyle, { mergeStyles } from '../../styles/commonStyles';
import Spinner from '../utilities/spinner';

import 'rodal/lib/rodal.css';

const style = {
	modalStyle: {
		width: '50%',
		textAlign: 'left'
	},
	contentWrapper: {
		padding: '50px',
	},
	heading: {
		marginBottom: '20px'
	},
	btnRow: {
		marginTop: '35px'
	}
};

export class TestResultsModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			visible: false
		};

		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
		this.verifyFile = this.verifyFile.bind(this);
	}

	componentWillUnmount() {
		this.props.clearTestResults();
	}

	show() {
		this.requestTestData();
		this.setState({ visible: true });
	}

	hide() {
		this.props.clearTestResults();
		this.setState({ visible: false });
	}

	requestTestData(event) {
		if (event) event.preventDefault();
		const { codeset, codes, decodingConfig } = this.props;

		const tag = moment();
		this.setState({ expectedTag: tag });
		this.props.runTests(decodingConfig, codes, '' + codeset, tag);
		setTimeout(this.verifyFile, 2000);
	}

	verifyFile() {
		const { file, fileLocked } = this.props;
		const { expectedTag } = this.state;
		if (fileLocked === false) {
			return;
		}
		if (file !== undefined && expectedTag !== undefined && file[expectedTag] !== undefined) {
			this.props.verifyFile(expectedTag, file[expectedTag]);
		}
		setTimeout(this.verifyFile, 1000);
	}

	renderTestResults() {
		const { testResults } = this.props;
		const { expectedTag } = this.state;
		if (!testResults || !expectedTag || !testResults[expectedTag]) {
			return (<div style={{ marginLeft: '15px' }}><br/><Spinner/></div>);
		}

		const resultList = testResults[expectedTag];
		const successCount = {};
		const falseCount = {};
		const noCount = {};
		const notDecodableCount = {};
		const decoders = [];
		if (resultList && resultList.length > 0) {
			resultList.forEach(test => {
				JSON.parse(test).decoderResults.forEach(result => {
					if (!decoders.includes(result.decoderName)) {
						decoders.push(result.decoderName);
					}
					if (result.status === 'SUCCESS') {
						successCount[result.decoderName] = successCount[result.decoderName] ? successCount[result.decoderName] + 1 : 1;
					} else if (result.status === 'FALSE POSITIVE') {
						falseCount[result.decoderName] = falseCount[result.decoderName] ? falseCount[result.decoderName] + 1 : 1;
					} else if (result.status === 'NO DECODE') {
						noCount[result.decoderName] = noCount[result.decoderName] ? noCount[result.decoderName] + 1 : 1;
					} else if (result.status === 'NOT DECODABLE') {
						notDecodableCount[result.decoderName] = notDecodableCount[result.decoderName] ? notDecodableCount[result.decoderName] + 1 : 1;
					}
				});
			});
			const resultOutput = [];
			decoders.forEach(d => {
				resultOutput.push(
					<li key={d}>
						<strong>{d}</strong><br/>
						<div style={{ marginLeft: '25px' }}>
							Success: {successCount[d] || 0} <br/>
							False Positive: {falseCount[d] || 0} <br/>
							No Decode: {noCount[d] || 0} <br/>
							Not Decodable: {notDecodableCount[d] || 0}
						</div>
					</li>
				);
			});

			return (
				<Col>
					{ resultOutput }
					<span style={{ cursor: 'pointer', 'color': 'blue', 'textDecoration': 'underline' }} onClick={fileDownload.bind(this, JSON.stringify(testResults[expectedTag], undefined, 2), 'testData.json')}>Download Full Test Data</span>
				</Col>
			);
		}

		return (
			<Col>
				<span>No Tests to Run</span>
			</Col>
		);
	}

	render() {
		const { visible } = this.state;

		return (
			<span>
				<span data-tip data-for="TestResultButton">
					<button onClick={this.show} style={mergeStyles(CommonStyle.btn, { fontSize: '14px', padding: '2px 9px', margin: '0px 5px', fontWeight: 'bold' })} className="btn btn-primary" ><span> T </span></button>
					<ReactTooltip id={'TestResultButton'} effect="solid" delayShow={500}>
						<div>Test Campaign Configurations</div>
					</ReactTooltip>
				</span>
				<Rodal
					visible={visible}
					height={300}
					showCloseButton={false}
					onClose={this.hide}
					customStyles={style.modalStyle}
				>
					<div style={style.contentWrapper}>
						<div style={{ textAlign: 'left', marginLeft: '-15px' }}>
							<h3 style={mergeStyles(style.heading)}>
								Test Results
							</h3>
						</div>
						<Row style={{ marginTop: '10px' }}>
							<div style={{ border: '1px solid black', padding: '10px', overflowY: 'scroll' }}>
								{this.renderTestResults()}
							</div>
						</Row>
						<Row style={{ width: '85px', marginTop: '10px', marginBottom: '-40px' }}>
							<button style={CommonStyle.btn} type="submit" className="btn btn-primary" onClick={e => { e.preventDefault(); this.hide(); }}>
								<span>Close</span>
							</button>
						</Row>
					</div>
				</Rodal>
			</span>
		);
	}
}

function mapStateToProps(state) {
	return {
		permissions: state.userRole.permissions,
		file: state.test.file,
		testResults: state.test.testResults,
		fileLocked: state.test.fileLocked,
	};
}

export default connect(mapStateToProps, {
	runTests,
	verifyFile,
	clearTestResults
})(TestResultsModal);
