import React, { Component } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { primaryLightBlue } from '../../styles/commonStyles';
import { Row, Col } from 'react-bootstrap';
import cs from '../../styles/commonStyles';

const CustomizedAxisTick = ({ x, y, payload }) => (
    <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)" style={{ cursor: 'pointer' }} >
            {payload.value}
        </text>
    </g>
);

class AnalyticsDateTimeBarGraph extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { title, data, dataKey, yAxisLabel, onClick } = this.props;
		return (
            <Col>
                <Row>
                    <Col xs={12}>
                        <h3 style={{ ...cs.textCenter }}>
                            {title}
                        </h3>
                    </Col>
                </Row>
                <ResponsiveContainer width="100%" height={335}>
                    <BarChart height={335} data={data}>
                        <XAxis dataKey={dataKey !== undefined ? dataKey : 'Date'} tick={<CustomizedAxisTick />} onClick={onClick} height={100} />
                        <YAxis label={{ value: yAxisLabel !== undefined ? yAxisLabel : 'Engagements', angle: -90, position: 'insideLeft' }} yAxisId="a" />
                        <Tooltip />
                        <Bar yAxisId="a" stackId="a" onClick={onClick} dataKey={ yAxisLabel !== undefined ? yAxisLabel : 'Engagements' } fill={primaryLightBlue} />
                    </BarChart>
                </ResponsiveContainer>
            </Col>
		);
	}
}

export default AnalyticsDateTimeBarGraph;
