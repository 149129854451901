import React, { Component } from 'react';
import _ from 'lodash';
import ConfirmModal from '../utilities/confirmModal';
import EditParameterModal from './editParameterModal';
import { FaTrash, FaPencil } from 'react-icons/lib/fa';
import { mergeStyles } from '../../styles/commonStyles';

const style = {
	chipWrapper: {
		display: 'inline-block',
		margin: '5px',
		width: '100%'
	},
	chip: {
		backgroundColor: '#ebf5ff',
		borderTop: '1px solid #b2d7fe',
		borderRight: '1px solid #b2d7fe',
		borderBottom: '1px solid #b2d7fe',
		display: 'inline-block',
		color: '#007eff',
		padding: '3px 6px',
	},
	chipDelete: {
		borderLeft: '1px solid #b2d7fe',
		borderRadius: '3px 0px 0px 3px',
		cursor: 'pointer',
		':hover': {
			backgroundColor: '#b2d7fe'
		}
	},
	chipText: {
		borderRadius: '0px 0px 0px 0px',
		borderLeft: 'none',
		borderRight: 'none',
		width: '70%',
	},
	chipEdit: {
		borderRadius: '0px 3px 3px 0px',
		borderLeft: '1px solid #b2d7fe',
		cursor: 'pointer',
		':hover': {
			backgroundColor: '#b2d7fe'
		}
	}
};

export class CampaignSelectedParameter extends Component {
	constructor(props) {
		super(props);

		const config = JSON.parse(props.parameter.Config);
		const key = Object.keys(config)[0];
		const name = key.split('_').map(_.capitalize).join(' ');

		this.state = {
			name: name,
			config: config
		};
		this.parameterConfigChangeHandler = this.parameterConfigChangeHandler.bind(this);
	}

	componentDidMount() {
		this.saveParameter();
	}

	removeParameter() {
		const config = this.getDefaultConfig(this.state.config);
		this.props.parameterSaveHandler(config);
		this.props.removeSelectedParameterHandler(this.props.parameter);
	}

	getDefaultConfig(parameterConfig) {
		let parameterValues = null;
		let parameter = null;
		if (parameterConfig) {
			parameter = Object.keys(parameterConfig)[0];
			const options = Object.keys(parameterConfig[parameter]);
			parameterValues = [];
			options.forEach(p => {
				parameterValues.push(parameterConfig[parameter][p].default);
			});
		}
		return {
			[parameter]: {
				Id: this.props.parameter.Id,
				Type: this.props.parameter.Type,
				values: parameterValues
			}
		};
	}

	saveParameter() {
		let config = null;
		if (this.state.parameterConfig) {
			const parameterConfigValues = [];
			const parameterConfigs = this.state.parameterConfig[Object.keys(this.state.parameterConfig)];
			parameterConfigs.forEach(p => {
				const key = Object.keys(p)[0];
				parameterConfigValues.push(p[key]);
			});
			config = {
				[Object.keys(this.state.config)[0]]: {
					Id: this.props.parameter.Id,
					Type: this.props.parameter.Type,
					values: parameterConfigValues
				}
			};
		} else {
			config = this.getDefaultConfig(this.state.config);
			if (this.props.parameter.values) {
				config[Object.keys(config)].values = this.props.parameter.values;
			}
		}
		this.props.parameterSaveHandler(config);
	}

	parameterConfigChangeHandler(parameterConfig) {
		this.setState({ parameterConfig });
	}

	renderParameterChip(id, removeParameter) {
		const title = 'Remove ' + id + '?';
		return (
			<div style={style.chipWrapper}>
				<ConfirmModal title={title}
					body="Are you sure you want to remove this parameter?"
					launchBtn={
						<div key={id} style={mergeStyles(style.chip, style.chipDelete)}>
							<FaTrash />
						</div>
					} closeBtnText="No"
					confirmBtn={
						<button className="btn btn-danger" onClick={removeParameter}>Yes</button>
					}
				/>
				<div style={mergeStyles(style.chip, style.chipText)}>{id}</div>
				<EditParameterModal
					launchBtn={
						<div style={mergeStyles(style.chip, style.chipEdit)}>
							<FaPencil />
						</div>
					}
					values={this.props.parameter.values}
					parameterConfig={this.state.config}
					parameterChangeHandler={this.parameterConfigChangeHandler}
					confirmFunc={this.saveParameter.bind(this)}
				/>
			</div>
		);
	}

	render() {
		return (<div> {this.renderParameterChip(this.state.name, this.removeParameter.bind(this))} </div>);
	}
}

export default CampaignSelectedParameter;
