import React, { Component } from 'react';
import { Field, reduxForm, touch } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import { required, actionDateRangeEnd } from '../../util/FormValidators';
import { formNames } from '../../util/constants';
import CommonStyle from '../../styles/commonStyles';
import DateRangePicker from '../utilities/dateRangePicker';
import moment from 'moment-timezone';

const formName = formNames.ACTION_EDIT;
const style = {
	formRow: {
		marginTop: '10px',
		marginBottom: '10px'
	}
};

const dateRangeEnd = (value, allValues) => {
	const endDate = moment(value);
	if (!allValues.rule || !allValues.rule.startDate) {
		return undefined;
	}
	const startDate = moment(allValues.rule.startDate);
	if (endDate.isBefore(startDate, 'day')) {
		return "End Date can't be before Start Date";
	}

	if (moment().isAfter(endDate, 'day')) {
		return 'End date must be at least today';
	}
	return undefined;
};

class ActionEditFormDate extends Component {
	constructor(props) {
		super(props);
	}

	touchStartDate() {
		this.props.dispatch(touch(formName, 'rule.startDate'));
	}

	touchEndDate() {
		this.props.dispatch(touch(formName, 'rule.endDate'));
	}

	render() {
		const bsResponsive = {
			xs: 12,
			sm: 6,
			md: 4,
			lg: 4
		};
		return (
			<div>
				<Row style={CommonStyle.formRow}>
					<DateRangePicker
						formName={this.props.formName}
						dispatch={this.props.dispatch}
						startDateValue={this.props.startDateValue}
						endDateValue={this.props.endDateValue}
						timezone={this.props.timezone}
						validate={dateRangeEnd}
						fieldPrefix={this.props.fieldPrefix}
					/>
				</Row>
			</div>
		);
	}
}

export default ActionEditFormDate;
