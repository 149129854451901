import moment from 'moment';

export const required = value => value !== undefined ? undefined : 'Required';

export const destinationRequired = (value, allValues) => {
	return (allValues.webDestination || allValues.appDestination) ? undefined : 'Please provide a Website and/or App Destination. ';
};

export const requiredForLaunch = value => value ? undefined : 'Required for Launch';

const maxLength = max => value => value && value.length > max ? `Cannot exceed ${max} characters` : undefined;

export const charLimit50 = maxLength(50);
export const charLimit20 = maxLength(20);
export const charLimit2  = maxLength(2);

const minValue = min => value => value && value < min ? `Must enter a value of greater than ${min}` : undefined;
const maxValue = max => value => value && value > max ? `Must enter a value less than  ${max}` : undefined;

export const minTimeOut = minValue(0.5);
export const maxTimeOut = maxValue(60);

export const onlyNumbers = value => value.replace(/[^0-9]/g, '');

export const onlyDecimal = value => {
	const newValue = value
		.replace(/[^0-9.]/g, ''); // Remove all chars except numbers and .

	// Create an array with sections split by .
	const sections = newValue.split('.');

	// Remove any leading 0s apart from single 0
	if (sections[0] !== '0' && sections[0] !== '00') {
		sections[0] = sections[0].replace(/^0+/, '');
	} else {
		sections[0] = '0';
	}

	// If numbers exist after first .
	if (sections[1]) {
		// Join first two sections and truncate end section to length 2
		return sections[0] + '.' + sections[1].slice(0, 4);
	// If original value had a decimal place at the end, add it back
	} else if (newValue.indexOf('.') !== -1) {
		return sections[0] + '.';
	// Otherwise, return only section
	}
	return sections[0];
};

export const email = value => {
	const emailRegex = new RegExp([
		'^(([^<>()[\\]\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\.,;:\\s@\"]+)*)',
		'|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.',
		'[0-9]{1,3}\])|(([a-zA-Z\\-0-9]+\\.)+',
		'[a-zA-Z]{2,}))$'
	].join(''));

	return emailRegex.test(value) ? undefined : 'Invalid Email';
};

export const password = value => {
	const errors = [];

	if (!/.{12}/.test(value)) 	errors.push('length');
	if (!/\d/.test(value)) 		errors.push('number');
	if (!/[A-Z]/.test(value)) errors.push('upper');
	if (!/[a-z]/.test(value)) errors.push('lower');
	if (!/[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/.test(value)) errors.push('symbol');

	return errors.length === 0 ? undefined : errors;
};

export const dateRangeStart = (value, allValues) => {
	const startDate = moment(value);
	const endDate = moment(allValues.endDate);

	if (allValues.endDate !== undefined && startDate.isAfter(endDate, 'day')) {
		return "End Date can't be before Start Date";
	}

	return undefined;
};

export const dateRangeEnd = (value, allValues) => {
	const endDate = moment(value);

	if (!allValues.startDate) {
		return undefined;
	}
	const startDate = moment(allValues.startDate);
	if (endDate.isBefore(startDate, 'day')) {
		return "End Date can't be before Start Date";
	}

	if (moment().isAfter(endDate, 'day')) {
		return 'End date must be at least today';
	}

	return undefined;
};

export const actionDateRangeEnd = (value, allValues) => {
	const endDate = moment(value);

	if (!allValues.rule.startDate) {
		return undefined;
	}

	const startDate = moment(allValues.rule.startDate);
	if (endDate.isBefore(startDate, 'day')) {
		return "End Date can't be before Start Date";
	}

	if (moment().isAfter(endDate, 'day')) {
		return 'End date must be at least today';
	}

	return undefined;
};
