// @flow

import { createActionName, BASE_URL } from './index';
import axios from 'axios';
import update from 'immutability-helper';
import _ from 'lodash';
import history from '../util/history';
import { toastr } from 'react-redux-toastr';
import { LS, routes } from '../util/constants';

// Actions
const DUCK_NAME = 'campaign';
export const CREATE_CAMPAIGN: string = createActionName(DUCK_NAME, 'CREATE_CAMPAIGN');
export const FETCH_CAMPAIGN: string = createActionName(DUCK_NAME, 'FETCH_CAMPAIGN');
export const FETCH_CAMPAIGNS: string = createActionName(DUCK_NAME, 'FETCH_CAMPAIGNS');
export const UPDATE_CAMPAIGN: string = createActionName(DUCK_NAME, 'UPDATE_CAMPAIGN');
export const AMEND_CAMPAIGN: string = createActionName(DUCK_NAME, 'AMEND_CAMPAIGN');
export const DELETE_CAMPAIGN: string = createActionName(DUCK_NAME, 'DELETE_CAMPAIGN');
export const FETCH_CAMPAIGNS_BY_CODESET: string = createActionName(DUCK_NAME, 'FETCH_CAMPAIGNS_BY_CODESET');
export const CLEAR_CAMPAIGNS_BY_CODESET: string = createActionName(DUCK_NAME, 'CLEAR_CAMPAIGNS_BY_CODESET');
export const CLEAR_CURRENT_CAMPAIGN: string = createActionName(DUCK_NAME, 'CLEAR_CURRENT_CAMPAIGN');
export const SELECT_CAMPAIGN: string = createActionName(DUCK_NAME, 'SELECT_CAMPAIGN');
export const NEW_CAMPAIGN: string = createActionName(DUCK_NAME, 'NEW_CAMPIAGN');
export const UPDATE_CURRENT_CAMPAIGN_TOUCHCODE: string = createActionName(DUCK_NAME, 'UPDATE_CURRENT_CAMPAIGN_TOUCHCODES');
export const UPDATE_CURRENT_CAMPAIGN_CODESET: string = createActionName(DUCK_NAME, 'UPDATE_CURRENT_CAMPAIGN_CODESET');
export const REMOVE_CURRENT_CAMPAIGN_TOUCHCODE: string = createActionName(DUCK_NAME, 'REMOVE_CURRENT_CAMPAIGN_TOUCHCODE');
export const CLEAR_CURRENT_CAMPAIGN_TOUCHCODES: string = createActionName(DUCK_NAME, 'CLEAR_CURRENT_CAMPAIGN_TOUCHCODES');
export const SET_SELECTED_TOUCHCODES: string = createActionName(DUCK_NAME, 'SET_SELECTED_TOUCHCODES');
export const SAVE_WORK_IN_PROGRESS: string = createActionName(DUCK_NAME, 'SAVE_WORK_IN_PROGRESS');
export const CLEAR_WORK_IN_PROGRESS: string = createActionName(DUCK_NAME, 'CLEAR_WORK_IN_PROGRESS');
export const FETCH_CAMPAIGN_ERROR: string = createActionName(DUCK_NAME, 'FETCH_CAMPAIGN_ERROR');
export const CLEAR_CODESET_FORM = createActionName(DUCK_NAME, 'CLEAR_FORM_STATE');
export const CLEAR_DEFAULT_TARGETS = createActionName(DUCK_NAME, 'CLEAR_DEFAULT_TARGETS');
export const CLEAR_SELECTED_TOUCHCODES = createActionName(DUCK_NAME, 'CLEAR_SELECTED_TOUCHCODES');
export const CLEAR_CAMPAIGNS = createActionName(DUCK_NAME, 'CLEAR_CAMPAIGNS');
export const UPDATE_SELECTED_DECODER_PARAMETERS = createActionName(DUCK_NAME, 'UPDATE_SELECTED_DECODER_PARAMETERS');
export const REMOVE_SELECTED_DECODER_PARAMETER = createActionName(DUCK_NAME, 'REMOVE_SELECTED_DECODER_PARAMETER');
export const UPDATE_PARAMETER_VALUE = createActionName(DUCK_NAME, 'UPDATE_PARAMETER_VALUE');
export const GET_CAMPAIGN_FOR_EXPORT = createActionName(DUCK_NAME, 'GET_CAMPAIGN_FOR_EXPORT');
export const REMOVE_CAMPAIGN_FOR_EXPORT = createActionName(DUCK_NAME, 'REMOVE_CAMPAIGN_FOR_EXPORT');

// Reducer
export default function reducer(state: Object = {}, action: Object = {}) {
	switch (action.type) {
		case CREATE_CAMPAIGN: {
			return state;
		}
		case FETCH_CAMPAIGN: {
			return { ...state, currentCampaign: { ...action.payload.data, selectedTouchcodes: [], selectedDecoderParameters: [] } };
		}
		case FETCH_CAMPAIGN_ERROR: {
			return { ...state, campaignError: action.message };
		}
		case FETCH_CAMPAIGNS: {
			return { ...state, 'campaigns': action.payload.data };
		}
		case UPDATE_SELECTED_DECODER_PARAMETERS: {
			const selectedDecoderParameters = state.currentCampaign.selectedDecoderParameters ? [...state.currentCampaign.selectedDecoderParameters] : [];
			const parameterTypes = selectedDecoderParameters[action.payload.Type] ? selectedDecoderParameters[action.payload.Type] : [];
			selectedDecoderParameters[action.payload.Type] = [...parameterTypes, action.payload];
			return { ...state, currentCampaign: { ...state.currentCampaign, 'selectedDecoderParameters': selectedDecoderParameters } };
		}
		case REMOVE_SELECTED_DECODER_PARAMETER: {
			const selectedDecoderParameters = state.currentCampaign.selectedDecoderParameters ? [...state.currentCampaign.selectedDecoderParameters] : [];
			const newSelection = [];
			selectedDecoderParameters[action.payload.Type].forEach(s => {
				if (s.Id !== action.payload.Id) {
					newSelection.push(s);
				}
			});
			selectedDecoderParameters[action.payload.Type] = [...newSelection];
			return { ...state, currentCampaign: { ...state.currentCampaign, 'selectedDecoderParameters': selectedDecoderParameters } };
		}
		case UPDATE_PARAMETER_VALUE:
			const id = action.payload[Object.keys(action.payload)[0]].Id;
			const type = action.payload[Object.keys(action.payload)[0]].Type;
			const values = action.payload[Object.keys(action.payload)[0]].values;
			const parameters = state.currentCampaign.selectedDecoderParameters;
			const newParameters = [];
			const parameterType = state.currentCampaign.selectedDecoderParameters[type];
			const newParameterType = [];

			if (parameterType) {
				parameterType.forEach(p => {
					if (id === p.Id) {
						p.values = values;
					}
					newParameterType.push(p);
				});
			}

			for (let i = 0; i < parameters.length; i++) {
				if (parameters[i] && parameters[i][0]) {
					const selectedParameterType = parameters[i][0].Type;
					if (type === selectedParameterType) {
						newParameters.push(newParameterType);
					} else {
						newParameters.push(parameters[i]);
					}
				} else {
					if (i !== type) {
						newParameters.push([]);
					} else {
						newParameters.push(newParameterType);
					}
				}
			}

			return { ...state, currentCampaign: { ...state.currentCampaign, 'selectedDecoderParameters': newParameters } };
		case UPDATE_CAMPAIGN: {
			return state;
		}
		case AMEND_CAMPAIGN: {
			return state;
		}
		case DELETE_CAMPAIGN: {
			return state;
		}
		case FETCH_CAMPAIGNS_BY_CODESET: {
			const currentCampaignId = state.currentCampaign ? state.currentCampaign.Id : '';
			const campaigns = action.payload.data.filter(obj => obj.Id !== currentCampaignId);
			return update(state, {
				campaignsByCodeset: { $set: campaigns }
			});
		}
		case CLEAR_CAMPAIGNS_BY_CODESET: {
			return update(state, {
				campaignsByCodeset: { $set: undefined }
			});
		}
		case NEW_CAMPAIGN: {
			return { ...state, 'currentCampaign': { Touchcodes: {}, DefaultTargets: {}, Codeset: { Touchcodes: [] }, selectedTouchcodes: [] } };
		}
		case UPDATE_CURRENT_CAMPAIGN_TOUCHCODE: {
			return update(state, {
				currentCampaign: {
					Touchcodes: {
						[action.touchcodeId]: { $set: action.selectedActions }
					},
					DefaultTargets: {
						[action.touchcodeId]: { $set: action.defaultTarget }
					}
				}
			});
		}
		case REMOVE_CURRENT_CAMPAIGN_TOUCHCODE: {
			const newTouchcodes = _.omit(state.currentCampaign.Touchcodes, action.touchcodeId);
			const newDefaultTargets = _.omit(state.currentCampaign.DefaultTargets, action.touchcodeId);
			return update(state, {
				currentCampaign: {
					Touchcodes: { $set: newTouchcodes },
					DefaultTargets: { $set: newDefaultTargets }
				}
			});
		}
		case UPDATE_CURRENT_CAMPAIGN_CODESET: {
			return update(state, {
				currentCampaign: {
					Codeset: { $set: action.codeset }
				}
			});
		}
		case CLEAR_CURRENT_CAMPAIGN_TOUCHCODES: {
			return update(state, {
				currentCampaign: {
					Touchcodes: { $set: {} }
				}
			});
		}
		case CLEAR_CURRENT_CAMPAIGN: {
			return { ...state, 'currentCampaign': undefined };
		}
		case CLEAR_CAMPAIGNS: {
			return { ...state, 'campaigns': undefined };
		}
		case SET_SELECTED_TOUCHCODES: {
			return update(state, {
				currentCampaign: {
					selectedTouchcodes: { $set: action.selectedTouchcodes }
				}
			});
		}
		case SAVE_WORK_IN_PROGRESS: {
			let newState = state;

			if (!newState.workInProgress) {
				newState.workInProgress = {};
			}

			const { campaignId, formValues, touchcodes } = action;
			newState = update(state, {
				workInProgress: {
					[campaignId]: { $set: { formValues, touchcodes } }
				}
			});

			localStorage.setObject(LS.WIP, newState.workInProgress);
			return newState;
		}
		case CLEAR_WORK_IN_PROGRESS: {
			const newState = state;

			delete newState.workInProgress[action.campaignId];

			localStorage.setObject(LS.WIP, newState.workInProgress);
			return newState;
		}
		case CLEAR_CODESET_FORM: {
			return { ...state, clearForm: action.payload };
		}
		case CLEAR_SELECTED_TOUCHCODES: {
			return update(state, {
				currentCampaign: {
					selectedTouchcodes: { $set: [] }
				}
			});
		}
		case CLEAR_DEFAULT_TARGETS: {
			return update(state, {
				currentCampaign: {
					DefaultTargets: { $set: {} }
				}
			});
		}
		case GET_CAMPAIGN_FOR_EXPORT: {
			const campaignsForExport = state.campaignsForExport || {};
			campaignsForExport[action.payload.campaignId] = action.payload.response.data;
			return { ...state, campaignsForExport };
		}
		case REMOVE_CAMPAIGN_FOR_EXPORT: {
			const campaignsForExport = state.campaignsForExport || {};
			campaignsForExport[action.payload] = undefined;
			return { ...state, campaignsForExport };
		}
		default: return state;
	}
}

// Action Creators
export function createCampaign(campaign, to) {
	return dispatch => {
		axios.post(`${BASE_URL}/campaign`, campaign)
			.then(response => {
				dispatch({
					type: CREATE_CAMPAIGN,
					payload: response
				});
				toastr.success('Success', 'Campaign created');
				if (to) history.push(to);
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to create campaign.');
				}
			});
	};
}

export function fetchCampaign(campaignId) {
	return dispatch => {
		axios
			.get(`${BASE_URL}/campaign/${campaignId}`)
			.then(response => {
				dispatch({
					type: FETCH_CAMPAIGN,
					payload: response
				});
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', `Unable to find campaign with Id ${campaignId}.`);
				}
				dispatch({
					type: FETCH_CAMPAIGN_ERROR,
					message: err.response.data
				});
			});
	};
}

export function updateCampaign(campaign, id, to) {
	return dispatch => {
		axios.put(`${BASE_URL}/campaign/${id}`, campaign)
			.then(response => {
				dispatch({
					type: UPDATE_CAMPAIGN,
					payload: response
				});
				toastr.success('Success', 'Campaign updated');
				if (to) history.push(to);
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to update Campaign.');
				}
			});
	};
}

export function amendCampaign(params: Object, id: string, to: ?string, postAction: ?Function) {
	return dispatch => {
		axios.patch(`${BASE_URL}/campaign/${id}`, params)
			.then(response => {
				dispatch({
					type: AMEND_CAMPAIGN,
					payload: response
				});

				if (postAction) {
					dispatch(postAction());
				}
				toastr.success('Success', 'Campaign updated');
				if (to) history.push(to);
			})
			.catch(err => {
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to update Campaign.');
				}
			});
	};
}

export function deleteCampaign(id, name, to) {
	return dispatch => {
		axios.delete(`${BASE_URL}/campaign/${id}`)
			.then(response => {
				dispatch({
					type: DELETE_CAMPAIGN,
					payload: response
				});
			})
			.then(() => {
				if (to) history.push(to);
				toastr.success('Success', `${name} deleted!`);
			})
			.catch(err => {
				toastr.error('Error');
				if (to) history.push(to);
				if (!err || (err.response && err.response.status !== 401)) {
					toastr.error('Error', 'Unable to delete Campaign.');
				}
			});
	};
}

export function fetchCampaigns(removeDeleted) {
	const routeURL = removeDeleted ? 'campaign/removeDeleted' : 'campaign/';
	return dispatch => {
		axios.get(`${BASE_URL}/${routeURL}`)
			.then(response => {
				dispatch({
					type: FETCH_CAMPAIGNS,
					payload: response
				});
			})
			.catch(err => {
				// TODO: Implement me
			});
	};
}

export function fetchCampaignsByCodeset(codesetId) {
	return dispatch => {
		axios.get(`${BASE_URL}/campaign/byCodeset/${codesetId}`)
			.then(response => {
				dispatch({
					type: FETCH_CAMPAIGNS_BY_CODESET,
					payload: response
				});
			})
			.catch(err => {
				// TODO: Implement me
			});
	};
}

export function clearCampaigns() {
	return {
		type: CLEAR_CAMPAIGNS
	};
}
export function clearCampaignsByCodeset() {
	return {
		type: CLEAR_CAMPAIGNS_BY_CODESET
	};
}

export function newCampaign() {
	return {
		type: NEW_CAMPAIGN
	};
}

export function updateCurrentCampaignTouchcode(touchcodeId, selectedActions, defaultTarget) {
	return {
		type: UPDATE_CURRENT_CAMPAIGN_TOUCHCODE,
		touchcodeId,
		selectedActions,
		defaultTarget
	};
}

export function removeCurrentCampaignTouchcode(touchcodeId) {
	return {
		type: REMOVE_CURRENT_CAMPAIGN_TOUCHCODE,
		touchcodeId
	};
}

export function updateCurrentCampaignCodeset(codeset) {
	return {
		type: UPDATE_CURRENT_CAMPAIGN_CODESET,
		codeset
	};
}

export function clearCurrentCampaignTouchcodes() {
	return {
		type: CLEAR_CURRENT_CAMPAIGN_TOUCHCODES
	};
}

export function clearCurrentCampaign() {
	return {
		type: CLEAR_CURRENT_CAMPAIGN
	};
}

export function setSelectedTouchcodes(selectedTouchcodes) {
	return {
		type: SET_SELECTED_TOUCHCODES,
		selectedTouchcodes
	};
}

export function saveWorkInProgress(campaignId, formValues, touchcodes) {
	return {
		type: SAVE_WORK_IN_PROGRESS,
		campaignId,
		formValues,
		touchcodes
	};
}

export function clearWorkInProgress(campaignId) {
	return {
		type: CLEAR_WORK_IN_PROGRESS,
		campaignId
	};
}

export function clearFetchCampaignError() {
	return {
		type: FETCH_CAMPAIGN_ERROR,
		message: null
	};
}

export function clearCodesetForm(clear = true) {
	return {
		type: CLEAR_CODESET_FORM,
		payload: clear
	};
}

export function clearSelectedTouchcodes() {
	return {
		type: CLEAR_SELECTED_TOUCHCODES
	};
}

export function clearDefaultTargets() {
	return {
		type: CLEAR_DEFAULT_TARGETS
	};
}

export function updateSelectedDecoderParameters(parameters) {
	return {
		type: UPDATE_SELECTED_DECODER_PARAMETERS,
		payload: parameters
	};
}

export function removeSelectedDecoderParameter(parameter) {
	return {
		type: REMOVE_SELECTED_DECODER_PARAMETER,
		payload: parameter
	};
}

export function updateParameterValue(parameter) {
	return {
		type: UPDATE_PARAMETER_VALUE,
		payload: parameter
	};
}

export function getCampaignForExport(campaignId) {
	return dispatch => {
		axios.get(`${BASE_URL}/campaign/forExport/${campaignId}`)
			.then(response => {
				dispatch({
					type: GET_CAMPAIGN_FOR_EXPORT,
					payload: {
						response,
						campaignId
					}
				});
			})
			.catch(err => {
				if (err.response && err.response.status === 403) {
					toastr.error('Error', 'Insufficient permissions.');
					history.push(routes.authExpired);
				}
			});
	};
}

export function removeCampaignForExport(campaignId) {
	return {
		type: REMOVE_CAMPAIGN_FOR_EXPORT,
		payload: campaignId
	};
}
// Selectors
