import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import history from '../../util/history';
import { toastr } from 'react-redux-toastr';
import ActionEditForm from './actionEditForm';
import PageHeading from '../utilities/pageHeading';
import PageContainer from '../utilities/pageContainer';
import { fetchAction, clearCurrentAction } from '../../ducks/action.duck';
import { routes } from '../../util/constants.js';
import Spinner from '../utilities/spinner';

class ActionEdit extends Component {
	static propTypes = {
		// React Router
		params: PropTypes.object,
		// Redux Store
		action: PropTypes.object,
		customerId: PropTypes.number,
		// Wrapped Actions
		fetchAction: PropTypes.func,
		clearCurrentAction: PropTypes.func
	}

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const { match, permissions, customerId } = this.props;
		const isAdminViewer = permissions ? permissions.AdminView : null;

		if (isAdminViewer && customerId == null) {
			toastr.error('No Customer Selected', 'Select a Customer from the dropdown at the top of the page');
			history.push(routes.actionManage);
		} else {
			this.props.fetchAction(match.params.id);
		}
	}

	componentWillUnmount() {
		this.props.clearCurrentAction();
	}

	render() {
		const { action } = this.props;

		return (
			<PageContainer>
				<PageHeading>
					Edit Action
				</PageHeading>

				{action && <ActionEditForm />}
				{!action && <Spinner />}
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	return {
		action: state.action.currentAction,
		customerId: state.auth.customerId,
		permissions: state.userRole.permissions
	};
}

function mapDispatchToProps(dispatch) {
	const actions = bindActionCreators(
		{ fetchAction, clearCurrentAction },
		dispatch
	);
	return { ...actions, dispatch };
}

ActionEdit = connect(mapStateToProps, mapDispatchToProps)(ActionEdit);

export default ActionEdit;
