import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import  { newCampaign, clearCurrentCampaign } from '../../ducks/campaign.duck.js';
import { setCustomerId, setCustomerIdEnabled } from '../../ducks/auth.duck';
import PageHeading from '../utilities/pageHeading';
import PageContainer from '../utilities/pageContainer';
import CampaignEditForm from './campaignEditForm';
import Spinner from '../utilities/spinner';

class CampaignNew extends Component {
	static propTypes = {
		// Redux Store
		campaign: PropTypes.object,
		// Wrapped Actions
		fetchCodesets: PropTypes.func,
		newCampaign: PropTypes.func,
		clearCurrentCampaign: PropTypes.func
	}

	constructor(props) {
		super(props);
		this.state = { title: undefined };
	}

	componentDidMount() {
		this.props.newCampaign();
	}

	componentWillUnmount() {
		this.props.setCustomerIdEnabled(true);
		this.props.clearCurrentCampaign();
	}

	setTitle(newTitle) {
		this.setState({ title: newTitle });
	}

	render() {
		const { campaign } = this.props;
		const { title } = this.state;
		return (
			<PageContainer>
				<PageHeading>
					{title ? title : 'Create a New Campaign' }
				</PageHeading>
				{campaign && <CampaignEditForm isNew campaign={campaign} setTitle={this.setTitle.bind(this)}/>}
				{!campaign && <Spinner />}
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	return {
		campaign: state.campaign.currentCampaign,
	};
}

CampaignNew = connect(mapStateToProps, {
	newCampaign,
	clearCurrentCampaign,
	setCustomerId,
	setCustomerIdEnabled
})(CampaignNew);

export default CampaignNew;
