import PropTypes from 'prop-types';
import React, { Component } from 'react';

const style = {
	block: {
		marginLeft: '10px',
		marginBottom: '50px'
	},
	heading: {
		color: 'black',
		display: 'inline',
		fontFamily: '"Raleway", "Helvetica", "Arial", sans-serif',
		fontSize: '30px'
	},
	iconSection: {
		float: 'right'
	}
};

class PageHeading extends Component {
	static propTypes = {
		children: PropTypes.node,
		iconSection: PropTypes.element
	};

	constructor(props) {
		super(props);
	}

	render() {
		const { children, iconSection } = this.props;
		return (
			<div style={style.block}>
				<h2 style={style.heading}>
					{children}
				</h2>
				<span style={style.iconSection}>
					{iconSection}
				</span>
			</div>
		);
	}
}

export default PageHeading;
