import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import Card from './dragAndDropCard';

const style = {
	container: {
		width: '100%',
		height: '200px',
		border: '1px solid #cccccc',
		borderRadius: '5px',
		overflowY: 'scroll'
	}
};

class DragAndDropContainer extends Component {
	static propTypes = {
		// From Parent
		list: PropTypes.array,
		pushCard: PropTypes.func,
		removeCard: PropTypes.func,
		moveCard: PropTypes.func,
		id: PropTypes.string,
		emptyMessage: PropTypes.string,
		// React DND
		isSelectContainer: PropTypes.bool,
		canDrop: PropTypes.bool,
		isOver: PropTypes.bool,
		connectDropTarget: PropTypes.func,
	}

	constructor(props) {
		super(props);
		this.state = { cards: props.list };
	}

	pushCard(id, card) {
		this.props.pushCard(id, card);
	}

	removeCard(id, index) {
		this.props.removeCard(id, index);
	}

	moveCard(id, dragIndex, hoverIndex) {
		this.props.moveCard(id, dragIndex, hoverIndex);
	}

	render() {
		const { canDrop, isOver, connectDropTarget, list, emptyMessage } = this.props;
		const isActive = canDrop && isOver;

		const backgroundColor = isActive ? '#F8F8F8' : '#FFF';

		return connectDropTarget(
			<div style={{ ...style.container, backgroundColor }}>
				{list.length > 0 ? (
					list.map((card, i) => {
						return (
							<Card
								key={card.id}
								index={i}
								listId={this.props.id}
								card={card}
								removeCard={this.removeCard.bind(this)}
								moveCard={this.moveCard.bind(this)} />
						);
					}))
					: (
						<div>
							{emptyMessage}
						</div>
					)
				}
			</div>
		);
	}
}

const cardTarget = {
	drop(props, monitor, component ) {
		const { id } = props;
		const sourceObj = monitor.getItem();
		if ( id !== sourceObj.listId ) component.pushCard(component.props.id, sourceObj.card);
		return {
			listId: id
		};
	}
};

DragAndDropContainer = DropTarget('CARD', cardTarget, (connector, monitor) => ({
	connectDropTarget: connector.dropTarget(),
	isOver: monitor.isOver(),
	canDrop: monitor.canDrop()
}))(DragAndDropContainer);

export default DragAndDropContainer;
